import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { ActivityTypes, ActivityTypeInfos } from './Constants.jsx';
import { SelectInput } from 'orfeo_common/Widgets.jsx'

/** Helper to always use the color preview when using activity status choice */
const ActivityStatusInput = props => {
    return (
        <SelectInput
            backendURL="/backend/activitystatus/" required
            className={props.className}
            disabled={props.isReadOnly}
            value={props.value} onChange={props.onChange}
            objectRepresentation={x =>
                <div>
                    <span className="color-block" style={{'backgroundColor': x.color.code, 'marginTop': '4px'}}></span> {x.name}
                </div>
            }
        />
    )
}


const ActivityTypesDropdownChoices = props => {
    const [expanded, setExpanded] = useState(false);
    const [selection, setSelection] = useState(props.value);

    // Attach a listener whenever the dropdown is opened to close it if we're cliking everywhere else
    useEffect(() => {
        if(expanded) {
            setSelection(props.value);
            let handler = document.addEventListener('click', function (ev) {
                if(!ev.target.closest('div.dropdown-types-choices'))
                    setExpanded(false);
            })

            return () => document.removeEventListener('click', handler);
        }
    }, [expanded]);

    const onSelection = valSwitched => {
        let newSelection = selection.slice();
        let pos = newSelection.indexOf(valSwitched);
        if(pos === -1)
            newSelection.push(valSwitched)
        else
            newSelection.splice(pos, 1)
        setSelection(newSelection);
    }

    const onApply = ev => {
        props.onChange(selection);
        setExpanded(false);
    }

    let label;
    if(props.value.length == 1) {
        let infos = ActivityTypeInfos[props.value[0]];
        label = infos.label_plural;
    }
    else
        label = trans.t("{{types_count}} types d'activité", {types_count: props.value.length});

    return (
        <div className="input-group dropdown-types-choices">
            <div>
                <span className="type-filter" onClick={() => setExpanded(!expanded)}>
                    {label} <i className="fa fa-fw fa-angle-down"></i>
                </span>
            </div>
            {expanded &&
            <div className="selector">
                <ul>
                    {(props.choices || ActivityTypes).map(x => {
                        let selected = selection.indexOf(x) !== -1;
                        return (
                            <li onClick={() => onSelection(x)} key={x}>
                                <i className={classNames('fa', {
                                    'fa-check-square-o': selected,
                                    'fa-square-o': !selected
                                })} /> {ActivityTypeInfos[x].label_plural}
                            </li>
                        )
                    })}
                </ul>
                <button className="btn btn-link" onClick={onApply} disabled={selection.length == 0}>{trans.t("Appliquer")}</button>
            </div>
            }
        </div>
    );
};

export {
    ActivityStatusInput,
    ActivityTypesDropdownChoices
};
