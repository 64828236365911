import React, { useState, useEffect } from 'react';
import { fetchCsrfWrapper } from 'orfeo_common/react-base.jsx';

import PlanningEventProgramManager from 'orfeo/production/jsx/planningevent/PlanningEventProgramManager.jsx';

const PlanningEventProgramPanel = props => {
    const [programElements, setProgramElements] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const projectProgramElementPromise = fetchCsrfWrapper(
            '/backend/projectprogramelement/?project=' + props.event.project.pk
        );

        const eventProgramElementPromise = fetchCsrfWrapper(
            `/backend/planningevent/${props.event.pk}/program/`
        )

        Promise.all([projectProgramElementPromise, eventProgramElementPromise]).then(([ppe, epe]) => {
            setProgramElements(ppe);
            props.onFieldChange({
                'instrumentation_summary': epe['instrumentation_summary'],
                'program_elements': epe['program_elements']
            });
        }).catch(error => {
            alert(trans.t("Erreur lors de la récupération du programme"));
            console.error(error);
        }).finally(() => setLoading(false));
    }, []);

    if (loading) {
        return <div className="d-flex justify-content-center mt-3">
            <div className="spinner-border" role="status"><span className="visually-hidden">{trans.t("Chargement...")}</span></div>
        </div>
    }

    if (!programElements.length || !props.event.program_elements) {
        return <div className="d-flex justify-content-center mt-3">
            <p>{trans.t("Pas de programme défini pour cet élément de planning")}</p>
        </div>
    }

    return (
        <PlanningEventProgramManager 
            program_elements={programElements}
            obj={props.event}
            onFieldChange={props.onFieldChange}
        />
    );
}

export default PlanningEventProgramPanel;