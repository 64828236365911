import React, {useState, useEffect, useRef}from 'react';
import moment from 'moment';

import * as FormUtils from 'orfeo_common/FormUtils.jsx';
import { CalendarInput, SelectInput } from 'orfeo_common/Widgets.jsx';

import ProjectAddFormWrapper from 'orfeo/production/jsx/project/ProjectAddForm.jsx';
import ProjectAddFormCommonFields from 'orfeo/production/jsx/project/ProjectAddFormCommonFields.jsx';

const ProjectAddFormFields = props => {
    const title = useRef();
    useEffect(() => {
        setTimeout(() => {if (title.current) title.current.focus()});
    }, [])

    return (<div>
        <div className={"mb-3 " + props.isInError('title')}>
            <input
                type="text" className="form-control input-sm" ref={title} placeholder={trans.t("Nom")}
                onChange={ev => props.onFieldChange('title', ev.target.value)}
            />
        </div>
        <div className="mb-3">
            <ProjectAddFormCommonFields
                initials={props.initials} eventType={props.eventType} showTime={props.eventType === 'other'}
                onFieldChange={props.onFieldChange} isInError={props.isInError}
                canEditTimeFields={props.canEditTimeFields}
                data={props.data}
                onSelectTemplate={props.onSelectTemplate}
            />
        </div>

        <div className={"mb-3" + props.isInError('venue')}>
            <SelectInput
                key="calendar"
                backendURL="/backend/calendar/?type=internal" initialLoad
                value={props.data['calendar.pk']}
                placeholder={trans.t("Calendrier")}
                onChange={data => props.onFieldChange('calendar.pk', data ? data.pk : null)}
            />
            <FormUtils.ErrorText errors={props.errors} field={"calendar"} />
        </div>
    </div>
    );
}

const ProjectAddForm = ProjectAddFormWrapper(
    ProjectAddFormFields,
    '/backend/project/'
)

export default ProjectAddForm;
