import { GenericListStoreFactory } from 'orfeo_common/react-base.jsx';

/**
 *  STORE DEFINITION
 *
 * Defines a list of employees with their engagement status. The list contains a series of
 * employees with possibly an engagement, or pre-engagement data in the case it's not
 * formalized yet.
 * An employee might be listed several times in the case of a multi-project view. The tuple
 * (employee, project) must be unique though.
 * [
 *      {'employee': A, pre_engagement: {start_date: ..., end_date: ...}, engagement: null},
 *      {'employee': B, pre_engagement: null, engagement: {...}},
 *      {'employee': C, pre_engagement: null, engagement: {...}},
 * ]
 */
const EngagementsStore = GenericListStoreFactory('uid');

EngagementsStore.setEngagementForUID = (uid, data) => {
    let item = EngagementsStore.get(uid);
    item.engagement = data;
    EngagementsStore.update(item);
}

/**
 * This function should be called whenever one engagement is updated to reflect the change
 * in the current store.
 */
EngagementsStore.updateSingleEngagement = (uid, data, action) => {
    if(action == 'delete') {
        EngagementsStore.setEngagementForUID(uid, null)
    }
    else if(data) {
        // When an engagement is being created, we need to update the uid so future actions
        // can rely on the correct one for later operations
        if(action == 'create') {
            let item = EngagementsStore.get(uid);
            item.uid = "eng-" + data.pk;
            item.engagement = data;
            uid = item.uid;
        }

        const projects = data.projects.map(x => x.pk);
        if(projects.length <= 1) {
            EngagementsStore.setEngagementForUID(uid, data)
        }
        else {
            for(let line of EngagementsStore.getList()) {
                if(
                    line.employee.pk === data.employee.pk
                    && projects.includes(line.project ? line.project.pk : null)
                    && (
                        line.employee.type == "artiste" ||
                        line.pre_engagement.profession?.pk === data.profession.pk
                    )
                ) {
                    EngagementsStore.setEngagementForUID(line.uid, data);
                }
            }
        }
    }
}

/**
 * Takes a list of engagements and set them in the existing store for the participants
 */
EngagementsStore.updateEngagements = engagements_updated => {
    if(!Array.isArray(engagements_updated)) {
        engagements_updated = [engagements_updated];
    }
    // Build list of items to update by appending them the new engagement
    let items_to_update = [];
    for(let engagement of engagements_updated) {
        // Get upper employee data dict
        let participant = EngagementsStore.get("eng-" + engagement.pk);
        if(!participant) {
            participant = EngagementsStore.get(`emp-${engagement.employee.pk}-${engagement.projects.length > 0 ? engagement.projects[0].pk : 'none'}`);
        }
        if(participant) {
            participant.engagement = engagement;
            items_to_update.push(participant)
        }
    }

    // Trigger only once the main update method
    EngagementsStore.update(items_to_update);
}
EngagementsStore.types = [
    ['no_engagement', trans.t('Engagements à établir'), '#d9534f'],
    ['to_establish', trans.t('Contrats à établir'), '#F0C03D'],
    ['to_generate', trans.t('Contrats à générer'), '#C3BE45'],
    ['to_sign', trans.t('Contrats à signer'), '#8CBB51'],
    ['signed', trans.t('Contrats signés'), '#4AB85C'],
    ['without_engagement', trans.t('Sans contrat'), '#337ab7'],
];

EngagementsStore.types_dict = {};
for(var i = 0, l = EngagementsStore.types.length; i < l; i++) {
    var type = EngagementsStore.types[i];
    EngagementsStore.types_dict[type[0]] = {'verbose_name': type[1], 'color': type[2]};
}

EngagementsStore.getTypeOf = function (item) {
    // No engagement steps
    if(item.without_engagement === true)
        return 'without_engagement';
    if(item.engagement == null)
        return 'no_engagement';

    // Generated document steps
    if(item.engagement.generated_document != null) {
        if(item.engagement.status[0] == 'signed')
            return 'signed';
        return 'to_sign';
    }

    // Pending engagement/doc steps
    if(item.engagement.document_body == '')
        return 'to_establish';
    return 'to_generate';
};

export default EngagementsStore;
