/**
 * Dedicated modal form to create a project and returns the result to a
 * parent object.
 */
'use strict';

import React, {useRef, useEffect, useState} from 'react';
import { createRoot } from 'react-dom/client'

import { getModalWrapper, AjaxNotification, hasPerm } from 'orfeo_common/react-base.jsx';
import { triggerMatomoEvent } from 'orfeo_common/utils/script.jsx';

import * as FormUtils from 'orfeo_common/FormUtils.jsx';
import Modal from 'orfeo_common/Modal.jsx';

const ProjectTitleForm = props => {
    const initials = props.initials || {};
    const [title, setTitle] = useState(initials.title || "");

    const onSave = event => {
        event.preventDefault();
        props.onModalClose({title: title})
    }

    return (
        <Modal show onHide={ev => props.onModalClose(null)}>
            <form className="modal-content" onSubmit={onSave}>
                <Modal.Header>Titre</Modal.Header>
                <Modal.Body>
                    <p>
                        <input
                            type="text" className="form-control" tabIndex="1"
                            value={title} placeholder={trans.t("Titre")} onChange={ev => setTitle(ev.target.value)}
                        />
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <a className="btn btn-outline-secondary" onClick={ev => props.onModalClose(null)}>
                        {trans.t("Annuler")}
                    </a>
                    <button className="btn btn-primary">
                        {trans.t("Créer")}
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}

function ProjectAddFormWrapper(FormFields, project_backend_url) {

    const ProjectAddFormModal = props => {
        const modal = useRef();

        return (
            <Modal show={true} onHide={ev => props.onClose(null)} ref={modal} modalCSSClass="project-add-modal">
                <form className="modal-content" onSubmit={props.onSave}>
                    <Modal.Header>
                        {trans.t("Créer un projet")}                  
                    </Modal.Header>

                      <Modal.Body>
                        {props.globalErrors && <p className="react-form-errors-intro">{props.globalErrors}</p>}
                        <FormFields {...props.fields_props} />
                    </Modal.Body>
                    <Modal.Footer>
                        <a className="btn btn-outline-secondary" onClick={ev => props.onClose(null)}>{trans.t("Annuler")}</a>
                        <button className="btn btn-primary">{trans.t("Créer")}</button>
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }

    const ProjectAddFormPanel = props => {
        const panel = useRef();

        document.addEventListener("Orfeo.CalendarClosePanel", ev => props.onClose(null));
        document.addEventListener("Orfeo.CloseAddProjectPanel", ev => props.onClose(null));

        let panel_style = {height:"100vh", overflowY: "hidden", top: 0};
        return (
            <div ref={panel} style={panel_style} className="project-add-panel float-end">
                <div>
                    <button type="button" className="btn-close float-end" onClick={() => props.onClose(null)}>
                        <span className="sr-only">{trans.t("Fermer")}</span>
                    </button>

                    <form className="panel-content" onSubmit={props.onSave}>
                        <h4>{trans.t("Créer un projet")}</h4>
                        <div>
                            {props.globalErrors && <p className="react-form-errors-intro">{props.globalErrors}</p>}
                            <FormFields {...props.fields_props} />

                        </div>
                        <div className="float-end">
                            {!props.quickCreate &&
                                <a className="btn btn-secondary" onClick={ev => props.onClose(null)}>{trans.t("Annuler")}</a>
                            }
                            <button className="btn btn-primary" value="create" style={{'margin': '0 10px'}}>
                                {trans.t("Créer")}
                            </button>
                            {props.quickCreate && props.eventType == 'project' &&
                                <button type="submit" className="btn btn-secondary" onClick={e => props.setRedirect(true)}>
                                    {trans.t("Créer et éditer")} &gt;
                                </button>
                            }
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    const ProjectAddForm = props => {
        const [errors, setErrors] = useState({});
        const [data, setData] = useState(props.initials || {});
        const [redirect, setRedirect] = useState(!props.quickCreate);
        const [eventType, setEventType] = useState(props.eventType);

        // Notify opening of the project modal to our analytics
        useEffect(() => {
            triggerMatomoEvent('open_modal_project');
        }, []);

        const onSave = (ev, title) => {
            if (ev) {
                ev.preventDefault();
            }
            let post_data = Object.assign({}, data, {
                useTemplatePlanning: data.projecttemplate && !data['spectacle.pk'] || data.useTemplatePlanning
            });
            let url = project_backend_url;

            if (eventType != "project") {
                // Send the request to current url
                url = "";
                post_data['action'] = "create_event";
                post_data['render_tpl'] = true;
                post_data['event_type'] = eventType;
                post_data['organization'] = window.ORGANIZATION_PK;
                post_data['start_time'] = post_data['start_time'] || post_data['start_date'].utc().format('HH:mm');
                post_data['end_time'] = post_data['end_time'] || post_data['end_date'].utc().format('HH:mm');
            }
            // Normalize date format
            if(post_data['start_date'])
                post_data['start_date'] = post_data['start_date'].utc().format('YYYY-MM-DD')
            if(post_data['end_date'])
                post_data['end_date'] = post_data['end_date'].utc().format('YYYY-MM-DD')

            if (title) {
                post_data['title'] = title
            }
            // Ask for title if needed
            if (!post_data['spectacle.pk'] && !post_data['title']) {
                onMissingTitle();
                return
            }

            let notif_id = AjaxNotification.show(trans.t('Création du projet...'));
            $.ajax({
                url: url,
                method: "POST",
                data: post_data
            }).success(
                (data, status) => {
                    if (eventType != "project" && data.result == 0) {
                        setErrors(FormUtils.getErrorsDict(data.output));
                        return
                    }
                    if(eventType == "project") {
                        triggerMatomoEvent('submit_project', {
                            'use_spectacle': (!!post_data['spectacle.pk']).toString(),
                            'use_template': (!!post_data['projecttemplate']).toString(),
                        });
                    }
                    props.onClose(data, redirect && eventType == "project");
                }
            ).error(
                (data, status) => setErrors(FormUtils.getErrorsDict(data.responseJSON))
            ).always(
                () => AjaxNotification.hide(notif_id)
            );
        }

        const onFieldChange = (field, value) => {
            let newData = {};
            newData[field] = value
            setData(data => Object.assign({}, data, newData));
        }

        const onSelectTemplate = tpl => {
            let contract = tpl ? tpl.main_contract : null;
            if (contract) {
                // Apply contract template field, if not empty (keep potentiel pre-initialized data otherwise)
                if(contract.organizer)
                    onFieldChange('contract_organizer', contract.organizer.pk);
                if(contract.place) {
                    onFieldChange('contract_place', contract.place.pk);
                    onFieldChange('venue_place', contract.venue ? contract.venue.pk : null);
                }
            }
            if (!props.initials['spectacle.pk']) {
                // Do not change the spectacle when creating a projet from the spectacle page
                onFieldChange('spectacle.pk', tpl && tpl.spectacle ? tpl.spectacle.pk : null);
            }
            onFieldChange('projecttemplate', tpl ? tpl.pk : null);
            onFieldChange('status.pk', tpl && tpl.status ? tpl.status.pk : null);
            onFieldChange('contract_type', tpl && tpl.contract_type ? tpl.contract_type.pk : data.contract_type);
            onFieldChange('displayed_tabs', tpl && tpl.displayed_tabs);
            onFieldChange('displayed_blocks', tpl && tpl.displayed_blocks);
            onFieldChange('calendar.pk', tpl && tpl.calendar ? tpl.calendar.pk : null);
        }

        const isInError = field => FormUtils.getCSSError(errors, field);

        const onMissingTitle = () => {
            let root = createRoot(getModalWrapper());

            const closeModal = data => {
                if (data) {
                    onSave(null, data.title);
                }
                root.unmount();
                return true
            }
            root.render(<ProjectTitleForm onModalClose={closeModal} />);
        }

        let model_verbose_name = trans_variants.get_variant_text('projet');
        let model_is_feminine = trans_variants.is_feminine('projet');
        let component_props = {
            model_is_feminine: model_is_feminine,
            model_verbose_name: model_verbose_name,
            onClose: props.onClose,
            onSave: onSave,
            globalErrors: FormUtils.getGlobalErrors(errors),
            eventType: eventType,
            quickCreate: props.quickCreate,
            setRedirect: setRedirect,
            fields_props: {
                isInError: isInError,
                errors: errors,
                initials: props.initials || {},
                onFieldChange: onFieldChange,
                show_spectacle: hasPerm('production.spectacle_access'),
                show_structure: hasPerm('production.project_contract_access'),
                eventType: eventType,
                showTime: props.showTime,
                canEditTimeFields: props.canEditTimeFields,
                setEventType: setEventType,
                quickCreate: props.quickCreate,
                onlyProjects: props.onlyProjects,
                data: data,
                onSelectTemplate: onSelectTemplate,
            }
        }
        let component = ProjectAddFormPanel;
        if (props.wrapperType == 'modal') {
            component = ProjectAddFormModal;
        }

        return React.createElement(component, component_props);
    }
    return ProjectAddForm
}


export default ProjectAddFormWrapper;
