import React, { useEffect, useState } from 'react';
import { fetchCsrfWrapper } from 'orfeo_common/react-base.jsx';
import * as FormUtils from 'orfeo_common/FormUtils.jsx';
import Modal from 'orfeo_common/Modal.jsx';
import { loadScript } from 'orfeo_common/utils/script.jsx';
import classNames from 'classnames';

const BACKEND_URL = '/backend/signeddocument/';

const SigningModal = ({item_id, onModalClose, onSuccess}) => {
    const [hideIframe, setHideIframe] = useState(true);
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        fetchCsrfWrapper(BACKEND_URL + `${item_id}/get_embed_url/`)
            .then((embed_infos) => {
                if(embed_infos.is_signrequest_legacy){
                    let iframe = document.createElement("iframe");
                    iframe.width = '100%';
                    iframe.src = embed_infos.url;
                    setHideIframe(false);
                    document.getElementById('embed-signing-modal').appendChild(iframe);
                    setLoading(false);
                    return
                }
                // Load js
                loadScript(embed_infos.docusign_js_src, () => {
                    window.DocuSign.loadDocuSign(embed_infos.clientId).then((docusign) => {
                        setLoading(false);
                        const signing = docusign.signing({
                            url: embed_infos.url,
                            displayFormat: 'default',
                            //displayFormat: 'focused',
                            style: {}
                        });
                        signing.on('sessionEnd', (event) => {
                            if(['exception', 'ttl_expired'].includes(event)){
                                setErrors({'__all__': [trans.t("Une erreur s'est produite durant l'opération.")]})
                            }
                            else if(event == 'session_timeout'){
                                setErrors({'__all__': [trans.t("Session expirée.")]})
                            }
                            else{
                                onSuccess();
                            }
                        });
                        setHideIframe(false);
                        signing.mount("#embed-signing-modal");
                    })
                    .catch((data) => {
                        setErrors(data);
                        setLoading(false);
                    });
                })
            })
            .catch((data) => {
                setErrors(data);
                setLoading(false);
            });
    }, []);

    return (
        <Modal show onHide={onModalClose} modalCSSClass={"large-modal scrollable-modal show"}>
            <Modal.Header>{trans.t("Signer le document")}</Modal.Header>
            <Modal.Body>
                {"__all__" in errors && <p className="react-form-errors-intro">{FormUtils.getGlobalErrors(errors)}</p>}
                {loading &&
                <p className="lead text-center py-3">
                    <i className="fa fa-spin fa-spinner"></i> {trans.t("Interface de signature en cours de chargement")}
                </p>
                }
                <div id="embed-signing-modal" className={classNames({"d-none": hideIframe})}></div>
                {Object.keys(errors).includes("signer") && (
                    <p className="react-form-errors-intro">
                        {trans.t("Erreur lors de l'intégration de la signature dans Orfeo")} <br />
                        {errors["signer"]}
                    </p>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default SigningModal;
