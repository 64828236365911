import React from 'react';

import { CurrencySelector } from 'orfeo_common/Fields';
import * as FormUtils from 'orfeo_common/FormUtils.jsx';
import { CustomFieldInput } from 'orfeo/custom_fields/jsx/Utils.jsx';
import { CONTRACT_TYPES } from '../Utils.jsx';
import AgreementContractSectionFields from './specific_zones/Agreement.jsx';
import ExtraContractSectionFields from './specific_zones/Extra.jsx';
import ReplacementContractSectionFields from './specific_zones/Replacement.jsx';
import SolistContractSectionFields from './specific_zones/Solist.jsx';

const getCustomFieldsOfType = (custom_fields, contract_type) => {
    return custom_fields.filter(
        field => !field.proxy_restriction || field.proxy_restriction.indexOf(contract_type) !== -1
    );
}

const _TYPES = {
    'standard': null,
    'extra': ExtraContractSectionFields,
    'solist': SolistContractSectionFields,
    'replacement': ReplacementContractSectionFields,
}

/**
 * Returns the right component to render the "specific" block of
 * an engagement, which depends on the type of contract
 */
const getSpecificFieldsComponent = (type, contract_type) => {
    if(type == 'agreement')
        return AgreementContractSectionFields;

    return _TYPES[contract_type];
}


const ContractTypeSpecificCustomFields = props => {
    return <React.Fragment>
        {getCustomFieldsOfType(props.custom_fields, props.engagement.contract_type).map(field =>
            <div className="row" key={"cf"+field.key}>
                <div className="col-xs-3">{field.label}</div>
                <div className="col-xs-9">
                    <CustomFieldInput
                        custom_field={field}
                        disableAutoFocus showEmptyChoice
                        onChange={val => props.updateEngagement({
                            'custom_fields': Object.assign({}, props.engagement.custom_fields || {}, {[field.key]: val})
                        })}
                        value={props.engagement.custom_fields ? props.engagement.custom_fields[field.key] : ""}
                    />
                </div>
            </div>
        )}
    </React.Fragment>
}


const ContractTypeFields = props => {
    if (props.engagement.pk)
        return null

    return <div className="row">
        <p className="col-xs-3">
            {trans.t("Type de contrat")}
        </p>
        <div className="col-xs-6">
            <label htmlFor="type_contract">
                <input
                    type="radio" name="type" id="type_contract" value="contract"
                    checked={
                        props.engagement.type == 'contract'
                        && props.engagement.contract_type == 'standard'
                    }
                    onChange={ev => {
                        props.updateEngagement({type: 'contract', 'contract_type': 'standard'})
                    }}
                /> {trans.t("Standard")}
            </label>

            {(
                props.engagement.possible_contract_type
                && props.engagement.possible_contract_type != 'standard'
            ) &&
            <label htmlFor="contract_type_specific" className="ms-3">
                <input
                    type="radio"
                    name="type" id="contract_type_specific"
                    checked={
                        props.engagement.contract_type
                        == props.engagement.possible_contract_type
                    }
                    onChange={ev => {
                        props.updateEngagement({type: 'contract', contract_type: ev.target.value});
                    }}
                    value={props.engagement.possible_contract_type}
                /> {CONTRACT_TYPES.filter(
                        ([k, v]) => k == props.engagement.possible_contract_type
                    )[0][1]}
            </label>
            }
            <label htmlFor="type_agreement" className="ms-3">
                <input
                    type="radio" name="type" id="type_agreement" value="agreement"
                    checked={props.engagement.type == 'agreement'}
                    onChange={ev => {
                        props.updateEngagement({type: 'agreement', contract_type: 'standard'});
                    }}
                /> {trans.t("Prestation")}
            </label>
        </div>
        <div className="col-xs-3">
            <CurrencySelector
                org_profile={null}
                currency={props.engagement.currency}
                onChange={obj => props.onFieldChange('currency', obj)}
                showLoader={false}
            />
            <FormUtils.ErrorText field="currency" errors={props.errors} />
        </div>
    </div>
}

const ContractTypeSpecificFields = props => {

    const specificFieldsModal = getSpecificFieldsComponent(
        props.engagement.type,
        props.engagement.contract_type
    );
    return <React.Fragment>
        <ContractTypeFields {...props} />
        {specificFieldsModal && React.createElement(
            specificFieldsModal,
            {
                'engagement': props.engagement,
                'errors': props.errors,
                'onFieldChange': props.onFieldChange,
            }
        )}
        <ContractTypeSpecificCustomFields {...props} />
    </React.Fragment>
}

export default ContractTypeSpecificFields;
