'use strict';

// This list is used to guarantee order of types displayed
const ActivityTypes = ["performance", "rehearsal", "technical", "journey", "accommodation", "meal", "other"];
const ActivityTypesForIsolatedEvent = ["rehearsal", "technical", "other"];
const ActivityTypesWithNatures = ["performance", "rehearsal"];

const ActivityTypeInfos = {
    "performance": {"label": trans.t("Représentation"), "icon": 'ticket', 'label_plural': trans.t("Représentations")},
    "rehearsal": {"label": trans.t("Répétition"), "icon": 'book', 'label_plural': trans.t("Répétitions")},
    "technical": {"label": trans.t("Technique"), "icon": 'wrench', 'label_plural': trans.t("Techniques")},
    "journey": {"label": trans.t("Voyage"), "icon": 'bus', 'label_plural': trans.t("Voyages")},
    "accommodation": {"label": trans.t("Hébergement"), "icon": 'bed', 'label_plural': trans.t("Hébergements")},
    "meal": {"label": trans.t("Repas"), "icon": 'cutlery', "label_plural": trans.t("Repas")},
    "other": {"label": trans.t("Autre"), "icon": 'calendar-o', "label_plural": trans.t("Autres")},
}

const PLANNING_TYPES = [
    'performance',
    'rehearsal',
    'technical'
];

const EVENT_TYPES_ICON = {
    'performance': 'ticket',
    'rehearsal': 'book',
    'technical': 'wrench',
    'journey': 'bus',
    'accommodation': 'bed',
    'meal': 'cutlery',
    'other': 'calendar-o',
};

// List of event types that can be created from a participant view
const EVENT_TYPE_SINGLE_ADD = ['journey', 'accommodation', 'meal', 'other'];

const MEAL_TIMES = {
    'breakfast': {
        'label': trans.t('Petit déjeuner'),
        'start_time': '08:30',
        'end_time': '09:30'
    },
    'lunch': {
        'label': trans.t('Déjeuner'),
        'start_time': '12:30',
        'end_time': '14:00'
    },
    'dinner': {
        'label': trans.t('Dîner'),
        'start_time': '19:30',
        'end_time': '21:00'
    }
}

export {
    ActivityTypes,
    ActivityTypesWithNatures,
    ActivityTypesForIsolatedEvent,
    ActivityTypeInfos,
    EVENT_TYPES_ICON,
    EVENT_TYPE_SINGLE_ADD,
    MEAL_TIMES,
    PLANNING_TYPES,
}
