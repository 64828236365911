/**
 * Orfeo object note manager
 * -------
 *
 * Display and manage notes on any entity object of the application.
 */
'use strict';

import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Trans } from 'react-i18next';
import moment from 'moment';
import { AjaxNotification, hasPerm, fetchCsrfWrapper, GenericListStoreFactory } from 'orfeo_common/react-base.jsx';
import { isReadOnly as isReadOnlyGlobal, getGlobalErrors, useFormErrors, useFormEditing } from 'orfeo_common/FormUtils.jsx';
import { urlize } from 'orfeo_common/utils/text.jsx';
import {
    AutosizeTextarea,
    ForeignKeyField
} from 'orfeo_common/Widgets.jsx';

import ContentEditable from 'react-contenteditable';
import TributeJS from 'tributejs';

function showStructurePersonField() { return !!window['SC_VIEW_TYPE']; }
function showSpectacleField() {
    return hasPerm('activities.can_link_spectacle_to_notes') && !!window['SC_VIEW_TYPE'];
}

const get_opposite_entity = sp => {
    if(window['SC_VIEW_TYPE'] == 'structure')
        return sp.person;

    return sp.structure;
}

const isReadOnly = () => {
    return isReadOnlyGlobal();
}

// Fix backspace bug in FF: https://bugzilla.mozilla.org/show_bug.cgi?id=685445
// Credit: https://stackoverflow.com/a/30574622/1433392
const fixContenteditableForFirefox = function(node) {
    // The fix should be applied only for Firefox
    if(!navigator.userAgent.match(/firefox/i))
        return;

    node.addEventListener('keydown', function (event) {
        if (window.getSelection && event.which == 8) { // backspace
            var selection = window.getSelection();
            if (!selection.isCollapsed || !selection.rangeCount) {
                return;
            }
            var curRange = selection.getRangeAt(selection.rangeCount - 1);
            if (curRange.commonAncestorContainer.nodeType == 3 && curRange.startOffset > 0) {
                // we are in child selection. The characters of the text node is being deleted
                return;
            }
            var range = document.createRange();
            if (selection.anchorNode != this) {
                // selection is in character mode. expand it to the whole editable field
                range.selectNodeContents(this);
                range.setEndBefore(selection.anchorNode);
            } else if (selection.anchorOffset > 0) {
                range.setEnd(this, selection.anchorOffset);
            } else {
                // reached the beginning of editable field
                return;
            }
            if(range.endOffset > 0)
                range.setStart(this, range.endOffset - 1);

            var previousNode = range.cloneContents().lastChild;
            if (previousNode && previousNode.contentEditable == 'false') {
                // Delete the contentEditable block
                range.deleteContents();
                event.preventDefault();
            }
        }
    });
}


function getNoteContent(content, collapsed = true){
    let truncable = !collapsed;
    let safe_content = urlize(content);
    if(collapsed) {
        if(safe_content.split('\n').length > 4) {
            truncable = true;
            safe_content = safe_content.split('\n', 4).join('\n').trim() + '...';
        }
        else if(safe_content.length > 450) {
            truncable = true;
            safe_content = safe_content.substring(0, 400).trim() + '...';
        }
    }

    return [safe_content, truncable]
}


var NotesStore = GenericListStoreFactory()
NotesStore.sort_function = (x, y) => {
    return moment(y['creation_date']).unix() - moment(x['creation_date']).unix()
};


const NoteForm = props => {
    const [content, setContent] = useState(() => {
        if(props.note.pk)
            return props.note.content.replace(/\n/g, '<br />')
        return '';
    });
    const [is_private, setIsPrivate] = useState(props.note.is_private || false);
    const [mentionsUsed, setMentionsUsed] = useState(false);

    // Contextual optional fields
    const [spectacle, setSpectacle] = useState(props.note.spectacle || null);
    const [structure_person, setStructurePerson] = useState(props.note.structure_person || null);
    const [errors, setErrors] = useFormErrors();

    const show_private_checkbox = (
        !props.note.pk  // new object
        || (props.note.created_by && props.note.created_by.pk == PROFILE_PK)  // ...or created by the current user
    )

    const onSave = ev => {
        ev.preventDefault();
        if(content.trim() == '') {
            alert(trans.t('Le contenu est obligatoire.'));
            return;
        }

        let post_data = {
            'content': content,
            'content_type': props.content_type,
            'object_id': props.object_id
        }
        if(show_private_checkbox)
            post_data['is_private'] = is_private;
        if(showStructurePersonField())
            post_data['structure_person'] = {'pk': structure_person ? structure_person.pk : null};
        if(showSpectacleField())
            post_data['spectacle'] = {'pk': spectacle ? spectacle.pk : null};

        let url = '/backend/note/', method = 'POST';
        if(props.note.pk) {
            url += props.note.pk + '/';
            method = 'PATCH';
        }

        fetchCsrfWrapper(
            url,
            {method: method, body: post_data},
        )
        .then(data => props.onSuccess(data))
        .catch(errData => setErrors(errData))
    }

    const textareaRef = useRef();
    const [tribute, setTribute] = useState();
    useEffect(() => {
        if(textareaRef.current) {
            // Set focus on node, as autoFocus does not work on contenteditable items
            textareaRef.current.focus();
            // Configure TributeJS for the main edition zone
            const t = new TributeJS({
                menuItemLimit: 6,

                selectTemplate: function(item) {
                    if (typeof item === "undefined")
                        return null;

                    // Use to display a warning if the user checks the "is_private" field
                    setMentionsUsed(true);
                    return (
                        '<span role="mention" contenteditable="false" data-pk="' + item.original.pk + '">@' + item.original.key + "</span>"
                    );
                },
                noMatchTemplate: function () {
                    // Hide suggestions menu when no users matches query
                    return '<span style:"visibility: hidden;"></span>';
                },
                values: function (text, cb) {
                    if(this._cached_values) {
                        cb(this._cached_values);
                    }
                    else {
                        fetchCsrfWrapper(
                            '/backend/user/?account_type=standard&is_active=true',
                            {method: 'GET'},
                            {noPendingMessage: true}
                        ).then(data => {
                            this._cached_values = data.map(user => ({
                                pk: user.pk,
                                key: user.display_name,
                                value: user.display_name,
                            }))
                            cb(this._cached_values);
                        });
                    }
                },
            });

            let editableNode = ReactDOM.findDOMNode(textareaRef.current)
            editableNode.addEventListener('paste', e => {
                // Custom paste listener to avoid pasting HTML (that would be escaped anyway) in the
                // note content. Breaklines will be properly translated to break lines.
                e.preventDefault();
                let clipboardData = e.clipboardData || window.clipboardData;
                window.document.execCommand('insertText', false, clipboardData.getData('Text'));
            });

            fixContenteditableForFirefox(editableNode);
            t.attach(editableNode);
            setTribute(t);
        }
    }, []);

    const onKeyUp = ev => {
        if(ev.key == 'Escape') {
            props.onCancel();
        }
    }

    const sp_field_placeholder = window['SC_VIEW_TYPE'] == 'structure' ? "Contact lié": "Structure liée";

    return (
        <form className="react-inline-form react-inline-full-width" id="add-note" onSubmit={onSave} onKeyUp={onKeyUp}>
            {Object.keys(errors).length > 0 &&
                <p className="react-form-errors-intro">
                    {trans.t("Erreur lors de la sauvegarde")}. {getGlobalErrors(errors)}
                </p>
            }

            <ContentEditable
                className="form-control" innerRef={textareaRef} html={content} tagName="p"
                onChange={ev => setContent(ev.target.value)}
            />

            <div className="actions text-end">
                <div className="float-start">
                    {props.note.pk &&
                        <a className="btn btn-danger" onClick={props.onDelete}>{trans.t("Supprimer")}</a>
                    }

                    {showStructurePersonField() &&
                    <div style={{'maxWidth': '200px', 'display': 'inline-block', 'marginRight': '10px'}}>
                        <ForeignKeyField
                            backend_url={`/backend/structureperson/?is_archived=false&${SC_VIEW_TYPE}=${props.object_id}`}
                            objectRepresentation={x => get_opposite_entity(x).name}
                            blank_choice_label={sp_field_placeholder}
                            defaultValue={structure_person} allowValueOutOfList={true}
                            onChange={(pk, instance) => setStructurePerson(instance)}
                        />
                    </div>}

                    {showSpectacleField() &&
                    <div style={{'maxWidth': '200px', 'display': 'inline-block', 'marginRight': '10px'}}>
                        <ForeignKeyField
                            backend_url={`/backend/spectacle/`}
                            blank_choice_label={trans.t("Spectacle lié")}
                            defaultValue={spectacle} onChange={(pk, instance) => setSpectacle(instance)}
                        />
                    </div>}

                    {show_private_checkbox &&
                    <div style={{'display': 'inline-block', 'paddingTop': '3px'}}>
                        <input type="checkbox" id="id_is_private" checked={is_private} onChange={ev => setIsPrivate(ev.target.checked)} />&nbsp;
                        <label htmlFor="id_is_private" title={trans.t("Cette note ne sera visible que par vous.")}>{trans.t("Privée")}</label>
                    </div>}
                </div>

                <a className="btn btn-outline-secondary" onClick={props.onCancel}>{trans.t("Annuler")}</a>&nbsp;
                <button type="submit" className="btn btn-primary">{trans.t("Sauvegarder")}</button>
            </div>

            {(show_private_checkbox && is_private && mentionsUsed) &&
                <p style={{'margin': '8px'}} className="text-warning-emphasis">
                    <i className="fa fa-warning"></i>&nbsp;
                    {trans.t(
                        "Les personnes mentionnées ne pourront pas voir cette note privée"
                        + " et ne seront pas notifiées."
                    )}
                </p>
            }
        </form>
    );
}


const NoteItem = (props) => {
    let note = props.note;
    let [editing, setEditing] = useFormEditing();
    let [collapsed, setCollapse] = useState(true);

    const onDelete = () => {
        if(confirm(trans.t("Êtes-vous sûr de vouloir supprimer cette note ?"))) {
            fetchCsrfWrapper(
                `/backend/note/${note.pk}/`,
                {method: "DELETE"},
            )
            .then(data => NotesStore.delete(note.pk))
            .catch(
                data => alert(trans.t('Une erreur est survenue lors de la suppression de la note'))
            );
        }
    }

    const onNoteClick = ev => {
        // Do not trigger the form if the user clicked on a link
        if(ev.target.nodeName != 'A')
            setEditing(true)
    }

    if(editing) {
        return (
            <NoteForm
                object_id={props.object_id} content_type={props.content_type}
                note={note} onDelete={onDelete}
                onCancel={() => setEditing(false)}
                onSuccess={data => NotesStore.update(data)}
            />
        )
    }

    let creator;
    if(note.created_by) { // some old note may not have creator specified
        creator = (
            <span className="created_by" title={note.is_private ? trans.t("Privée - visible que par vous") : ""}>
                {note.is_private && <i className="fa fa-lock">&nbsp;</i>}
                {note.created_by.display_name}
            <br /></span>
        );
    }

    // If the state has already been set to false, it means it has been truncable at a moment
    const [safe_content, truncable] = getNoteContent(note.content, collapsed);

    let creation_date = moment(note.creation_date).locale('fr');
    // Display note hours only if the note is recent (7 days ago or less)
    let date_format = 'Do MMM YYYY';
    if(creation_date.isAfter(moment().subtract(7, 'days')))
        date_format += ', HH:mm';

    let left_related_link, right_related_link;
    if(props.content_type == 447 && note.structure_person) { // spectacle
        left_related_link = (
            <a href={`/notes/redirect/${note.pk}/`} onClick={ev => ev.stopPropagation()}>
                {note.structure_person.structure.name} / {note.structure_person.person.name}
            </a>
        )
    }
    else {
        if(note.spectacle) {
            left_related_link = (
                <React.Fragment>{trans.t("Spectacle")} <a href={note.spectacle.absolute_url}>{note.spectacle.name}</a></React.Fragment>
            )
        }
        if(note.structure_person && showStructurePersonField()) {
            let mirror_obj = get_opposite_entity(note.structure_person);
            right_related_link = (
                <Trans>
                    Associée à <a href={mirror_obj.absolute_url}>{{name: mirror_obj.name}}</a>
                </Trans>
            )
        }
    }

    return (
        <li onClick={onNoteClick}>
            <div className="note-metainfo">
                {creator}
                <span className="creation_date">{creation_date.format(date_format)}</span>
            </div>
            <p className="note-content" dangerouslySetInnerHTML={{__html: safe_content}}></p>

            <div className="clearfix"></div>

            {left_related_link &&
                <span className="text-muted"><i className="fa fa-link"></i> {left_related_link}</span>
            }
            {right_related_link &&
                <p className="float-end note-structure-person" style={{'display': 'inline-block'}}>{right_related_link}</p>
            }
            {truncable &&
                <CollapseLink collapsed={collapsed} callback={ev => {
                        ev.stopPropagation();
                        setCollapse(!collapsed);
                }} />
            }
            <div className="clearfix"></div>
        </li>
    );
}


let CollapseLink = ({collapsed, callback}) => {
    return (
        <a className="text-muted" onClick={callback} style={{'padding': '12px 10px', 'margin': '-10px', 'display': 'block', 'fontSize': '0.9em'}}>
            {collapsed ?
                <span>{trans.t("Voir la suite")} <i className="fa fa-caret-down"></i></span>
            :
                <span>{trans.t("Replier")} <i className="fa fa-caret-up"></i></span>
            }
        </a>
    )
}


const NotesManager = (props) => {
    const [notes, setNotes] = useState(null);
    const [next_page, setNextPage] = useState('/backend/note/?page=1&page_size=10');
    const [editing, setEditing] = useFormEditing();
    const [loading, setLoading] = useState(false);

    // Method that will fetch next notes from the backend
    const loadNextNotes = () => {
        if(!next_page)
            return;

        setLoading(true);
        $.ajax({
            url: next_page, method: "GET",
            data: {'object_id': props.object_id, 'content_type': props.content_type},
            cache: false,
        }).success(
            (data, status) => {
                setNextPage(data['next']);
                NotesStore.add(data['results']);
                setLoading(false);
            }
        ).error(
            (data, status) => console.error(trans.t("Impossible de charger les notes"))
        );
    }

    // Add listener on any store change and load first items
    useEffect(() => {
        const cb = lst => setNotes(lst);
        NotesStore.setList([]);
        NotesStore.addOnListChange(cb)
        loadNextNotes();

        return () => NotesStore.removeListener(cb)
    }, []);

    // Compute space reserved to note creation
    let add_block;
    if(!isReadOnly() && editing) {
        add_block = (
            <NoteForm
                object_id={props.object_id} content_type={props.content_type} note={{}}
                onCancel={() => setEditing(false)}
                onSuccess={data => {
                    NotesStore.add(data)
                    setEditing(false)
                }}
            />
        );
    }

    return (
        <React.Fragment>
            <h4>
                Notes
                {(!isReadOnly() && !editing) &&
                    <a className="add-link action-link" onClick={() => setEditing(true)}>{trans.t("Ajouter")}</a>
                }
            </h4>
            {add_block}

            {notes !== null &&
                <ul className="notes">
                {notes.map(
                    (elem, idx) => (
                        <NoteItem
                            object_id={props.object_id} content_type={props.content_type}
                            note={elem}
                            key={elem.pk+"-"+elem.update_date}
                        />
                    )
                )}
                </ul>
            }
            {loading && <i className="fa fa-spin fa-spinner"></i>}
            {(!loading && next_page) &&
                <a onClick={loadNextNotes} className="pointer"><em>{trans.t("Afficher plus")}</em></a>
            }
        </React.Fragment>
    );
}

export default NotesManager;
export { getNoteContent }
