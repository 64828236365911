/**
 * Returns a best readable format for the size, rounded to 1 decimal.
 * humanFileSize(1024)      = "1.0 ko"
 * humanFileSize(12345678)  = "11.8 Mo"
 */
const humanFileSize = function(bytes) {
    let thresh = 1024;
    if(Math.abs(bytes) < thresh) {
        return bytes + ' o';
    }
    let units = ['ko','Mo','Go','To','Po','Eo'];
    let u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while(Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
}


/**
 * Returns filename truncated in the middle if its name length exceed the given
 * parameter
 */
const getTruncatedFilename = (path, maxLength) => {
    maxLength = maxLength || 24;

    // Get part after the last / of the path
    let filename = path.substring(path.lastIndexOf('/') + 1);
    let size = filename.length;

    if(size > maxLength) {
        let halfSize = Math.ceil(maxLength / 2);
        return filename.substring(0, halfSize) + '…' + filename.substring(size - halfSize);
    }

    return filename
}


const extension_to_icon = [
    [/pdf/, 'fa fa-file-pdf-o'],
    [/jpe?g|png|gif/, 'fa fa-file-image-o'],
    [/xlsx?/, 'fa fa-file-excel-o'],
    [/docx?/, 'fa fa-file-word-o'],
    [/pptx?/, 'fa fa-file-powerpoint-o'],
    [/txt|csv|xml/, 'fa fa-file-text-o'],
]

const getIconNameFromFile = function(filename) {
    let icon = "fa fa-file-o";

    let extension = filename.split('.').pop();
    for(let [reg, result] of extension_to_icon) {
        if(!!reg.exec(extension)) {
            icon = result;
            break;
        }
    }

    return icon;
}


export {
    humanFileSize,
    getTruncatedFilename,
    getIconNameFromFile,
}
