import React, { useEffect, useState } from 'react';

import { fetchCsrfWrapper } from '../react-base.jsx';
import Tooltip from '../Tooltip.jsx';

let _COLORS = [], _loadingColors = false;


function loadColorsFromBackend() {
    if(!!_loadingColors || _COLORS.length > 0)
        return

    return (
        fetchCsrfWrapper('/backend/color/')
            .then(data => {
                _COLORS = data;
                return new Promise((resolve, reject) => resolve(data));
            })
            .catch(
                data => alert(trans.t('Erreur lors du chargement de la liste des couleurs'))
            )
    );
}


/**
 * Panel displayed on top of the picker to allow the user to choose
 * a color. It displays a grid of colors loaded from our backend
 */
const ColorPickerInner = props => {
    const [colors, setColors] = useState(_COLORS);

    // Side-loading of colors available if it's hasn't been done yet
    useEffect(() => {
        if(colors.length == 0) {
            loadColorsFromBackend().then(
                data => setColors(data)
            )
        }
    }, []);

    // Add listener to detect Escape key and click outside which will hide the panel opened
    useEffect(() => {
        let clickListener = ev => {
            if(!ev.target.closest('.color-picker')) {
                props.onHidePanel();
            }
        };
        let keyListener = ev => {
            if(ev.key == 'Escape') {
                props.onHidePanel();
            }
        };
        document.addEventListener('click', clickListener);
        document.addEventListener('keyup', clickListener);
        // Unbind listeners once the component is destroyed
        return () => {
            document.removeEventListener('click', clickListener);
            document.removeEventListener('keyup', keyListener);
        }
    }, []);

    if(colors.length == 0)
        return (
            <div className="color-picker">
                <p className="text-muted">
                    <i className="fa fa-spin fa-spinner"></i> {trans.t("Chargement des couleurs...")}
                </p>
            </div>
        );

    return (
        <div className="color-picker">
            <div className="color-picker-tools float-end">
                <button className="btn-close" onClick={props.onHidePanel}></button><br />
                {(props.value && !props.required && props.show_empty !== false) &&
                    <Tooltip
                        role="button"
                        onClick={() => props.onChange(null)}
                        title="Vider la sélection"
                    >
                        <i className="fa fa-trash" role="button"></i>
                    </Tooltip>
                }
            </div>
            {_COLORS.map((color, idx) =>
                <i
                    title={color.name} key={'color-'+color.pk}
                    onClick={() => props.onChange(color)}
                    role="button"
                    className="color-block"
                    style={{
                        'backgroundColor': color.code,
                        'border': (props.value?.code == color.code) ? '1px solid #666' : ''
                    }}
                ></i>
            )}
        </div>
    );
}


/**
 * Main component which display by default the current color select and
 * opens the picker on click.
 */
const ColorPicker = props => {
    const [panelShown, setPanelShown] = useState(false);

    useEffect(() => {
        // Assign a value if none is given
        if(props.required && !props.value) {
            ColorPicker.getRandomColor().then(
                color => props.onChange(color)
            )
        }
    }, []);

    let color = props.value;
    return (
    <div className="color-input">
        <div
            onClick={ev => {
                ev.stopPropagation();
                setPanelShown(!props.disabled && !panelShown);
            }}
            role="button"
        >
            <span style={props.disabled ? {'color': '#666', 'cursor': 'default'} : {}}>
                <i className="color-block" style={{'background': props.value?.code}}></i>
                {props.hide_label ?
                    <span className="caret"></span>
                    :
                    <>
                        &nbsp;
                        {props.value ? props.value.name : (
                            props.required
                                ? <em>{trans.t("choisir une couleur")}</em>
                                : <em>{trans.t("couleur par défaut")}</em>
                        )}
                    </>
                }
            </span>
        </div>
        {panelShown &&
            <ColorPickerInner
                {...props}
                onChange={color => {
                    props.onChange(color);
                    setPanelShown(false);
                }}
                onHidePanel={() => setPanelShown(false)}
            />
        }
    </div>
    );
}

/**
 * Returns a Promise that will return a color selected randomly,
 * which might trigger a XHR query to first retrieve list of colors
 * available
 */
const _getRandom = lst => lst[Math.floor(Math.random() * lst.length)];
ColorPicker.getRandomColor = () => {
    return new Promise((resolve, reject) => {
        // Colors already loaded, just pick a random one
        if(_COLORS.length > 0)
            resolve(_getRandom(_COLORS));

        // Otherwise load colors then returns a random one
        loadColorsFromBackend().then(
            data => resolve(_getRandom(data))
        )
    });
}


export default ColorPicker;
