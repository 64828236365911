import React, { useState, useEffect, useReducer } from 'react';
import { createRoot } from 'react-dom/client';


import classNames from 'classnames';

import { capitalize, fetchCsrfWrapper, getModalWrapper } from 'orfeo_common/react-base.jsx';
import Modal from 'orfeo_common/Modal.jsx';
import Tooltip from 'orfeo_common/Tooltip.jsx';

const reducer = (state, action) => {
    let newState = Object.assign({}, state);
    if (action.type == 'clearSelection') {
        return Object.assign(newState, {items: [], lastIndex: null});
    }
    if (action.type == 'selectAll') {
        return  Object.assign(
            newState,
            {
                items: state.options.filter(x => x.selectable).map(x => x.value),
                lastIndex: null
            }
        )
    }
    if (action.type == 'selectItem') {
        newState.lastIndex = action.index;
        if (action.event.shiftKey && state.lastIndex !== null) {
            newState.items = action.options.slice(
                Math.min(state.lastIndex, action.index),
                Math.max(state.lastIndex, action.index) + 1,
            ).map(x => x.value)
            // preserve last index in case of successive shift selection
            newState.lastIndex = state.lastIndex;
        }
        else if (action.event?.ctrlKey) {
            if (!state.items.includes(action.item)) {
                newState.items = [...newState.items, action.item];
            } else {
                newState.items = newState.items.filter(x => x != action.item)
            }
        } else {
            newState.items = [action.item]
        }
    }
    if (action.type == 'selectGroup') {
        newState.items = [...action.items]
    }
    console.warn("unknown action type : " + action.type);

    return newState;
}

// custom react hook for handling of selection in our two columns modal
const useSelection = options => {
    const [selection, dispatchSelection] = useReducer(reducer, {
        lastIndex: null,
        items: [],
        options: options
    })
    return [selection.items, dispatchSelection]
}

const DisabledOptionTooltip = props => {
    if (!props.title) {
        return props.children;
    }
    return <Tooltip title={props.title}>
        {props.children}
    </Tooltip>

}

const Option = ({element, selected, dispatchSelection, options}) => {
    let selectable = element.selectable === undefined ? true : element.selectable;
    const onClick = ev => {
        if (!selectable){
            return
        }
        dispatchSelection({
            type: 'selectItem',
            event: ev,
            item: element.value,
            index: options.map(x => x.value).indexOf(element.value),
            options: options,
        });
    }

    return (
        <DisabledOptionTooltip title={element.selectable_reason}>
            <li className={classNames({"selected": selected, "text-muted": !selectable})}
                onMouseDown={onClick}
            >
                {element.label}
                {element.subtext && <span className="text-muted"> - {element.subtext}</span>}
            </li>
        </DisabledOptionTooltip>
    );
}

const OptionsGroup = props => {
    if (!props.elements.length) {
        return null
    }

    return <React.Fragment>
        <h5 className={classNames({'pointer': props.elements.some(x => x.selectable)})}
            onClick={() => props.dispatchSelection({
                type:'selectGroup',
                items: props.elements.filter(x => x.selectable).map(x => x.value),
            })}
        >
            {props.group}
        </h5>
        <ul>
            {props.elements.map(
                element => <Option
                    key={element.value}
                    element={element}
                    options={props.options}
                    selected={props.selection.includes(element.value)}
                    dispatchSelection={props.dispatchSelection}
                />
            )}
        </ul>
    </React.Fragment>
}

const Column = props => {
    return (
        <div className="column-container mt-2">
            <div className="column-label">{ capitalize(props.label) }</div>

            <div className="column">
            {Object.entries(props.optGroups).map(
                ([group, elements]) => <OptionsGroup
                    key={group + '' + elements.map(x => x.pk).join(',')}
                    group={group}
                    options={props.options}
                    elements={elements}
                    selection={props.selection}
                    dispatchSelection={props.dispatchSelection}
                />
            )}
            </div>

            <div className="column-buttons mt-1">
            {props.options.length == props.selection.length ?
                <button className="btn btn-sm btn-secondary"
                    onClick={() => props.dispatchSelection({'type': 'clearSelection'})}
                >
                    {trans.t("Tout désélectionner")}
                </button>
                :
                <button className="btn btn-sm btn-secondary"
                    onClick={() => props.dispatchSelection({'type': 'selectAll'})}
                >
                    {trans.t("Tout sélectionner")}
                </button>
            }
            </div>
        </div>
    );
}


const TwoColumnsModal = props => {
    // put values in the state since the modal will only be rendered once
    const [values, setValues] = useState(props.values);
    const [groupFilter, setGroupFilter] = useState('');

    const filterOptGroups = ({ selected }) => {
        let newGroups = {};
        for (let [group, elements] of Object.entries(props.optGroups)) {
            if (!groupFilter || groupFilter == group) {
                newGroups[group] = elements.filter(e => (
                    (selected && values.includes(e.value))
                    || (!selected && !values.includes(e.value))
                ))
            }
        }
        return newGroups;
    }
    const availableOptions = props.options.filter(x => !values.includes(x.value));
    const selectedOptions = props.options.filter(x => values.includes(x.value));

    const [availableSelection, dispatchAvailableSelection] = useSelection(availableOptions);
    const [selectedSelection, dispatchSelectedSelection] = useSelection(selectedOptions);

    const addSelection = () => {
        setValues(values => values.concat(availableSelection));
        dispatchAvailableSelection({'type': 'clearSelection'});
    }
    const removeSelection = () => {
        setValues(values => values.filter(x => !selectedSelection.includes(x)));
        dispatchSelectedSelection({'type': 'clearSelection'});
    }
    return <Modal show modalCSSClass="two-columns-modal">
        <Modal.Header>{trans.t("Sélectionner les {{object_type}}", {object_type: props.verboseNamePlural})}</Modal.Header>
        <Modal.Body>
            {/* group filter to reduce the number of elements displayed */}
            <div className="form-inline">
                <div className="input-group"
                    style={{'marginRight': '20px', 'minWidth': '250px'}}
                >
                    <select
                        className="form-select input-sm"
                        value={groupFilter}
                        onChange={ev => setGroupFilter(ev.target.value)}
                    >
                        <option value="">Tous</option>
                        {Object.keys(props.optGroups).map(
                            group => <option value={group} key={group}>{group}</option>
                        )}
                    </select>
                </div>
            </div>
            <div className="select-grid">
                {/* Available elements column */}

                <Column
                    options={props.options.filter(x => !props.values.includes(x.value))}
                    optGroups={filterOptGroups({selected: false})}
                    selection={availableSelection}
                    dispatchSelection={dispatchAvailableSelection}
                    label={props.availableLabel}
                />
                {/* Buttons */}
                <div className="buttons">
                    <a className="btn btn-secondary" onClick={addSelection}>
                        <i className="fa fa-arrow-right"></i>
                    </a><br />
                    <a className="btn btn-secondary" onClick={removeSelection}>
                        <i className="fa fa-arrow-left"></i>
                    </a>
                </div>
                {/* Selected elements column */}
                <Column
                    options={props.options.filter(x => props.values.includes(x.value))}
                    optGroups={filterOptGroups({selected: true})}
                    selection={selectedSelection}
                    dispatchSelection={dispatchSelectedSelection}
                    label={props.selectedLabel}
                />
            </div>
        </Modal.Body>
        <Modal.Footer>
            <div className="col-md-6 text-end">
                <a className="btn btn-outline-secondary" onClick={() => props.onClose()}>
                    {trans.t("Annuler")}
                </a>
                &nbsp;
                <button className="btn btn-primary" onClick={() => props.onClose(values)}>
                    {trans.t("Valider")}
                </button>
            </div>
        </Modal.Footer>
    </Modal>
}

const getFlatOptions = optGroups => {
    let options = [];
    let all_values = Object.values(optGroups);
    if (!all_values.length) {
        return options
    }
    for (let values of all_values) {
        options = options.concat(values);
    }
    return options;
}

// We may want to display the confirmation modal if the user tries to leave the
// page after changing data
const triggerFormChangeListeners = () => {
    const form = document.getElementById('the-object-form');
    if (!form) {
        return;
    }
    let event = new CustomEvent('change');
    form.dispatchEvent(event);
}

// Needs to be used directly from another react element as well as from an empty react node
const GroupedOptionsSelector = props => {
    const [optGroups, setOptGroups] = useState([]);
    const [values, setValues] = useState(props.value.map(x => +x) || []);
    const options = getFlatOptions(optGroups);
    const verboseName = props.verboseName || "élément";
    const verboseNamePlural = props.verboseNamePlural || "éléments";
    const openModal = () => {
        let wrapper = getModalWrapper();
        const root = createRoot(wrapper); // createRoot(container!) if you use TypeScript
        const onClose = data => {
            if (data) {
                setValues(data);
                triggerFormChangeListeners();
            }
            root.unmount()
        }
        root.render(
            <TwoColumnsModal
                onClose={onClose}
                optGroups={optGroups}
                options={options}
                values={values}
                verboseName={verboseName}
                verboseNamePlural={verboseNamePlural}
                availableLabel={props.availableLabel}
                selectedLabel={props.selectedLabel}
            />
        );
    }
    const getDisplayValue = () => {
        if (!values.length) {
            return trans.t("Aucun {{object_type}} sélectionné", {object_type: verboseName});
        }
        else if(values.length == 1){
            return trans.t("1 {{object_type}} sélectionné", {object_type: verboseName});
        }
        else if (values.length == options.length) {
            return trans.t("Tous les {{object_type}} sélectionnés", {object_type: verboseNamePlural});
        }
        return trans.t("{{objects_count}} {{object_type}} sélectionnés", {objects_count: values.length, object_type: verboseNamePlural});
    }
    useEffect(
        () => {
            fetchCsrfWrapper(props.backendUrl).then(data => setOptGroups(data))
        },
        [props.backendUrl]
    );
    return (
        <>
        <select multiple className="d-none" name="employees" value={values} readOnly>
            {options.map(o => <option key={o.value} value={o.value}>{o.label}</option>)}
        </select>
        <div className="input-group" onClick={() => openModal()}>
            <div className="input-group-text">
                <i className="fa fa-user-o" />
            </div>
            <input
                type="text"
                className="form-control pointer input-sm"
                value={getDisplayValue()}
                onChange={ev => null}
            />
        </div>
        </>
    );
}

export default GroupedOptionsSelector;