import React, { useEffect, useState } from 'react'
import { fetchCsrfWrapper, GenericListStoreFactory } from 'orfeo_common/react-base.jsx';
import { SelectInput } from 'orfeo_common/Widgets.jsx';

const InstrumentsStore = GenericListStoreFactory();
const TessiturasStore = GenericListStoreFactory();

const SolistFunction = props => {
    const [instruments, setInstruments] = useState([]);
    const [tessituras, setTessituras] = useState([]);

    useEffect(() => {
        InstrumentsStore.addOnListChange(lst => setInstruments(lst));
        fetchCsrfWrapper(
            '/backend/instrumentwithgroup/', {}, {rejectOnError: true}
        ).then(data => InstrumentsStore.setList(data))
    }, []);

    useEffect(() => {
        TessiturasStore.addOnListChange(lst => setTessituras(lst));
        fetchCsrfWrapper(
            '/backend/tessitura/', {}, {rejectOnError: true}
        ).then(data => TessiturasStore.setList(data));
    }, []);

    const get_section = section_id => {
        for (let section of props.sections) {
            if (section.pk == section_id) return section
        }
    }

    const getFunctionOptions = () => {
        let group;
        let instruments_by_group = {};
        let options = [];
        for (let instrument of instruments) {
            group = get_section(instrument.group.section);
            if (group.slug == "other") {
                group = instrument.group;
            }
            if (!instruments_by_group[group.name]) {
                instruments_by_group[group.name] = [];
            }
            instrument._type = "instrument";
            instruments_by_group[group.name].push(instrument);
        }
        for (let group in instruments_by_group) {
            options.push({
                label: group,
                options: instruments_by_group[group]
            });
        }
        options.push({
            label: trans.t("Tessitures"),
            options: tessituras.map(t => {
                t._type = "tessitura";
                return t;
            })
        })
       return options;
    }

    // Handle cases where the initial function is not serialized and is only a pk
    let defaultValue = props.instrument || props.tessitura;
    if (defaultValue && !(defaultValue instanceof Object)) {
        let source = props.instrument? instruments : tessituras;
        defaultValue = source.find(elem => elem.pk == defaultValue);
    }
    return (
        <SelectInput options={getFunctionOptions()}
            placeholder="Fonction" value={defaultValue}
            onChange={props.onChange} labelKey="name"
            onClear={() => props.onClear && props.onClear()}
        />
    )
}

export {
    SolistFunction,
}