import React, { useState, useEffect } from 'react';
import { fetchCsrfWrapper } from 'orfeo_common/react-base.jsx';

import NotesManager from 'orfeo_common/react-notes.jsx';
import TaskList from 'orfeo/activities/tasks/static/tasks/js/react-tasks.jsx'

const PlanningEventTasksPanel = props => {
    const [contentTypePk, setContentTypePk] = useState(null);

    useEffect(() => {
        fetchCsrfWrapper(
            '/configuration/content-type/planningevent',
            // Since the content type will never be updated, we can use browser cache for this request
            {method: 'GET', 'cache-control': 'force-cache'}
        ).then(contentType => {
            setContentTypePk(contentType.pk);
        })
    }, []);

    if(!contentTypePk){
        return <div className="d-flex justify-content-center mt-3">
            <div className="spinner-border" role="status"><span className="visually-hidden">{trans.t("Chargement...")}</span></div>
        </div>
    }

    return (
        <div className="mt-3">
            <div className="information-block">
                <div id="tasks-list">
                    <TaskList
                        content_type={contentTypePk}
                        object_id={props.event.pk}
                        account_types="standard,tour-manager"
                    />
                </div>
            </div>

            <div className="information-block">
                <NotesManager
                    content_type={contentTypePk}
                    object_id={props.event.pk}
                />
            </div>
        </div>
    )
}

export default PlanningEventTasksPanel;