/**
 *
 *
 */

import React from 'react';
import moment from 'moment';

import { AjaxNotification } from 'orfeo_common/react-base.jsx';
import * as FormUtils from 'orfeo_common/FormUtils.jsx';
import { TimeInput } from 'orfeo_common/Widgets.jsx';
import Modal from 'orfeo_common/Modal.jsx';


const TimeInputWrapper = props => (
    <div className="input-group time-input">
        <div className="input-group-text">
            <i className="fa fa-clock-o" />
        </div>
        {props.children}
    </div>
)

const getDeltaFromInput = (input, reference) => {
    let parts = input.match(/^(\d{1,2}):(\d\d)$/);
    if(!parts)
        return null;

    // Clone the reference object and change the hours with the one
    let delta = moment(reference);
    delta.hour(parseInt(parts[1]));
    delta.minute(parseInt(parts[2]));
    return delta.diff(reference, 'seconds');
}


const BaseModal = props => {
    const {values, onChange, onKeyUp, onSubmit} = FormUtils.useForm(
        Object.assign({'start_delta': '', 'end_delta': ''}, props.eps || {}),
        handleSave,
        props.closeModal
    );
    const [errors, setErrors] = FormUtils.useFormErrors();

    function handleSave() {
        var notif_id = AjaxNotification.show(trans.t('Mise à jour...'));
        $.ajax({
            url: '/backend/eventparticipantstatus/' + props.eps.pk + '/?timeslot=1', method: "PATCH", data: {
                'start_delta': values.start_delta,
                'end_delta': values.end_delta
            }
        }).success(
            (data, status) => props.onModalClose(data)
        ).error(
            (data, status) => setErrors(data.responseJSON)
        ).always(
            () => AjaxNotification.hide(notif_id)
        );
    }

    let event_start_dt = moment(props.event.start_datetime);
    let event_end_dt = moment(props.event.end_datetime);
    return (
        <Modal show onHide={() => props.onModalClose(null)}>
            <form className="modal-content form-horizontal" onSubmit={onSubmit} onKeyUp={onKeyUp}>
                <Modal.Header>{props.technician.name} / {props.event.title}</Modal.Header>
                  <Modal.Body>
                    {Object.keys(errors).length > 0 &&
                    <p className="react-form-errors-intro">
                       {trans.t("Erreur lors de la sauvegarde.")} {FormUtils.getGlobalErrors(errors)}
                    </p>}

                    <p>
                        {event_start_dt.format('dddd LL')}
                    </p>
                    <div className="form-group">
                        <label className="col-xs-3 control-label">{trans.t("Horaire du service")}</label>
                        <div className="col-xs-4">
                            <TimeInputWrapper>
                                <input
                                    className="form-control" disabled
                                    value={event_start_dt.format('HH:mm')}
                                />
                            </TimeInputWrapper>
                        </div>
                        <div className="col-xs-4">
                            <TimeInputWrapper>
                                <input
                                    className="form-control" disabled
                                    value={event_end_dt.format('HH:mm')}
                                />
                            </TimeInputWrapper>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="col-xs-3 control-label">{trans.t("Participe ?")}</label>
                        <div className={"col-xs-9" + FormUtils.getCSSError(errors, 'start_delta')}>
                            <input type="checkbox" checked  />
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="col-xs-3 control-label">{trans.t("Horaire technicien")}</label>
                        <div className="col-xs-4">
                            <TimeInputWrapper>
                                <TimeInput
                                    className="form-control"
                                    placeholder={event_start_dt.format('HH:mm')}
                                    value={moment(event_start_dt).add(values.start_delta, 'second').format('HH:mm')}
                                    onChange={val => {
                                        let delta = getDeltaFromInput(val, event_start_dt);
                                        if(delta != null)
                                            onChange('start_delta', delta)
                                    }}
                                />
                            </TimeInputWrapper>
                            {!!values.start_delta &&
                                <p className="text-end text-muted">{values.start_delta / 60} min</p>
                            }
                        </div>
                        <div className="col-xs-4">
                            <TimeInputWrapper>
                                <TimeInput
                                    className="form-control"
                                    placeholder={event_end_dt.format('HH:mm')}
                                    value={moment(event_end_dt).add(values.end_delta, 'second').format('HH:mm')}
                                    onChange={val => {
                                        let delta = getDeltaFromInput(val, event_end_dt);
                                        if(delta != null)
                                            onChange('end_delta', delta)
                                    }}
                                />
                            </TimeInputWrapper>
                            {!!values.end_delta &&
                                <p className="text-end text-muted">{values.end_delta / 60} min</p>
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <a className="btn btn-outline-secondary" onClick={() => props.onModalClose(null)}>{trans.t("Annuler")}</a>
                    <button className="btn btn-primary">{trans.t("Sauvegarder")}</button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}


export {
    getDeltaFromInput,
    TimeInputWrapper
} ;
