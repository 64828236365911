import React, { useState } from "react";

import PropTypes from "prop-types";
import * as FormUtils from "orfeo_common/FormUtils.jsx";
import Modal from "orfeo_common/Modal.jsx";
import { AutosizeTextarea } from 'orfeo_common/Widgets.jsx';
import {fetchWrapper} from "orfeo_common/react-base.jsx";


const CancelElectronicSignatureModalProps = {
    // Contracts pks
    pks: PropTypes.arrayOf(PropTypes.number),
    // endpoint to viewset of type SignableDocumentViewSetMixin
    document_type: PropTypes.string,
    onCloseModal: PropTypes.func,
    onSuccess: PropTypes.func
};
const CancelElectronicSignatureModal = (props) => {
    const [reason, setReason] = useState();
    const [errors, setErrors] = FormUtils.useFormErrors();

    // Cancellation can take some time so we warn the user about it
    const [postConfirmationMessage, setPostConfirmationMessage] = useState(false);

    const onSend = () => {
        fetchWrapper("/backend/" + props.document_type + "/cancel_electronic_signatures/", {
            method: "POST",
            body: {pks: props.pks, reason: reason},
        }).then((data) => {
            setErrors({});
            setPostConfirmationMessage(true)
            setTimeout(
                () => props.onSuccess(),
                4500
            );
        })
        .catch((data) => {
            setErrors(data);
        });
    };

    return (
        <Modal show onHide={props.onCloseModal}>
            <Modal.Header>{trans.t("Annuler les demandes de signature")}</Modal.Header>
            <Modal.Body>
                <p>{trans.t("Êtes-vous sûr(e) de vouloir annuler les demandes de signature ?")}</p>
                <AutosizeTextarea
                    className="form-control"
                    value={reason}
                    disabled={postConfirmationMessage}
                    name="reason"
                    placeholder="Motif d'annulation"
                    onChange={(ev) => {
                        setReason(ev.target.value);
                    }}
                />
                <FormUtils.ErrorText field="reason" errors={props.errors} />
                {Object.keys(errors).length > 0 && (
                    <p className="react-form-errors-intro">
                        {trans.t("Erreur lors de la sauvegarde")} {FormUtils.getGlobalErrors(errors)}
                    </p>
                )}

                {postConfirmationMessage &&
                <div className="mt-4">
                    <hr />
                    <h5>{trans.t("Demande d'annulation en cours de traitement")}</h5>
                    <p className="text-success">
                        {trans.t(
                            "Le temps de prise en compte est de l'ordre de quelques secondes,"
                            + " durant laquelle le document peut encore apparaitre comme en signature."
                        )}
                    </p>
                </div>}
            </Modal.Body>
            <Modal.Footer>
                <a className="btn btn-outline-secondary" onClick={props.onCloseModal}>
                    {trans.t("Fermer")}
                </a>
                <a
                    className={"btn btn-primary " + (postConfirmationMessage ? "disabled": "")}
                    onClick={onSend}
                >
                    {trans.t("Valider")} {postConfirmationMessage && <i className="fa fa-check"></i>}
                </a>
            </Modal.Footer>
        </Modal>
    );
};
CancelElectronicSignatureModal.propTypes = CancelElectronicSignatureModalProps;

export default CancelElectronicSignatureModal;
