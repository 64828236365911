import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import 'moment-range';

import { getOrgProfileLabel } from 'orfeo/accounts/jsx/utils';
import { fetchCsrfWrapper, pluralize } from 'orfeo_common/react-base.jsx';
import { getCSSError, ErrorText } from 'orfeo_common/FormUtils.jsx';
import { CalendarInput, SelectInput, TimeInput } from 'orfeo_common/Widgets.jsx';
import Tooltip from 'orfeo_common/Tooltip.jsx'

import EntityAddForm from 'orfeo/entities/jsx/EntityAddForm.jsx';

import { PAY_CATEGORIES } from '../Utils.jsx';


const DaysWorkedSelector = props => {
    const [planning, setPlanning] = useState(null);
    const selectorNode = useRef(null);

    useEffect(() => {
        if(!props.readOnly) {
            $(selectorNode.current).datepicker({
                multidate: true,
                format: 'yyyy-mm-dd',
                language: 'fr',
            }).on("changeDate", function (datepicker) {
                if (datepicker.dates.length == 0 && props.days.length > 0)
                    props.onChange([]);

                props.onChange($(selectorNode.current).datepicker('getFormattedDate').split(',').filter(d => d !== ""))
            });
        }

        if(props.displayPlanning && props.participant) {
            let daysWorked = {};
            props.days.forEach(e => {
                daysWorked[e] = 0
            })
            fetchCsrfWrapper(
                "/orchestra/backend/projectparticipant/" + props.participant + "/get_events/?activity_type=rehearsal,performance"
            ).then(
                data => {
                    data.forEach(e => {
                        let day = moment(e.start_datetime).format("YYYY-MM-DD")
                        let nb_events = daysWorked[day];
                        if (nb_events !== undefined) {
                            daysWorked[day] = nb_events + 1
                        }
                    })
                    setPlanning(
                        Object.entries(daysWorked).map(entry => {
                            let day_str = moment(entry[0]).format("ddd DD MMM YYYY")
                            return day_str + " : " + entry[1] + " service" + (entry[1] != 1 ? "s" : "")
                        }).join("<br/>")
                    )
                }
            ).catch(
                () => setPlanning(trans.t("Erreur de chargement"))
            )
        }
    }, []);

    if(!props.readOnly) {
        useEffect(() => {
            $(selectorNode.current).datepicker('setStartDate', props.boundStartDate);
            $(selectorNode.current).datepicker('setEndDate', props.boundEndDate);
        }, [props.boundStartDate, props.boundEndDate]);
    }

    return (
        <span>
            {props.days.length + ' ' + pluralize('jour', props.days.length)}&nbsp;
            {!props.readOnly && (
                <button className="btn btn-xs btn-secondary" type="button"
                    value={props.days.join(',')} ref={selectorNode} data-date={props.days.join(',')}>
                    <i className="fa fa-calendar"></i>
                </button>
            )}
            {(props.displayPlanning && planning) && (
                <Tooltip title={planning} placement="right" className="tooltip-semi-wide" html>
                    &nbsp;<i className="fa fa-list-ul"></i>
                </Tooltip>
            )}
        </span>
    );
}


const getRangeDateList = (start, end) => {
    if(!start || !end)
        return [];

    return (
        Array.from(moment.range(start, end).by('days'))
             .map(x => x.format('YYYY-MM-DD'))
    );
}


const PayCategoryField = props => (
    <select
        className="form-select input-sm"
        onChange={ev => props.onFieldChange('pay_category', [ev.target.value, ''])}
        value={props.value ? props.value[0] : null}
    >
        {PAY_CATEGORIES.map(
            item => <option value={item[0]} key={item[0]}>{item[1]}</option>
        )}
    </select>
);

const ProfessionField = props => (
    <SelectInput
        backendURL="/backend/profession/" value={props.value}
        onChange={instance => props.onFieldChange('profession', instance)}
    />
);

const StartTimeField = props => (
    <TimeInput
        defaultValue={props.value}
        onChange={val => props.onFieldChange('start_time', val)}
    />
)

const WorkPlacesField = props => { 
    return(
        <input
        type="text" className="form-control input-sm" maxLength="500"
        onChange={ev => props.onFieldChange('work_places', ev.target.value)}
        value={props.value || ''}
        />
    );
    
};

const NotesField = props => (
    <input
        type="text" className="form-control input-sm" maxLength="500" value={props.value || ''}
        onChange={ev => props.onFieldChange('notes', ev.target.value)}
    />
);


const GeneralSectionFields = (props) => {
    const [showOrgProfileInput, setShowOrgProfileInput] = useState(false);
    const obj = props.engagement;
    const shouldDisplayOrgProfileChoice =
        obj.projects && obj.projects.length == 0 && (window["HAS_SEVERAL_ORG_PROFILES"] || true);

    const start_date = useRef(null);
    const end_date = useRef(null);

    useEffect(() => {
        if(obj['days_worked'] === undefined) {
            props.onFieldChange('days_worked', getRangeDateList(obj['start_date'], obj['end_date']))
        }
        if(props.show_temporal_fields) {
            // Set relation between temporals fields to forbid invalid inputs
            start_date.current.setRelation('end', end_date.current);
        }
    }, []);

    const onStartDateChange = (new_date) => {
        if(!new_date)
            return;

        // When all days where selected as "worked" before, extend/shrink the selection to match
        // the new boundaries. Otherwise, just remove days becoming outbounds
        let start_obj = moment(props.engagement.start_date);
        let end_obj = moment(props.engagement.end_date);
        let new_days_worked = null;
        if(new_date > start_obj) {
            new_days_worked = props.engagement.days_worked.filter(x => moment(x) >= new_date);
        }
        else if(props.engagement.days_worked.length == end_obj.diff(start_obj, 'days') + 1) {
            new_days_worked = getRangeDateList(new_date.format('YYYY-MM-DD'), props.engagement.end_date);
        }

        if(new_days_worked)
            props.onFieldChange('days_worked', new_days_worked)

        props.onFieldChange('start_date', new_date.format('YYYY-MM-DD'));
    }

    const onEndDateChange = (new_date) => {
        if(!new_date)
            return;

        // Apply same behaviour as in onStartDateChange, but with the end_date
        let start_obj = moment(props.engagement.start_date);
        let end_obj = moment(props.engagement.end_date);
        let new_days_worked = null;
        if(new_date < end_obj) {
            new_days_worked = props.engagement.days_worked.filter(x => moment(x) <= new_date);
        }
        else if(props.engagement.days_worked.length == end_obj.diff(start_obj, 'days') + 1) {
            new_days_worked = getRangeDateList(props.engagement.start_date, new_date.format('YYYY-MM-DD'));
        }

        if(new_days_worked)
            props.onFieldChange('days_worked', new_days_worked)

        props.onFieldChange('end_date', new_date.format('YYYY-MM-DD'));
    }

    return (
        <React.Fragment>
            {props.show_temporal_fields &&
            <div className="row">
                <p className="col-xs-3">
                    {trans.t("Période d'engagement")}
                </p>
                <div className={"col-xs-3" + getCSSError(props.errors, 'start_datetime')}>
                    <CalendarInput
                        ref={start_date} defaultValue={obj.start_date}
                        onChange={onStartDateChange} placeholder={trans.t("Date de début")}
                        key={obj.start_date}
                    />
                </div>
                <div className={"col-xs-2" + getCSSError(props.errors, 'start_datetime')} style={{'paddingLeft': '0'}}>
                    <StartTimeField value={obj.start_time} onFieldChange={props.onFieldChange} />
                </div>
                <div className={"col-xs-4" + getCSSError(props.errors, 'end_date')}>
                    <CalendarInput
                        ref={end_date} defaultValue={obj.end_date}
                        onChange={onEndDateChange} placeholder={trans.t("Date de fin")}
                        key={obj.end_date}
                    />
                </div>
            </div>}

            {props.show_temporal_fields &&
            <div className="row">
                <p className="col-xs-3">
                    {trans.t("Jours travaillés")}
                </p>
                <div className="col-xs-3">
                    <DaysWorkedSelector
                        readOnly={VARIANT == 'orchestra' && !(props?.employee?.type == 'technicien')}
                        displayPlanning={VARIANT == 'orchestra' && !(props?.employee?.type == 'technicien')}
                        participant={props.engagement.participant}
                        onChange={lst => props.onFieldChange('days_worked', lst)}
                        days={obj.days_worked || []}
                        boundStartDate={obj.start_date} boundEndDate={obj.end_date}
                    />
                    <ErrorText errors={props.errors} field='days_worked' />
                </div>
            </div>}

            <div className="row">
                <p className="col-xs-3">
                    {trans.t("Catégorie de salarié")}
                </p>
                <div className="col-xs-9">
                    <PayCategoryField value={obj.pay_category} onFieldChange={props.onFieldChange} />
                </div>
            </div>

            <div className="row">
                <p className="col-xs-3">
                    {trans.t("Profession")}
                    {props.profession_field_hint}
                </p>
                <div className={"col-xs-9 " + getCSSError(props.errors, 'profession')}>
                    <ProfessionField
                        value={obj.profession} onFieldChange={props.onFieldChange}
                        key={obj['_ReactID'] || '0'}
                    />
                    <ErrorText errors={props.errors} field='profession' />
                </div>
            </div>

            <div className="row">
                <p className="col-xs-3">
                    {trans.t("Lieux de travail")}
                </p>
                <div className="col-xs-9">
                    <WorkPlacesField value={obj.work_places} onFieldChange={props.onFieldChange} />
                </div>
            </div>

            <div className="row">
                <p className="col-xs-3">
                    {trans.t("Notes")}
                </p>
                <div className="col-xs-9">
                    <NotesField value={obj.notes} onFieldChange={props.onFieldChange} />
                </div>
            </div>
            {shouldDisplayOrgProfileChoice && (showOrgProfileInput ? (
                <SelectInput
                    backendURL={"/backend/organizationprofile/"}
                    value={obj.org_profile}
                    placeholder={trans.t("Organisation")}
                    onChange={(instance) => props.onFieldChange("org_profile", instance)}
                />
                ) : (
                    <div className="row">
                        <div className="col-xs-3">
                            <a role="button" className="action-link" onClick={setShowOrgProfileInput}>
                                {trans.t("Modifier l'organisation")}
                            </a>
                        </div>
                        <div className="col-xs-9">
                            {obj.org_profile && getOrgProfileLabel(obj.org_profile)}
                        </div>
                    </div>
                )
            )}
        </React.Fragment>)
}


export {
    DaysWorkedSelector,
    PayCategoryField,
    ProfessionField,
    StartTimeField,
    WorkPlacesField,
    NotesField,

    GeneralSectionFields
};
