import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { hasPerm } from 'orfeo_common/react-base.jsx';
import { isReadOnly } from 'orfeo_common/FormUtils.jsx';
import Tooltip from 'orfeo_common/Tooltip.jsx';

import EngagementsStore from './EngagementsStore.jsx';
import { CONTRACT_TYPES, openEngagementModal, EngagementGlobalContext } from './Utils.jsx';

const showEngagementType = () => window['VARIANT'] == 'orchestra';

const getMissingFieldsMessage = (line_data) => {
    let fieldInError = null
    if(['orchestra', 'venue'].includes(window['VARIANT']) && ['technicien', 'staff-member'].includes(line_data.employee.type)){
        if(line_data.pre_engagement.projects.length == 0){
            fieldInError = trans.t("le projet")
        }
        else if(!line_data.pre_engagement?.profession){
            fieldInError = trans.t("la profession")
        }
        if(fieldInError){
            return trans.t("Veuillez préciser {{missing_field}} des créneaux de travail depuis le planning du personnel.", {missing_field: fieldInError});
        }
    }
    return null
}
/**
 * Display a table fragment (represented by a <thead> + <tbody>) collapsable, that list engaements,
 * and possible mass actions.
 * To be able to apply mass actions to all items, the engagements must share the same state for a
 * given section (established, to sign, signed, etc.)
 */
const EngagementsTableProps =  {
    // Employees being displayed: list of objects with at employee & engagement (nullable) attributes
    employees_data: PropTypes.arrayOf(PropTypes.object),
    // Current state of engagements passed
    list_state: PropTypes.string.isRequired,
    // Section name. If not provided, it will fallback on state label
    header_name: PropTypes.string,
    // List of mass actions possible, represented by a list of callbacks, that takes the
    // current selection as param and returns a JSX node that can reference the selection
    mass_actions_buttons: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
    ),
    // The modal tab being opened when clicking on an engagement
    default_modal_tab: PropTypes.string,
    // Render extra fields depending on current engagement available
    render: PropTypes.func
};
const EngagementsTable = (props) => {
    let type_infos = EngagementsStore.types_dict[props.list_state || 'no_engagement'];

    const [collapsed, setCollapsed] = useState(false);
    const [lastChecked, setLastChecked] = useState(null);
    const context = useContext(EngagementGlobalContext);

    // Listen to the collapse/uncollapse global event. We prefer to handle a boolean
    // at the component level than briging it from a list from the parent component
    useEffect(() => {
        const handleCollapseAll = ev => {
            setCollapsed(ev.detail == 'collapsed')
        }
        document.addEventListener('ContractsManager.setCollapseAll', handleCollapseAll);
        return () => {
            document.removeEventListener('ContractsManager.setCollapseAll', handleCollapseAll);
        };
    });

    // Retrieve from context elements belonging to the current category
    let selection = context.selections[props.list_state] || [];
    let employees_data = props.employees_data;
    if(context.listFilterCallback)
        employees_data = context.listFilterCallback(employees_data);

    // Handle checkbox
    const allSelected = (selection.length > 0 && selection.length == employees_data.length);
    const onAllSelection = ev => {
        context.onSelection([
            props.list_state, {
            type: 'set',
            new_list: allSelected ? [] : employees_data.filter(x => !getMissingFieldsMessage(x)).map(x => x.uid)
        }])
    }

    const onSingleSelection = (ev, participant) => {
        let line_uid = participant.uid;
        if(lastChecked && ev.nativeEvent.shiftKey && lastChecked != line_uid) {
            // get all ids between the lastChecked and the current one
            let inrange = false;
            let new_selection = selection.slice().concat([line_uid]);
            for(let p of employees_data) {
                if(p.pk == lastChecked || p.pk == line_uid)
                    inrange = !inrange;
                if(inrange && selection.indexOf(p.pk) === -1)
                    new_selection.push(p.pk);
            }
            context.onSelection([props.list_state, {new_list: new_selection, type: 'set'}]);
        }
        // A single checkbox is manipulated, just switch its state.
        else {
            context.onSelection([props.list_state, {pk: line_uid, type: ev.target.checked ? 'add' : 'remove'}]);
        }
        // Keep in memory the current checkbox as the last checked
        setLastChecked(line_uid);
    }

    if(employees_data.length == 0)
        return null;

    return (
    <React.Fragment>
        <thead>
        <tr className="category-header">
            <td colSpan="999">
                {props.header_name !== null &&
                    <h4 onClick={ev => setCollapsed(!collapsed)} className="pointer">
                        <i className={"fa fa-angle-" + (collapsed ? "right": "down")}></i>
                        {props.header_name || type_infos['verbose_name']}
                        <span className="badge" style={{'backgroundColor': type_infos['color']}}>{employees_data.length}</span>
                    </h4>
                }

                {(!isReadOnly() && !collapsed && props.mass_actions_buttons.length > 0) &&
                <div className="btn-group float-end" role="group" style={{'marginTop': '8px'}}>
                    <button
                        type="button" className="btn btn-secondary dropdown-toggle"
                        disabled={selection.length == 0}
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    >
                        {trans.t("Actions multiples")}&nbsp; <i className="caret"></i>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-right">
                        {props.mass_actions_buttons
                            // as a shortcut, parent might send null instead of a button when the user can't see
                            // the action due to lack of permission.
                            .filter(x => !!x)
                            .map((btn_callback, idx) => <li key={idx}>{btn_callback(selection)}</li>)
                        }
                    </ul>
                </div>}
            </td>
        </tr>
        {!collapsed &&
            <tr>
                {!isReadOnly() &&
                <th style={{'width': '16px'}}>
                    {employees_data.length > 0 &&
                        <input type="checkbox" checked={allSelected} onChange={onAllSelection} />
                    }
                </th>}
                <th style={{'width': '245px'}}>{trans.t("Nom")}</th>
                {showEngagementType() &&
                    <th>{trans.t("Type d'engagement")}</th>
                }
                {(props.additional_headers || []).length > 0 && props.additional_headers.map(header => (
                    <th key={header}>{header}</th>
                ))}
                <th>{trans.t("Profession")}</th>
                <th>{trans.t("Dates contrat")}</th>
                <th>{trans.t("Salaire")}</th>
                <th>{trans.t("Défraiements")}</th>
                {hasPerm('administration.allow_dpae_through_api') && <th style={{'width': '28px'}}>{trans.t("DPAE")}</th>}
                <th style={{'width': '28px'}}>{trans.t("Contrat")}</th>
                {!isReadOnly() && <th style={{'width': '235px'}} className="text-center">{trans.t("Actions")}</th>}
            </tr>
        }
        </thead>

        {!collapsed &&
            <tbody>
            {employees_data.map(
                line_data => {
                    let contract_type = (
                        line_data.engagement ?
                            line_data.engagement.contract_type
                            : line_data.pre_engagement.possible_contract_type
                    ) || 'standard';
                    const missingFieldMessage = getMissingFieldsMessage(line_data);

                    return (
                    <Tooltip
                        title={missingFieldMessage}
                        key={line_data.uid}
                        tagName="tr"
                        className={classNames({
                            "inline-editable": !isReadOnly() && !missingFieldMessage,
                            "no-engagement": !line_data.engagement
                        })}
                        onClick={ev => {
                            if(!missingFieldMessage){
                                openEngagementModal(
                                    line_data,
                                    props.default_modal_tab || 'general',
                                    (data, action) => EngagementsStore.updateSingleEngagement(
                                        line_data.uid, data, action
                                    )
                                )
                            }
                        }}
                    >
                        {!isReadOnly() &&
                        <td onClick={ev => ev.stopPropagation()}>
                            <input
                                type="checkbox"
                                disabled={missingFieldMessage}
                                onChange={ev => onSingleSelection(ev, line_data)}
                                checked={selection.indexOf(line_data.uid) !== -1}
                            />
                        </td>}
                        <td>
                            <a href={line_data.absolute_url} onClick={ev => ev.stopPropagation()}>
                                {line_data.employee.name}
                            </a><br />
                            {line_data.role_name && <small className="text-muted">{line_data.role_name}</small>}
                        </td>
                        {showEngagementType() &&
                        <td>
                            {CONTRACT_TYPES.filter(([k, v]) => k == contract_type)[0][1]}
                        </td>}
                        {props.render(line_data)}
                    </Tooltip>
                    );
                }
            )}
            </tbody>
        }
    </React.Fragment>
    );
}
EngagementsTable.propTypes = EngagementsTableProps;


export default EngagementsTable;
export { getMissingFieldsMessage }
