import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
    fetchCsrfWrapper,
    GenericListStoreFactory,
} from 'orfeo_common/react-base.jsx';
import Modal from 'orfeo_common/Modal.jsx';
import * as FormUtils from 'orfeo_common/FormUtils.jsx';
import { CalendarInput, SelectInput } from 'orfeo_common/Widgets.jsx';

const OrgProfilesStore = GenericListStoreFactory();
function getOrgProfileByPk(pk) {
    let lst = OrgProfilesStore.getList();
    let res = lst.filter(x => x.pk == pk);
    if(res.length > 0)
        return res[0];
    else if(!pk && lst.length > 0)
        return lst[0];

    return null;
}

const LABELS = {
    'invoice': trans.t("facture"),
    'creditnote': trans.t('avoir'),
    'quotation': trans.t('devis'),
}

const LABELS_WITH_ARTICLE = {
    'invoice': trans.t('une facture'),
    'creditnote': trans.t('un avoir'),
    'quotation': trans.t('un devis'),
}

const AccountingDocumentAddModal = props => {
    let initials = props.initials || {};
    const {values, onChange, onKeyUp, onSubmit} = FormUtils.useForm(
        Object.assign(
            {
                'title': '',
                'number': '',
                'date': moment().format("YYYY-MM-DD"),
                'entity': props.entity ? {'pk': props.entity.pk} : null
            },
            props.initials || {}
        ),
        handleSave,
        props.onClose
    );
    const [errors, setErrors] = FormUtils.useFormErrors();

    const [org_profile, setOrgProfile] = useState(getOrgProfileByPk(props.org_profile));
    const [loading, setLoading] = useState(OrgProfilesStore.count() == 0);

    // Load organization profiles if it hasn't been done yet. These data are needed
    // to check if the user needs to input a document number
    useEffect(() => {
        if(OrgProfilesStore.count() == 0 || OrgProfilesStore._type_loaded != props.type) {
            fetchCsrfWrapper(
                '/backend/organizationprofile/?accountingdocument=' + props.type,
            ).then(data => {
                // Cache result in case the modal is re-opened later
                OrgProfilesStore.setList(data);
                OrgProfilesStore._type_loaded = props.type;

                // Set correct org_profile data once data are loaded
                let org_profile_selected = getOrgProfileByPk(props.org_profile);
                setOrgProfile(org_profile_selected)
                setLoading(false);
            })
        }
    }, []);

    function handleSave() {
        let post_data = {
            'type': props.type,
            'org_profile': {'pk': org_profile.pk},
            'title': values.title,
            'number': 'DRAFT',
        }

        if(props.project_contract)
            post_data['project_contract'] = {'pk': props.project_contract};
        if(props.opportunity)
            post_data['opportunity'] = {'pk': props.opportunity};
        if(props.currency)
            post_data['currency'] = {'pk': props.currency};

        if(props.entity)
            post_data['entity'] = {'pk': props.entity}
        else if(values.entity)
            post_data['entity'] = {'pk': values.entity.pk}

        // When the document number is generated automatically, number is determined by the backend
        // and the date is forced to the current one to ensure integrity of the sequential numbering
        if(numberNeeded) {
            post_data['date'] = values.date;
            post_data['number'] = values.number
        }

        if(props.type === 'quotation' && org_profile && org_profile.has_accounting_sequence_number){
            delete post_data['number']
        }

        if(props.document_nature){
            post_data['document_nature'] = props.document_nature;
        }

        fetchCsrfWrapper(
            '/backend/accountingdocument/',
            {method: "POST", body: post_data},
        ).then(data => {
            props.onModalClose(data);
            window.location = data.absolute_url;
        }).catch(
            data => setErrors(data)
        );
    }

    const numberNeeded = (org_profile && !org_profile.has_accounting_sequence_number && props.type === 'quotation');
    return (
        <Modal show onHide={() => props.onModalClose(null)}>
            <form className="modal-content form-horizontal" onSubmit={onSubmit} onKeyUp={onKeyUp}>
                <Modal.Header>
                    {trans.t("Ajouter {{document_type}}", {document_type: LABELS_WITH_ARTICLE[props.type]})}
                    {(org_profile && OrgProfilesStore.count() > 1) &&
                        <span className="text-muted"> - {org_profile.name}</span>
                    }
                </Modal.Header>
                <Modal.Body>
                    {Object.keys(errors).length > 0 &&
                    <p className="react-form-errors-intro">
                       {trans.t("Erreur lors de la sauvegarde.")} {FormUtils.getGlobalErrors(errors)}
                    </p>}

                    {(!props.org_profile && OrgProfilesStore.count() > 1) &&
                        <div className="row mb-1">
                            <p className="col-xs-4">{trans.t("Organisation")}</p>
                            <div className={"form-group col-xs-8" + FormUtils.getCSSError(errors, 'date')}>
                                <SelectInput
                                    options={OrgProfilesStore.getList()}
                                    value={org_profile}
                                    onChange={val => setOrgProfile(val)}
                                />
                            </div>
                            <FormUtils.ErrorText field='date' errors={errors} />
                        </div>
                    }

                    <div className="row mb-1">
                        <p className="col-xs-4">{trans.t("Date")} {LABELS[props.type]}</p>
                        <div className={"form-group col-xs-8" + FormUtils.getCSSError(errors, 'date')}>
                            <CalendarInput
                                defaultValue={values.date}
                                onChange={val => onChange('date', val.format("YYYY-MM-DD"))}
                                disabled={!numberNeeded}
                            />
                            <div><FormUtils.ErrorText field='date' errors={errors} /></div>
                        </div>
                    </div>

                    {numberNeeded &&
                    <div className={"row mb-1" + FormUtils.getCSSError(errors, 'number')}>
                        <p className="col-xs-4">{trans.t("Numéro")}</p>
                        <div className="form-group col-xs-8">
                            <input
                                type="text" className="form-control input-sm" autoFocus
                                name="number" value={values.number} onChange={onChange}
                            />
                            <FormUtils.ErrorText field='number' errors={errors} />
                        </div>
                    </div>}

                    <div className={"row mb-1" + FormUtils.getCSSError(errors, 'title')}>
                        <p className="col-xs-4">{trans.t("Objet")}</p>
                        <div className="form-group col-xs-8">
                            <input
                                type="text" className="form-control input-sm" maxLength="255"
                                name="title" value={values.title} onChange={onChange}
                            />
                            <FormUtils.ErrorText field='title' errors={errors} />
                        </div>
                    </div>

                    {!props.entity &&
                    <div className={"row mb-1" + FormUtils.getCSSError(errors, 'entity')}>
                        <p className="col-xs-4">{trans.t("Entité facturée")}</p>
                        <div className="form-group col-xs-8">
                            <SelectInput
                                backendURL='/backend/entity/' initialLoad={false}
                                value={values.entity} placeholder={trans.t("Saisissez une entité")}
                                onChange={val => onChange('entity', val)}
                                objectRepresentation={x => x.hasOwnProperty('label') ?x.label : x.fiscal_name || x.name}
                                creatable createURL='/backend/structure/'
                                formatCreateLabel={
                                    inputValue => <a className="action-link">{trans.t('Créer la structure "{{inputValue}}"', { inputValue })}</a>
                                }
                            />
                            <FormUtils.ErrorText field='entity' errors={errors} />
                        </div>
                    </div>}

                    {(numberNeeded && org_profile.last_documents_numbers.length > 0) &&
                        <p className="text-info-emphasis">
                            <i className="fa fa-info-circle"></i>
                            {trans.t("Pour information, les derniers numéros utilisés sont")} : 
                            {org_profile.last_documents_numbers.join(', ')}
                        </p>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <a className="btn btn-outline-secondary" onClick={() => props.onModalClose(null)}>{trans.t("Annuler")}</a>
                    <button className="btn btn-primary" disabled={!org_profile}>{trans.t("Créer")}</button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}


export default AccountingDocumentAddModal;
