/**
 * Component EmailViewer, able to display one e-mail (body and its headers).
 *
 * This component is used at several places to display the body of a message
 * and its meta information (sender/recipients...)
 */
'use strict';
import React, { useState, useEffect, useRef } from 'react';

import EmailComposer from './EmailComposer.jsx';
import { fetchWrapper } from 'orfeo_common/utils/http.jsx';
import { humanFileSize } from 'orfeo_common/utils/files.jsx';
import { get_mimetype_icon } from './utils.jsx';

/**
 * List of attachments, with links to download, for a single mail
 */

const EmailAttachments = ({attachments, message_id}) => {
    if(attachments.length == 0)
        return false;

    var base_url = '/mails/api/attachments/download/?msgId=' + encodeURIComponent(message_id) + '&attachmentId=';
    return (
        <ul className="email-attachments">
        {attachments.map(
            attach => (
                <li key={attach.attachment_id}>
                    {get_mimetype_icon(attach.mime_type)}&nbsp;
                    <a href={base_url + encodeURIComponent(attach.attachment_id)}>
                        {attach.filename}
                    </a>&nbsp;({humanFileSize(attach.size)})
                </li>
            )
        )}
        </ul>
    );
}


function prettify_email(obj) {
    if(obj == null)
        return '';

    if(obj.full_name != '')
        return obj.full_name + ' <' + obj.email + '>';

    return obj.email;
}

function render_addresses_list(email, addresses) {
    return (
        // For each address, append an "eye" to indicate if the recipient has opened the email
        addresses.map(function(addr, idx) {
            let nb_opens = email.opening_trackers[addr.email] || 0;
            if(nb_opens > 0) {
                return (
                    <span key={'email'+idx}>
                        {addr.email} <i className="fa fa-envelope-open-o text-success"
                                        title={trans.t("A ouvert le mail")} style={{'margin': '0 5px'}}></i>
                    </span>
                );
            }

            return addr.email;
        })
        // Join each value extracted with a comma, using a reduce to ease React usage
        .reduce(function(accu, elem) {
            return !accu.length ? [elem] : [accu, ', ', elem];
        }, [])
    );
}


/**
 * Show the full mail, including all recipients/sender, date, body and attachments.
 * Clicking on the headers will collapse the mail in its default state
 */
const EmailViewer = props => {
    const [composerMode, setComposerMode] = useState('');
    const iframeNode = useRef();

    useEffect(() => {
        if(!!iframeNode.current) {
            let iframeWin = iframeNode.current.contentWindow;
            iframeWin.document.write(props.email.html_body.replace(/<script/g, '&lt;script'));

            // The iframe is not loaded directly on mounting synchronously,
            // we need to wait a tiny bit so it is correctly initialized
            setTimeout(function () {
                // Adjust height of the mail if the mail is smaller than the initial size set
                let outer_div = iframeNode.current;
                let container = (iframeWin.document.body || outer_div);
                if(container && outer_div && container.height < outer_div.offsetHeight) {
                    $(outer_div).height(height + 50);
                }

                // Add _blank to all link in iframe so they don't open inside the sandbox
                let anchors = iframeWin.document.getElementsByTagName("a");
                for (let i in anchors) {
                    if(typeof anchors[i] != "object")
                        continue
                    anchors[i].setAttribute("target", "_blank");
                }
            }, 60);
        }
    }, [props.email.message_id]);

    const trashMessage = ev => {
        ev.stopPropagation();

        if(confirm(
            trans.t("Êtes-vous sûr de vouloir supprimer l'e-mail suivant ?") + "\n"
            + "« " + props.email.subject + " »\n"
            + trans.t("Il sera déplacé dans la corbeille de votre messagerie.")
        )) {
            // Apply the callback before the fetch query since it can take several
            // seconds to trash the message on the endpoint and we don't want to freeze
            // the whole interface during the operation
            if(props.onMessageTrashed)
                props.onMessageTrashed(props.email)

            fetchWrapper(
                "/mails/api/trash/",
                {method: "POST", body: {'message_id': props.email.message_id}},
                {pendingMessage: trans.t("Mise à la corbeille..."), contentType: 'form-data'}
            ).catch(
                (data, status) => alert(
                    trans.t("Une erreur est survenue lors de la mise à la corbeille")
                )
            )
        }
    }

    const email = props.email;
    let sending_info = [];
    if(email.outbound && email.outbound.results) {
        for(let address in email.outbound.results) {
            let status = email.outbound.results[address];
            if(status != 'OK')
                sending_info.push(address + ' : ' + status);
        }
    }
    return (
        <div className="email-full-message" data-id={email.message_id}>
            {composerMode != '' &&
            <div>
                <EmailComposer
                    parent={props.email}
                    mode={composerMode}
                    entities={window['ENTITY'] ? [window['ENTITY'].pk] : null}
                    onCancel={() => setComposerMode('')}
                    onMailSent={() => setComposerMode('')}
                    composerInfosUrl="/mails/api/composer/init/"
                />

                <hr style={{'height': '2px', 'backgroundColor': '#ccc'}} />
            </div>
            }

            <div onClick={props.onHeaderClick} className="email-headers">
                <div className="row main-row">
                    <div className="col-md-9">
                        <i className="fa fa-caret-down"></i> {prettify_email(email.email_from)}
                    </div>
                    {props.hasEmailAccount &&
                    <div className="col-md-3 text-end" onClick={ev => ev.stopPropagation()}>
                        <div className="thread-actions">
                            <a role="button" onClick={() => setComposerMode('reply')}>
                                <i className="fa fa-reply" title={trans.t("Répondre")}></i>
                            </a>
                            <a role="button" onClick={() => setComposerMode('reply-all')}>
                                <i className="fa fa-reply-all" title={trans.t("Répondre à tous")}></i>
                            </a>
                            <a role="button" onClick={() => setComposerMode('forward')}>
                                <i className="fa fa-share" title={trans.t("Transférer")}></i>
                            </a>
                            {email.imported_by == PROFILE_PK &&
                            <a role="button" onClick={trashMessage} style={{'color': '#666'}}>
                                <i className="fa fa-trash" title={trans.t("Supprimer")}></i>
                            </a>}
                        </div>
                    </div>}
                </div>
                <div className="row">
                    <div className="col-md-9">
                    À : {render_addresses_list(email, email.email_to)}
                    <br />
                    {email.email_cc.length > 0 &&
                        <span>Cc : {render_addresses_list(email, email.email_cc)}</span>
                    }
                    {email.email_replyto.length > 0 &&
                        <span>{trans.t("Répondre à : {{reply_to}}", {reply_to: email.email_replyto.map(obj => obj.email).join(', ')})}</span>
                    }
                    </div>
                </div>

                {(email.outbound && email.outbound.error_reason) &&
                <p className="sending-alert-report">
                    <i className="fa fa-warning"></i>&nbsp;{email.outbound.error_reason}
                </p>}
            </div>

            {email.html_body ?
                <iframe className="email-body html-iframe" ref={iframeNode}></iframe>
            :
                <div className="email-body text-body">{email.text_body}</div>
            }
            <EmailAttachments message_id={email.message_id} attachments={email.attachments} />
        </div>
    );
}


export default EmailViewer;
