/**
 * Define React components to manage the programmation a Concert/PlanningEvent. It is defined
 * by a list of Work.
 */
import React, { useState, useRef, useEffect } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { pluralize, hasPerm, hasUserPerm } from 'orfeo_common/react-base.jsx';
import * as FormUtils from 'orfeo_common/FormUtils.jsx';
import { secondsToHHmm } from 'orfeo_common/CalendarUtils.jsx';
import { getTotalSeconds } from '../ProgramUtils.jsx';
import { 
    getInstrumentationSummaryString,
    getSummaryNotesTooltip,
    getSecondaryBlockInstrumentationSummaryString 
} from 'orfeo/repertoire/jsx/Utils.jsx';
import Tooltip from 'orfeo_common/Tooltip.jsx';
import {AjaxNotification, useListOrdering} from "../../../ui/common/static/js/react-base";

const canEditPlanning = () => (
    !FormUtils.isReadOnly() && hasUserPerm('production.project_planning_edit')
)


/**
 * Display a line of the program
 */
const ProgramElementLine = props => {
    const element = props.element;
    const useInstrumentationLayout = hasPerm('repertoire.instrumentation_access');

    const summaryString = (
        element.work.secondary_block_from ?
            getSecondaryBlockInstrumentationSummaryString(props.element.instrumentation_summary['simple_mode'])
        :
            getInstrumentationSummaryString(props.element.instrumentation_summary['grid_mode'], true)
    );
    const [hasNotes, instrNotes] = getSummaryNotesTooltip(
        props.element.instrumentation_summary, props.element.instrumentation_notes
    );

    return (
        <tr
            className={props.selected ? '' : 'cancelled'}
            data-id={element.pk}
            data-checked={props.selected}
            style={props.customStyle}
        >
           {canEditPlanning() && <td>
               {props.selected &&
                   <span className="reorder-icon">
                        <i className="fa fa-bars"></i>
                   </span>
               }
            </td>}
            <td className="text-center">
                {canEditPlanning() &&
                <input
                    type="checkbox" onChange={props.onCheckboxChange}
                    checked={props.selected} value={element.pk}
                />}
            </td>
            <td>
                <a href={element.work.secondary_block_from ? element.absolute_url : element.work.absolute_url}
                   onClick={ev => ev.stopPropagation()}>
                    {element.work.composer.first_name} {element.work.composer.last_name}
                    &nbsp;: {element.work.secondary_block_from && `${element.work.primary_work_title} :`} {element.work.title}
                </a><br />
                <em>{element.notes}</em>
            </td>
            <td>
                {useInstrumentationLayout &&
                    <React.Fragment>
                        {summaryString ?
                            <span>
                                {summaryString}
                                {hasNotes &&
                                    <span style={{paddingLeft: '5px'}}>
                                        <Tooltip title={renderToStaticMarkup(instrNotes)} html>
                                            <i className="fa-regular fa-comment" />
                                        </Tooltip>
                                    </span>
                                }
                            </span>
                            :
                            <span className="text-muted">{trans.t("Non définie")}</span>
                        }
                    </React.Fragment>
                }
            </td>
            <td className="text-end">{!!element.duration && secondsToHHmm(element.duration, false, true)}</td>
        </tr>
    );
}


/**
 * @param {object} event: planningevent item
 * @param {list[object]} global_elements: list of projectprogramelement
 * @return {list[number]} -> returns list ok pks of program elements selected
 */
function getInitialProgramSelect(event, global_elements) {
    let selected = event.program_elements.map(x => x.program_element);
    if(event.all_program_played)
        selected = global_elements.filter(x => !!x.work).map(x => x.pk);

    return selected;
}


const PlanningEventProgramManager = props => {
    const [programsSelected, setProgramsSelected] = useState(
        () => getInitialProgramSelect(props.obj, props.program_elements)
    );
    const [errors, setErrors] = FormUtils.useFormErrors();
    const useInstrumentationLayout = hasPerm('repertoire.instrumentation_access');

    // Meta state management function that will update internal state and push to parent
    // info for future save
    const onProgramListChange = new_pk_list => {
        const all_program_played = (
            new_pk_list.length == (props.program_elements.filter(p => !!p.work)).length
        );

        setProgramsSelected(new_pk_list);
        props.onFieldChange({
            "all_program_played": all_program_played,
            "_new_program_elements": new_pk_list,
        });
    }

    const listRef = useListOrdering(
        null, null,
        newOrder => {
            // Send the list of PK in the order given by the useListOrdering
            onProgramListChange(newOrder.map(x => Number(x)))
        },
        true
    );
    const onCheckboxChange = ev => {
        let new_list = programsSelected.slice();
        let pkChanged = Number(ev.target.value)
        if(ev.target.checked) {
            new_list.push(pkChanged);
        }
        else {
            new_list.splice(new_list.indexOf(pkChanged), 1);
        }
        onProgramListChange(new_list);
    }

    // Construct two lists: one of selected elements (ordered by the user) and one of unselected
    let selectedElements = new Array(programsSelected.length);
    let unselectedElements = [];
    for(let pe of props.program_elements) {
        if(!pe.work)
            continue;

        let selectedPosition = programsSelected.indexOf(pe.pk);
        if(selectedPosition !== -1)
            selectedElements[selectedPosition] = pe;
        else
            unselectedElements.push(pe);
    }

    const totalSeconds = getTotalSeconds(props.program_elements.filter(x => !!x.work && programsSelected.indexOf(x.pk) !== -1));
    const display_first_col = canEditPlanning();

    return (
        <table className="table" id='event-program-manager'>
            <thead>
                <tr>
                    {display_first_col && <th style={{width: '30px'}}></th>}
                    <th style={{'width': '30px'}}></th>
                    <th>{trans.t("Œuvre")}</th>
                    {useInstrumentationLayout && <th>{trans.t("Nomenclature d'exécution")}</th>}
                    <th>{trans.t("Durée")}</th>
                </tr>
            </thead>
            <tbody ref={listRef}>
            {selectedElements.map(element =>
                <ProgramElementLine
                    key={element.pk}
                    element={element}
                    onCheckboxChange={onCheckboxChange}
                    selected
                />
            )}
            </tbody>
            <tfoot>
            {unselectedElements.map((element, idx) =>
                <ProgramElementLine
                    key={element.pk}
                    element={element}
                    onCheckboxChange={onCheckboxChange}
                    selected={false}
                    customStyle={idx === 0 ? {'borderTopStyle': 'double'} : {}}
                />
            )}
            {props.program_elements.length > 0 &&
                <tr>
                    <td colSpan={display_first_col ? "3" : "2"}></td>
                    <td><strong>{props.obj.instrumentation_summary}</strong></td>
                    <td className="text-end">
                        {totalSeconds > 0 && <b>{secondsToHHmm(totalSeconds, false, true)}</b>}
                    </td>
                </tr>
            }
            </tfoot>
        </table>
    )
}


export default PlanningEventProgramManager;
