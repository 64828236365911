'use strict';

import React from "react";
import * as FormUtils from 'orfeo_common/FormUtils.jsx';
import { TimeInput } from "orfeo_common/Widgets.jsx";


export const InputsHoursCount = ({
  formationInfos,
  onCountChange,
  notAccountable,
  participate,
  type,
  errors
}) => {
  return (
    <>
      <div className="col-3">
        <div className="input-group">
          <div className="input-group-text">
            <i className="fa fa-clock-o" />
          </div>
          <TimeInput
            className="form-control"
            value={
              formationInfos ? formationInfos[`working_hours_count${type}`] : ""
            }
            onChange={(value) =>
              onCountChange(`working_hours_count${type}`, value)
            }
            placeholder={trans.t("Service")}
            disabled={notAccountable || !participate}
          />
          <FormUtils.ErrorText
            errors={errors}
            field={`working_hours_count${type}`}
          />
        </div>
      </div>

      <div className="col-3">
        <div className="input-group">
          <div className="input-group-text">
            <i className="fa fa-clock-o" />
          </div>
          <TimeInput
            value={
              formationInfos
                ? formationInfos[`travelling_hours_count${type}`]
                : ""
            }
            onChange={(value) =>
              onCountChange(`travelling_hours_count${type}`, value)
            }
            placeholder={trans.t("Déplacement")}
            disabled={notAccountable || !participate}
          />
          <FormUtils.ErrorText
            errors={errors}
            field={`travelling_hours_count${type}`}
          />
        </div>
      </div>
    </>
  );
};

export const InputUnitCount = ({
  formationInfos,
  onCountChange,
  notAccountable,
  participate,
  type,
  errors,
}) => {
  const onChange = (e) => {
    onCountChange(`unit_count${type}`, e.target.value);
  };
  return (
    <div className="col-3">
      <div className="input-group">
        <input
          type="number"
          min={0}
          className="form-control"
          value={formationInfos ? formationInfos[`unit_count${type}`] : participate ? 1 : ""}
          onChange={onChange}
          disabled={notAccountable || !participate}
          defaultValue={notAccountable || !participate ? "" : 1}
        />
      </div>
      <FormUtils.ErrorText errors={errors} field={`unit_count${type}`} />
    </div>
  );
};
