import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';

import { getModalWrapper } from 'orfeo_common/react-base.jsx';
import { AmountField } from 'orfeo_common/Fields';
import { SelectInput } from 'orfeo_common/Widgets.jsx';
import EntityAddForm from 'orfeo/entities/jsx/EntityAddForm.jsx';

const ArtisticAgencyField = props => (
    <SelectInput
        backendURL="/backend/structure/"
        value={props.value}
        initialLoad={false} show_add
        onChange={instance => props.onFieldChange('artistic_agency', instance)}
        onAddClick={() => {
            const closeModal = function(data) {
                if(data != null) {
                    props.onFieldChange('artistic_agency', data);
                }
                return true;
            };
            var root = createRoot(getModalWrapper());
            root.render(
                <EntityAddForm entityType="structure" onModalClose={closeModal} />
            );
        }}
    />
);


const SolistContractSectionFields = props => {
    const [feeMode, setFeeMode] = useState(props.engagement.global_fee_mode ? "global" : "detailed");

    const obj = props.engagement;

    const handleFeeModeChange = e => {
        props.onFieldChange('global_fee_mode', e.target.value === "global");
        setFeeMode(e.target.value);
    }

    return (
        <React.Fragment>
            <div className="row">
                <p className="col-3">
                    {trans.t("Cachet")}
                </p>
                <div className="col-9">
                    <label className="radio-inline">
                        <input type="radio" name="feeMode" value="global" onChange={handleFeeModeChange}
                               checked={feeMode === "global"}
                        /> {trans.t("Global")}
                    </label>
                    <label className="radio-inline ms-3">
                        <input type="radio" name="feeMode" value="detailed" onChange={handleFeeModeChange}
                               checked={feeMode === "detailed"}
                        /> {trans.t("Détaillé par représentation")}
                    </label>
                </div>
            </div>

            <div className="row">
                {feeMode === "global" ?
                    <div className="col-xs-9 offset-xs-3">
                        <AmountField
                            className="form-control input-sm text-end"
                            value={obj.global_fee_amount || ''}
                            min="0" step="0.01"
                            onChange={ev => props.onFieldChange('global_fee_amount', ev.target.value)}
                        />
                    </div>
                :
                    <React.Fragment>
                        <div className="col-xs-3 offset-xs-3" style={{paddingRight: 0}}>
                            <p className="help-block" style={{marginBottom: "5px", marginTop: 0}}>
                                {trans.t("1ère repr.")}
                            </p>
                            <div className="row">
                                <div className="col-xs-10">
                                    <AmountField
                                        className="form-control input-sm text-end"
                                        value={obj.first_performance_fee_amount || ''}
                                        min="0" step="0.01"
                                        onChange={ev => props.onFieldChange('first_performance_fee_amount', ev.target.value)}
                                    />
                                </div>
                                <div className="col-xs-2" style={{padding: "3px 0"}}>
                                    +
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-4" style={{paddingLeft: 0}}>
                            <div className="row">
                                <div className="col-xs-7">
                                    <p className="help-block" style={{marginBottom: "5px", marginTop: 0}}>
                                        {trans.t("Repr. suivantes")}
                                    </p>
                                    <AmountField
                                        className="form-control input-sm text-end"
                                        value={obj.next_performances_fee_amount || ''}
                                        min="0" step="0.01"
                                        onChange={ev => props.onFieldChange('next_performances_fee_amount', ev.target.value)}
                                    />
                                </div>
                                <div className="col-xs-1" style={{padding: "8px 0"}}>
                                    <br/>
                                    x
                                </div>
                                <div className="col-xs-3" style={{paddingLeft: 0, paddingRight: 0}}>
                                    <p className="help-block" style={{marginBottom: "5px", marginTop: 0}}>
                                        {trans.t("Nb repr.")}
                                    </p>
                                    <input
                                        type="number" className="form-control input-sm"
                                        value={obj.performance_quantity || ''} min="0" step="1"
                                        onChange={ev => props.onFieldChange('performance_quantity', ev.target.value)}
                                    />
                                </div>
                                <div className="col-xs-1 text-center" style={{padding: "8px 0"}}>
                                    <br/> =
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-2 form-group" style={{paddingLeft: 0}}>
                            <p className="help-block" style={{marginBottom: "5px", marginTop: 0}}>
                                {trans.t("Global")}
                            </p>
                            <input
                                type="number" className="form-control input-sm"
                                value={obj.global_fee_amount} readOnly
                            />
                        </div>
                    </React.Fragment>
                }
            </div>
            <div className="row">
                <p className="col-xs-3">
                    {trans.t("Agence artistique")}
                </p>
                <div className="col-xs-9">
                    <ArtisticAgencyField
                        value={obj.artistic_agency}
                        onFieldChange={props.onFieldChange}
                    />
                </div>
            </div>
            <div className="row">
                <p className="col-xs-3">
                    {trans.t("Rémunération agent")}
                </p>
                <div className="col-xs-3">
                    <div className="input-group">
                        <input
                            type="number" className="form-control input-sm text-end"
                            min="0" max="100" step="any" value={obj.agent_commission_rate || ''}
                            onChange={ev => props.onFieldChange('agent_commission_rate', ev.target.value)}
                        />
                        <div className="input-group-text">%</div>
                    </div>
                </div>
                <div className="col-xs-6 col-form-label">
                    <div className="form-switch">
                        <label>
                            <input
                                type="checkbox"
                                checked={obj.commission_deducted_from_fee || false}
                                className="form-check-input"
                                onChange={ev => props.onFieldChange('commission_deducted_from_fee', ev.target.checked)}
                            />&nbsp; {trans.t("En déduction du cachet")}
                        </label>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SolistContractSectionFields;
