/**
 * Handle the burden of modal HTML structure of Bootstrap
 *
 * Example of use, once Modal is imported:
 *
     <Modal show={this.state.show_modal} onHide={this.closeModal}>
      <Modal.Header>Modal heading</Modal.Header>
      <Modal.Body>
            <p>Lorem ipsum dolor sit amet</p>
      </Modal.Body>
      <Modal.Footer>
            <a className="btn btn-primary" onClick={this.onClick}>
                Créer
            </a>
      </Modal.Footer>
    </Modal>
 */

import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';


const ModalHeader = (props, context) => {
    return (
        <div className="modal-header">
            <h4 className="modal-title w-100">{props.children}</h4>
            {context.onHide &&
                <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label={trans.t("Fermer")}
                ></button>}
        </div>
    );
}
ModalHeader.contextTypes = {onHide: PropTypes.func}

const ModalBody = props => (
    <div className="modal-body">{props.children}</div>
)

const ModalFooter = (props) => (
    <div className="modal-footer">{props.children}</div>
);


class Modal extends React.Component {

    getChildContext() {
        return {onHide: () => this.handleClosing()};
    }

    componentDidMount() {
        if(this.props.show)
            this.handleOpening();
    }

    componentDidUpdate(prevProps, prevState) {
        // Handle opening of the modal when the props changed to "show=true"
        if(!prevProps.show && this.props.show)
            this.handleOpening();
    }

    componentWillUpdate(nextProps, nextState) {
        // Handle closing of the modal when the props changed to "show=false"
        if(this.props.show && !nextProps.show)
            this.handleClosing();
    }

    componentWillUnmount() {
        this.handleClosing();
    }

    handleOpening () {
        let modalNode = ReactDOM.findDOMNode(this);
        this.modal = new bootstrap.Modal(modalNode);
        modalNode.addEventListener('hidden.bs.modal', function (ev) {
            if(this.props.onHide)
                this.props.onHide();
        }.bind(this));
        this.modal.show();
    }

    handleClosing () {
        if(this.modal)
            this.modal.hide();
    }

    render() {
        if(this.props.show) {
            return (
                <div className={"modal fade " + (this.props.modalCSSClass || '')} role="dialog" data-bs-backdrop="static">
                    <div className={"modal-dialog " + (this.props.dialogCSSClass || '')}>
                        <div className="modal-content">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }
}
Modal.getDefaultProps = {'show': false, 'modalCSSClass': ''}
Modal.childContextTypes = {onHide: PropTypes.func, hide: PropTypes.func};

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

export default Modal;
