import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import {
    AjaxNotification,
    fetchCsrfWrapper,
    floatFormat,
    useListOrdering,
    GenericListStoreFactory
} from 'orfeo_common/react-base.jsx';
import { ErrorText, isReadOnly, setEditing } from 'orfeo_common/FormUtils.jsx';
import Tooltip from 'orfeo_common/Tooltip.jsx';
import { formatCurrency } from 'orfeo_common/utils/formats.jsx';

import { computePayrollLinesTotal } from '../Utils.jsx';


const moment = extendMoment(Moment);
const round2decimal = function(val) {
    return (Math.round(val * 100) / 100).toFixed(2);
}

const PayTypeStore = GenericListStoreFactory();

const PayrollLineForm = props => {
    const [pay_line, setPayLine] = useState(() => {
        let pl = props.pay_line;
        pl.unit_amount = Number(pl.unit_amount).toFixed(2)
        return pl;
    });
    // Maybe data have already been loaded by another React instance, get them if so
    const [pay_types, setPayTypes] = useState(PayTypeStore.getList());

    useEffect(() => {
        const handler = lst => setPayTypes(lst);
        PayTypeStore.addOnListChange(handler);

        return () => PayTypeStore.removeListener(handler);
    }, []);

    const onValueChange = new_data => {
        let new_object = Object.assign(pay_line, new_data);
        setPayLine(new_object);
        props.onUpdate(new_object);
    }

    const onTotalChange = ev => {
        // in case the quantity was not set, we force it to 1
        let new_quantity = pay_line.quantity || 1;
        onValueChange({
            'quantity': new_quantity,
            'unit_amount': round2decimal(Number(ev.target.value) / new_quantity)
        });
    }

    const readOnly = !!pay_line.auto_generated_key;

    return (
        <tr data-id={pay_line._uid}>
            <td onClick={ev => ev.stopPropagation()} className="align-middle pointer">
                <i className="fa fa-bars" />
            </td>
            <td>
                <input
                    type="number" className="form-control input-sm text-end"
                    step="any" min="0" value={pay_line.quantity || 1}
                    onChange={ev => onValueChange({'quantity': Number(ev.target.value)})}
                    disabled={readOnly}
                />
            </td>
            <td>
                <select
                    className="form-select input-sm"
                    onChange={ev => onValueChange({'type': pay_types.filter(x => x.pk == ev.target.value)[0]})}
                    value={pay_line.type ? pay_line.type.pk : ''}
                    disabled={readOnly}
                >
                    <option value=""></option>
                    {pay_types.map(
                        opt => <option value={opt.pk} key={'o'+opt.pk}>{opt.code} - {opt.name}</option>
                    )}
                </select>
                <ErrorText
                    errors={props.errors} field="pay_lines"
                    index={props.index} subfield="quantity"
                />
                <ErrorText
                    errors={props.errors} field="pay_lines"
                    index={props.index} subfield="type"
                />
            </td>
            <td>
                <input
                    type="number" className="form-control input-sm text-end"
                    step="0.01" min="0"
                    value={pay_line.unit_amount || ''}
                    onChange={ev => onValueChange({'unit_amount': ev.target.value})}
                    disabled={readOnly}
                />
                <ErrorText
                    errors={props.errors} field="pay_lines"
                    index={props.index} subfield="unit_amount"
                />
            </td>
            <td>
                <input
                    type="number" className="form-control input-sm text-end"
                    step="0.01" min="0"
                    onChange={onTotalChange}
                    value={round2decimal(pay_line.unit_amount * pay_line.quantity)}
                    disabled={readOnly}
                />
            </td>
            <td className="align-middle text-center">
                {readOnly ? 
                    <Tooltip title={trans.t("Impossible de modifier une ligne automatiquement générée")}>
                        <i className="fa fa-info-circle"></i>
                    </Tooltip>
                :
                    <a role="button" onClick={() => props.onDelete(pay_line.pk)}>
                        <i className="fa fa-trash"></i>
                    </a>
                }
            </td>
        </tr>
    );
}


const PayrollManager = props => {

    useEffect(() => {
        // Load pay types if none have been loaded yet
        if(PayTypeStore.count() == 0) {
            fetchCsrfWrapper(
                "/backend/payrolllinetype/"
            ).then(
                data => PayTypeStore.setList(data)
            ).catch(
                data => alert(trans.t('Erreur lors du chargement des données de paie'))
            );
        }

    }, []);

    const handleNewOrder = newOrder => {
        newOrder = newOrder.filter(id => id !== '');
        let new_lines = props.pay_lines.slice();
        for (let i = 0; i < new_lines.length; i++){
            new_lines[i].order_index = newOrder.indexOf(new_lines[i]._uid.toString()) + 1;
        }
        props.onChange(new_lines)
    };

    const listRef = useListOrdering(
        null,
        null,
        handleNewOrder,
        true,
        null,
        {items: '> tr[data-id]'} // Avoid resorting between headers and add links
    );

    const onAddLine = day_str => {
        let next_order_index = 1;
        if(props.pay_lines.length > 0)
            next_order_index = props.pay_lines[props.pay_lines.length - 1].order_index + 1;

        props.onChange(
            props.pay_lines.concat([
                {
                    '_uid': (props.pay_lines.length * -1).toString(),
                    'type': null,
                    'date': day_str,
                    'quantity': 1,
                    'unit_amount': 0,
                    'order_index': next_order_index,
                    'project_id': props.project ? props.project.pk : null,
                }
            ])
        )
    }

    const onUpdateLine = (uid, data) => {
        props.onChange(
            props.pay_lines.map(item => (item._uid == uid) ? data : item)
        );
    }

    const onDeleteLine = uid => {
        props.onChange(
            props.pay_lines.filter(x => x._uid != uid)
        )
    }

    let total_cell;
    const [total_salary, total_expenses, total_hours] = computePayrollLinesTotal(props.pay_lines);

    // The total cell depends on the mode of the engagement. No engagement is passed in mass update
    if(props.engagement && props.engagement.paid_in_net) {
        total_cell = (
            <span>
                {trans.t("Total net")} : {formatCurrency(props.engagement.net_amount, props.currency?.code)}
            </span>
        );
    }
    else if(total_salary > 0 || total_expenses > 0) {
        total_cell = (
            trans.t('Total salaire') + ' : ' + formatCurrency(total_salary, props.currency?.code)
            + (total_expenses > 0 ?
                (' | ' + trans.t("Total défraiements") + ' : ' + formatCurrency(total_expenses, props.currency?.code))
            : '')
        )
    }

    let content;
    if(props.pay_lines.length == 0){
        content = (
            <p>
                <a className="action-link" onClick={() => onAddLine(null)}>
                    {trans.t("Ajouter une ligne de paie")}
                </a>
            </p>
        );
    } else {
        // Create a list of months possible for the period given in props
        let months = [];
        if(props.start_date && props.end_date) {
            for (let month of moment.range(
                moment(props.start_date).date(1),
                moment(props.end_date).date(1)
            ).by('month')) {
                months.push([month.date(1), []]);
            }
            // Put each existing line in the correct month list
            for(let line of props.pay_lines) {
                if(!line.date) {
                    months[0][1].push(line)
                }
                else {
                    let foundMonth = false;
                    for(let [month, lst] of months) {
                        if(month.isSame(moment(line.date), 'month')) {
                            lst.push(line);
                            foundMonth = true;
                            break;
                        }
                    }
                    if(!foundMonth){
                        months.push([moment(line.date).date(1), []]);
                    }
                }
            }
            // Once lines have been categorize per month, reorder them (they should all have
            // the same first day in the month but that wasn't true in the past so we better
            // check the order_index inside a month is respected
            months = months.sort((a, b) => a[0].isAfter(b[0]))
            for(let [month, lst] of months) {
                lst.sort((a, b) => a.order_index - b.order_index);
            }
        }
        // If no dates is defined, fallback to "one-month" view without any dates
        else {
            months.push([null, props.pay_lines]);
        }

        content = (
            <table className="table table-condensed" style={{'marginTop': '15px', 'marginBottom': '5px'}}>
                <thead>
                    <tr>
                        <th style={{'width': '18px'}}></th>
                        <th style={{'width': '75px'}}>{trans.t("Qté")}</th>
                        <th>{trans.t("Rubrique de paie")}</th>
                        <th style={{'width': '170px'}}>{trans.t("Base")}</th>
                        <th style={{'width': '180px'}}>{trans.t("Montant")}</th>
                        <th colSpan="2"></th>
                    </tr>
                </thead>
                <tbody ref={listRef}>
                {months.map(([month, lines]) => (
                    <React.Fragment key={month}>
                        {months.length > 1 &&  // Set a month divider only when several months are displayed
                            <tr className="pay-lines-month-line">
                                <th colSpan="6">
                                    {month.locale('fr').format('MMMM YYYY')}
                                </th>
                            </tr>
                        }
                        {lines.map((item, index) => (
                            <PayrollLineForm
                                pay_line={item}
                                key={'line' + item._uid}
                                index={index}
                                errors={props.errors}
                                onDelete={() => onDeleteLine(item._uid)}
                                onUpdate={data => onUpdateLine(item._uid, data)}
                            />
                        ))}
                        <tr>
                            <th colSpan="6" style={{'fontWeight': 'normal'}}>
                                <a className="action-link" onClick={() => onAddLine(month ? month.format('YYYY-MM-DD') : null)}>
                                    {trans.t("Ajouter une ligne de paie")}
                                </a>
                            </th>
                        </tr>
                    </React.Fragment>
                ))}
                </tbody>
                <tfoot>
                    <tr>
                        <th colSpan="4">
                            {(props.engagement && total_hours > 0 && props.engagement.contract_type == 'standard')
                                && trans.t("{{hours}} heures travaillées", {hours: floatFormat(total_hours)})
                            }
                        </th>
                        <th colSpan="2" className="text-end">
                            {total_cell}
                        </th>
                    </tr>
                </tfoot>
            </table>
        );
    }

    return (
        <div className="payroll-manager-container">
            <hr />
            <h4>
                {trans.t("Lignes de paie")}
                {props.displayProjectTitle && <React.Fragment>
                    {!!props.project ? (
                        <a style={{marginLeft: '5px'}} className="text-muted"
                           href={props.project.absolute_url + "contracts/"} target="_blank"
                        >
                            ({props.project.title})
                        </a>
                    ) : (
                        <span style={{marginLeft: '5px'}} className="text-muted">
                            <em>({trans.t("Hors projet")})</em>
                        </span>
                    )}

                    {props.canRemoveProject && <a
                        className="float-end action-link"
                        onClick={() => props.onRemoveProject()}
                        style={{fontSize: '14px'}}
                    >
                        {trans.t("Retirer le projet")}
                    </a>}
                </React.Fragment>}
            </h4>
            {content}
        </div>
    );

}


export default PayrollManager;
