/**
 * Orfeo attachment handler: display and allows attachment of files to any object
 */
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import {
    AjaxNotification,
    fetchCsrfWrapper,
    GenericListStoreFactory,
    getModalWrapper,
    PlaceholderLoadingBars
} from 'orfeo_common/react-base.jsx';
import Modal from 'orfeo_common/Modal.jsx';
import { loadScript } from 'orfeo_common/utils/script.jsx';
import { BootstrapSwitch } from 'orfeo_common/Widgets.jsx';
import { isReadOnly } from 'orfeo_common/FormUtils.jsx';
import Tooltip from 'orfeo_common/Tooltip.jsx';


const AttachmentsStore = GenericListStoreFactory();
let googlePickerLoaded = false;
let googleTokenClient;

const SOURCE_TO_FA = {
    'local': 'fa-solid fa-desktop',
    'dropbox': 'fa-brands fa-dropbox',
    'gdrive': 'fa-brands fa-google-drive',
}

/**
 * Each file provider should be defined with a single function, that will be
 * called each time the user clicked on the upload button
 */
const onDropboxClick = callback => {
    Dropbox.choose({
        // Called when a user selects an item in the Chooser.
        success: function(files) {
            callback(files);
        },
        // Called when the user closes the dialog without selecting a file
        cancel: function() {},
        linkType: "preview", // or "direct"
        folderselect: true,
        multiselect: false,
    });
    return false;
}

const onGoogleClick = (googleIds, callback) => {
    const SCOPE = 'https://www.googleapis.com/auth/drive.readonly';
    let accessToken;

    /* Function to open a Google file picker */
    const createPicker = function () {
        var picker = new google.picker.PickerBuilder().
          addView(new google.picker.DocsView()).
          setOAuthToken(accessToken).
          setDeveloperKey(googleIds['developer_key']).
          setCallback(function pickerCallback(data) {
              if(data[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
                var doc = data[google.picker.Response.DOCUMENTS][0];
                callback(doc);
              }
          }).build();
        picker.setVisible(true);
    }

    /*
     * On first click, we must load Google Service Identity and Picker service
     **/
    if(!googlePickerLoaded) {
        loadScript('https://apis.google.com/js/api.js', () => {
            // Load Google Picker modudle
            gapi.load('client:picker', async function() {
                await gapi.client.load('https://www.googleapis.com/discovery/v1/apis/drive/v3/rest');
                googlePickerLoaded = true;
            });
        })
    }
    if(!googleTokenClient) {
        loadScript('https://accounts.google.com/gsi/client', () => {
            // Load OAuth service
            googleTokenClient = google.accounts.oauth2.initTokenClient({
              client_id: googleIds['client_id'],
              scope: SCOPE,
              callback: '', // defined later
            });
            onGoogleTokenLoaded();
        });
    }
    else {
        onGoogleTokenLoaded();
    }

    function onGoogleTokenLoaded() {
        googleTokenClient.callback = async (response) => {
            if (response.error !== undefined) {
                throw (response);
            }
            accessToken = response.access_token;
            await createPicker();
        };


        if (accessToken === null) {
          // Prompt the user to select a Google Account and ask for consent to share their data
          // when establishing a new session.
          googleTokenClient.requestAccessToken({prompt: 'consent'});
        } else {
          // Skip display of account chooser and consent dialog for an existing session.
          googleTokenClient.requestAccessToken({prompt: ''});
        }
    }
}


/**
 * Functional part of the notes. Used in React components to run actions.
 * These actions will update the state in NoteStore.
 */
var pending_upload = null;
var AttachmentsActions = {
    add: function (source, link, filename) {
        fetchCsrfWrapper(
            "/attachments/post-attachment-link/",
            {method: "POST", body: {
                'link': link, 'source': source,
                'filename': filename,
                'content_type': AttachmentsStore.content_type,
                'object_id': AttachmentsStore.object_id,
            }},
        ).then(
            data => AttachmentsStore.add(data['attachment'])
        ).catch(
            data => alert(trans.t("Une erreur est survenue lors de l'ajout du fichier"))
        );
    },

    delete: function (att_pk) {
        if(confirm(trans.t("Êtes-vous sûr de vouloir supprimer le fichier de cette fiche ?"))) {
            fetchCsrfWrapper(
                `/attachments/delete-attachment/${att_pk}/`,
                {'method': 'DELETE'},
            ).then(
                data => AttachmentsStore.delete(att_pk)
            ).catch(
                data => alert(trans.t('Une erreur est survenue lors de la suppression du fichier'))
            )
        }
    },

    toggleShare: function(att_pk, shared) {
        fetchCsrfWrapper(
            `/attachments/toggle-share/${att_pk}/`,
            {method: "POST", body: {shared: shared}},
            {contentType: "url-encoded"}
        ).then(
            data => AttachmentsStore.update(data['attachment'])
        ).catch(
            data => alert(trans.t('Erreur lors de la sauvegarde du fichier'))
        )
    },

    uploadFile: function (form, onSuccess, onError) {
        // Add mandatory field to attach the file to the current object
        form.append("content_type", AttachmentsStore.content_type);
        form.append("object_id", AttachmentsStore.object_id);

        let progress = document.querySelector('#add-local-file-form .progress');
        let progressbar;
        // Re-init progressbar, if present
        if(!!progress) {
            progress.style.display = 'block';
            progressbar = progress.querySelector('.progress-bar');
            progressbar.style.width = '0%'
            progressbar.innerText = '0 %';
        }

        pending_upload = $.ajax({
            url: "/attachments/upload-file/",
            method: "POST", data: form,
            processData: false, contentType: false,
            // xhr field is defined to animate the progress bar
            xhr: function() {
                var xhr = new window.XMLHttpRequest();
                xhr.upload.addEventListener("progress", function(evt) {
                    if (evt.lengthComputable && progressbar) {
                        var percentComplete = Math.round((evt.loaded / evt.total) * 100);
                        progressbar.style.width = percentComplete + '%';
                        progressbar.innerText = percentComplete + ' %';
                    }
               }, false);
               return xhr;
            },
        }).success(function(data, status, headers, config) {
            AttachmentsStore.add(data['attachments']);
            $('#add-local-file-form .progress').hide();
            $('#add-local-file-form').modal('hide');

            pending_upload = null;
            if(onSuccess !== undefined) {
                onSuccess();
            }
        }).error(function(data, status, headers, config) {
            if(status != 'abort') {
                alert(
                    data.responseJSON?.errors?.file
                    || trans.t("Une erreur est survenue lors de l'upload du fichier")
                );
            }
            onError(data);
        });
    }
};


const AttachmentsLocalForm = props => {
    const [errors, setErrors] = useState('');
    const [inProgress, setInProgress] = useState(false);

    // Current file upload instance. Useful to abort it if the modal is closed
    let onSubmit = function (ev) {
        ev.preventDefault();
        if(ev.target.filefield.files.length == 0) {
            setErrors(trans.t("Veuillez sélectionner un fichier."));
            return;
        }

        let form = new FormData();
        for(let f of ev.target.filefield.files) {
            // Prevent files larger than 10 Mo from being uploaded
            if(f.size > 10 * 1000 * 1000) {
                setErrors(
                    trans.t("Le fichier {{filename}} excède la taille maximale autorisée de 10 Mo.", {filename: f.name})
                );
                return;
            }
            form.append('files', f);
        }

        setInProgress(true);
        AttachmentsActions.uploadFile(
            form,
            () => closeModal(),
            () => setInProgress(false)
        );
    }

    let closeModal = function () {
        if(pending_upload != null) {
            pending_upload.abort();
        }
        props.onModalClose();
    }

    return (
    <Modal show onHide={closeModal}>
        <form id="add-local-file-form" encType="multipart/form-data" onSubmit={onSubmit}>
          <Modal.Header>{trans.t("Importer un fichier")}</Modal.Header>
          <Modal.Body>
            <p>{trans.t("Veuillez sélectionner le fichier à importer")} :</p>

            <p className="form-group">
                <input type="file" name="filefield" multiple />
            </p>

            <p className="text-muted">
                {trans.t("Taille maximale du fichier")} : 10 Mo
            </p>

            <div className="progress" style={{'display': 'none'}}>
              <div className="progress-bar progress-bar-striped active" role="progressbar" style={{"width": "0%"}}>
                0 %
              </div>
            </div>

            {errors &&
                <p className="text-danger">{errors}</p>
            }
        </Modal.Body>
        <Modal.Footer>
            <button type="button" className="btn btn-outline-secondary" onClick={closeModal}>{trans.t("Annuler")}</button>
            <button type="submit" disabled={inProgress} className="btn btn-primary">{trans.t("Envoyer")}</button>
        </Modal.Footer>
        </form>
    </Modal>
    );
}

const AttachmentsView = (props) => {
    let [attachments, setAttachments] = useState(null);
    let [googleIds, setGoogleIds] = useState(null);
    let [inDropZone, setInDropZone] = useState(false);

    useEffect(() => {
        // Initialize store listener
        const store_callback = lst => setAttachments(lst);
        AttachmentsStore.content_type = props.content_type_id;
        AttachmentsStore.object_id = props.object_id;
        AttachmentsStore.addOnListChange(store_callback);

        // Load initial data
        fetchCsrfWrapper(
            "/attachments/attachments-list/" + props.content_type_id + "/" + props.object_id + "/",
        ).then(data => {
            AttachmentsStore.setList(data['attachments'])
            setGoogleIds({
                'client_id': data['google_client_id'],
                'developer_key': data['google_developer_key']
            })
        }).catch(
            data => console.error(trans.t("Impossible de charger les fichiers attachés"))
        )

        return () => AttachmentsStore.removeListener(store_callback)
    }, []);

    // Set move & drop mecanism visual handler
    const handleDragOver = ev => {
        ev.preventDefault();
        setInDropZone(true);
    }
    const handleDragLeave = ev => {
        setInDropZone(false);
    }

    const onLocalFileButton = ev => {
        const wrapper = getModalWrapper();
        ReactDOM.render(
            <AttachmentsLocalForm
                onModalClose={() => ReactDOM.unmountComponentAtNode(wrapper)}
            />,
            wrapper
        );
    }

    const handleDrop = ev => {
        ev.preventDefault();
        var dataTransfer = ev.dataTransfer;
        var files = [];
        if (dataTransfer.items) {
            for (var i = props.single ? dataTransfer.items.length - 1 : 0; i < dataTransfer.items.length; i ++) {
                if (dataTransfer.items[i].kind == "file") {
                    var file = dataTransfer.items[i].getAsFile();
                    files.push(file);
                }
            }
        } else {
            for (var i = props.single ? dataTransfer.files.length - 1 : 0; i < dataTransfer.files.length; i ++) {
                files.push(dataTransfer.files[i]);
            }
        }

        if(files.length == 0) {
            AjaxNotification.show(trans.t("Impossible d'attacher ce fichier."), 1000);
            setInDropZone(false);
            return;
        }

        var formData = new FormData();
        for (var i = 0; i < files.length; i++) {
          formData.append('files', files[i]);
        }
        let notif_id = AjaxNotification.show(trans.t('Upload en cours...'));
        AttachmentsActions.uploadFile(
            formData,
            () => {
                setInDropZone(false);
                AjaxNotification.hide(notif_id)
            },
            () => {
                setInDropZone(false);
                AjaxNotification.hide(notif_id)
            }
        );
    }

    if(attachments === null) {
        return <PlaceholderLoadingBars />
    }

    const dropboxCallback = files => AttachmentsActions.add('dropbox', files[0].link, files[0].name);
    const gdriveCallback = doc => AttachmentsActions.add('gdrive', doc[google.picker.Document.URL], doc[google.picker.Document.NAME]);

    if(isReadOnly() && attachments.length == 0)
        return null;

    // Rendering form & table
    return (
        <div className="information-block" onDrop={handleDrop} onDragOver={handleDragOver}>
            <div className={inDropZone ? "drag-zone" : ""}>
                {inDropZone &&
                <div className="drag-zone-inner" onDragLeave={handleDragLeave}>
                    <p><i className="fa fa-download"></i><br />{trans.t("Attacher un fichier")}</p>
                </div>}

                <h4>{trans.t("Fichiers")}</h4>

                {!isReadOnly() &&
                <p>Ajouter depuis :
                    <a className="provider-add-link local btn btn-outline-secondary" onClick={onLocalFileButton}>
                        <i className="fa-solid fa-fw fa-lg fa-desktop"></i> {trans.t("Votre ordinateur")}
                    </a>
                    <a className="provider-add-link dropbox btn btn-outline-secondary" onClick={() => onDropboxClick(dropboxCallback)}>
                        <i className="fa-brands fa-fw fa-lg fa-dropbox"></i> Dropbox
                    </a>
                    <a className="provider-add-link google btn btn-outline-secondary" onClick={() => onGoogleClick(googleIds, gdriveCallback)}>
                        <i className="fa-brands fa-fw fa-lg fa-google-drive"></i> Google Drive
                    </a>
                </p>}

                {attachments.length > 0 && (
                    <table className="table attachments-list">
                        <thead>
                            <tr>
                                <th>{trans.t("Fichier")}</th>
                                <th>{trans.t("Ajouté par")}</th>
                                <th>{trans.t("Ajouté le")}</th>
                                {props.sharable && <th className="text-center" style={{width: '20px'}}>
                                    <Tooltip title={props.shareTitle}>
                                        <i className="fa fa-share-alt-square fa-lg"></i>
                                    </Tooltip>
                                </th>}
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {attachments.map(
                            elem => {
                                const canEdit = +props.content_type_id === elem.content_type;
                                return (
                                    <tr key={elem.filename+' '+elem.pk}>
                                        <td>
                                            <i className={SOURCE_TO_FA[elem.source] + " fa-fw text-muted"}></i>
                                            &nbsp;<a href={elem.url} target="_blank">{elem.filename}</a>
                                        </td>
                                        <td>{elem.created_by}</td>
                                        <td>{moment(elem.creation_date).locale('fr').format('LLL')}</td>
                                        {props.sharable && <td className="text-center">
                                            <Tooltip title={props.shareTitle}>
                                                <BootstrapSwitch 
                                                    value={elem.shared} 
                                                    onChange={val => AttachmentsActions.toggleShare(elem.pk, val)}
                                                    disabled={!canEdit}
                                                />
                                            </Tooltip>
                                        </td>}
                                        <td className="text-center">{!isReadOnly() && canEdit &&
                                            <a className="delete-icon" role="button" onClick={() => AttachmentsActions.delete(elem.pk)}>
                                                <i className="fa fa-trash"></i>
                                            </a>
                                        }</td>
                                    </tr>
                                )
                            }
                        )}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
}

export default AttachmentsView;
export { onDropboxClick, onGoogleClick };
