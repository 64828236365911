import React, { useEffect, useMemo, useState} from 'react';
import moment from 'moment';

import { fetchCsrfWrapper, isObject } from 'orfeo_common/react-base.jsx';;
import * as FormUtils from 'orfeo_common/FormUtils.jsx';
import { SelectInput, SELECT_INPUT_STYLES } from 'orfeo_common/Widgets.jsx';

import ProjectAddFormWrapper from 'orfeo/production/jsx/project/ProjectAddForm.jsx';
import ProjectAddFormCommonFields from 'orfeo/production/jsx/project/ProjectAddFormCommonFields.jsx';
import { applyActivityTypeConfigurationToEvent } from 'orfeo/production/jsx/planning/Utils.jsx';
import {ActivityTypes, ActivityTypeInfos} from "orfeo/production/jsx/Constants.jsx";


let _activity_sub_types_cache = null;
let _activity_type_configs_cache = null;
const ProjectAddFormFields = props => {
    const [activitySubtypes, setActivitySubtypes] = useState( _activity_sub_types_cache || []);
    const [activityTypeConfigs, setActivityTypeConfigs] = useState( _activity_type_configs_cache || []);
    // Override default react-select styling to add padding on subtype choices
    const TypesSelectStyles = useMemo(() => Object.assign(
        {},
        SELECT_INPUT_STYLES,
        {option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            if(Number.isInteger(data.pk))
                styles['paddingLeft'] = '2em'
            return styles
        }}
    ), []);

    useEffect(() => {
        if(_activity_sub_types_cache === null) {
            fetchCsrfWrapper(
                '/backend/activitysubtype/',
            ).then(data => {
                _activity_sub_types_cache = data;
                setActivitySubtypes(data);
            });
        }

        if(_activity_type_configs_cache === null) {
            fetchCsrfWrapper(
                '/backend/activitytypeconfiguration/'
            ).then(data => {
                _activity_type_configs_cache = data;
                setActivityTypeConfigs(data);
            });
        }
    }, [])

    const activityTypes = useMemo(
        () =>
            ActivityTypes.reduce((acc, activityType) => {
                return [...acc, activityType, ...activitySubtypes.filter((x) => x.activity_type === activityType)];
            }, []),
        [activitySubtypes]
    );

    const organizer = props.data.contract_organizer;
    const place = props.data.contract_place;
    const setContractOrganizer = data => {
        props.onFieldChange('contract_organizer', data);
    }

    const setContractPlace = data => {
        props.onFieldChange('contract_place', data);
        props.onFieldChange('venue_place', null)
    }

    const setActivityType = data => {
        if(!data)
            console.error("data is null, that should not be possible!")

        data = data.pk;
        props.onFieldChange('activity_type', data)

        let new_data = {};
        // Type selected is a standard one, not a sub-type
        if(ActivityTypes.includes(data)) {
            const activityTypeConfig = activityTypeConfigs.find(x => x.activity_type === data);
            new_data = applyActivityTypeConfigurationToEvent(new_data, activityTypeConfig, null, false);
        }
        else {
            const newActivitySubType = activitySubtypes.find(x => x.pk == data);
            new_data = applyActivityTypeConfigurationToEvent(new_data, newActivitySubType, null, false);
        }
        // Delete start and end time as their inputs are not updated automatically
        delete new_data.start_time;
        delete new_data.end_time;
        for (let [field, value] of Object.entries(new_data)) {
            if(isObject(value)){ // don't pass objects to form
                value = value.pk;
            }
            props.onFieldChange(field, value);
        }
    }

    return (
        <React.Fragment>
        {props.quickCreate && !props.onlyProjects &&
            <div className="row event-type-selector">
                <button type="button"
                    className={"form-group col-xs-2 toggle-btn btn" + (props.eventType == "project" ? " selected": "")}
                    onClick={ev => props.setEventType('project')}
                >
                    {trans.t("Projet")}
                </button>
                <button type="button"
                    className={"form-group col-xs-6 toggle-btn btn" + (props.eventType == "activity" ? " selected": "")}
                    onClick={ev => props.setEventType('activity')}
                >
                    {trans.t("Élément de planning")}
                </button>
            </div>
        }

        <div className={"mb-2 " + props.isInError('title')}>
            <input
                type="text" placeholder="Titre" className="form-control mb-2" autoFocus
                onChange={ev => props.onFieldChange('title', ev.target.value)}
                value={props.data.title}
            />
            <FormUtils.ErrorText errors={props.errors} field={"title"} />
        </div>

        <ProjectAddFormCommonFields
            initials={props.initials}
            eventType={props.eventType}
            showTime={props.eventType == 'activity'}
            canEditTimeFields={props.canEditTimeFields}
            onFieldChange={props.onFieldChange}
            isInError={props.isInError}
            data={props.data}
            onSelectTemplate={props.onSelectTemplate}
        />

        {props.eventType == 'project' ?
        <React.Fragment>
            {props.show_structure &&
                <div className={"mb-2" + props.isInError('contract_organizer')}>
                    <SelectInput
                        backendURL={"/backend/structure/" + (organizer ? "?pks=" + organizer : "")}
                        mostCommonUrl="/backend/structure/most_common?role=organizer"
                        initialLoad
                        placeholder={trans.t("Contractant")}
                        disabled={!!props.initials['structure']}
                        value={organizer}
                        creatable
                        onChange={data => setContractOrganizer(data ? data.pk : null)}
                    />
                    <FormUtils.ErrorText errors={props.errors} field={"contract_organizer"} />
                </div>
            }

            <div className={"mb-2 " + props.isInError('place')}>
                <SelectInput
                    backendURL={"/backend/structure/" + (place ? "?pks=" + place : "")}
                    initialLoad placeholder={trans.t("Lieu")}
                    mostCommonUrl={
                        "/backend/structure/most_common?role=place" +
                        (organizer ? "&selected=" + organizer : "")
                    }
                    value={place}
                    onChange={data => setContractPlace(data ? data.pk : null)}
                    creatable
                />
                <FormUtils.ErrorText errors={props.errors} field={"contract_place"} />
            </div>
        </React.Fragment>
        :
            <React.Fragment>
                <div className={"mb-2 " + FormUtils.getCSSError(props.errors, 'activity_type', 'activity_sub_types')}>
                    <SelectInput
                        value={{
                            pk: props.data.activity_type,
                            name:
                                ActivityTypeInfos[props.data.activity_type]?.label ||
                                activitySubtypes.find((x) => x.pk == props.data.activity_type)?.name,
                        }}
                        onChange={setActivityType}
                        styles={TypesSelectStyles}
                        options={activityTypes.map(activity_type => (
                            isObject(activity_type) ? activity_type
                            : {'pk': activity_type, 'name': ActivityTypeInfos[activity_type].label}
                        ))}
                    />
                    <FormUtils.ErrorText errors={props.errors} field="activity_type" />
                    <FormUtils.ErrorText errors={props.errors} field="activity_sub_type" />
                </div>
            </React.Fragment>
        }
        </React.Fragment>
    )
}

const ProjectAddForm = ProjectAddFormWrapper(
    React.memo(ProjectAddFormFields),
    '/backend/project/'
)

export default ProjectAddForm;
