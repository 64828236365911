// Utility function that reduce opacity of an item gradually before removing it
function fadeOut(el) {
    el.style.transition = '0.75s';
    el.style.opacity = 0;
    setTimeout(() => { el.remove() }, 800)
}

const AjaxNotification = {
    _id: 0,
    _list: {},

    show: function (text, timeoutTime) {
        this._list[++this._id] = text;
        this.onChange();

        // If the notification is for a fixed duration, set the timeout callback
        if(timeoutTime !== undefined) {
            let _id = this._id;
            setTimeout(() => this.hide(_id), timeoutTime);
        }

        return this._id;
    },

    hide: function (id) {
        delete this._list[id];
        this.onChange();
    },

    onChange: function () {
        // Make existing loaders fade out
        for(let item of document.querySelectorAll('body .ajax-loading-spinner'))
            fadeOut(item);

        for(var id in this._list) {
            const text = this._list[id];
            const div = document.createElement('div');
            div.classList.add('ajax-loading-spinner')
            div.innerHTML = '<p class="badge bg-primary">' + text + '</p>';
            document.querySelector('body').appendChild(div);
        }
    }
};

export default AjaxNotification;
