import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { AjaxNotification } from 'orfeo_common/react-base.jsx';
import * as FormUtils from 'orfeo_common/FormUtils.jsx';
import { fetchWrapper } from 'orfeo_common/utils/http.jsx';
import { BootstrapSwitch, SelectInput } from 'orfeo_common/Widgets.jsx';
import Modal from 'orfeo_common/Modal.jsx';

const ExtractFromField = props => {
    let backendURL = "/backend/work/";
    if(props.composer)
        backendURL += "?composer=" + props.composer.pk;

    return (
        <SelectInput
            autoFocus initialLoad={false}
            backendURL={backendURL}
            value={props.value}
            onChange={props.onChange}
            placeholder={trans.t("Œuvre parente")}
            objectRepresentation={x => (x.composer.last_name + ' : ' + x.title)}
        />
    )
}

const WorkAddForm = props => {
    let initials = props.initials || {};

    // data
    const [composer, setComposer] = useState(initials.composer || null);
    const [title, setTitle] = useState(initials.title || '');
    const [parent, setParent] = useState(null);
    const [duplicateInstrumentation, setDuplicateInstrumentation] = useState(true);

    const [showExtractFrom, setShowExtractFrom] = useState(false);
    const [errors, setErrors] = FormUtils.useFormErrors();

    useEffect(() => {
        // Load initial composer if specified by PK
        if(!isNaN(parseFloat(props.composer_pk)) && isFinite(props.composer_pk)) {
            fetchWrapper(
                '/backend/composer/' + props.composer_pk + '/'
            ).then(
                data => setComposer(data)
            );
        }
    }, []);

    const onSave = ev => {
        ev.preventDefault();

        if(showExtractFrom && !parent){
            setErrors({'parent': trans.t("Veuillez sélectionner l'œuvre parente")})
            return;
        }

        const post_data = {
            'composer': {'pk': composer?.pk || null},
            'parent': {'pk': (showExtractFrom && parent) ? parent.pk : null},
            'duplicate_instrumentation': (showExtractFrom && duplicateInstrumentation),
            'title': title,
        };
        fetchWrapper(
            '/backend/work/?with_secondary_blocks=1',
            {method: 'POST', body: post_data}
        ).then(
            data => props.onModalClose(data)
        ).catch(
            data => setErrors(data)
        );
    }

    return (
        <Modal show={true} onHide={() => props.onModalClose(null)}>
            <form className="modal-content" onSubmit={onSave}>
                <Modal.Header>Ajouter une œuvre</Modal.Header>

                  <Modal.Body>
                    {Object.keys(errors).length > 0 &&
                    <p className="react-form-errors-intro">
                       {trans.t("Erreur lors de la sauvegarde.")} {FormUtils.getGlobalErrors(errors)}
                    </p>}

                    <div className="row mb-3">
                        <label className="col-3 col-form-label">{trans.t("Compositeur")}</label>

                        <div className={"form-group col-9" + FormUtils.getCSSError(errors, 'composer')}>
                            <SelectInput
                                backendURL="/backend/composer/" value={composer} onChange={instance => setComposer(instance)}
                                placeholder={trans.t("Compositeur")} initialLoad={false}
                                objectRepresentation={x => x.first_name + ' ' + x.last_name} autoFocus
                            />
                            <FormUtils.ErrorText errors={errors} field='composer' />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label className="col-3 col-form-label" htmlFor="titleInput">
                            {trans.t("Titre")}
                        </label>
                        <div className={"form-group col-9" + FormUtils.getCSSError(errors, 'title')}>
                            <input
                                type="text" className="form-control" required autoFocus id="titleInput"
                                value={title} onChange={ev => setTitle(ev.target.value)}
                            />
                            <FormUtils.ErrorText errors={errors} field='title' />
                        </div>
                    </div>

                    <div className="row mb-1">
                        <label className="col-3" htmlFor="extractFromSwitch">
                            {trans.t("Extrait d'une œuvre")}
                        </label>
                        <div className={"form-group col-9" + FormUtils.getCSSError(errors, 'parent')}>
                            <BootstrapSwitch
                                value={showExtractFrom}
                                htmlID="extractFromSwitch"
                                onChange={val => setShowExtractFrom(val)}
                            /><br />
                        </div>
                    </div>
                    {showExtractFrom &&
                    <div className="row mb-3">
                        <div className="offset-3 col-9">
                            <ExtractFromField
                                value={parent}
                                composer={composer}
                                onChange={instance => setParent(instance)}
                            />
                            <div className="form-group my-1">
                                <input
                                    type="checkbox"
                                    checked={duplicateInstrumentation}
                                    onChange={ev => setDuplicateInstrumentation(ev.target.checked)}
                                /> {trans.t("Dupliquer la nomenclature")}
                            </div>
                            <FormUtils.ErrorText errors={errors} field='parent' />
                        </div>
                    </div>}
                </Modal.Body>
                <Modal.Footer>
                    <a className="btn btn-outline-secondary" onClick={() => props.onModalClose(null)}>{trans.t("Annuler")}</a>
                    <button className="btn btn-primary">{trans.t("Créer")}</button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default WorkAddForm;

export {ExtractFromField};
