/**
 * Dedicated modal form to create an entity and returns the result to a
 * parent object.
 * The form handle any type of entities: contact, structure, artist and ensemble
 * and can be used in several contexts:
 *  - When the user wants to create a new contact, through the Django list
 *  - When the user wants to create a StructurePerson link but one of the
 *    entities does not exist yet
 */
'use strict';

import React from 'react';
import PropTypes from 'prop-types';
var createReactClass = require('create-react-class');
import ReactDOM from 'react-dom';
import { AjaxNotification, BloodhoundFactory, fetchCsrfWrapper } from 'orfeo_common/react-base.jsx';
import { ForeignKeyField, SelectInput } from 'orfeo_common/Widgets.jsx';

import Modal from 'orfeo_common/Modal.jsx';

var URL_TO_TYPE = {
    'artiste': 'artist',
    'ensemble': 'ensemble',
    'technicien': 'technician',
    'contact': 'contact',
    'structure': 'structure',
    'publisher': 'publisher',
    'place': 'place',
    'artistic-agency': 'artisticagency',
}


var EntityAddForm = createReactClass({
    propTypes: {
        entityType: PropTypes.string,
        initials: PropTypes.object,
        extraMessage: PropTypes.string,
        onModalClose: PropTypes.func.isRequired,
    },

    getInitialState: function () {
        var entityType = this.props.entityType;
        // A choice will be displayed to allow switching with technician
        if(entityType == 'employe')
            entityType = 'artiste';

        return {
            'saving': false,
            'errors': {},
            'entityType': entityType,
            'entityCategory': null,
            'tags' : []
        };
    },

    componentDidMount: function() {
        // Focus on the first field possible
        this.refs[this.isForAPerson() ? 'first_name' : 'name'].focus();

        let structure_suggestions = BloodhoundFactory('/backend/structure/');

        // Use a typeahead field for structure to avoid creating duplicates
        if(this.props.entityType === 'structure'){
            var input = $(this.refs['name']);
            input.typeahead(
                {hint: true, items: 10, highlight: true, minLength: 3},
                {name: 'suggestions', displayKey: 'name', source: structure_suggestions.ttAdapter()}
            );
            input.on('typeahead:selected', (evt, item) => {
                window.location.href = item.absolute_url;
            });
        }
    },

    isForAPerson: function () {
        return (
            ['artiste', 'contact', 'technicien'].indexOf(this.state.entityType) !== -1
        );
    },

    isInError: function(field) {
        return this.state.errors[field] === true ? ' has-error' : '';
    },

    closeModal: function (data) {
        // onModalClose should do arbitrary stuff and return true if it needs to hide the modal
        if(this.props.onModalClose(data))
            $(ReactDOM.findDOMNode(this)).modal('hide');
    },

    onSave: function (event) {
        // Avoid regular form submit
        event.preventDefault();

        var post_data, backend_url;
        // Fill post_data and check their validity
        if(this.isForAPerson()) {
            post_data = {
                'civility': {'pk': this.refs.civility.getValue()},
                'first_name': this.refs.first_name.value,
                'last_name': this.refs.last_name.value
            };
            // First name and last name are mandatory
            if(!post_data['first_name'] && !post_data['last_name']) {
                this.setState({'errors': {
                    'first_name': !post_data['first_name'],
                    'last_name': !post_data['last_name'],
                }});
                return;
            }
        }
        else {
            post_data = {'name': this.refs.name.value};
            if(!post_data['name']) {
                this.setState({'errors': {'name': true}});
                return;
            }
        }

        if (this.state.entityCategory) {
            post_data['entity_category'] = {'pk': this.state.entityCategory.pk};
        }
        if (this.state.tags.length){
            post_data['tags'] = this.state.tags.map(x => x.name);
        }

        if(this.props.related_structure)
            post_data['structure'] = this.props.related_structure.pk;

        fetchCsrfWrapper(
            '/backend/' + URL_TO_TYPE[this.state.entityType] + '/',
            {method: "POST", body: post_data},
        ).then(
            data => this.closeModal(data)
        ).catch(
            (data, status) => alert(trans.t('Une erreur est survenue lors de la création de l\'entité'))
        )
    },

    onShowCategoriesTags: function () {
        this.setState({'show_categories_tags': !this.state.show_categories_tags});
    },

    onEntityTypeChange: function (ev) {
        this.setState({'entityType': ev.target.value});
    },

    render: function () {
        var initials = this.props.initials || {};

        // Manage verbose name for the modal title
        var title = "Ajouter ";
        if(this.state.entityType == 'artist')
            title += "un artiste";
        else if(this.state.entityType == 'structure')
            title += "une structure";
        else if(this.state.entityType == 'place')
            title += "un espace";
        else if(this.state.entityType == 'publisher')
            title += "un éditeur";
        else if(this.state.entityType == "artistic-agency")
            title += "une agence artistique";
        else {
            let verbose_name = trans_variants.get_variant_text(this.state.entityType);
            let is_feminine = trans_variants.is_feminine(this.state.entityType);
            title += `un${is_feminine ? 'e' : ''} ${verbose_name}`;
        }

        if(this.props.related_structure) {
            title += ' à ' + this.props.related_structure.name;
        }

        var name_fields;
        if(this.isForAPerson()) {
            name_fields = [
                <div className={"form-group col-2" + this.isInError('civility')} key="field-civ">
                    <ForeignKeyField ref="civility" backend_url="/backend/civility/" input_css="" />
                </div>,

                <div className={"form-group col-5" + this.isInError('first_name')} key="field-first-name">
                    <input type="text" className="form-control" ref="first_name"
                           placeholder={trans.t("Prénom")} defaultValue={initials['first_name']} />
                </div>,

                <div className={"form-group col-5" + this.isInError('last_name')} key="field-last-name">
                    <input type="text" className="form-control" ref="last_name"
                           placeholder={trans.t("Nom")} defaultValue={initials['last_name']} />
                </div>,
            ];
        }
        else {
            name_fields = (
                <div className={"form-group col-12" + this.isInError('name')}>
                    <input
                        type="text" className="form-control" ref="name" required
                        placeholder={trans.t("Nom")} defaultValue={initials['name']}
                    />
                </div>
            );
        }

        return (
            <Modal show onHide={this.closeModal}>
                <form className="modal-content" onSubmit={this.onSave} onKeyUp={this.onKeyUp}>
                    <Modal.Header>{title}</Modal.Header>
                    <Modal.Body>
                        <p>{this.props.extraMessage}</p>

                        {this.props.entityType == 'employe' && // Allow switch between artist and technician in this case
                        <div className="row" style={{'height': '40px'}}>
                            <div className="col-10">
                                <label>
                                    <input type="radio" name="entity_type" value="artiste"
                                           checked={this.state.entityType == 'artiste'} onChange={this.onEntityTypeChange} /> {trans.t("Artiste")}
                                </label>
                                <label style={{'marginLeft': '30px'}}>
                                    <input type="radio" name="entity_type" value="technicien"
                                           checked={this.state.entityType == 'technicien'} onChange={this.onEntityTypeChange} /> {trans.t("Technicien")}
                                </label>
                            </div>
                        </div>}

                        <div className="row">
                            {name_fields}
                        </div>

                        {!this.state.show_categories_tags ?
                        <p className="show-categories-tags-fields text-end">
                            <a role="button" className="text-muted" onClick={this.onShowCategoriesTags}>
                                {trans.t("Plus d'options")}
                                <i className="fa fa-caret-down"></i>
                            </a>
                        </p>
                        :
                        <p className="show-categories-tags-fields text-end">
                            <a role="button" className="text-muted" onClick={this.onShowCategoriesTags}>
                                {trans.t("Replier")}
                                <i className="fa fa-caret-up"></i>
                            </a>
                        </p>
                        }

                        {this.state.show_categories_tags &&
                        <div>
                            <div className="row">
                                <div className="col-md-12">
                                    <SelectInput
                                        value={this.state.entityCategory}
                                        onChange={data => this.setState({entityCategory: data})}
                                        placeholder={trans.t("Catégorie")}
                                        backendURL={"/backend/entitycategory/?entity_type="+URL_TO_TYPE[this.state.entityType]}
                                    />
                                </div>
                            </div>

                            <div className="row" style={{'marginTop': '10px'}}>
                                <div className="col-md-12">
                                    <SelectInput
                                        value={this.state.tags}
                                        onChange={data => this.setState({tags: data})}
                                        multiple creatable placeholder={trans.t("Mots-clés")}
                                        backendURL={"/backend/entitytag/?entity_type="+URL_TO_TYPE[this.state.entityType]}
                                    />
                                </div>
                            </div>
                        </div>}
                    </Modal.Body>
                    <Modal.Footer>
                        <a className="btn btn-outline-secondary" onClick={this.closeModal.bind(this, null)}>
                            {trans.t("Annuler")}
                        </a>
                        <button className="btn btn-primary">{trans.t("Créer")}</button>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }
});

export default EntityAddForm;
