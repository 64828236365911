/**
 * Hybrid react-select field displaying an autocomplete from both
 * our database and GoogleMaps Places API service
 */

import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import Select from 'react-select';

import { fetchCsrfWrapper, getModalWrapper, partition } from 'orfeo_common/react-base.jsx';
import { SELECT_INPUT_STYLES } from 'orfeo_common/Widgets.jsx';

import { useAddressAutocomplete } from 'orfeo/entities/jsx/AddressAutocompleteInput.jsx';
import EntityAddForm from 'orfeo/entities/jsx/EntityAddForm.jsx';

import { useTranslation } from "react-i18next";


function getPlaceObject(event) {
    if (!event.place) return null;

    let d = {
        place: event.place ? event.place.pk : null,
        venue: event.venue ? event.venue.pk : null,
        name: event.place ? event.place.name : ''
    };
    // Sometimes we compute the place name in backend and some times we don't for historical reasons
    if (event.venue) {
        d['name'] += ' : ' + event.venue.name;
    }
    if (event.place_full_name) {
        d.name = event.place_full_name
    }
    return d;
}

function getSecondaryPlaceObject(event, index) {
    if (!event.secondary_places) return null;

    let d = event.secondary_places[index]
        ? {
              place: event.secondary_places[index].pk,
              name: event.secondary_places[index].name,
          }
        : null;
    return d;
}

const FieldGroupHeading = props => {
    const group = props.data;
    return (
        <div className="group-label">
            {group.label}

            <div className="float-end counter">
                {group.options.filter(o => !o.__isNew__).length}
            </div>
        </div>
    )
}



const PlaceOrAddressField = props => {
    const [searchTerm, setSearchTerm] = useState('');
    const onAddressSelected = (addressData, googleId) => {
        // Save the new POI in the database.
        // Get the title to put it in the name
        const selectedOption = addressOptions.find(option => option.value === googleId);
        const name = selectedOption.label.split(', ', 1)[0];

        fetchCsrfWrapper('/backend/pointofinterest/', {
            method: 'POST',
            headers: {Accept: 'application/json', 'X-Requested-With': 'XMLHttpRequest'},
            body: {
                address1: addressData['address1'],
                address2: addressData['address2'],
                city: addressData['city'],
                cedex: addressData['cedex'],
                zipcode: addressData['zipcode'],
                address_latitude: addressData['address_latitude'],
                address_longitude: addressData['address_longitude'],
                external_id: googleId,
                name: name
            }
        }).then(
            newObj => {
                let new_val = {place: newObj.pk, venue: null, name: newObj.name};
                setValue(new_val)
                if(props.onChange)
                    props.onChange(new_val)
            }
        );
    };
    const [addressOptions, loading, getAddressDetail] = useAddressAutocomplete(searchTerm, onAddressSelected);
    const [placeOptions, _setPlaceOptions] = useState({});
    const [value, setValue] = useState(
        props.secondary_place_index != undefined
            ? getSecondaryPlaceObject(props.event, props.secondary_place_index)
            : getPlaceObject(props.event)
    );

    const setPlaceOptions = newOptions => {
        const [places, structures] = partition(newOptions, item => item['type'] == 'espace');
        _setPlaceOptions({
            'places': places,
            'structures': structures
        });
    }

    useEffect(() => {
        if(searchTerm){
            fetch(
                '/backend/places/?limit=7&term=' + searchTerm
                + '&has_venue=' + (props.secondary_place_index==undefined).toString(),
            {
                headers: {Accept: 'application/json', 'X-Requested-With': 'XMLHttpRequest'},
                credentials: "same-origin",
            }).then(
                response => response.json()
            ).then(
                json => setPlaceOptions(json)
            );
        }
    }, [searchTerm]);


    const onSelect = selection => {
        if(selection === null){
            setValue(null);
            if(props.onChange)
                props.onChange(null)
            return;
        }

        if(selection.__isNew__){
            onStructureAdd(searchTerm);
        }
        else if(addressOptions.indexOf(selection) !== -1){
            getAddressDetail(selection.value);
        }
        else {
            setValue(selection);
            if(props.onChange)
                props.onChange(selection)
        }
    };

    const onStructureAdd = name => {
        const closeModal = data => {
            root.unmount();
            if (data != null) {
                setValue({ place: data.pk, venue: null, name: data.name });
            }
            // Node already unmount, modal should not hide itself
            return false;
        };

        var wrapper = getModalWrapper();
        const root = createRoot(wrapper);
        root.render(<EntityAddForm entityType="structure" onModalClose={closeModal} initials={{name}} />)
    };

    const getOptionLabel = (option, meta) => {
        if(option.__isNew__){
            return (
                <div>
                    <span style={{color: '#c72263'}}>
                    + Créer&nbsp;
                    </span>
                    {searchTerm}
                </div>
            );
        }
        let title = '',
            subtitle = '';

        if(option.label){
            [title, ...subtitle] = option.label.split(', ');
            subtitle = subtitle.join(', ');
        } else {
            title = option.name;
            subtitle = option.subtitle;
        }

        // Don't display the subtitle of the selected option
        if(meta.selectValue[0] === option){
            return title;
        }

        return (
            <div>
                <div>{title}</div>
                {subtitle && subtitle.length > 0 && <div style={{color: '#777'}}>{subtitle}</div>}
            </div>
        );
    }

    let structuresList = placeOptions['structures'] || [];
    if(!!searchTerm)
        structuresList.push({'__isNew__': true})

    const options = [
        {
            'label': trans.t("espaces").toUpperCase(),
            'options': placeOptions['places'] || []
        },
        {
            'label': trans.t('structures').toUpperCase(),
            'options': structuresList
        },
        {
            'label': trans.t('adresses').toUpperCase(),
            'options': addressOptions
        }
    ];

    return (
        <div className="input-group">
            <div className="input-group-text" style={{width: '40px'}}>
                <i className="fa fa-map-marker"></i>
            </div>
            <div
                style={{"position": "relative", "flex": "1 1 auto", "width": "1%", "minWidth": "0"}}
                onKeyUp={ev => ev.stopPropagation()}
            >
                <Select
                    value={value}
                    isDisabled={props.disabled}
                    className="place-address-select"
                    options={options}
                    inputValue={searchTerm}
                    onInputChange={setSearchTerm}
                    loadingPlaceholder={trans.t('Chargement...')}
                    placeholder={trans.t('Lieu')}
                    onChange={onSelect}
                    styles={SELECT_INPUT_STYLES}
                    noOptionsMessage={() => trans.t('Commencer la saisie pour rechercher')}
                    formatOptionLabel={getOptionLabel}
                    components={{
                        GroupHeading: FieldGroupHeading
                    }}
                    filterOption={() => true}
                    isOptionSelected={() => false}  // Fix bug that display all options as selected
                    isClearable
                />
            </div>
            <input type="hidden" value={JSON.stringify(value)} ref={props.valueRef} />
        </div>
    );
}

export default PlaceOrAddressField;
export { getPlaceObject, getSecondaryPlaceObject }
