import React, { useState } from 'react';
import moment from 'moment';

import Tooltip from 'orfeo_common/Tooltip.jsx';

import EmailViewer from './EmailViewer.jsx';
import EmailStore from './EmailStore.jsx';
import { humanShortDate } from './utils.jsx';


function get_email_preview(text_variant, html_variant) {
    if(text_variant != '') {
        // Remove breaklines, duplicated spaces and keep the 200 first characters
        return (
            text_variant
                .replace(/[\r\n]+/gm, ' ')
                .replace(/ +(?= )/g, '')
                .substring(0, 200)
        )
    }

    // Removes HTML from the string using the browser parser
    var div = document.createElement("div");
    div.innerHTML = html_variant;
    return (div.textContent || div.innerText || "").substring(0, 200);
}


/**
 * Render an e-mail line, which might be expand to see the full e-mail in its own
 * iframe through EmailViewer.
 * Since a single e-mail can be opened at a time inside a conversation, the click
 * event is handling through the onToggle callback given by the parent.
 *
 * @param {object} email The e-mail object from the database
 * @param {boolean} opened Current state on the line, either collapsed or expanded
 * @param {function} onToggle Callback to executed on click to collapse/expand the e-mail
 */
const EmailLine = ({email, opened, onToggle}) => {
    const onMessageTrashed = () => {
        EmailStore.delete(email.message_id)
    }

    if(opened) {
        return (
            <EmailViewer
                email={email}
                hasEmailAccount={true}
                onMessageTrashed={onMessageTrashed}
                onHeaderClick={() => onToggle(null)}
            />
        );
    }

    let email_date = moment(email.date).locale('fr')

    return (
    <div
        className="row p-1 lh-base"
        role="button"
        onClick={ev => onToggle(email.message_id)}
    >
        <div className="col-11 overflow-hidden" style={{'maxHeight': '1.1rem'}}>
            <span className="text-muted pe-1">
                {(email.email_from?.full_name || email.email_from?.email)}
            </span> {get_email_preview(email.text_body, email.html_body)}
        </div>
        <div className="col-1 text-end">
            {email.attachments.length > 0 &&
                <Tooltip title={trans.t('{nb} pièce(s) jointe(s)', {nb: email.attachments.length})}>
                    <i className="fa fa-paperclip"></i>
                </Tooltip>
            } <Tooltip title={email_date.format('LLL')}>{humanShortDate(email_date)}</Tooltip>
        </div>
    </div>
    );
}


/**
 * Display an e-mail conversation, consisting on a series of e-mail tied together and making
 * a linear conversation.
 * The component will render a first header line with the main subject of the conversation,
 * some action and then a line per e-mail contained in the group.
 *
 * @param {array} emails List of e-mails to display
 */
const Conversation = ({emails}) => {
    const [emailOpened, setEmailOpened] = useState(null);

    const first_email = emails[0];
    return (
    <div className="border my-1">
        <p className="bg-body-tertiary lh-lg px-1 mb-0">{first_email.subject}</p>

        {emails.map(email => (
            <EmailLine
                key={email.message_id}
                email={email}
                opened={email.message_id == emailOpened}
                onToggle={newId => setEmailOpened(newId)}
            />
        ))}
    </div>
    );
}

export default Conversation;
