/**
 * Format a number into a human-readable decimal number. It is possible
 * to force the decimal part of the number using force_decimal and its
 * precision with nb_decimals (default is 2 numbers after the comma.)
 * The formatting using the browser locale so the result may vary according
 * to user client.
 *
 * Example:
 * >>> floatFormat(1234)
 * "1 234"
 * >>> floatFormat(1234.23)
 * "1 234,23"
 * >>> floatFormat(1234, true, 3)
 * "1 234,000"
 */
export const floatFormat = function (number, force_decimal, nb_decimals) {
    number = Number(number);
    if(number % 1 == 0 && !force_decimal) {
        return number.toLocaleString();
    }
    // Use locale default but force the number of decimals
    return number.toLocaleString(undefined, { minimumFractionDigits: nb_decimals || 2, maximumFractionDigits: nb_decimals || 2});
}


// Take two rgb colors and return a color in the range, based on the ratio given
export const computeGradientColor = (bound1, bound2, ratio) => {
    // strip '#' character if needed
    bound1 = bound1.length == 7 ? bound1.substring(1, 7) : bound1;
    bound2 = bound2.length == 7 ? bound2.substring(1, 7) : bound2;

    const toHex = x => {
        x = x.toString(16);
        return (x.length == 1 ? '0' : '') + x;
    };

    const getIntFromHex = (bound, part) => {
        let offset = (part * 2);
        return parseInt(bound.substring(offset, offset + 2), 16);
    };

    var r = Math.ceil(getIntFromHex(bound1, 0) * ratio + getIntFromHex(bound2, 0) * (1 - ratio));
    var g = Math.ceil(getIntFromHex(bound1, 1) * ratio + getIntFromHex(bound2, 1) * (1 - ratio));
    var b = Math.ceil(getIntFromHex(bound1, 2) * ratio + getIntFromHex(bound2, 2) * (1 - ratio));

    return toHex(r) + toHex(g) + toHex(b);
}

export function formatCurrency(amount, currency){
    if(!currency)
        currency = 'EUR';
    return new Intl.NumberFormat(trans.i18n.language, {style: 'currency', currency}).format(amount);
}

export function formatVatRate(vat_rate) {
    return Number(vat_rate / 100).toLocaleString(undefined, {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 10,
    })
}