import AjaxNotification from '../AjaxNotification';

function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie != '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) == (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}
function csrfSafeMethod(method) {
    // these HTTP methods do not require CSRF protection
    return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
}
function sameOrigin(url) {
    // test that a given url is a same-origin URL
    // url could be relative or scheme relative or absolute
    var host = document.location.host; // host + port
    var protocol = document.location.protocol;
    var sr_origin = '//' + host;
    var origin = protocol + sr_origin;
    // Allow absolute or scheme relative URLs to same origin
    return (url == origin || url.slice(0, origin.length + 1) == origin + '/') ||
        (url == sr_origin || url.slice(0, sr_origin.length + 1) == sr_origin + '/') ||
        // or any other URL that isn't scheme relative or absolute i.e relative.
        !(/^(\/\/|http:|https:).*/.test(url));
}

function hideNotif(promise, notif_id) {
    if(notif_id) {
        return promise.finally(() => AjaxNotification.hide(notif_id));
    }
    return promise;
}

/**
 * Construct a valid query params string based on the Javascript object given.
 * Must be used when we want to build an URL with dynamic parameters.
 *
 * Example:
 * >>> buildQueryParams({'is_active': true, 'age': 18})
 * "is_active=true&age=18"
 */
const buildQueryParams = function(params) {
    return (
        Object.keys(params)
              .map(x => encodeURIComponent(x) + '=' + params[x])
              .join('&')
    );
}


// For GET requests, build an optional in-memory cache with url in key and the response in value
// So that subsequent identical requests in the same page which will (most probably) not be updated
// doesn't trigger a useless ajax requests.
let _fetchCsrfWrapperCache = {}

const fetchWrapper = (url, options, orfeoOptions) => {
    options = options || {};
    orfeoOptions = orfeoOptions || {}
    // Used by the sub functions
    let method = (options?.method || 'GET').toUpperCase();
    let contentType = orfeoOptions.contentType || 'application/json';
    let notif_id;

    const showPendingMessage = () => {
        // Display a pending message while the fetch is active so the user
        // notices the query is running
        if(!orfeoOptions.noPendingMessage) {
            let notif_msg;
            if(orfeoOptions.pendingMessage)
                notif_msg = orfeoOptions.pendingMessage;
            else if(method == 'GET')
                notif_msg = trans.t('Chargement...');
            else if(method == 'DELETE')
                notif_msg = trans.t('Suppression...');
            else
                notif_msg = trans.t('Sauvegarde...');

            notif_id = AjaxNotification.show(notif_msg);
        }
    }

    const getHeaders = originalHeaders => {
        let headers = Object.assign(
            {'X-Requested-With': 'XMLHttpRequest'},
            originalHeaders || {}
        )
        if (!csrfSafeMethod(method) && sameOrigin(url)) {
            headers["X-CSRFToken"] = getCookie('csrftoken')
        }
        if (!['url-encoded', 'form-data', 'multipart/form-data'].includes(contentType)) {
            headers['Content-type'] = 'application/json'
        }
        return headers
    }
    const getRequestBody = originalBody => {
        // If we're sending some object data, we're supposing it's JSON stuff
        let body;
        if(method == 'GET' && originalBody) {
            return [body, url + '?' + buildQueryParams(originalBody)]
        }
        if(
            originalBody
            && originalBody instanceof Object
            && !(originalBody instanceof FormData)
        ) {
            // Handle special url-encoded data and form-data and defaults to json
            switch (contentType) {
                case "url-encoded":
                    body = new URLSearchParams(originalBody);
                    break;
                case "form-data":  // case form-data was passed as object/dict and not FormData instance
                    let new_data = new FormData()
                    for (const [key, value] of Object.entries(originalBody)){
                        new_data.append(key, value);
                    }
                    body = new_data
                    break;
                default:
                    body = JSON.stringify(originalBody)
                    break;
            }
        }
        else {
            body = originalBody
        }
        return [body, url]
    }

    const processResponse = async response => {
        let resp = await response;

        // Get data response according to content-type, so empty responses doesn't
        // trigger a failure on json() promise
        let data;
        const responseContentType = resp.headers.get("content-type") || "";
        if(response.status == 204) {
            return null;
        }
        else if(responseContentType.indexOf("application/json") !== -1) {
            data = await resp.json()
        }
        else if(responseContentType.indexOf("application/pdf") !== -1) {
            data = await resp.blob()
        }
        else {
            data = await resp.text()
        }

        if(!response.ok) {
            if(response.status == 0)
                return Promise.reject({'errors': {'__all__': trans.t("La requête n'a pu aboutir, veuillez réessayer.")}});
            if(response.status >= 500)
                return Promise.reject({'errors': {'__all__': trans.t("Une erreur interne s'est produite.")}});

            if(data instanceof Object)
                data['response'] = response;

            throw data;
        }

        if(orfeoOptions?.withHttpStatus) {
            return Promise.all([response.status, data])
        }
        return data;
    }

    if (orfeoOptions?.hasOwnProperty('rejectOnError')) {
        console.warn("Deprecated: rejectOnError is now true by default if you wish to get the raw response you can use rawResponse: true")
    }
    // Do the concrete fetching
    showPendingMessage()
    let updated_options = Object.assign(options, {method});
    updated_options.headers = getHeaders(updated_options.headers);
    orfeoOptions = Object.assign({}, orfeoOptions);
    const cached = orfeoOptions?.cached && method == 'GET';
    let [body, requestUrl] = getRequestBody(updated_options.body);
    updated_options.body = body

    // If the request is cached, try getting it from the in-memory cache
    if(cached && requestUrl in _fetchCsrfWrapperCache){
        if(notif_id){
            AjaxNotification.hide(notif_id);
        }
        return Promise.resolve(_fetchCsrfWrapperCache[requestUrl]);
    }
    let promise = fetch(requestUrl, updated_options);
    if (orfeoOptions?.rawResponse) {
        return hideNotif(promise, notif_id);
    }
    promise = promise.then(processResponse)

    // Register the response in the in-memory cache and return it
    if(cached){
        promise.then(response => {
            _fetchCsrfWrapperCache[requestUrl] = response;
            return response;
        });
    }
    return hideNotif(promise, notif_id);
};


export {
    buildQueryParams,
    fetchWrapper,
    getCookie,
}
