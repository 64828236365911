const REFRESH_LOCAL_STORAGE_KEY = '__resetLocalStorage';

/**
 * Wrapper around local storage to be able to
 * reset the storage when the page is visited with REFRESH_LOCAL_STORAGE_KEY
 * in the url query.
 */
export function getFromLocalStorage(key){
    const url = new URL(window.location.href);

    if(url.searchParams.has(REFRESH_LOCAL_STORAGE_KEY)){
        window.localStorage.removeItem(key);
        return null;
    }

    return window.localStorage.getItem(key);
}
