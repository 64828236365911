import React from 'react';
import moment from 'moment';
import { GenericListStoreFactory, get_pretty_dates } from 'orfeo_common/react-base.jsx';
import { CalendarInput } from 'orfeo_common/Widgets.jsx';

const DATE_FORMAT = {
    'input': 'DD/MM/YYYY',
    'django': 'YYYY-MM-DD',
}


const noPropagation = ev => { ev.stopPropagation(); }

const urlify = text => {
    // Match either a string starting with http(s):// or www. and without any spaces then
    const urlRegex = /((?:(?:https?:\/\/)|(?:w{3}\.))[^\s]+)/g;
    return text.split(urlRegex)
       .map(part => {
          if(part.match(urlRegex)) {
                let url = part;
                // If the URL doesn't start with http, prepend // so it's correctly clickable
                if(url.indexOf('http') != 0)
                    url = '//' + url;
                return <a href={url} target="_blank" onClick={noPropagation}>{part}</a>;
          }
          return part;
       });
}

export const CustomFieldStore = GenericListStoreFactory('pk', 'order_index');

export class CustomFieldInput extends React.Component {

    constructor(props) {
        super(props)

        this.state = {'value': this.props.value};

        this.onValueChange = this.onValueChange.bind(this);
        this.onMultipleChoiceValueChange = this.onMultipleChoiceValueChange.bind(this);
    }

    getValue() {
        return this.state.value;
    }

    componentWillReceiveProps(props) {
        if(props.custom_field.pk != this.props.custom_field.pk)
            this.setState({'value': props.value});
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.onChange && prevState.value != this.state.value)
            this.props.onChange(this.state.value);
    }

    onValueChange(ev) {
        if(ev.target.type == 'checkbox')
            this.setState({'value': ev.target.checked});
        else
            this.setState({'value': ev.target.value});
    }

    onMultipleChoiceValueChange(ev) {
        let choices;
        if(!Array.isArray(this.state.value))
            choices = [];
        else
            choices = (this.state.value || []).slice();

        var position = choices.indexOf(ev.target.value);
        if(ev.target.checked && position == -1)
            choices.push(ev.target.value)
        else if(position !== -1)
            choices.splice(position, 1)

        this.setState({'value': choices});
    }

    render() {
        let custom_field = this.props.custom_field;

        switch(custom_field.field_type) {
            case 'date':
                // let datepicker lib handle the change of value
                return (
                    <CalendarInput
                        defaultValue={this.state.value} disabled={this.props.disabled}
                        onChange={val => this.setState({
                            'value': val ? val.format(DATE_FORMAT['django']) : null
                        })}
                        required={false}
                    />
                );
            case 'boolean':
                return (
                    <div className="form-switch form-switch-md">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            checked={this.state.value}
                            disabled={this.props.disabled}
                            onChange={this.onValueChange}
                        />
                    </div>
                );
            case 'choices':
                return (
                    <select
                        className="form-select input-sm" value={this.state.value}
                        disabled={this.props.disabled} onChange={this.onValueChange}
                    >
                        {this.props.showEmptyChoice && <option value=""></option>}
                        {custom_field.choices.map(choice => <option value={choice} key={choice}>{choice}</option>)}
                    </select>
                );
            case 'multiple-choices':
                return (
                    <div>
                        {custom_field.choices.map(function (choice) {
                            const uniq_key = 'cf-' + custom_field.key + choice;
                            // Handle cases where the value is empty or wrongly type to
                            // avoid exception. The change listener will cast it to a list
                            // if necessary
                            const checked = (
                                Array.isArray(this.state.value)
                                && this.state.value.indexOf(choice) !== -1
                            );
                            return (
                                <div key={uniq_key}>
                                    <input type="checkbox" value={choice}
                                        onChange={this.onMultipleChoiceValueChange}
                                        checked={checked}
                                        id={uniq_key} disabled={this.props.disabled}
                                    />
                                    &nbsp;<label htmlFor={uniq_key}>{choice}</label>
                                </div>
                            );
                        }.bind(this))}
                    </div>
                );
            case 'text-block':
                return (
                    <textarea value={this.state.value} className="form-control input-sm"
                              autoFocus={!this.props.disableAutoFocus}
                              disabled={this.props.disabled}
                              style={{'height': '130px'}} onChange={this.onValueChange} />
                );
            case 'number':
                return (
                    <input type="number" className="form-control input-sm" autoFocus={!this.props.disableAutoFocus}
                           step="0.01" disabled={this.props.disabled}
                           value={this.state.value} onChange={this.onValueChange} />
                );
            default:
                // safe-fail case
                return (
                    <input type="text" className="form-control input-sm"
                           autoFocus={!this.props.disableAutoFocus} disabled={this.props.disabled}
                           value={this.state.value} onChange={this.onValueChange} />
                );
        }
    }
}

export function getDisplayableValue(cf, raw_value) {
    switch(cf.field_type) {
        case 'boolean':
            return (!!raw_value ? 'Oui' : 'Non');

        case 'date':
            return get_pretty_dates(moment(raw_value).locale('fr'));

        case 'text-block':
            return <span style={{'whiteSpace': 'pre-line'}}>{urlify(raw_value)}</span>;

        case 'multiple-choices':
            if(Array.isArray(raw_value))
                return raw_value.join(', ');
        default:
            if(typeof raw_value === "string")
                raw_value = urlify(raw_value);
            return raw_value;
    }
}

export function get_cf_index(cf, order){
    // If the rank is defined in the project specific ordering in priority
    let rank = order.indexOf(cf.key);
    // If it wasn't defined here, use the order set in the settings
    if(rank === -1){
        rank = order.length + cf.order_index
    }
    return rank
}
