import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { fetchCsrfWrapper } from 'orfeo_common/react-base.jsx';
import * as FormUtils from 'orfeo_common/FormUtils.jsx';
import Modal from 'orfeo_common/Modal.jsx';


const ComposerAddForm = props => {
    const {values, onChange, onKeyUp, onSubmit} = FormUtils.useForm(
        Object.assign({'first_name': '', 'last_name': ''}, props.initials || {}),
        handleSave,
        closeModal
    );
    const [errors, setErrors] = FormUtils.useFormErrors();
    const firstNameNode = useRef();
    const modalWrapper = useRef();

    useEffect(() => {
        // Force focus once the modal is shown
        firstNameNode.current.focus();
    }, []);

    function handleSave() {
        fetchCsrfWrapper(
            '/backend/composer/', {
                method: "POST",
                body: {
                    'first_name': values.first_name,
                    'last_name': values.last_name,
                }
            },
        )
        .then(data => closeModal(data))
        .catch(data => setErrors(data));
    }

    function closeModal(data) {
        // onModalClose should do arbitrary stuff and return true if it needs to hide the modal
        if(props.onModalClose(data))
            $(modalWrapper.current).modal('hide');
    }


    return (
        <Modal show onHide={closeModal} ref={modalWrapper}>
            <form className="modal-content" onSubmit={onSubmit} onKeyUp={onKeyUp}>
                <Modal.Header>{trans.t("Ajouter un compositeur")}</Modal.Header>

                  <Modal.Body>
                    {Object.keys(errors).length > 0 &&
                    <p className="react-form-errors-intro">
                       {trans.t("Erreur lors de la sauvegarde.")} {FormUtils.getGlobalErrors(errors)}
                    </p>}

                    <div className="row">
                        <div className={"form-group col-xs-6" + FormUtils.getCSSError(errors, 'first_name')}>
                            <input
                                type="text" className="form-control" name="first_name" onChange={onChange}
                                placeholder={trans.t("Prénom")} defaultValue={values['first_name']} ref={firstNameNode}
                            />
                        </div>
                        <div className={"form-group col-xs-6" + FormUtils.getCSSError(errors, 'last_name')}>
                            <input
                                type="text" className="form-control" name="last_name" onChange={onChange} required
                                placeholder={trans.t("Nom")} defaultValue={values['last_name']}
                            />
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <a className="btn btn-outline-secondary" onClick={() => closeModal(null)}>
                        {trans.t("Annuler")}
                    </a>
                    <button type="submit" className="btn btn-primary">{trans.t("Créer")}</button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default ComposerAddForm;
