/**
 * Date fields of ProjectAddForm that are common to all variants
 **/
'use strict';

import React from 'react';
import moment from 'moment';
import classNames from 'classnames';

import * as FormUtils from 'orfeo_common/FormUtils.jsx';
import { BootstrapSwitch, CalendarInput, SelectInput, TimeInput } from 'orfeo_common/Widgets.jsx';
import { hasPerm } from 'orfeo_common/react-base.jsx';
import { fetchWrapper } from 'orfeo_common/utils/http.jsx';


const ProjectAddFormCommonFields = props => {
    const [projectTemplates, setProjectTemplates] = React.useState([]);

    const startDate = React.useRef();
    const endDate = React.useRef();
    const startTime = React.useRef();
    const endTime = React.useRef();

    React.useEffect(() => {
        startDate.current.setRelation('end', endDate.current);

        // To avoid displaying a selector for organizations not using project templates, we
        // fetch it manually and only display the select if necessary
        if(hasPerm("production.project_template_access")) {
            fetchWrapper(
                "/backend/projecttemplate/?sz=detail"
            ).then(
                data => setProjectTemplates(data)
            ).catch(
                data => console.error(trans.t("Erreur lors du chargement des modèles de projets"))
            )
        }
    }, []);

    React.useEffect(() => {
        const listener = ev => {
            /**
             * When user tries to reopen the panel we instead set the current panel
             * to newly selected date to let user keep their other inputs
             **/
            ev.stopPropagation();

            let new_start_date = moment.utc(ev.detail.start_date);
            let new_end_date = moment.utc(ev.detail.end_date);
            if (startDate.current) {
                startDate.current.setValue(new_start_date.toString());
            }
            if (endDate.current) {
                endDate.current.setValue(new_end_date.toString());
            }
            if (startTime.current) {
                startTime.current.setValue(new_start_date.format('HH:mm'));
            }
            if (endTime.current) {
                endTime.current.setValue(new_end_date.format('HH:mm'));
            }
            props.onFieldChange('start_date', new_start_date);
            props.onFieldChange('end_date', new_end_date);
            props.onFieldChange('start_time', new_start_date);
            props.onFieldChange('end_time', new_end_date);
        }
        document.addEventListener('Orfeo.CalendarCreateEvent', listener);
        return () => document.removeEventListener('Orfeo.CalendarCreateEvent', listener);
    }, []);


    let start_date = props.initials ? props.initials.start_date : null;
    let end_date = props.initials ? props.initials.end_date : null;

    return <React.Fragment>
        <div className="row mb-2">
            <div className={"col " + props.isInError('start_date')}>
                <CalendarInput
                    placeholder={trans.t("Date de début")} ref={startDate} input_css=""
                    defaultValue={start_date ? start_date.clone().format('YYYY-MM-DD') : null}
                    onChange={val => props.onFieldChange('start_date', val)}
                    disabled={!!props.initials.start_date}
                    utc
                />
                <FormUtils.ErrorText errors={props.errors} field="start_date" />
            </div>

            <div className={"col " + props.isInError('end_date')}>
                <CalendarInput
                    placeholder={trans.t("Date de fin")} ref={endDate}  input_css=""
                    defaultValue={end_date ? end_date.clone().format('YYYY-MM-DD') : null}
                    onChange={val => props.onFieldChange('end_date', val)}
                    disabled={!!props.initials.end_date}
                    utc
                />
                <FormUtils.ErrorText errors={props.errors} field="end_date" />
            </div>
        </div>

        {props.eventType != 'project' && props.showTime &&
            <div className="row mb-2">
                <div className={"col" + props.isInError('start_date')}>
                    <TimeInput
                        placeholder={trans.t("Heure de début")} input_css=""
                        defaultValue={start_date ? start_date.format('HH:mm') : null}
                        onChange={val => props.onFieldChange('start_time', val)}
                        ref={startTime}
                        disabled={!props.canEditTimeFields}
                    />
                </div>
                <div className={"col" + props.isInError('end_date')}>
                    <TimeInput
                        placeholder={trans.t("Heure de fin")} input_css=""
                        defaultValue={end_date ? end_date.format('HH:mm') : null}
                        onChange={val => props.onFieldChange('end_time', val)}
                        ref={endTime}
                        disabled={!props.canEditTimeFields}
                    />
                </div>
            </div>
        }
        {props.eventType == 'project' &&
            <React.Fragment>
                {projectTemplates.length > 0 &&
                <div className={"form-group mb-2 " + props.isInError('projecttemplate')}>
                        <SelectInput
                            options={projectTemplates}
                            placeholder={trans.t("Modèle")}
                            value={props.data.projecttemplate}
                            onChange={props.onSelectTemplate}
                            objectRepresentation={x => x.title}
                        />
                        <FormUtils.ErrorText errors={props.errors} field="projecttemplate" />
                        <div>
                            <label>
                            {trans.t("Importer le planning du modèle")}&nbsp;
                            <BootstrapSwitch
                                value={props.data.projecttemplate && !props.data['spectacle.pk'] || props.data.useTemplatePlanning}
                                onChange={val => props.onFieldChange('useTemplatePlanning', val)}
                                disabled={!props.data['spectacle.pk'] || !props.data.projecttemplate}
                            />
                            </label>
                        </div>
                </div>
                }
                <div className="row mb-2">
                    <div className={classNames("form-group", {
                        "col-6": VARIANT != 'orchestra',
                        'col-12': VARIANT == 'orchestra'
                    })}>
                        {/* Using input_css, remove input-sm to make it "normal size" */}
                        <SelectInput
                            backendURL="/backend/projectstatus/" required input_css=""
                            value={props.data['status.pk']}
                            onChange={data => props.onFieldChange('status.pk', data ? data.pk : null)}
                        />
                    </div>

                    {VARIANT != 'orchestra' &&
                    <div className="form-group col-6">
                        <SelectInput
                            backendURL="/backend/projectcontracttype/" required input_css=""
                            value={props.data.contract_type}
                            onChange={data => props.onFieldChange('contract_type', data ? data.pk : null)}
                            getDefaultValue={options => options.find(x => x.is_default) || options[0]}
                        />
                    </div>
                    }
                </div>
            </React.Fragment>
        }
    </React.Fragment>
}

export default React.memo(ProjectAddFormCommonFields);
