import React from 'react';

import * as FormUtils from 'orfeo_common/FormUtils.jsx';

const AgreeementContractSectionFields = props => {
    return (
        <div className="row">
            <p className="col-xs-3">
                {trans.t("Montant global")}
            </p>
            <div className="col-xs-3">
                <div className="input-group">
                    <input
                        type="number" className="form-control input-sm text-end" min="0" step="0.01"
                        onChange={ev => props.onFieldChange('net_amount', ev.target.value)}
                        value={props.engagement.net_amount || 0}
                    />
                    {props.engagement.currency &&
                        <div className="input-group-text">{props.engagement.currency.symbol}</div>
                    }
                </div>
            </div>
        </div>
    )
}

export default AgreeementContractSectionFields;
