import React, { useState, useEffect } from 'react';

/** Compute the decimal precision of a given number represented as a string */
function numDec(n) {
    let dot_position = n.lastIndexOf(".");
    if(dot_position == -1)
        return 0;

    return n.length - dot_position - 1;
}

const AmountField = ({value, ...props}) => {
    let shown_value = value;
    if(typeof shown_value == "string" && numDec(shown_value) > 2) {
        shown_value = Number(shown_value).toFixed(2)
    }

    return (
        <input
            type="number"
            value={shown_value}
            {...props}
        />
    )
}

export default AmountField;
