'use strict';
import React from 'react';
import moment from 'moment';


const mime_to_faicon = {
    'application/pdf': 'file-pdf',
    'application/vnd.ms-excel': 'file-excel',
    'application/msword': 'file-word',
    'application/vnd.ms-powerpoint': 'file-powerpoint',
    'text/plain': 'file-text',
}

const get_mimetype_icon = function(mime_type) {
    let icon = "file";

    if(mime_to_faicon[mime_type] !== undefined)
        icon = mime_to_faicon[mime_type]
    else if(mime_type !== null && mime_type.indexOf('image') !== -1)
        icon = "image";

    return <i className={"fa-regular fa-fw fa-" + icon}></i>;
}


let _now = moment();

/**
 * Returns the shortest and yet context-free interpretation of a
 * moment instance date:
 * - If the date is in the same day than today, just returns the hour
 * - For dates in the same year, returns the day and the abbrev. month
 * - For older dates, append the year to the day/month format
 *
 * Given today is 20/05/2020, the expected values are:
 *
 */
const humanShortDate = function (moment_date) {
    let date_format = 'D MMM YY';
    if(moment_date.isSame(_now, 'day'))
        date_format = 'HH:mm'
    else if(moment_date.isSame(_now, 'year'))
        date_format = 'D MMM'

    return moment_date.format(date_format)
}


export {
    humanShortDate,
    get_mimetype_icon,
}
