import { isToday, isTomorrow, isYesterday, differenceInCalendarDays, isThisYear, intlFormat, differenceInYears, startOfToday } from 'date-fns';

/**
 * pretty_dates used to display planning dates, using two momentsJS objects
 * Could be moved in an utils.js file
 */

const momentToLocaleDateString = (m, format) => {
    /*
     * This is not a formatting function it only serves to try and resolve some
     * of the mess created by handling both timezone naive and timezone aware
     * dates in the app
     */
    const DEFAULT_FORMAT = "YYYY-MM-DD HH:mm";
    let fmt = format || DEFAULT_FORMAT
    if (!m) {
        return undefined
    }
    return m.utc(true).format(fmt)
}

const get_pretty_dates = function(start, end = start, _is_all_day, _show_year, _show_day_name) {
    var is_all_day = _is_all_day === undefined ? true : _is_all_day;
    var end = end || start; // We may need to display a unique day, sometimes

    let fmt_dayname = _show_day_name !== false ? "ddd " : "";
    let show_year = _show_year === undefined ? true : _show_year;
    // Define the display of each day component
    let fmt_day = 'Do ';
    let fmt_month = 'MMM ';
    let fmt_year = show_year ? "YYYY" : "";
    if(window['DATE_FORMAT_PREFERENCE'] == 'short') {
        fmt_dayname = '';
        fmt_day = 'DD';
        fmt_month = '/MM';
        fmt_year = '/YYYY';
    }
    // Ease the code below with some precomputed concatenation
    let full_date_format = fmt_dayname + fmt_day + fmt_month + fmt_year;

    // Single day event
    if(start.isSame(end, 'day')) {
        var day = start.format(full_date_format);
        if(is_all_day) {
            return day;
        }
        var start_hour = start.format('HH:mm');
        var end_hour = end.format('HH:mm');
        if(start_hour == end_hour)
            return day + " à " + start_hour;

        return day + " (" + start_hour + " à " + end_hour + ")";
    }
    else {
        if(start.year() == end.year()) {
            if(start.month() == end.month()) {
                return start.format(fmt_dayname + fmt_day) + "- " + end.format(full_date_format);
            }
            return start.format(fmt_dayname + fmt_day + fmt_month) + "- " + end.format(full_date_format);
        }
        return start.format(full_date_format) + " - " + end.format(full_date_format);
    }
}

/**
 * Get a string representation of the number of days/weeks/months/years until the specified date from today
 * @param someDate The data as a javascript date object
 * @returns the relative name of the date as a string
 */
const getRelativeDate = (someDate) => {
    const today = startOfToday();
    const deltaDay = differenceInCalendarDays(today, someDate);
    const deltaYear = differenceInYears(today, someDate)

    if (isToday(someDate)) return trans.t("Aujourd'hui");
    if (isTomorrow(someDate)) return trans.t("Demain");
    if (isYesterday(someDate)) return trans.t("Hier");

    if (deltaDay > 1 && deltaDay < 7)
        return trans.t('Il y a {{deltaDay}} jours', {deltaDay});
    if (deltaDay >= 7 && deltaDay < 14)
        return trans.t('Il y a 1 semaine');
    if (deltaDay >= 14 && deltaYear < 1)
        return trans.t('Il y a {{deltaWeek}} semaines', {deltaWeek: Math.floor(deltaDay / 7)});
    if (deltaYear == 1)
        return trans.t('Il y a 1 an')
    if (deltaYear > 1)
        return trans.t('Il y a {{deltaYear}} ans', {deltaYear})

    if (!isThisYear(someDate))
        return getLocalizedDate(someDate, {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
        })

    return getLocalizedDate(someDate, {
        weekday: 'long',
        day: 'numeric',
        month: 'short',
    })
}

/**
 * Get a localized representation of the date
 * @param someDate The data as a javascript date object
 * @returns a string representation of the localized date
 */
const getLocalizedDate = (someDate, options = {}) => {
    return intlFormat(
        someDate,
        {
            ...options
        },
        {
            locale: window.LANGUAGE,
        }
    )
}


export { momentToLocaleDateString, get_pretty_dates, getRelativeDate, getLocalizedDate }