/**
 * Render a modal tab to determine which technicians participate to the event
 * given and at which hours (some technicians might start earlier/end after
 * the timeslot of the event)
 *
 * Props:
 * - event: representation of a planningevent instance
 * - technicians: list of entities that can participate to the event
 * - timeslots: existing timeslots for the event and technicians
 */
import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

import { fetchCsrfWrapper, flatten } from 'orfeo_common/react-base.jsx';
import { absenceIsAllDay } from 'orfeo_common/CalendarUtils.jsx';
import { SelectInput, TimeInput } from 'orfeo_common/Widgets.jsx';
import Tooltip from 'orfeo_common/Tooltip.jsx';
import { getDeltaFromInput, TimeInputWrapper } from '../technical/TechnicianCommonModal.jsx';
import { getAbsenceText } from 'orfeo/calendar/jsx/planning/_Utils.jsx';

function buildInitialTable(event, technicians) {
    let values = [];
    for(let tech of technicians) {
        let ts = event.technicians.find(x => x.entity == tech.pk);
        if(ts)
            values.push({
                'pk': ts.pk,
                'source': 'service',
                'entity': tech.pk,
                'start_delta': ts.start_delta,
                'end_delta': ts.end_delta,
                'participate': true,
            });
        else
            values.push({
                'pk': null,
                'source': 'service',
                'entity': tech.pk,
                'start_delta': null,
                'end_delta': null,
                'participate': false,
            });
    }
    return values;
}

const _check_fitting_ewh = (working_hours, start_dt, end_dt) => {
    let period_to_cover = moment.range(start_dt, end_dt);
    let period_covered = working_hours.reduce(
        (period_array, ewh) => {
            const ewh_range = moment.range(ewh.start_datetime, ewh.end_datetime);
            period_array = period_array.map((ev_period) => {
                const event_ewh_intersection = ev_period.intersect(ewh_range);
                return event_ewh_intersection ? ev_period.subtract(event_ewh_intersection) : ev_period;
            });
            return flatten(period_array);
        },
        [period_to_cover]
    );
    return period_covered.length == 0 || period_covered.reduce((sum, el) => sum + el.valueOf()) == 0
}
const TechnicianForm = props => {
    const form_data = props.data;
    const participate = !!form_data.participate;
    const event_start_dt = moment(props.event.start_datetime).locale('fr');
    const event_end_dt = moment(props.event.end_datetime);
    const index_or_pk = form_data.source === 'service' ? form_data.entity_obj.pk : props.index;
    const line_id = 'event-technician-tab-' + index_or_pk;
    const [fittingEwh, setFittingEwh] = useState(true);
    // If deltas have been set up, display time inputs
    const [minimalTimeDisplay, setMinimalTimeDisplay] = useState(!(form_data.start_delta || form_data.end_delta));

    useEffect(() => {
        // Initialize fittingEwh when workinghours are loaded
        // Component is rerender on every props.onTableChange so no need to check fitting ewh elsewhere
        setFittingEwh(
            !participate ||
                _check_fitting_ewh(
                    props.workingHours,
                    moment(event_start_dt).add(form_data.start_delta || 0, "second"),
                    moment(event_end_dt).add(form_data.end_delta || 0, "second")
                )
        );
    }, [props.workingHours.length]);

    useEffect(() => {
        !fittingEwh && props.setEwhMissing(true);
    }, [fittingEwh]);

    const absence_reason_name = props.absence ? getAbsenceText(props.absence) : null;

    return (
        <div className="row my-1 d-flex align-items-center">
            {form_data.source == 'service' ?
                <label className="col-4 control-label" htmlFor={line_id}>{form_data.entity_obj.name}</label>
            :
                <div className="col-xs-4">
                    <SelectInput
                        backendURL={
                            "/backend/technician/"
                            + "?extra_fields=main_service"
                            + "&exclude_ids="
                            + (props.current_entities.join(','))
                            + "&sz=planning"
                        }
                        value={form_data.entity_obj}
                        placeholder={trans.t("Nom du salarié")}
                        onChange={val => props.onTableChange('entity_obj', val, index_or_pk, form_data.source)}
                    />
                </div>
            }
            <div className="col-1 text-center">
                {props.absence && props.absence.is_all_day && !participate ? (
                    <Tooltip className="text-warning-emphasis" title={absence_reason_name}>
                        <input
                            type="checkbox" name="participating"
                            checked={participate} id={line_id}
                            disabled
                        />
                    </Tooltip>
                ):(
                    <input
                        type="checkbox" name="participating"
                        checked={participate} id={line_id}
                        onChange={ev => {
                            props.onTableChange("participate", ev.target.checked, index_or_pk, form_data.source);
                        }}
                        disabled={props.is_read_only}
                    />
                )}
            </div>
            {participate &&
                (minimalTimeDisplay ? (
                    <div className="col-4">
                        <p className="m-0">
                            <a className="customize-time me-2" onClick={() => setMinimalTimeDisplay(false)}>
                                {trans.t("Personnaliser")}
                            </a>
                            {moment(event_start_dt).format("HH:mm")} - {moment(event_end_dt).format("HH:mm")}
                        </p>
                    </div>
                ) : (
                    <React.Fragment>
                        <div className="col-2">
                            <TimeInputWrapper>
                                <TimeInput
                                    className="form-control"
                                    placeholder={participate ? event_start_dt.format("HH:mm") : " "}
                                    disabled={!participate || props.is_read_only}
                                    value={
                                        form_data.start_delta
                                            ? moment(event_start_dt)
                                                  .add(form_data.start_delta, "second")
                                                  .format("HH:mm")
                                            : ""
                                    }
                                    onChange={(val) => {
                                        let delta = getDeltaFromInput(val, event_start_dt);
                                        if (delta != null) {
                                            props.onTableChange("start_delta", delta, index_or_pk, form_data.source);
                                        }
                                    }}
                                />
                            </TimeInputWrapper>
                            {!!form_data.start_delta && (
                                <p className="offset-hint m-0">{form_data.start_delta / 60} min</p>
                            )}
                        </div>
                        <div className="col-2">
                            <TimeInputWrapper>
                                <TimeInput
                                    className="form-control"
                                    placeholder={participate ? event_end_dt.format("HH:mm") : " "}
                                    disabled={!participate || props.is_read_only}
                                    value={
                                        form_data.end_delta
                                            ? moment(event_end_dt)
                                                  .add(form_data.end_delta, "second")
                                                  .format("HH:mm")
                                            : ""
                                    }
                                    onChange={(val) => {
                                        let delta = getDeltaFromInput(val, event_end_dt);
                                        if (delta != null) {
                                            props.onTableChange("end_delta", delta, index_or_pk, form_data.source);
                                        }
                                    }}
                                />
                            </TimeInputWrapper>
                            {!!form_data.end_delta && <p className="offset-hint m-0">{form_data.end_delta / 60} min</p>}
                        </div>
                    </React.Fragment>
                ))}
            <div className="col-sm-1 text-center">
                {!props.is_read_only && participate && props.absence && (
                    <Tooltip
                        className="text-warning-emphasis"
                        title={
                            absence_reason_name +
                            "\n" +
                            props.absence.start_datetime.slice(11, 16) +
                            " - " +
                            props.absence.end_datetime.slice(11, 16)
                        }
                    >
                        <i className="fa fa-warning"></i>
                    </Tooltip>
                )}
            </div>
            <div className="col-sm-1 text-center">
                {/* Display a warning if the entity participating is defined and don't have fitting ewh */}
                {!props.is_read_only && form_data.entity_obj && participate && !fittingEwh && (
                    <Tooltip
                        className="text-warning-emphasis"
                        title={trans.t("Ce salarié n'a aucun créneau de travail couvrant ces horaires d’activité")}
                    >
                        <i className="fa fa-warning"></i>
                    </Tooltip>
                )}
            </div>
        </div>
    );
}

const ServiceGroup = props => {
    const [expanded, setExpanded] = useState(!!props.technicians.find(x => x.participate));
    const service_name = props.technicians[0].entity_obj.main_service?.name || trans.t("Sans service");

    return (
        <div className="service-group">
            <div className="service-container" onClick={() => setExpanded(!expanded)}>
                <i className={"fa " + (expanded ? "fa-chevron-down" : "fa-chevron-right")}></i>

                {service_name}
            </div>
            {expanded && !props.readOnly && (
                <div className="row my-2 d-flex align-items-center">
                    <label className="col-4 fw-bold" htmlFor="all_participating">
                        {trans.t("Sélectionner tout")}
                    </label>
                    <div className="col-1 text-center">
                        <input
                            type="checkbox"
                            name="all_participating"
                            onChange={(ev) => {
                                props.selectTechnicians( // Select all technicians except all-day absentees
                                    props.technicians.filter(
                                        (tech) =>
                                            !props.absences.find(
                                                (x) => x.employee.pk == tech.entity_obj.pk && x.is_all_day
                                            )
                                    ),
                                    ev.target.checked
                                );
                            }}
                        />
                    </div>
                </div>
            )}
            {expanded && props.technicians.filter(
                x => (x.entity_obj && x.entity_obj.name.toLowerCase().includes(props.textFilter.toLowerCase()))
            ).
                map((form_data) => {
                return (
                    <TechnicianForm
                        data={form_data}
                        event={props.event}
                        index={props.index}
                        key={JSON.stringify(form_data) + props.index}
                        current_entities={props.current_entities}
                        onTableChange={props.onTableChange}
                        absence={props.absences.find(x => x.employee.pk == form_data.entity_obj.pk)}
                        workingHours={props.workingHours.filter(x => x.employee == form_data.entity_obj.pk)}
                        is_read_only={props.readOnly}
                        setEwhMissing={props.setEwhMissing}
                    />
                )
            })}
        </div>
    );
}

const PlanningEventTechniciansPanel = props => {
    const [techniciansLoaded, setTechniciansLoaded] = useState(props.technicians);
    const [techniciansTable, setTechniciansTable] = useState(
        props.technicians ? buildInitialTable(props.event, techniciansLoaded) : []
    );
    const [textFilter, setTextFilter] = useState('');
    const [workingHours, setWorkingHours] = useState(props.working_hours || []);
    const [absences, setAbsences] = useState([]);
    const [servicePermissions, setServicePermissions] = useState(props.service_permissions || null);
    const [publication, setPublication] = useState(props.publication);

    useEffect(() => {
        if(!props.technicians) {
            let employees = [];
            fetchCsrfWrapper(
                '/backend/technician/?extra_fields=main_service&status=permanent&with_services_only=True',
                {},
                {}
            ).then(
                data => {
                    employees = data;
                    // List IDs of intermittents details to load
                    let intermittent_to_load = [];
                    let permanents_ids = data.map(x => x.pk);
                    for(let ets of props.event.technicians) {
                        if(!intermittent_to_load.includes(ets.entity) && !permanents_ids.includes(ets.entity) && ets.entity)
                            intermittent_to_load.push(ets.entity)
                    }

                    return new Promise((resolve) => {
                        if(intermittent_to_load.length == 0) {
                            resolve([]);
                        }
                        else {
                            fetch(
                                '/backend/technician/?extra_fields=main_service&id__in=' + intermittent_to_load.join(',')
                            ).then(resp => resolve(resp.json()))
                        }
                    })
                }
            ).then(
                intermittents => {
                    employees = employees.concat(intermittents);
                    setTechniciansLoaded(employees);
                    setTechniciansTable(buildInitialTable(props.event, employees))

                    const date_fmt = 'YYYY-MM-DDTHH:MM:SS'
                    return fetchCsrfWrapper(
                        '/backend/employeeattendance/'
                        + '?overlap=' + moment(props.event.start_datetime).format(date_fmt)
                            + ',' + moment(props.event.end_datetime).format(date_fmt)
                        + '&employee__in=' + employees.map(x => x.pk).join(',')
                        + '&sz=planning&is_available=False'
                    )
                }
            ).then(
                data => {
                    setAbsences(
                        data.map((absence) => {
                            return {
                                ...absence,
                                is_all_day: absenceIsAllDay(
                                    moment(absence.start_datetime),
                                    moment(absence.end_datetime),
                                    moment(props.event.slot_date, "YYYY-MM-DD")
                                ),
                            };
                        })
                    );
                }
            )
        }
        else{
            // When accessing PlanningEventTechniciansPanel from EmployeesPlanning,
            // props.event.start_datetime/end_datetime are moment datetimes
            fetchCsrfWrapper(
                "/backend/employeeattendance/" +
                    "?overlap=" +
                    moment(props.event.start_datetime).format("YYYY-MM-DDTHH:mm:ss") +
                    "," +
                    moment(props.event.end_datetime).format("YYYY-MM-DDTHH:mm:ss") +
                    "&employee__in=" +
                    props.technicians.map(x => x.pk).join(",") +
                    "&sz=planning&is_available=False"
            ).then((data) => {
                setAbsences(
                    data.map((absence) => {
                        return {
                            ...absence,
                            is_all_day: absenceIsAllDay(
                                moment(absence.start_datetime),
                                moment(absence.end_datetime),
                                moment(props.event.slot_date, "YYYY-MM-DD")
                            ),
                        };
                    })
                );
            });
        }
        if(servicePermissions == null) {
            fetchCsrfWrapper(
                '/backend/servicepermission/',
                {},
                {}
            ).then(
                data => {
                    setServicePermissions(data);
                }
            ).catch((data) => alert(trans.t("Une erreur inconnue s'est produite")))
        }
        if(!props.publication) {
            fetchCsrfWrapper(
                "/backend/planningpublication/" +
                    "?overlap=" +
                    moment(props.event.start_datetime).format("YYYY-MM-DD") +
                    "," +
                    moment(props.event.end_datetime).format("YYYY-MM-DD")
            )
                .then((data) => {
                    setPublication(data[0]);
                })
                .catch((data) => alert(trans.t("Une erreur inconnue s'est produite")));
        }
        if (!props.working_hours) {
            fetchCsrfWrapper(
                "/backend/employeeworkinghours/" +
                    "?start_datetime__range=" +
                    event_start_dt.format("YYYY-MM-DD") +
                    "," +
                    event_end_dt.format("YYYY-MM-DD")
            )
                .then((data) => setWorkingHours(data))
                .catch((data) => alert(trans.t("Une erreur inconnue s'est produite")));
        }
    }, []);

    let event_start_dt = moment(props.event.start_datetime).locale('fr');
    let event_end_dt = moment(props.event.end_datetime);

    const reloadAbsences = (techniciansTable) => {
        fetchCsrfWrapper(
            "/backend/employeeattendance/" +
                "?overlap=" +
                moment(props.event.start_datetime).format("YYYY-MM-DDTHH:mm:ss") +
                "," +
                moment(props.event.end_datetime).format("YYYY-MM-DDTHH:mm:ss") +
                "&employee__in=" +
                techniciansTable.map(x => x.entity).join(",") +
                "&sz=planning&is_available=False"
        ).then((data) => {
            setAbsences(
                data.map((absence) => {
                    return {
                        ...absence,
                        is_all_day: absenceIsAllDay(
                            moment(absence.start_datetime),
                            moment(absence.end_datetime),
                            moment(props.event.slot_date, "YYYY-MM-DD")
                        ),
                    };
                })
            );
        });
    }

    const onTableChange = (field, val, index_or_pk, source) => {
        let newTable = techniciansTable.slice();
        const idx = source === 'service' ? newTable.findIndex(x => x.entity === index_or_pk) : index_or_pk;
        // Ugly hack to also update the PK field used by the handleSave in one shot
        if(field == 'entity_obj'){
            if(val){
                newTable[idx] = Object.assign({}, newTable[idx], {['entity_obj']: val});
                newTable[idx]['entity'] = newTable[idx]['entity_obj'].pk;
                reloadAbsences(newTable);
            } else newTable.splice(idx, 1);

        }
        else{
            newTable[idx] = Object.assign({}, newTable[idx], {[field]: val});
        }
        setTechniciansTable(newTable)
        props.onFieldChange('technicians', newTable);
    }

    const selectTechnicians = (technicians, checked) => {
        let newTable = techniciansTable.slice();
        technicians.forEach(tech => {
            const idx = newTable.findIndex(x => x.entity === tech.entity_obj.pk);
            newTable[idx] = Object.assign({}, newTable[idx], {["participate"]: checked});
        });
        setTechniciansTable(newTable);
        props.onFieldChange('technicians', newTable);
    }

    const onAddLine = () => {
        let newTable = techniciansTable.slice();
        newTable.push({
            'pk': null,
            'entity': null,
            'source': 'select',
            'start_delta': null,
            'end_delta': null,
            'participate': true
        });
        setTechniciansTable(newTable)
    }

    let technicianGroupedByServices = techniciansTable.filter(tech => tech.source === 'service').reduce((acc, tech) => {
        if(!tech.entity_obj){
            tech.entity_obj = techniciansLoaded.find(x => x.pk == tech.entity);
        }

        const service = tech.entity_obj.main_service || {'pk': null};
        if(!acc.has(service.pk)){
            acc.set(service.pk, [tech]);
        } else {
            acc.get(service.pk).push(tech);
        }
        return acc;
    }, new Map());

    // Re-order based on permissions (write first)
    technicianGroupedByServices = Array.from(technicianGroupedByServices).sort((a,b) => {
        const permissions_a = (servicePermissions || []).filter(x => x.service.pk == a[0]).map(x => x.permission_type[0]);
        const permissions_b = (servicePermissions || []).filter(x => x.service.pk == b[0]).map(x => x.permission_type[0]);

        if(permissions_a.includes('manage-employees')){
            return -1;
        }

        if(permissions_b.includes('manage-employees')){
            return 1;
        }

        if(permissions_a.includes('read-antepublication')){
            return -1;
        }

        if(permissions_a.includes('read-postpublication')){
            return permissions_b.includes('read-antepublication') ? 1 : -1;
        }

        return 1;
    });

    return (
        <div className="tab-technicians">
            <div className="row my-2">
                <div className="offset-xs-1 col-3">
                    <div className="input-group">
                        <div className="input-group-text">
                            <i className="fa fa-filter" />
                        </div>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={trans.t("Filtrer par nom de salarié")}
                            onChange={ev => setTextFilter(ev.target.value)}
                            value={textFilter}
                        />
                    </div>
                </div>
                <div className="offset-xs-1 col-2">
                    <TimeInputWrapper>
                        <input
                            className="form-control" disabled
                            value={event_start_dt.format('HH:mm')}
                        />
                    </TimeInputWrapper>
                </div>
                <div className="col-2">
                    <TimeInputWrapper>
                        <input
                            className="form-control" disabled
                            value={event_end_dt.format('HH:mm')}
                        />
                    </TimeInputWrapper>
                </div>
            </div>
            {technicianGroupedByServices.map(([service, serviceTechnicians], idx) => {
                let permissions = [];
                let readOnly = false;
                if(service){
                    permissions = (servicePermissions || []).filter(x => x.service.pk == service).map(x => x.permission_type[0]);

                    // No permissions at all
                    if(permissions.length == 0){
                        return;
                    }

                    // Read-only post publication
                    if(
                        (permissions.includes('read-postpublication') && !permissions.includes('read-antepublication'))
                        && !(publication && publication.service.pk == service)
                    ){
                        return;
                    }

                    readOnly = !permissions.includes('manage-employees');
                }
                return (
                    <ServiceGroup
                        key={idx}
                        technicians={serviceTechnicians}
                        absences={absences}
                        workingHours={workingHours}
                        textFilter={textFilter}
                        onTableChange={onTableChange}
                        event={props.event}
                        selectTechnicians={selectTechnicians}
                        readOnly={readOnly}
                        servicePermissions={permissions}
                        setEwhMissing={props.setEwhMissing}
                    />
                );
            })}

            {techniciansTable.map(
                (form_data, idx) => {
                    if(form_data.source === 'service'){
                        return null;
                    }
                    return (
                        <TechnicianForm
                            data={form_data}
                            event={props.event}
                            index={idx}
                            key={JSON.stringify(form_data) + idx}
                            current_entities={techniciansTable.filter((l) => l.entity).map((l) => l.entity)}
                            onTableChange={onTableChange}
                            absence={
                                form_data.entity_obj && absences.find((x) => x.employee.pk == form_data.entity_obj.pk)
                            }
                            workingHours={workingHours.filter(x => x.employee == (form_data.entity_obj || {}).pk)}
                            setEwhMissing={props.setEwhMissing}
                        />
                    );
                }
            )}

            {servicePermissions && servicePermissions.find(x => x.permission_type[0] == 'manage-employees') && (
                <p>
                    <a className="action-link" onClick={onAddLine}>Ajouter un salarié</a>
                </p>
            )}
            {props.errors['technician_overlap'] &&
                <p style={{'borderLeft': '3px solid #ad4e49', 'padding': '5px 0 0 8px' }}>
                    <span className="text-danger">{props.errors['technician_overlap']}</span><br />
                    <label>
                        <input
                            type="checkbox"
                            checked={props.forceTechnicianOverlap || false}
                            onChange={ev => props.setForceTechnicianOverlap(ev.target.checked)}
                        />&nbsp;{trans.t("Forcer l'enregistrement de l'évenement sur ce créneau horaire")}
                    </label>
                </p>
            }
        </div>
    );
}


export default PlanningEventTechniciansPanel;
