import React, { useEffect, useState, useMemo } from 'react';
import classNames from 'classnames';
import moment from 'moment';


import {
    AjaxNotification,
    hasPerm,
    pluralize,
} from 'orfeo_common/react-base.jsx';
import Modal from 'orfeo_common/Modal.jsx';
import { durationFormat } from 'orfeo_common/CalendarUtils.jsx';
import { CurrencySelector } from 'orfeo_common/Fields';
import * as FormUtils from 'orfeo_common/FormUtils.jsx';
import { SelectInput } from 'orfeo_common/Widgets.jsx';
import Tooltip from 'orfeo_common/Tooltip.jsx';
import { fetchWrapper } from 'orfeo_common/utils/http.jsx';
import { groupByArray } from 'orfeo_common/utils/arrays.jsx';
import { get_pretty_dates } from 'orfeo_common/utils/dates.jsx';
import { formatCurrency } from 'orfeo_common/utils/formats.jsx';


import ContractTypeSpecificFields from 'orfeo/administration/jsx/employeeengagement/modal/ContractTypeSpecificFields.jsx';
import { GeneralSectionFields } from 'orfeo/administration/jsx/employeeengagement/modal/GeneralSectionFields.jsx';
import PayrollManager from 'orfeo/administration/jsx/employeeengagement/modal/PayrollManager.jsx';
import { getPayInfosCellContent } from 'orfeo/administration/jsx/employeeengagement/Utils.jsx';

moment.locale('fr');

const MODE_UNIQUE_FORM = 'unique-form';
const MODE_MODEL_SELECTION = 'model-selection';
const MODE_EWH = 'ewh'
const FirstStepModeSelection = ({onModeClick, canUseModel, hideModelChoice, showEwhChoice}) => {
    return (
        <div className="block-choices-with-icon">
            <a role="button" onClick={() => onModeClick(MODE_UNIQUE_FORM)}>
                <i className="fa fa-lg fa-pencil"></i><br />
                {trans.t("Créer le même engagement pour les salariés sélectionnés")}
            </a>
            {!hideModelChoice &&
                <a role="button" onClick={() => canUseModel && onModeClick(MODE_MODEL_SELECTION)} className={canUseModel ? "" : "disabled"}>
                    <i className="fa fa-lg fa-list-alt"></i><br />
                    {trans.t("Sélectionner un engagement type pour chaque salarié")}
                    {!canUseModel && <small>
                        <br/>
                        <i className="fa fa-info-circle"></i>
                        {trans.t("Impossible lorsque plusieurs spectacles différents sont sélectionnés")}
                    </small>}
                </a>
            }
            {showEwhChoice &&
                <a role="button" onClick={() => onModeClick(MODE_EWH)}>
                    <i className="fa fa-lg fa-list-alt"></i><br />
                    {trans.t("Créer des engagements pour chaque salarié selon le planning du personnel")}
                </a>
            }
        </div>
    );
}


/**
 * Modal variant where the user can select a default engagement per employee to
 * create their concrete counterpart in the current project
 */
class EngagementModelSelectionForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            'loading': true
        }
    }

    componentDidMount() {
        fetchWrapper(
            '/backend/employeedefaultengagement/?project=' + this.props.project.pk
        ).then(
            data => {
                // Group models per employee and set initial dict structure to note the selection
                let participants_dict = {};
                for(let p of this.props.participants)
                    participants_dict[p.employee.pk] = {'participant': p, 'models': [], 'model_selected': null}
                for(let model of data) {
                    if(!!participants_dict[model.employee.pk])
                        participants_dict[model.employee.pk]['models'].push(model)
                }

                // Transform the dict structure into a flat list of dict and set default selection for
                // lines with a single model
                let participants = [];
                for(let key of Object.keys(participants_dict)) {
                    let d = participants_dict[key];
                    if(d['models'].length == 1)
                        d['model_selected'] = d['models'][0];

                    participants.push(d);
                }
                participants.sort(
                    (x, y) => {
                        let name1 = x['participant']['employee']['last_name'].toLowerCase();
                        let name2 = y['participant']['employee']['last_name'].toLowerCase();
                        if(name1 > name2)      return 1;
                        else if(name1 < name2) return -1;

                        return 0;
                    }
                )
                this.setState({'participants': participants, 'loading': false})
            }
        );
    }

    onSave(ev) {
        ev.preventDefault();

        let model_selection = {};
        for(let data of this.state.participants) {
            model_selection[data['participant'].pre_engagement.participant] = (
                data['model_selected'] ? data['model_selected'].pk : null
            );
        }
        let post_data = {'models': model_selection};

        let notif_id = AjaxNotification.show(trans.t('Sauvegarde...'));
        $.ajax({
            url: '/backend/employeeengagement/mass_creation_from_model/', method: 'POST',
            data: JSON.stringify(post_data), contentType: "application/json",
        }).success(
            (data, status) => this.props.onClose(data)
        ).error(
            (data, status) => this.setState({'errors': FormUtils.getErrorsDict(data.responseJSON)})
        ).always(
            () => AjaxNotification.hide(notif_id)
        )
    }

    onModelChange(participant_pk, model) {
        let participants = this.state.participants.slice()
        for(let data of participants) {
            if(data['participant'].pre_engagement.participant == participant_pk) {
                data['model_selected'] = model != data['model_selected'] ? model : null;
            }
        }
        this.setState({'participants': participants});
    }

    render() {
        if(this.state.loading)
            return (
                <p className="empty-list-placeholder">
                    <i className="fa fa-spin fa-spinner"></i> {trans.t("Chargement...")}
                </p>
            );


        let nb_engagements_to_create = this.state.participants.filter(x => x['model_selected'] !== null).length;
        return (
        <form className="react-modal-form" onSubmit={this.onSave.bind(this)}>
            <Modal.Body>
                <table className="table table-condensed table-model-selection">
                    <thead>
                        <tr>
                            <th>{trans.t("Salarié")}</th>
                            <th>{trans.t("Modèle")}</th>
                            <th>{trans.t("Aperçu conditions")}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.participants.map(
                        ({participant, models, model_selected}) => (
                            models.length > 0 ?
                                models.map(
                                    (model, idx) => {
                                        let participant_id = participant.pre_engagement.participant;
                                        let current_model_selected = model_selected && model_selected.pk == model.pk;
                                        return (
                                        <tr key={participant_id+'-'+model.pk}>
                                            {idx == 0 && // Make the cell itself colored in case the model selected is not the first one
                                            <td className={"artist-cell " + (model_selected != null ? 'line-selected': '')} rowSpan={models.length}>
                                                <a href={participant.employee.absolute_url}>
                                                    {participant.employee.name}
                                                </a>
                                            </td>}
                                            <td
                                                className={classNames(
                                                    "model-name-cell", {'selected': current_model_selected}
                                                )}
                                                onClick={ev => this.onModelChange(participant_id, model)}>
                                                <i className={classNames("fa fa-fw", {
                                                    'fa-check-square-o': current_model_selected,
                                                    'fa-square-o': !current_model_selected
                                                })}></i>&nbsp;{model.name}
                                            </td>
                                            <td style={{'width': '30%'}} onClick={ev => this.onModelChange(participant_id, model)}>
                                                {getPayInfosCellContent(model, null, false)}
                                            </td>
                                        </tr>
                                        )
                                    }
                                )
                            :
                            <tr>
                                <td className="artist-cell no-models">
                                    <a href={participant.employee.absolute_url}>
                                        {participant.employee.name}
                                    </a>
                                </td>
                                <td colSpan="2"><em>{trans.t("Aucun modèle disponible")}</em></td>
                            </tr>
                        )
                    )}
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <a className="btn btn-outline-secondary" onClick={() => this.props.onClose(null)}>{trans.t("Annuler")}</a>
                <button type="submit" className="btn btn-primary" disabled={nb_engagements_to_create == 0}>
                    {nb_engagements_to_create > 1 ? 
                        trans.t("Créer {{nb_engagements}} engagements", {nb_engagements: nb_engagements_to_create})
                    :
                        trans.t("Créer {{nb_engagements}} engagement", {nb_engagements: nb_engagements_to_create})
                    }
                </button>
            </Modal.Footer>
        </form>
        );
    }
}


/**
 * Modal variant where user can submit arbitrary data for an engagement, then
 * submit to use these data in an engagement per participant selected
 */
const EngagementUniqueForm = props => {
    const [engagement, setEngagement] = useState(props.engagement);
    const [payLines, setPayLines] = useState([]);
    const [errors, setErrors] = FormUtils.useFormErrors();
    const [customFieldsDefinition, setCustomFieldsDefinition] = useState(props.custom_fields || []);

    const onFieldChange = (field, value) => setEngagement(
        engagement => Object.assign({}, engagement, {[field]: value})
    )
    const onPayLinesChange = lines => setPayLines(lines);

    useEffect(() => {
        if(props.project){
            fetchWrapper(
                "/backend/employeeengagement/initialize_from_project/?project=" + props.project.pk,
            ).then(data => setEngagement(
                engagement => Object.assign({}, engagement, data))
            );
        }

        if(!props.custom_fields){
            fetchWrapper(
                '/backend/customfield/?model=employeeengagement',
            ).then(data => {
                setCustomFieldsDefinition(data)
            });
        }
    }, []);

    const onSave = ev => {
        ev.preventDefault();
        let post_data = {
            'participants': props.participants.map(
                x => x.pre_engagement.participant
            ),
            'engagement': {
                'type': engagement.type,
                'custom_fields': JSON.stringify(engagement.custom_fields || {}),
                'pay_category': engagement.pay_category[0],
                'profession': engagement.profession,
                'work_places': engagement.work_places,
                'currency': engagement.currency,
                'notes': engagement.notes || '',
                'contract_type': engagement.contract_type,
                'category': engagement.category
            }
        };

        if (props.show_temporal_fields){
            post_data.engagement['start_datetime'] = engagement.start_date + 'T' + engagement.start_time
            post_data.engagement['end_date'] = engagement.end_date;
            post_data.engagement['days_worked'] = engagement.days_worked;
        }

        if(engagement.type == 'contract'){
            post_data.engagement['pay_lines'] = (
                payLines.filter(item => !!item.type).map(
                    item => ({
                        'pk': item.pk > 0 ? item.pk : null,
                        'type': item.type,
                        'date': item.date || engagement.start_date,
                        'quantity': item.quantity,
                        'unit_amount': item.unit_amount,
                    })
                )
            );
        } else {
            post_data.engagement["net_amount"] = engagement.net_amount;
        }
        fetchWrapper(
            '/backend/employeeengagement/mass_creation/',
            {method: 'POST', body: post_data}
        ).then(
            (data, status) => props.onClose(data)
        ).catch(
            (data, status) => setErrors(data)
        )
    }

    let nb_with_engagements = props.participants.filter(x => !!x.engagement).length;
    return (
        <form className="react-modal-form" onSubmit={onSave}>
            <Modal.Body>
                {nb_with_engagements > 0 &&
                    <p className="text-warning-emphasis">
                        {trans.t(
                            "Parmi la sélection, il existe déjà {{nb_engagements}} engagements."
                            + " Ces engagements seront supprimés et les conditions saisies ici remplaceront les précédentes.",
                            {nb_engagements: nb_with_engagements}
                        )}
                    </p>
                }

                {Object.keys(errors).length > 0 &&
                    <p className="react-form-errors-intro">
                        {trans.t("Erreur lors de la sauvegarde.")} {FormUtils.getGlobalErrors(errors)}
                    </p>
                }
                <GeneralSectionFields
                    project={props.project}
                    profession_field_hint={
                        <span className="float-end text-muted">
                            <Tooltip placement="right" title={
                                trans.t("Tous les engagements seront alors créé avec cette profession. Si le champ est laissé vide, "
                                        + "la profession spécifiée dans la fiche du salarié sera utilisée.")
                            }>
                                <i className="fa fa-info-circle"></i>
                            </Tooltip>
                        </span>
                    }
                    engagement={engagement}
                    errors={errors}
                    show_temporal_fields={props.show_temporal_fields}
                    excludeOrchestraCategory={props.participants.every(p => p.extra_category)}
                    categoryFieldHint={
                        <span className="float-end text-muted">
                            <Tooltip placement="right" title={
                                trans.t("Cette catégorie ne sera appliqué qu'aux participants n'ayant pas de catégorie définie dans la distribution.")
                            }>
                                <i className="fa fa-info-circle"></i>
                            </Tooltip>
                        </span>
                    }
                    onFieldChange={onFieldChange}
                />
                <hr style={{'margin': '20px 0'}} />

                <ContractTypeSpecificFields
                    engagement={engagement}
                    custom_fields={customFieldsDefinition}
                    errors={errors}
                    onFieldChange={onFieldChange}
                    updateEngagement={data => setEngagement(Object.assign({}, engagement, data))}
                />

                <hr style={{'margin': '20px 0'}} />
                {engagement.type == "contract" && (
                    <PayrollManager
                        engagement={engagement}
                        pay_lines={payLines}
                        start_date={engagement.start_date}
                        currency={engagement.currency}
                        end_date={engagement.end_date}
                        onChange={onPayLinesChange}
                        onInitializePayLines={null}
                    />
                )}
                <FormUtils.ErrorText field="pay_lines" errors={errors} />
            </Modal.Body>
            <Modal.Footer>
                <a className="btn btn-outline-secondary" onClick={() => props.onClose(null)}>Annuler</a>
                <button type="submit" className="btn btn-primary">
                    {props.participants.length > 1 ? 
                        trans.t("Créer {{nb_engagements}} engagements", {nb_engagements: props.participants.length})
                    :
                        trans.t("Créer {{nb_engagements}} engagement", {nb_engagements: props.participants.length})
                    }
                </button>
            </Modal.Footer>
        </form>
    )
}


const EmployeeSection = props => {
    const [loading, setLoading] = useState(null);
    const [engagementsData, setEngagementsData] = useState(null);
    const [error, setError] = useState(null);

    const lines = props.selectedParticipants.filter(x => !!x.project && !!x.pre_engagement.profession);

    useEffect(() => {
        const url = '/backend/employeeengagement/engagements_data_from_working_hours/';
        const body = {
            employee: props.employee.pk,
            month: props.month.format('YYYY-MM-DD'),
            // Build a list of project - profession
            lines: lines.map(x => ({
                project: x.project.pk,
                profession: x.pre_engagement.profession.pk
            }))
        };

        if(lines.length === 0){
            setEngagementsData([]);
        } else {
            setLoading(true);

            fetchWrapper(url, {method: 'POST', body})
                .then(data => setEngagementsData(data))
                .catch(err => {
                    setError(err)
                })
                .finally(() => setLoading(false));
        }
    }, [lines.length]);

    function getEngagementData(participant){
        if(engagementsData){
            return engagementsData.find(x => (
                x.project == participant.project.pk
                && x.profession == participant.pre_engagement.profession.pk
            ));
        }
    }

    // For AB testing extra hours and payrolls are disabled
    const NB_COLUMNS = 9;
    // const NB_COLUMNS = 7;

    if(error){
        return (
            <tr>
                <td rowSpan={props.participants.length}>{props.employee.name}</td>
                <td className="text-danger" colSpan={NB_COLUMNS}>{error}</td>
            </tr>
        );
    }

    return (
        <React.Fragment>
            {props.participants.map((participant, index) => (
                <EngagementEwhRow
                    key={participant.uid}
                    loading={loading}
                    participant={participant}
                    month={props.month}
                    selectedParticipants={props.selectedParticipants}
                    toggleParticipantSelection={props.toggleParticipantSelection}
                    header={index === 0 && props.employee.name}
                    headerRowSpan={index === 0 && props.participants.length}
                    data={getEngagementData(participant)}
                />
            ))}
        </React.Fragment>
    )
}

const PayrollExtrasCell = props => {
    const payrollCount = props.payroll_extras.reduce((acc, payroll) => acc + payroll.quantity, 0) || 0;

    function getRecap(){
        return props.payroll_extras.map(payroll => (
            `${payroll.quantity} × ${payroll.code}`
        )).join('<br/>')
    }

    return (
        <td>
            {payrollCount || ' - '}
            {payrollCount > 0 && <Tooltip title={getRecap()} html>
                <i className="fa fa-info-circle ms-1 text-secondary"></i>
            </Tooltip>}

            {payrollCount > 0 && <p className="text-muted">
                <small>{formatCurrency(Object.values(props.salary).reduce((acc, x) => acc + x, 0), props.currency)}</small>
            </p>}
        </td>
    )
}


const EngagementEwhRow = props => {
    const employee = props.participant.employee;
    const project = props.participant.project;
    const profession = props.participant.pre_engagement.profession;
    const canSelectRow = project && profession;

    const grossSalary = props.data ? Number(props.data.salary.recap.total) : 0;
    const currency = props.data ? props.data.salary.currency : '';

    const hoursBreakdown = useMemo(() => {
        if(props.data){
            // Convert seconds to moment duration
            let hours = Object.entries(props.data.hours).map(([key, value]) => (
                [key, moment.duration(value, 'seconds')]
            ));
            hours = Object.fromEntries(hours);
            hours.extra_hours = hours.first_extra_hours.add(hours.next_extra_hours);

            return hours;
        }

        return null;
    }, [props.data]);

    const selected = props.selectedParticipants.map(p => p.uid).includes(props.participant.uid);

    return <tr>
        {!!props.header && <td rowSpan={props.headerRowSpan}>{ props.header }</td>}
        <td>
            <input type="checkbox" onChange={() => canSelectRow && props.toggleParticipantSelection(props.participant)}
                checked={selected}
                disabled={!canSelectRow}
            />
        </td>
        <td>
            {profession?.name}
            {profession?.hourly_rate && <p className="text-muted">
                <small>
                    {formatCurrency(profession.hourly_rate.amount, profession.hourly_rate.currency?.code)}/h
                </small>
            </p>}
        </td>
        <td>
            {project?.title}
            {props.participant?.pre_engagement && <p className="text-muted">
                <small>
                    <span>
                        {get_pretty_dates(
                            moment(props.participant.pre_engagement.start_datetime),
                            moment(props.participant.pre_engagement.end_date),
                            true, false, false
                        )}
                    </span>
                    <span>
                        / {props.participant.pre_engagement.days_worked.length} {trans.t("j. trav.")}
                    </span>
                </small>
            </p>}
        </td>
        {props.loading ?
            <td colSpan="5">
                <i className="fa fa-spinner fa-spin"></i>
            </td>
            :
            <>
                <td>
                    {hoursBreakdown?.normal.asMinutes() ? durationFormat(hoursBreakdown.normal) : ' - '}
                    {!!props.data?.salary.recap?.normal && <p className="mt-1 text-muted">
                        <small>{formatCurrency(props.data?.salary.recap?.normal, currency)}</small>
                    </p>}
                </td>
                <td>
                    {hoursBreakdown?.extra_hours.asMinutes() ? durationFormat(hoursBreakdown.extra_hours) : ' - '}
                    {(!!props.data?.salary.recap?.first_extra_hours || !!props.data?.salary.recap?.next_extra_hours) && <p className="mt-1 text-muted">
                        <small>
                            {[
                                props.data?.salary.recap?.first_extra_hours ?
                                    formatCurrency(props.data?.salary.recap?.first_extra_hours, currency)
                                : null,
                                props.data?.salary.recap?.next_extra_hours ?
                                    formatCurrency(props.data?.salary.recap?.next_extra_hours, currency)
                                : null,
                            ].filter(x => !!x).join(" + ")}
                        </small>
                    </p>}
                </td>
                <td>
                    {hoursBreakdown?.night_hours?.asMinutes() ? durationFormat(hoursBreakdown.night_hours) : ' - '}
                    {!!props.data?.salary.recap?.night_hours && <p className="mt-1 text-muted">
                        <small>{formatCurrency(props.data?.salary.recap?.night_hours, currency)}</small>
                    </p>}
                </td>
                <td>
                    {hoursBreakdown?.sunday_hours?.asMinutes() ? durationFormat(hoursBreakdown.sunday_hours) : ' - '}
                    {!!props.data?.salary.recap?.sunday_hours && <p className="mt-1 text-muted">
                        <small>{formatCurrency(props.data?.salary.recap?.sunday_hours, currency)}</small>
                    </p>}
                </td>
                <PayrollExtrasCell
                    payroll_extras={props.data?.payroll_extras || []}
                    salary={props.data?.salary.recap?.payrolls || {}}
                    currency={currency}
                />
                <td>{formatCurrency(grossSalary, currency)}</td>
            </>
        }
    </tr>
}

const EngagementEwhForm = props => {
    const [errors, setErrors] = FormUtils.useFormErrors();
    const [selectedParticipants, setSelectedParticipants] = useState(props.participants);

    const toggleParticipantSelection = selection => {
        const pks = selectedParticipants.map(p => p.uid);
        if (pks.includes(selection.uid)) {
            setSelectedParticipants(lst => lst.filter(p => p.uid != selection.uid))
        } else {
            // Use filter to keep ordering intact
            setSelectedParticipants(props.participants.filter(x => pks.includes(x.uid) || selection.uid == x.uid))
        }
    }
    const onSave = (ev) => {
        ev.preventDefault();

        const participantsData = selectedParticipants.map(p => ({
            employee: p.employee.pk,
            project: p.project.pk,
            profession: p.pre_engagement.profession.pk,
        }));

        fetchWrapper(
            '/backend/employeeengagement/generate_engagements_from_working_hours/',
            {method: 'POST', body: {
                month: props.month.format('YYYY-MM-DD'),
                lines: participantsData
            }}
        ).then(data => {
            window.location.reload();
        }).catch(errors => {
            setErrors(errors)
        });
    }
    const allParticipantsSelected = selectedParticipants.length == props.participants.length;

    function toggleMassSelection(){
        if(allParticipantsSelected){
            setSelectedParticipants([]);
        } else {
            setSelectedParticipants([...props.participants]);
        }
    }


    const participantsPerEmployee = groupByArray(props.participants, 'employee', e => e.pk);

    return (
        <form className="react-modal-form" onSubmit={onSave}>
            <Modal.Body>
                {Object.keys(errors).length > 0 &&
                    <p className="react-form-errors-intro">
                        {trans.t("Erreur lors de la sauvegarde. ") + FormUtils.getGlobalErrors(errors)}
                    </p>
                }
                <table className="table">
                    <thead>
                        <tr>
                            <th>{trans.t("Salarié")}</th>
                            <th>
                                <input
                                    type="checkbox"
                                    onChange={toggleMassSelection}
                                    checked={allParticipantsSelected}
                                />
                            </th>
                            <th>{trans.t("Profession")}</th>
                            <th>{trans.t("Projet")}</th>
                            <th>{trans.t("H. normales")}</th>
                            <th>{trans.t("H. supp")}</th>
                            <th>{trans.t("H. nuit")}</th>
                            <th>{trans.t("H. dimanche")}</th>
                            <th>{trans.t("Suppl.")}</th>
                            <th>{trans.t("Salaire brut")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {participantsPerEmployee.map(({key: employee, values: participants}) => (
                            <EmployeeSection
                                key={employee.pk}
                                employee={employee}
                                participants={participants}
                                month={props.month}
                                selectedParticipants={selectedParticipants}
                                toggleParticipantSelection={toggleParticipantSelection}
                            />
                        ))}
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <a className="btn btn-outline-secondary" onClick={() => props.onClose(null)}>
                    {trans.t("Annuler")}
                </a>
                <button type="submit" className="btn btn-primary" disabled={selectedParticipants.length === 0}>
                    {selectedParticipants.length > 1 ? 
                        trans.t("Créer {{nb_engagements}} engagements", {nb_engagements: selectedParticipants.length})
                    :
                        trans.t("Créer {{nb_engagements}} engagement", {nb_engagements: selectedParticipants.length})
                    }
                </button>
            </Modal.Footer>
        </form>

    )
}


const ProjectParticipantEngagementMassCreationModal = props => {
    // Engagement model are tied to spectacle, so if the user doesn't have access to it we don't need to let it him select
    let canSeeModelChoice = hasPerm('production.spectacle_access');
    let canSeeEwhChoice = (
        props.month
        && hasPerm('administration.mass_engagement_from_ewh')
    );
    const [mode, setMode] = useState(canSeeModelChoice || canSeeEwhChoice ? null : MODE_UNIQUE_FORM);

    let canUseModel = true;
    if(!props.project){
        if(new Set(props.participants.map(x => x.project ? x.project.spectacle : null)).size > 1){
            canUseModel = false;
        }
    }
    let modal_content;
    if(mode == MODE_UNIQUE_FORM || !props.withModels)
        modal_content = <EngagementUniqueForm {...props} />;
    else if(mode == MODE_MODEL_SELECTION)
        modal_content = <EngagementModelSelectionForm {...props} />;
    else if (mode == MODE_EWH)
        modal_content = <EngagementEwhForm {...props} />;
    else {
        modal_content = (
            <React.Fragment>
                <Modal.Body>
                    <FirstStepModeSelection
                        onModeClick={mode => setMode(mode)}
                        canUseModel={canUseModel}
                        hideModelChoice={!canSeeModelChoice}
                        showEwhChoice={canSeeEwhChoice}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <a className="btn btn-secondary" onClick={() => props.onClose(null)}>
                        {trans.t("Annuler")}
                    </a>
                </Modal.Footer>
            </React.Fragment>
        );
    }

    return (
        <Modal show={true} onHide={props.onClose} modalCSSClass="wide project-engagements-mass-creation modal-lg">
            <Modal.Header>{trans.t("Création multiple d'engagements")}</Modal.Header>
            {modal_content}
        </Modal>
    );
}

export default ProjectParticipantEngagementMassCreationModal;
