/**
 */
'use strict';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
    deepCopy,
    hasSelectedTextInBrowser
} from 'orfeo_common/react-base.jsx';
import Tooltip from 'orfeo_common/Tooltip.jsx';
import { fetchWrapper, getCookie } from 'orfeo_common/utils/http.jsx';

import Conversation from './Conversation.jsx';
import EmailComposer from './EmailComposer.jsx';
import EmailStore from './EmailStore.jsx';
import { humanShortDate } from './utils.jsx';
import EmailViewer from './EmailViewer.jsx';

/**
 * Single mail line, displayed by default where only the sender and a snippet of the body
 * is displayed. The user can unwrap the mail to show it in details.
 */
function get_email_preview(text_variant, html_variant) {
    if(text_variant != '')
        return text_variant.substring(0, 200);

    // Removes HTML from the string using the browser parser
    var div = document.createElement("div");
    div.innerHTML = html_variant;
    return (div.textContent || div.innerText || "").substring(0, 200);
}

const EmailPreview = props => {
    const opens = Object.keys(props.email.opening_trackers).filter(
        elem => props.email.opening_trackers[elem] > 0
    );

    return (
        <div className="email-line" onClick={props.onHeaderClick}>
            <p className="email-preview">
                {props.email.attachments.length > 0 &&
                    <Tooltip title={trans.t("{{attachment_count}} pièce(s) jointe(s)", {attachment_count: props.email.attachments.length})}>
                        <i className="float-end fa fa-paperclip"></i>
                    </Tooltip>
                }
                <span className="text-muted">
                    <i className="fa fa-caret-right"></i> {props.email.email_from && (props.email.email_from.full_name || props.email.email_from.email)}
                </span>

                {opens.length > 0 &&
                    <Tooltip title={trans.t("E-mail ouvert par : {{openers}}", {openers: opens.join(', ')})}>
                        <i className="fa fa-envelope-open-o text-success" style={{'marginLeft': '10px'}}></i>
                    </Tooltip>
                }

                &nbsp; {get_email_preview(props.email.text_body, props.email.html_body)}
            </p>
        </div>
    );
}

/**
 * Display the collection of mail of a thread, with the subject as a header
 */
const EmailContainer = ({email, has_email_account}) => {
    const [collasped, setCollasped] = useState(true);
    const onMarkAsDone = ev => {
        ev.stopPropagation();
        let operation = (
            email.marked_as_done.indexOf(PROFILE_PK) !== -1 ?
                "mark-not-done"
            :
                "mark-done"
        );
        $.ajax({
            url: "/mails/inbox/" + operation + "/", method: "POST",
            data: {'message_id': [email.message_id]}, dataType: "json",
        }).success((data, status) => {
            var new_email = deepCopy(email);
            if(operation == 'mark-done')
                email.marked_as_done.push(PROFILE_PK);
            else
                email.marked_as_done.splice(email.marked_as_done.indexOf(PROFILE_PK), 1);

            EmailStore.update(new_email);
        }).error(
            (data, status) => alert(trans.t('Erreur lors de la sauvegarde'))
        );
    }

    const onMessageTrashed = () => {
        EmailStore.delete(email.message_id)
    }

    const onExpandMessage = val => {
        if(!hasSelectedTextInBrowser())
            setCollasped(!val)
    }

    let show_done_marker = (email.email_from && email.email_from.email != EmailStore._email_address);
    let marked_as_done = email.marked_as_done.indexOf(PROFILE_PK) !== -1;

    const in_outbound_box = (
        email.outbound && (email.outbound.status == 'sending' || email.outbound.status == 'enqueued')
    );
    let has_sending_error = false;
    if(email.outbound && email.outbound.results) {
        for(let address in email.outbound.results) {
            let status = email.outbound.results[address];
            if(status != 'OK') {
                has_sending_error = true;
                break;
            }
        }
    }

    const msg_date = email.date ? moment.utc(email.date).locale('fr') : null;
    return (
        <div className="thread clearfix">
            <p className="thread-header">
                <span className="subject">{email.subject}</span>

                {email.survey_url && !window.location.href.includes(email.survey_url) &&
                    <a className="float-end mx-1" href={email.survey_url} target="_blank" rel="noreferrer">
                        <i className="fa fa-external-link"></i>
                        {trans.t("Voir le pré-contrat")}
                    </a>
                }
                {in_outbound_box &&
                <span className="float-end mark-as-done" data-outbound-id={email.outbound.id}>
                    <span className="text-info-emphasis">{trans.t("Boite d'envoi")}</span>
                </span>}
                {has_sending_error &&
                <Tooltip
                    className="float-end mark-as-done text-danger"
                    title={email.outbound.error_reason}
                >
                    <i className="fa fa-warning"></i> {trans.t("Erreur d'envoi")}
                </Tooltip>}

                {(show_done_marker && !marked_as_done) &&
                <span className="float-end mark-as-done me-2" onClick={onMarkAsDone}>
                    <a className="btn btn-sm btn-success">
                        <i className="fa fa-check"></i> {trans.t("Terminé")}
                    </a>
                </span>}

                <span className="date">
                {msg_date &&
                    <Tooltip title={msg_date.format('LLL')} placement="left" className="float-end text-muted">
                        {humanShortDate(msg_date)}
                    </Tooltip>
                }
                </span>
            </p>

            {collasped ?
                <EmailPreview
                    email={email}
                    onHeaderClick={() => onExpandMessage(true)}
                />
                :
                <EmailViewer
                    email={email}
                    hasEmailAccount={has_email_account}
                    onMessageTrashed={onMessageTrashed}
                    onHeaderClick={() => onExpandMessage(false)}
                />
            }
        </div>
    );
}

// Set a idle time tracker to stop fetching new mail if the user
// is inactive for a long time
const MAX_IDLE_TIME = 8;
var user_idle_time = 0;
var next_refresh;
var next_idle_increase;
function bindIdleWatcher() {
    // increment every minute
    next_idle_increase = setInterval(function () { user_idle_time += 1; }, 60000);

    // Delay next idle on mousemove, so the user does not reset fetch to 45sec if he
    // is just looking at the page
    document.addEventListener('mousemove', function (e) {
        clearInterval(next_idle_increase);
        next_idle_increase = setInterval(function () { user_idle_time += 1; }, 60000);
    });
    document.addEventListener('keypress', function (e) {
        user_idle_time = 0;
    });
}

const EmailManager = props => {
    const [hasAccount, setHasAccount] = useState(null);
    const [initialScan, setInitialScan] = useState(null);
    const [nextOffset, setNextOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [emails, setEmails] = useState([]);

    const [xhrLoading, setXhrLoading] = useState(null);
    const [warningMessage, setWarningMessage] = useState(null)

    const [composeNewMail, setComposeNewMail] = useState(props.newMail);
    const [composeInitialRecipient, setComposeInitialRecipient] = useState(props.initialRecipient);

    const [lastEmailDateFetched, setLastEmailDateFetched] = useState(null);
    const [entitySelected, setEntitySelected] = useState(props.default_entity_selected);


    const _CONVERSATION_UI_BETA = getCookie('_ui_ab_testing') == "true";
    let conversations = [];
    if(_CONVERSATION_UI_BETA) {
        /**
         * Group e-mails loaded per conversation by scanning the "In-Reply-To" header.
         * This header contains the message ID of another e-mail if the user used the
         * standard reply button of most of e-mails clients
         */
        for(let i = emails.length - 1; i >= 0; i--) {
            let email = emails[i];
            let found = false;

            // Only lookup for ancestor if a in_reply_to is specified, otherwise we'll
            // create a new group directly
            if(email.in_reply_to) {
                for(let group of conversations) {
                    // Is there any e-mail of the conversation built that correspond to our parent?
                    if(group.find(x => email.in_reply_to == x.message_id)) {
                        group.push(email)
                        found = true;
                        break;
                    }
                }
            }
            if(!found) {
                conversations.unshift([email]);
            }
        }
    }

    const urlForSurvey = url => {
        /*
         * In survey view we only want to see the mails we sent for the survey
         * so we need to pass a parameter to say that to the backend
         */
        if(!props.survey)
            return url;

        let newUrl = new URL(url, window.location.origin);
        let queryParams = newUrl.searchParams
        if (props.survey) {
            queryParams.append('survey_uuid', props.survey.uuid)
        }
        newUrl.search = queryParams.toString()
        return newUrl.href;
    }

    const fetchPreviousEmails = () => {
        setXhrLoading(true);
        fetchWrapper(
            urlForSurvey(props.fetchUrl),
            {
                method: "GET",
                body: {'offset': nextOffset}
            }
        ).then(data => {
            EmailStore.update(data.emails || [], true); // Update or add new e-mails fetched
            setNextOffset(data.next_offset);
            setHasMore(data.has_more);
        }).catch(
            err => console.error(trans.t("Impossible de charger les e-mails"))
        ).finally(
            () => setXhrLoading(false)
        );
    }

    const fetchNewEmails = () => {
        // Postpone the refreshing if:
        // - The user has no email account attached yet
        // - The user is inactive for more than 8 minutes on the current page
        // - A XHR loading request is already running
        if(!hasAccount || user_idle_time >= MAX_IDLE_TIME || xhrLoading) {
            next_refresh = setTimeout(fetchNewEmails, 1000 * 30);
            return;
        }

        var xhr_loading = $.ajax(
            urlForSurvey(props.fetchUrl),
            {
                method: "GET",
                body: {'after': lastEmailDateFetched}
        }).success(
            data => EmailStore.update(data.emails, true)
        ).always(jqXHR => {
            setXhrLoading(null);

            // Check if there is any outbound message
            var any_outbound = false;
            if(jqXHR && jqXHR.emails) {
                any_outbound = jqXHR.emails.filter(item => item.outbound != null).length > 0;
            }
            // Flat 10sec delay in case of outbound msg, otherwise increase it depending on the activity
            var next_call_time = any_outbound ? 5000 : (1000 * 30 + user_idle_time * 30);

            // Plan a new refresh in the future. The longer the user is inactive,
            // on the page, the longer we wait before running the query.
            next_refresh = setTimeout(fetchNewEmails, next_call_time);
        });
        // Keep track of the running request
        setXhrLoading(xhr_loading)
    }

    useEffect(() => {
        bindIdleWatcher();
        EmailStore.addOnListChange(() => setEmails(EmailStore.getList()));
        var xhr_loading = $.ajax({
            url: urlForSurvey(props.initialInfosUrl),
            method: "GET",
            dataType: "json",
        }).success(data => {
            EmailStore.setList(data['emails']);
            EmailStore._email_address = data['email_address'];

            setXhrLoading(null);
            setInitialScan(data.initial_scan);
            setHasAccount(data.has_email_account);
            setWarningMessage(data.warning_message);
            setNextOffset(data.next_offset)
            setHasMore(data.has_more)
            // If no mails are returned, load data for the current date only
            setLastEmailDateFetched(
                data.emails.length > 0 ?
                    data.emails[0].date
                    : moment().format('YYYY-MM-DD') + 'T00:00:00'
            )
        }).error(
            () => console.error(trans.t("Impossible de charger les e-mails"))
        )
        // Keep track of the running request
        setXhrLoading(xhr_loading);
    }, []);

    // On any component refresh, set up a future e-mail reload if none is set yet. We don't
    // defer it in XHR callback since the state might not be up to date yet in those callbacks
    useEffect(() => {
        if(!!next_refresh || !hasAccount)
            return;

        next_refresh = setTimeout(fetchNewEmails, 1000 * 10);
    });

    useEffect(() => {
        // Attach listener of global events
        const onInfoChanged = () => {
            clearTimeout(next_refresh);
            fetchNewEmails();
        };
        document.addEventListener('contactInfoChanged', onInfoChanged);
        return () => {document.removeEventListener('contactInfoChanged', onInfoChanged)}
    }, [])

    useEffect(() => {
        let listener = ev => {
            if (!hasAccount) {
                alert(trans.t(
                    'Veuillez lier votre compte e-mail avant de pouvoir envoyer'
                    + ' des e-mails depuis Orfeo.'
                ));
                return
            }

            // Open the composer if not opened yet. The event will be handled by
            // the composer itself otherwise
            if(!composeNewMail) {
                setEntitySelected(ev.detail.entity_pk);
                setComposeInitialRecipient(ev.detail.address);
                setComposeNewMail(true);
            }
        }

        document.addEventListener('Orfeo.OpenEmailComposer', listener);
        return () => {
            document.removeEventListener('Orfeo.OpenEmailComposer', listener)
        }
    }, [hasAccount, composeNewMail]);

    const onMailSent = data => {
        EmailStore.add(data['message']);
        setComposeNewMail(false)
        // set up a new refresh in 10sec
        clearTimeout(next_refresh);
        next_refresh = setTimeout(fetchNewEmails, 1000 * 10);
    }

    var new_message_composer;
    if(hasAccount && composeNewMail) {
        new_message_composer = (
            <EmailComposer
                mode="new"
                entities={[entitySelected]}
                survey={props.survey}
                onMailSent={onMailSent}
                composerInfosUrl={props.composerInfosUrl}
                onCancel={() => setComposeNewMail(false)}
                emailBody={props.newBody}
                to={composeInitialRecipient ? [composeInitialRecipient.email] : []}
            />
        )
    }
    return (
        <div className="email-manager">
            <h4>
                {xhrLoading && <i className="fa fa-spin fa-spinner float-end"></i>}
                {props.blockTitle || trans.t("E-mails")}

                {(hasAccount && !composeNewMail) &&
                <a className="add-link action-link" role="button" onClick={() => setComposeNewMail(true)}>
                    {trans.t("Nouveau message")}
                </a>}
            </h4>

            {warningMessage ?
                <p className="text-warning-emphasis" dangerouslySetInnerHTML={{__html: warningMessage}}>
                </p>
            :
                <div>
                    {hasAccount === false &&
                        <p className="alert alert-light">
                            {trans.t("Vous n'avez pas associé de compte de messagerie.")}
                            &nbsp;{trans.t("Pour visualiser mes e-mails directement depuis Orfeo,")}
                            &nbsp;<a href="/accounts/integrations/e-mail/">
                                {trans.t("associer un compte e-mail")}
                            </a>.
                        </p>
                    }

                    {initialScan &&
                        <p className="text-info-emphasis" title={trans.t("Scan démarré le {{date}}", {date: moment(initialScan.start_date).locale('fr').format('LLL')})}>
                            <i className="fa fa-info-circle"></i>&nbsp;
                            {trans.t(
                                "Vous venez de lier votre compte et l'indexation "
                                + "initiale est en cours. Certains e-mails "
                                + "peuvent encore manquer pendant quelques "
                                + "heures."
                            )}
                        </p>
                    }
                </div>
            }

            {new_message_composer}

            {_CONVERSATION_UI_BETA ?
                <div className="email-list">
                    {conversations.map((emails, idx) => (
                        <Conversation
                            key={idx + '' + emails.length}
                            emails={emails}
                        />
                    ))}
                </div>
            :
            <div className="email-list">
            {emails.map(
                email => (
                    <EmailContainer
                        email={email}
                        has_email_account={hasAccount}
                        key={email.message_id + (email.outbound || '') + email.marked_as_done}
                    />
                )
            )}
            </div>}
            {hasMore &&
            <p className="float-end">
                <button className="btn btn-link" onClick={fetchPreviousEmails}>
                    {trans.t("Charger les messages plus anciens")}&nbsp;
                    <i className="fa fa-chevron-circle-down"></i>
                </button>
            </p>}
        </div>
    );
}

export default EmailManager;
