import React, { useEffect, useState, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import { Editor as TinyMCE } from '@tinymce/tinymce-react';
import moment from 'moment';

import {
    AjaxNotification,
    fetchCsrfWrapper,
    floatFormat,
    hasPerm,
    PlaceholderLoadingBars,
    getModalWrapper
} from 'orfeo_common/react-base.jsx';
import { getISOTimeFromInput } from 'orfeo_common/CalendarUtils.jsx';
import Modal from 'orfeo_common/Modal.jsx';
import * as FormUtils from 'orfeo_common/FormUtils.jsx';
import { SelectInput } from 'orfeo_common/Widgets.jsx';
import { formatCurrency } from 'orfeo_common/utils/formats.jsx';

import { GeneralSectionFields } from './GeneralSectionFields.jsx';
import ContractTypeSpecificFields from './ContractTypeSpecificFields';
import EmployeeDPAEInfosView from 'orfeo/production/jsx/employee-engagement/EmployeeDPAEInfosView.jsx';
import PayrollManager from './PayrollManager.jsx';
import { computePayrollLinesTotal, CONTRACT_TYPES, PAY_CATEGORIES } from '../Utils.jsx';


let _EngagementCustomFieldsCache = null;
let _DefaultCurrencyCache = null;
let _DefaultOrgProfileCache = null;

let _uid = 0;
function getUniqueId() {
  return _uid++ + '';
}


/**
 * Determine if we should display the lines allowing to precompute payroll lines
 */
const canInitializePayLines = (engagement) => (
    // Only allow initialization from planning for artists in orchestra variant
    engagement.type == 'contract' && (VARIANT === 'orchestra' || !engagement.participant)
);

/**
 * Returns the data from the "specific" block according to the contract
 * type being initialized
 */
const getContractSpecificPostData = engagement => {
    if(engagement.contract_type === "extra") {
        return {'category': engagement.category ? engagement.category.pk : null}
    }
    else if(engagement.contract_type == 'replacement') {
        return {
            'substitute_name': engagement.substitute_name,
            'absence_reason': engagement.absence_reason,
            'category': engagement.category ? engagement.category.pk : null,
        }
    }
    else if(engagement.contract_type === "solist") {
        let data = {
            'global_fee_mode': engagement.global_fee_mode,
            'global_fee_amount': engagement.global_fee_amount
        }

        if(!engagement.global_fee_mode){
            data['first_performance_fee_amount'] = engagement.first_performance_fee_amount;
            data['next_performances_fee_amount'] = engagement.next_performances_fee_amount;
            data['performance_quantity'] = engagement.performance_quantity;
        }
        if(engagement.artistic_agency) {
            data['artistic_agency'] = engagement.artistic_agency.pk;
        }
        data['agent_commission_rate'] = engagement.agent_commission_rate;
        data['commission_deducted_from_fee'] = engagement.commission_deducted_from_fee;
        return data;
    }
}

const DeleteButton = ({engagement, onDelete, onDeleteContract}) => {
    if (!engagement || !engagement.pk)
        return null;

    if (!engagement.generated_document) {
        return <a className="btn btn-danger me-auto" onClick={onDelete}>
            {trans.t("Supprimer")}
        </a>
    }
    return <div className="btn-group me-auto">

        <button
            type="button"
            className="btn btn-danger dropdown-toggle"
            data-bs-toggle="dropdown"
        >
            {trans.t("Supprimer")}
        </button>
        <ul className="dropdown-menu">
            <li>
                <a className="dropdown-item"
                    role="button"
                    onClick={onDeleteContract}
                >
                    {trans.t("Supprimer le contrat")}
                </a>
                <a
                    role="button" className="dropdown-item"
                    onClick={onDelete}
                >
                    {trans.t("Supprimer l'engagement")}
                </a>
            </li>
        </ul>
    </div>
}
const GeneralPanel = (props) => {
    // Store a boolean sets to true once a single field has been edited
    const [loading, setLoading] = useState(!props.engagement.pk);
    const [dirty, setDirty] = useState(false);
    const [defaultEngagements, setDefaultEngagements] = useState([]);
    const [availableProjects, setAvailableProjects] = useState([]);
    const [displayProjectField, setDisplayProjectField] = useState(false);
    const generalFormRef = useRef(null);
    
    // On initial render, load default engagements to propose in the first <select> item
    useEffect(() => {
        // If the engagement is already created, we should not be able to override it
        // with a default one
        if(props.engagement.pk) {
            return;
        }

        if(!props.engagement.currency) {
            if(_DefaultCurrencyCache)
                onFieldChange('currency', _DefaultCurrencyCache)
            else {
                fetchCsrfWrapper(
                    '/backend/currency/organization_restricted/'
                ).then(data => {
                    _DefaultCurrencyCache = data[0];
                    onFieldChange('currency', _DefaultCurrencyCache);
                })
            }
        }

        // TODO: A weird bug on jsx is happening, org_profile is being reset to null sometimes... 
        if(!props.engagement.org_profile) {
            if(_DefaultOrgProfileCache){
                onFieldChange('org_profile', _DefaultOrgProfileCache);
            }else {
                fetchCsrfWrapper(
                    '/backend/organizationprofile/'
                ).then(data => {
                    _DefaultOrgProfileCache = data[0];
                    onFieldChange('org_profile', _DefaultOrgProfileCache);
                })
            }
        }

        /**
         * When creating an engagement for the participant of one project, we can infer
         * information from its ProjectParticipant object, so we're doing some data
         * fetching here
         */
        if(props.engagement.participant) {
            // Generate a pre-filled engagement based on the project participant. Lock
            // the whole modal during loading
            setLoading('general');
            fetchCsrfWrapper(
                "/backend/employeeengagement/initialize_from_participant/?participant=" + props.engagement.participant,
            ).then(data => {
                props.onEngagementChange(prevData => parseEngagementForState(
                    // The projects are already fetched by EmployeeWithPossibleEngagementSerializer
                    Object.assign(prevData, data, {'projects': props.engagement.projects})
                ))
            }).finally(
                () => setLoading(false)
            );

            // Load default engagement so we can show the input field only if some data is
            // present instead of displaying an empty selection to users not using this feature
            fetchCsrfWrapper(
                '/backend/employeedefaultengagement/?participant=' + props.engagement.participant,
            ).then(
                data => setDefaultEngagements(data)
            ).catch(
                data => alert(trans.t('Erreur lors du chargement des engagements type de ce salarié.'))
            );
        }
        // For standalone engagement, load data from the employee info only
        else {
            fetchCsrfWrapper(
                "/backend/employeeengagement/initialize_from_employee/"
                + "?employee=" + props.employee.pk
                + "&start_date=" + props.engagement.start_datetime.split('T')[0]
                + "&end_date=" + props.engagement.end_date
                + "&profession=" + (props.engagement.profession ? props.engagement.profession.pk : "")
                + "&project__in=" + (props.engagement.projects ? props.engagement.projects.map(x => x.pk).join() : ""),
            ).then(data => {
                props.onEngagementChange(prevData => parseEngagementForState(
                    // The projects are already fetched by EmployeeWithPossibleEngagementSerializer
                    Object.assign(prevData, data, {'projects': props.engagement.projects})
                ))
            }).catch(
                data => alert(data || trans.t('Erreur lors du chargement des données.'))
            ).finally(
                () => setLoading(false)
            );

            fetchCsrfWrapper(
                '/backend/employeedefaultengagement/?employee=' + props.employee.pk,
            ).then(
                data => setDefaultEngagements(data)
            ).catch(
                data => alert(trans.t('Erreur lors du chargement des engagements type de ce salarié.'))
            );
        }
    }, []);

    useEffect(() => {
        if (
            hasPerm('production.spectacle_access')
            && props.engagement.projects.length
            && props.engagement.projects[0]
        ){
            fetchCsrfWrapper(
                "/backend/employeeengagement/get_available_projects/"
                + "?main_project=" + props.engagement.projects[0].pk
                + "&employee=" + props.employee.pk
            ).then(data => {
                setAvailableProjects(data);
            }).catch(
                data => console.error("Can't load list of available projects")
            );
        }
    }, []);

    const onInitializePayLines = () => {
        setLoading('payroll');
        let engagement_data = Object.assign({
            'type': props.engagement.type,
            'employee': props.employee.pk,
            'participant': props.engagement.participant,
            'profession': props.engagement.profession ? props.engagement.profession.pk : null,
            'start_datetime': props.engagement.start_date + 'T' + getISOTimeFromInput(props.engagement.start_time),
            'end_date': props.engagement.end_date,
            'days_worked': props.engagement.days_worked,
            'contract_type': props.engagement.contract_type,
            'projects': props.engagement.projects.map(x => x ? x.pk : null),
        }, getContractSpecificPostData(props.engagement));

        fetchCsrfWrapper(
            '/backend/employeeengagement/initialize_payroll_lines/',
            {method: 'POST', body: engagement_data}
        ).then(
            data => {
                onFieldChange('pay_lines', data);
                props.setErrors({});
            }
        ).catch(
            data => props.setErrors(data)
        ).finally(
            () => setLoading(false)
        );
    }


    // Form handlers
    const onFieldChange = (field, value) => {
        props.onEngagementChange(old_obj => {
            // Assign a unique ID to each pay lines to ease detection of changes in React
            if(field == 'pay_lines')
                value = value.map(x => {
                    if(!x['_uid']) {
                        x['_uid'] = getUniqueId();
                        // Cast the quantity into a float then back to a string to strip
                        // the decimal part .00
                        x['quantity'] = ""+parseFloat(x['quantity'])
                    }
                    return x;
                })

            let new_obj = Object.assign({}, old_obj, {[field]: value});

            // In case of changes in the engagements dates, check that no pay lines are out of
            // bounds. Otherwise, move them into the first month to avoid lose of data
            if(field == 'start_date' || field == 'end_date') {
                let start = moment(new_obj['start_date']).date(1);
                let end = moment(new_obj['end_date']);

                let anyLineMoved = false;
                new_obj.pay_lines = new_obj.pay_lines.map(
                    line => {
                        if(!line.date){
                            return line;
                        }
                        if(!moment(line.date).isBetween(start, end, null, '[]')) {
                            line.date = start.format('YYYY-MM-DD');
                            anyLineMoved = true;
                        }
                        return line;
                    }
                )
                if(anyLineMoved){
                    alert(
                        trans.t(
                            'Des lignes de paie saisies précédemment se retrouvent en dehors de '
                            + "la période d'engagement."
                        ) + "\n" + trans.t("Ces lignes ont été déplacées pour éviter la perte de données.")
                    )
                }
            }

            // Update global fee amount if necessary
            if(["first_performance_fee_amount", "next_performances_fee_amount", "performance_quantity"].indexOf(field) !== -1){
                new_obj.global_fee_amount = Number(new_obj.first_performance_fee_amount) + (
                    Number(new_obj.next_performances_fee_amount) * new_obj.performance_quantity
                );
            }
            return new_obj;
        });
        setDirty(true);
    }

    const onUseDefaultEngagement = instance => {
        if(!instance)
            return;

        // Only import data that do not nullify existing field in our current state
        let new_engagement = Object.assign({}, instance, {
            'pk': null,
            'custom_fields': props.engagement.custom_fields || {},
        });
        for(let key in new_engagement) {
            if(!key)
                delete new_engagement[key];
        }

        // Copy the line for each projects in the current engagement
        new_engagement.pay_lines = new_engagement.pay_lines.reduce((acc, line) => {
            return acc.concat(
                props.engagement.projects.map(x => Object.assign({}, line, {
                    // Compute a unique UID for each pay_lines to reset the table
                    '_uid': getUniqueId(),
                    'quantity': "" + parseFloat(line['quantity']),
                    'project': {'engagement': null, 'project': x.pk},
                }))
            );
        }, []);

        props.onEngagementChange(
            Object.assign(
                {},
                props.engagement,
                new_engagement,
                {'default_engagement_source': instance.pk}
            )
        )
    }

    let defaultEngagementLine = null;
    if(!props.engagement.pk && defaultEngagements.length > 0) {
        defaultEngagementLine = (
            <div className="row">
                <p className="col-xs-3">Engagement type</p>
                <div className="col-xs-9">
                    <SelectInput
                        options={defaultEngagements}
                        onChange={onUseDefaultEngagement}
                    />
                </div>
            </div>
        );
    }

    let refresh_document_part;
    if(
        dirty
        && props.engagement.pk
        && (props.engagement.contract_template_source || props.engagement.default_engagement_source)
    ) {
        let refresh_label;
        if(props.engagement.generated_document) {
            refresh_label = (
                <p className="text-warning-emphasis">
                    <i className="fa fa-fw fa-exclamation-triangle"></i>&nbsp;
                    {trans.t("Vous modifiez un engagement dont le contrat a déjà été généré.")}<br />
                    <label style={{'color': 'inherit'}}>
                        <input
                            type="checkbox"
                            checked={props.engagement.refresh_document_body || false}
                            onChange={ev => onFieldChange('refresh_document_body', ev.target.checked)}
                        /> {trans.t("Mettre à jour le contrat PDF également")}
                    </label>
                </p>
            )
        }
        else if(MERGE_FIELDS_APPLICATION_PREFERENCE == 'draft-step' && props.engagement.document_body) {
            refresh_label = (
                <p className="text-warning-emphasis">
                    <i className="fa fa-fw fa-exclamation-triangle"></i>&nbsp;
                    {trans.t("Vous êtes sur le point de modifier un engagement dont le contrat a déjà été rédigé.")}
                    <br />
                    <label style={{'color': 'inherit'}}>
                        <input
                            type="checkbox"
                            checked={props.engagement.refresh_document_body || false}
                            onChange={ev => onFieldChange('refresh_document_body', ev.target.checked)}
                        /> {trans.t("Ré-appliquer le modèle de contrat utilisé lors de la sauvegarde")}
                    </label>
                </p>
            )
        }
        if(refresh_label)
            refresh_document_part = (
                <React.Fragment>
                    <hr style={{'margin': '15px 0 '}} />
                    {refresh_label}
                </React.Fragment>
            );
    }

    if(loading == 'general')
        return (
            <i className="fa fa-spin fa-spinner"></i>
        )


    const onAddProject = (project) => {
        if(!project){
            return;
        }
        const projects = [...props.engagement.projects, project]
        onFieldChange('projects', projects);
        if(generalFormRef.current){
            const minDate = moment.min(projects.map(x => moment(x.start_date)));
            if(minDate.isBefore(moment(props.engagement.start_date))){
                generalFormRef.current.onStartDateChange(minDate);
            }

            const maxDate = moment.max(projects.map(x => moment(x.end_date)));
            if(maxDate.isAfter(moment(props.engagement.end_date))){
                generalFormRef.current.onEndDateChange(maxDate);
            }
        }
    }

    const onRemoveProject = project => {
        onFieldChange('projects', props.engagement.projects.filter(x => x.pk !== project));
        onFieldChange('pay_lines', props.engagement.pay_lines.filter(
            x => !x.project || x.project.project !== project
        ))
    }

    const onUpdateLines = (project, lines) => {
        let newLines = lines;
        if(project) {
            newLines = newLines.map(x => Object.assign(x, {'project': {'project': project, 'engagement': props.engagement.pk}}))
            newLines = newLines.concat(props.engagement.pay_lines.filter(x => !x.project || x.project.project != project));
        } else {
            newLines = newLines.concat(props.engagement.pay_lines.filter(x => !!x.project && !!x.project.project));
        }
        onFieldChange('pay_lines', newLines);
    }

    const payrollManagerProps = {
        'engagement': props.engagement,
        'currency': props.engagement.currency,
        'errors': props.errors,
        'start_date': props.engagement.start_date,
        'end_date': props.engagement.end_date,
        'onInitializePayLines': (
            canInitializePayLines(props.engagement) ? onInitializePayLines : null
        ),
    };

    let projects = [...props.engagement.projects];
    if(props.engagement.pay_lines.some(x => !x.project || x.project.project == null)){
        projects.push(null);
    }
    const groupedPayrollLines = props.engagement.projects.length > 0 ? (
        projects.reduce(
            (acc, project) => {
                acc.set(project ? project.pk : null, props.engagement.pay_lines.filter(
                    x => project ? (x.project && x.project.project == project.pk) : (!x.project || !x.project.project)
                ));
                return acc;
            },
            new Map()
        )
    ) : (
        new Map([[null, props.engagement.pay_lines]])
    );

    const selectableProjects = availableProjects.filter(project => !props.engagement.projects.map(x => x.pk).includes(project.pk));

    const [total_salary, total_expenses, total_hours] = computePayrollLinesTotal(props.engagement.pay_lines);

    return (
        <div>
            {defaultEngagementLine}

            <GeneralSectionFields
                participant={props.participant}
                project={props.project}
                engagement={props.engagement}
                employee={props.employee}
                errors={props.errors}
                show_temporal_fields
                onFieldChange={onFieldChange}
                ref={generalFormRef}
            />

            <hr style={{'margin': '20px 0'}} />

            <ContractTypeSpecificFields
                engagement={props.engagement}
                custom_fields={props.custom_fields}
                errors={props.errors}
                onFieldChange={onFieldChange}
                updateEngagement={data => Object.entries(data).map(([field, value]) => onFieldChange(field, value))}
            />

            {props.engagement.type == "contract" &&
            <React.Fragment>

                {!!props.errors.pay_lines &&
                    <p className="text-danger">
                        {trans.t("Au moins une ligne de paie est incorrectement renseignée")}
                    </p>
                }

                {Array.from(groupedPayrollLines.entries()).map(([project, lines]) => (
                    <PayrollManager
                        key={project || 'none'}
                        project={(projects || []).find(p => (p ? p.pk : null) == project)}
                        displayProjectTitle={(projects || []).length > 1}
                        onRemoveProject={() => onRemoveProject(project)}
                        canRemoveProject={(projects || []).length > 1}
                        pay_lines={lines.sort((a, b) => a.order_index - b.order_index) || []}
                        onChange={lst => onUpdateLines(project, lst)}
                        {...payrollManagerProps}
                    />
                ))}
                {(canInitializePayLines(props.engagement) && props.engagement.pay_lines.length === 0) && (
                    <p>
                        <a className="action-link" onClick={onInitializePayLines}>
                            {trans.t("Initialiser selon le planning")}
                        </a>
                    </p>
                )}
                {loading == 'payroll' &&
                    <i className="fa fa-spin fa-spinner"></i>
                }

                {hasPerm('production.spectacle_access') && <div className="projects-summary">
                    {selectableProjects.length > 0 && <div className="project-selector">
                        {displayProjectField ? (
                            <SelectInput
                                options={selectableProjects}
                                objectRepresentation={obj => obj.start_date + " : " + obj.title}
                                onChange={(val) => onAddProject(val)}
                                placeholder={trans.t("Ajouter un projet")}
                            />
                        ) : (
                            <a className="action-link" onClick={() => setDisplayProjectField(true)}>
                                {trans.t("Ajouter un projet à l'engagement")}
                            </a>
                        )}
                    </div>}

                    <div className="totals">
                        {Array.from(groupedPayrollLines.keys()).length > 1 && <span>
                            <span>
                                {trans.t("Total projets ({{lines_count}})", {lines_count: Array.from(groupedPayrollLines.keys()).length})}
                            </span>
                            {(total_hours > 0 && props.engagement.contract_type === 'standard') && <span>
                                &nbsp;- {trans.t("{{hours}} heures travaillées", {hours: floatFormat(total_hours)})}
                            </span>}
                            <span>
                                &nbsp;- {trans.t("Total salaire")} : {formatCurrency(total_salary, props.engagement?.currency.code)}
                            </span>
                            {(total_expenses > 0 && <span>
                                &nbsp;| {trans.t("Total défraiements")} : {formatCurrency(total_expenses, props.engagement?.currency.code)}
                            </span>)}
                        </span>}
                    </div>
                </div>}
            </React.Fragment>}

            {refresh_document_part}
        </div>
    );
}


const ContractPanel = (props) => {
    let [config, setConfig] = useState(null);
    let [template, setTemplate] = useState(null);
    let [templateLoading, setTemplateLoading] = useState(false);

    const is_contract = props.engagement.type == 'contract';

    // Util function to propagate contract content update
    const setEngagementBody = body => props.onEngagementChange(
        Object.assign(
            {}, props.engagement, {
                'document_body': body,
                'contract_template_source': (template && is_contract) ? template.pk : null
            }
        )
    );

    // When the user selects a template, we load it merged with the current engagement so
    // the content has no longer any merge fields
    const onTemplateValidation = ev => {
        ev.preventDefault();
        setTemplateLoading(true)

        fetchCsrfWrapper(
            `/backend/employeeengagement/${props.engagement.pk}/merge_template/?template=${template.pk}`
        ).then(
            data => setEngagementBody(data['body'])
        ).catch(
            resp => alert(trans.t("Une erreur s'est produite lors de la génération du contrat"))
        ).finally(
            () => setTemplateLoading(false)
        )
    }

    // Load TinyMCE config on initialization
    useEffect(() => {
        fetchCsrfWrapper(
            '/backend/employeeengagement/' + props.engagement.pk + '/tinymce_config/?height=450',
        ).then(
            data => setConfig(eval('('+data+')'))
        );
    }, [props.engagement.pk]);

    if(!config) {
        return <PlaceholderLoadingBars nb_lines={15} />;
    }

    if(props.docQueryRunning) {
        return (
            <p className="empty-list-placeholder text-muted">
                <i className="fa fa-spin fa-spinner"></i> {trans.t("Génération du document en cours")}
            </p>
        );
    }

    if(props.engagement.generated_document) {
        const file_parts = props.engagement.generated_document.split('.')
        const icon = file_parts[file_parts.length - 1] == 'pdf' ? 'pdf' : 'word';

        return (
        <div>
            {icon == 'pdf' &&
                <embed
                    src={props.engagement.generated_document} type="application/pdf"
                    width="100%" height="600" border="1"
                />
            }

            <p className="text-muted" style={{'marginTop': '5px'}}>
                <i className={`fa fa-file-${icon}-o`}></i>&nbsp;
                {trans.t("Document généré le {{date}}", {date: moment(props.engagement.generation_date).locale('fr').format('lll')})}

                <span className="float-end">
                    <a href={props.engagement.generated_document} download style={{'marginRight': '25px'}}>
                        <i className="fa fa-download"></i> {trans.t("Télécharger")}
                    </a>
                </span>
            </p>
        </div>
        )
    }

    return (
        <React.Fragment>
            <div style={{'width': '798px', 'display': 'flex'}}>
                <label>{trans.t("Utiliser un modèle existant")}</label>
                <div style={{'flexGrow': 1, 'padding': '0 15px'}}>
                    <SelectInput
                        backendURL={
                            "/backend/" + (is_contract ? "employeeengagementtemplate" : "participantagreementtemplate") + "/"
                        } value={template} onChange={instance => setTemplate(instance)}
                        filterOptions={
                            (option, inputValue) => (
                                option.label.toUpperCase().includes(inputValue.toUpperCase())
                                && (
                                    // In case of ProjectParticipantAgreement we have no contract_type
                                    !option.data.contract_type || !props.engagement.contract_type
                                    || props.engagement.contract_type === option.data.contract_type
                                )
                            )
                        }
                    />
                </div>
                <p>
                    <button className="btn btn-secondary" onClick={onTemplateValidation} disabled={!template}>
                        <i className={"fa fa-" + (templateLoading ? "spin fa-spinner" : "check")}></i> {trans.t("Choisir")}
                    </button>
                </p>
            </div>

            <TinyMCE
                value={props.engagement.document_body}
                init={config}
                onEditorChange={content => setEngagementBody(content)}
            />
        </React.Fragment>
    );
}


const PreviewPDFModal = props => {
    const [pdfContent, setPdfContent] = useState(null);

    useEffect(() => {
        fetchCsrfWrapper(
            `${props.engagement.list_url}${props.engagement.pk}/preview-pdf/`,
            {method: "POST", body: {body: props.engagement.document_body}}
        ).then(
            data => {
                setPdfContent(URL.createObjectURL(data));
            }
        );
    }, []);

    return (
        <Modal show onHide={props.onClose} modalCSSClass="modal-lg">
            <Modal.Header>{trans.t("Prévisualisation du document")}</Modal.Header>
            <Modal.Body>
                <div className="text-center pdf-container" style={{position: "relative", height: "620px"}}>
                    {pdfContent === null ? (
                        <p className="loading text-center">
                            <i className="fa fa-spin fa-spinner"></i> {trans.t("Chargement en cours")}
                        </p>
                    ) : (
                        <embed src={pdfContent} width="780" height="620" type="application/pdf" />
                    )}
                </div>
            </Modal.Body>
        </Modal>
    )
}


/**
 * Modal component to create and handle an employee engagement. Once created, the
 * component also manages creation of the contract document and DPAE state.
 */
const ModalProps =  {
    // Engagement instance serializer, if it has been created before
    engagement: PropTypes.object,
    // Employee being concerned by the current engagement.
    employee: PropTypes.object.isRequired,
    // In case of a project bind to a project, the participant item, that should
    // correspond to the employee given above
    participant: PropTypes.object,
    // First tab shown on modal opening
    default_tab: PropTypes.string,
    // Callback when the instance is created/updated. On deletion, triggered with "null" value
    onEngagementUpdate: PropTypes.func,
    // Callback when the modal is being closed
    onClose: PropTypes.func,
};

const parseEngagementForState = engagement => {
    let eng = Object.assign({
        'start_date': null,
        'start_time': '',
        'pay_category': PAY_CATEGORIES[0],
        'pay_lines': [],
        'projects': [],
    }, engagement);
    // Set org_profile from projects if needed
    if (!eng.org_profile && eng.projects.length > 0){
        eng.org_profile = eng.projects[0].org_profile;
    }
    // Append an UID to all existing pay_lines so we can manipulate them along new lines
    eng.pay_lines = eng.pay_lines.map(x => {
        x._uid = getUniqueId();
        x.quantity = ""+parseFloat(x.quantity);
        return x;
    })
    // The backend stores a single attribute "start_datetime", we split it into
    // two components date and time to ease listening of changes in the form UI
    if(eng.start_datetime) {
        let dt = moment(eng.start_datetime)
        eng['start_date'] = dt.format('YYYY-MM-DD');
        eng['start_time'] = dt.format('HH:mm:SS');
    }
    if(eng.net_amount)
        eng.net_amount = Number(eng.net_amount).toFixed(2);
    return eng;
}

const EmployeeEngagementModal = (props) => {
    // Store the employee as state, since when loading engagement data asynchronously,
    // we won't have the employee in props at the initial loading.
    let [employee, setEmployee] = useState(props.employee);
    let [engagement, setEngagement] = useState(
        () => parseEngagementForState(props.engagement)
    );
    let [errors, setErrors] = FormUtils.useFormErrors();
    let [docQueryRunning, setDocQueryRunning] = useState(false);
    let [currentTab, setCurrentTab] = useState(props.default_tab || 'general');
    const [loading, setLoading] = useState(true);

    const [customFieldsDefinition, setCustomFieldsDefinition] = useState(
        _EngagementCustomFieldsCache || []
    )

    useEffect(() => {
        // Load custom fields definition if not done yet on the page
        if(_EngagementCustomFieldsCache === null) {
            fetchCsrfWrapper(
                '/backend/customfield/?model=employeeengagement',
            ).then(data => {
                _EngagementCustomFieldsCache = data;
                setCustomFieldsDefinition(_EngagementCustomFieldsCache)
            })
        }

        // Load engagement if an engagement_pk has been passed instead of the object
        if(props.engagement_pk) {
            fetchCsrfWrapper(
                `/backend/employeeengagement/${props.engagement_pk}/`,
            ).then(data => {
                setEmployee(data['employee']);
                setEngagement((prevState) => Object.assign({}, prevState, parseEngagementForState(data)))
                setLoading(false);
            }).catch(
                data => alert(trans.t("Une erreur est survenue lors du chargement des données de l'engagement"))
            );
        }
        else if(!props.engagement?.pk && props.project?.pk) {
            let url = "/backend/employeeengagement/initialize_from_project/?project=" + props.project.pk;

            if(props.engagement?.contract_type){
                url += "&contract_type=" + props.engagement.contract_type;
            }
            fetchCsrfWrapper(url)
                .then(data => setEngagement(engagement => Object.assign({}, engagement, data)))
                .then(setLoading(false));
        } else {
            setLoading(false);
        }
    }, [props.engagement?.pk, props.engagement_pk, props.project?.pk]);

    const generateContract = file_format => {
        setDocQueryRunning(true);
        fetchCsrfWrapper(
            '/backend/employeeengagement/' + engagement.pk + '/generate_document/',
            {method: "POST", body: {
                format: file_format,
                document_body: engagement.document_body,
                contract_template_source: engagement.contract_template_source || null,
            }}
        ).then(data => {
            props.onEngagementUpdate(data, 'update')
            setEngagement(data)
        }).catch(
            data => alert(trans.t('Une erreur est survenue dans la génération du document'))
        ).finally(
            () => setDocQueryRunning(false)
        );
    }

    const previewPdf = () => {
        let wrapper = getModalWrapper();
        const root = createRoot(wrapper);

        root.render(
            <PreviewPDFModal
                engagement={engagement}
                onClose={() => root.unmount()}
            />
        )

    }

    const onDelete = ev => {
        let confirm_msg = trans.t('Êtes-vous sûr de vouloir supprimer cet engagement ?');
        if (engagement.signature_date) {
            confirm_msg = trans.t("Attention la signature engagée reste valide légalement") + "\n" + confirm_msg
        }
        if(confirm(confirm_msg)) {
            fetchCsrfWrapper(
                '/backend/employeeengagement/' + engagement.pk + '/',
                {method: 'DELETE'}
            ).then(
                data => props.onEngagementUpdate(null, 'delete')
            ).catch(data => {
                if(data.response.status == 404) // was already deleted, ignore the error
                    props.onEngagementUpdate(null, 'delete')
                else if(data.response.status == 403){
                    alert(data['detail']);
                }
                else
                    alert(trans.t("Une erreur s'est produite lors de la suppression"))
            });
        }
    }

    // Handler to delete existing contract
    const onDeleteExistingDocument = ev => {
        if(confirm(trans.t("Êtes-vous sûr de vouloir supprimer le document ? L'engagement sera conservé."))) {
            $.ajax({
                url: '/backend/employeeengagement/' + engagement.pk + '/', method: 'PATCH',
                data: {generated_document: null}
            }).success(data => {
                setEngagement(data);
                props.onEngagementUpdate(data, 'update');
            }).error(jqXHR => {
                let error_msg = '';
                if (jqXHR.status === 403){
                    error_msg = jqXHR.responseJSON['detail'];
                }
                else if (jqXHR.responseJSON['__all__']) {
                    jqXHR.responseJSON['__all__'].forEach(msg => error_msg += msg + '\n');
                }
                if (!error_msg) {
                    error_msg = trans.t('Une erreur est survenue lors de la suppression du contrat');
                }
                alert(error_msg);
            });
        }
    }

    const onSave = ev => {
        ev.preventDefault();

        // Main block of data
        let post_data = {
            'employee': employee.pk,
            'org_profile': engagement.org_profile?.pk,
            'type': engagement.type,
            'pay_category': engagement.pay_category[0],
            'profession': engagement.profession,
            'start_datetime': engagement.start_date + 'T' + getISOTimeFromInput(engagement.start_time),
            'days_worked': engagement.days_worked,
            'work_places': engagement.work_places,
            'end_date': engagement.end_date,
            'currency': engagement.currency,
            'notes': engagement.notes || '',
            'contract_type': engagement.contract_type,

            'document_body': engagement.document_body,
            'contract_template_source': engagement.contract_template_source || null,
            'default_engagement_source': engagement.default_engagement_source || null,
            'refresh_document_body': engagement.refresh_document_body || false,
            'custom_fields': JSON.stringify(engagement.custom_fields || {}),

            // Get data from the correct specific block
            ...getContractSpecificPostData(engagement)
        };

        //
        if(engagement.participant)
            post_data['participant'] = engagement.participant;

        if(post_data['type'] == 'contract'){
            for(let project of engagement.projects){
                if(!engagement.pay_lines.find(x => 
                    (x.project ? x.project.project : null) == (project ? project.pk : null)
                )){
                    alert(trans.t("Veuillez renseigner au moins une ligne de paie par projet"))
                    return;
                }
            }

            // Ensure the list order is the one of the UI
            engagement.pay_lines.sort((a, b) => a.order_index - b.order_index);
            post_data['pay_lines'] = engagement.pay_lines.map(
                (item, idx) => ({
                    'pk': item.pk || null,
                    'type': item.type,
                    'date': item.date || engagement.start_date,
                    'quantity': item.quantity,
                    'unit_amount': item.unit_amount,
                    'order_index': idx,
                    'project_id': item.project ? item.project.project : null,
                    'auto_generated_key': item.auto_generated_key,
                    'source_ids': item.annotated_source_ids || [],
                })
            )
        }
        else
            post_data['net_amount'] = engagement.net_amount;

        let url = '/backend/employeeengagement/', method = 'POST';
        if(engagement.pk) {
            url += engagement.pk + '/';
            method = 'PATCH';
        }
        fetchCsrfWrapper(
            url,
            {method: method, body: post_data}
        ).then(
            data => props.onEngagementUpdate(data, method == 'POST' ? 'create' : 'update')
        ).catch(
            data => setErrors(data)
        )
    }

    let tabContent;
    let extra_button;
    if(!loading){
        switch(currentTab) {
            case 'general':
                tabContent = (
                    <GeneralPanel
                        project={props.project}
                        employee={employee}
                        custom_fields={customFieldsDefinition}
                        engagement={engagement}
                        errors={errors}
                        setErrors={setErrors}
                        onEngagementChange={setEngagement}
                    />
                );
                break;
            case 'dpae':
                tabContent = (
                    <EmployeeDPAEInfosView
                        employee={employee}
                        engagement={engagement}
                        onEngagementChange={data => {
                            setEngagement(data);
                            props.onEngagementUpdate(data, 'update')
                        }}
                    />
                );
                break;
            default:
                tabContent = (
                    <ContractPanel
                        employee={employee}
                        engagement={engagement}
                        docQueryRunning={docQueryRunning}
                        onEngagementChange={setEngagement}
                    />
                );
                if(!engagement.generated_document)
                    extra_button = (
                        <React.Fragment>
                            <div
                                onClick={() => (engagement.document_body && !docQueryRunning) && previewPdf()}
                                className={"btn btn-secondary" + (!engagement.document_body ? " disabled" : "")}
                            >
                                <i className="fa fa-search"></i> {trans.t("Prévisualiser")}
                            </div>
                            <div className={"btn-group" + (docQueryRunning ? " disabled" : "")}>
                                <a className="btn btn-primary"
                                    onClick={() => !docQueryRunning && generateContract('pdf')}
                                    disabled={docQueryRunning}
                                >
                                    {trans.t("Générer PDF")}
                                </a>

                                {hasPerm('documents.export_word_documents') &&
                                <React.Fragment>
                                    <button
                                        type="button"
                                        className="btn btn-primary dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        disabled={docQueryRunning}
                                    >
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <a
                                                role="button" className="dropdown-item"
                                                onClick={() => generateContract('docx')}
                                                disabled={docQueryRunning}
                                            >
                                                <i className="fa fa-file-word-o"></i>&nbsp; {trans.t("au format Word")}
                                            </a>
                                        </li>
                                    </ul>
                                </React.Fragment>
                                }
                            </div>
                        </React.Fragment>
                    )
                break;
        }
    }


    return (
        <Modal show onHide={props.onClose} modalCSSClass="employee-engagement-modal modal-lg">
            <form className="react-modal-form" onSubmit={onSave}>
                {!loading &&
                    <Modal.Header>
                        {trans.t("Engagement de {{employee}}", {employee: employee.name})}
                    </Modal.Header>
                }
                <Modal.Body>
                    {loading ? 
                        <i className="fa fa-spin fa-spinner"></i>
                    :
                        <div>
                            {// In engagement creation mode, only the general tab should be available so we don't need to show tabs
                            (engagement && engagement.pk) && (
                                <ul className="panel-tabs" style={{'margin': '-15px -15px 15px'}}>
                                    <li className={currentTab == 'general' ? 'selected': ''}>
                                        <a onClick={() => setCurrentTab('general')}>{trans.t("Général")}</a>
                                    </li>
                                    <li className={currentTab == 'contract' ? 'selected': ''}>
                                        <a onClick={() => setCurrentTab('contract')}>{trans.t("Contrat")}</a>
                                    </li>
                                    {hasPerm('administration.allow_dpae_through_api') && engagement.type == 'contract' &&
                                    <li className={currentTab == 'dpae' ? 'selected': ''}>
                                        <a onClick={() => setCurrentTab('dpae')}>{trans.t("DPAE")}</a>
                                    </li>}
                                </ul>
                            )}
                            {tabContent}

                            {Object.keys(errors).length > 0 &&
                                <p className="react-form-errors-intro">
                                    {trans.t("Erreur lors de la sauvegarde.")} {
                                        FormUtils.getGlobalErrors(errors)
                                        || trans.t("Veuillez vérifier la saisie des champs.")
                                    }
                                    <FormUtils.ErrorText errors={errors} field="participant" />
                                </p>
                            }
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <DeleteButton engagement={engagement} onDelete={onDelete}
                        onDeleteContract={onDeleteExistingDocument}
                    />
                    <a className="btn btn-outline-secondary"
                        disabled={docQueryRunning}
                        onClick={() => !docQueryRunning && props.onClose(null)}
                    >
                        {trans.t("Annuler")}
                    </a>
                    {!loading && <React.Fragment> 
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={docQueryRunning}
                        >
                            {trans.t("Sauvegarder")}
                            </button>
                        {extra_button}
                    </React.Fragment>}
                </Modal.Footer>

            </form>
        </Modal>
    );
}
EmployeeEngagementModal.propTypes = ModalProps;

export default EmployeeEngagementModal;
