import React, { useMemo, useReducer, useState, useEffect, useRef } from 'react';
import classNames from 'classnames';

import { AjaxNotification } from 'orfeo_common/react-base.jsx';
import { isReadOnly as isReadOnlyGlobal, useFormEditing } from 'orfeo_common/FormUtils.jsx';
import * as CustomFieldUtils from './Utils.jsx';

function formReducer(state, action) {
    if(action.type == 'fieldChanged') {
        let value = '';
        if(action.field.field_type == 'choices')
            value = action.field.choices[0];
        else if(action.field.field_type == 'multiple-choices')
            value = [];

        return {field: action.field, value: value}
    }
    else if(action.type == 'valueChanged') {
        return {field: state.field, value: action.value}
    }

    return state;
}

/** Externalize XHR to be able to call it through different components **/
const updateCustomFieldXHR = (backendURL, field_key, value, successCallback) => {
    let notif_id = AjaxNotification.show('Sauvegarde...');
    $.ajax({
        url: backendURL, method: "PATCH", dataType: "json",
        data: {
            'custom_fields': JSON.stringify({[field_key]: value})
        }
    }).success(
        (data, status) => successCallback(data)
    ).error(
        (data, status) => alert(trans.t("Une erreur inconnue s'est produite"))
    ).always(
        () => AjaxNotification.hide(notif_id)
    );
}


const ObjectCustomFieldsForm = props => {
    // Reducer storage
    const initReducer = () => {
        // Get the custom_field selected... or the first available in case of new line
        let initial_field, initial_value = null;
        for(let cf of props.custom_fields) {
            if((!props.cf_key && props.fields_used.indexOf(cf.key) === -1) || props.cf_key == cf.key) {
                initial_field = cf;
                initial_value = props.obj.custom_fields[cf.key];
                // Some types needs a default value to properly work
                if(initial_value === undefined) {
                    if (initial_field.field_type == 'choices') {
                        initial_value = initial_field.choices[0];
                    } else if (initial_field.field_type == 'boolean') {
                        initial_value = false;
                    } else {
                        initial_value = ''
                    }
                }
                break;
            }
        }

        return {field: initial_field, value: initial_value};
    }
    const [state, dispatch] = useReducer(formReducer, null, initReducer);
    // compute custom field dict from the list given in props
    const custom_fields_dict = useMemo(() => {
        let _custom_field_dict = {};
        for(let cf of props.custom_fields) {
            _custom_field_dict[cf.key] = cf;
        }
        return _custom_field_dict;
    }, [props.custom_fields]);

    // Form buttons handlers
    const handleSave = ev => {
        ev.preventDefault();
        updateCustomFieldXHR(props.backend_url, state.field.key, state.value, props.onDataChange)
    }
    const handleDelete = ev => {
        updateCustomFieldXHR(props.backend_url, props.cf_key, null, props.onDataChange)
    }
    const onKeyUp = event => {
        if(event.key == 'Escape') {
            props.onCloseForm();
        }
    }

    return (
        <form className="form react-inline-form" style={{'maxWidth': 'none'}} onSubmit={handleSave} onKeyUp={onKeyUp}>
            <div className="row">
                {!props.cf_key &&
                <div className="col-xs-4">
                    <select
                        className="form-select input-sm" value={state.field.key}
                        onChange={ev => dispatch({type: 'fieldChanged', field: custom_fields_dict[ev.target.value]})}
                    >
                        {props.custom_fields.filter(
                            // Only display custom fields not used yet by the current object
                            field => (field.key == state.field.key || props.fields_used.indexOf(field.key) === -1)
                        ).map(
                            field => <option value={field.key} key={field.key}>{field.label}</option>
                        )}
                    </select>
                </div>}
                <div className={props.cf_key ? "col-xs-12" : "col-xs-8"}>
                    <CustomFieldUtils.CustomFieldInput
                        custom_field={state.field} value={state.value}
                        onChange={val => dispatch({type: 'valueChanged', value: val})}
                    />
                </div>
            </div>
            <div className="actions text-end">
                {props.displayDeleteButton &&
                    <a className="btn btn-danger float-start" onClick={handleDelete}>{trans.t("Supprimer")}</a>
                }

                <a className="btn btn-outline-secondary" onClick={() => props.onCloseForm()}>{trans.t("Annuler")}</a>&nbsp;
                <button type="submit" className="btn btn-primary">{trans.t("Sauvegarder")}</button>
            </div>
        </form>
    );
}

const NEW_LINE_VAL = -1;
const ObjectCustomFieldsManager = props => {
    const isReadOnly = () => props.isReadOnly || isReadOnlyGlobal();

    const [editing, setEditing] = useFormEditing(null, isReadOnly);
    const [obj, setObject] = useState(props.obj);
    const custom_fields = props.custom_fields || window['CUSTOM_FIELDS'];
    const backendURL = props.backend_url || window['OBJ_BACKEND_URL'];

    let _custom_field_dict = {};
    for(let cf of custom_fields) {
        _custom_field_dict[cf.key] = cf;
    }

    // used_key should also contains keys not used but always displayed (with the
    // empty value), since the line will already be "shown" as editable.
    let used_key = (
        custom_fields
            .filter(cf => cf.always_displayed || obj.custom_fields[cf.key] !== undefined)
            .map(cf => cf.key)
    )

    // Compute columns sizes according to the array given as props
    // [4, 6, 8] renders: col-xs-4 col-sm-6 col-md-8 for label column
    //               and  col-xs-8 col-sm-6 col-md-4 for content
    let col1_sizes, col2_sizes;
    if(!props.renderLinesOnly) {
        const sizes = ['xs', 'sm', 'md'];
        let label_column_sizes = props.label_column_sizes || [6, 4, 3];
        col1_sizes = label_column_sizes.map((elem, idx) => 'col-' + sizes[idx] + '-' + elem).join(' ')
        col2_sizes = label_column_sizes.map((elem, idx) => 'col-' + sizes[idx] + '-' + (12 - elem)).join(' ');
    }

    let existing_lines = [];
    // Render one line per custom field
    for (let cf of custom_fields) {
        let cf_label = _custom_field_dict[cf.key].label;
        let displayed_value = obj.custom_fields[cf.key];
        if(editing === cf.key) {
            const onDataChange = data => {
                setEditing(null);
                setObject(data);
            };
            const showDeleteButton = (cf.key && obj.custom_fields[cf.key] !== undefined);
            existing_lines.push(
                <tr>
                    <td className={col1_sizes}>
                        {cf_label}
                        {props.renderLinesOnly &&
                            <React.Fragment>
                                <p className="text-muted">
                                    <small>{trans.t("Champ personnalisé")}</small>
                                </p>

                                {(showDeleteButton && props.renderLinesOnly) &&
                                    <a className="btn btn-sm btn-danger" onClick={ev => updateCustomFieldXHR(
                                        backendURL, cf.key, null, onDataChange
                                    )}>
                                        {trans.t("Supprimer")}
                                    </a>
                                }
                            </React.Fragment>
                        }
                    </td>
                    <td>
                        <ObjectCustomFieldsForm
                            cf_key={cf.key} obj={obj}
                            custom_fields={custom_fields}
                            fields_used={used_key}
                            key={'form'+cf.key}
                            backend_url={backendURL}
                            displayDeleteButton={showDeleteButton && !props.renderLinesOnly}
                            onDataChange={onDataChange}
                            onCloseForm={() => setEditing(null)}
                        />
                    </td>
                </tr>
            );
        }
        else if(displayed_value !== undefined || cf.always_displayed) {
            if(displayed_value === undefined && !props.renderLinesOnly) {
                displayed_value = <em className="text-muted">{trans.t("non renseigné")}</em>;
            }
            else if(displayed_value || (cf.field_type === 'boolean' && displayed_value === false)) {
                displayed_value = CustomFieldUtils.getDisplayableValue(_custom_field_dict[cf.key], displayed_value);
            }

            existing_lines.push(
                <tr key={cf.key+obj.custom_fields[cf.key]} data-key={cf.key}>
                    <td className={col1_sizes}>{cf_label}</td>
                    <td
                        className={classNames(col2_sizes, {'inline-editable': !isReadOnly()})}
                        onClick={() => setEditing(cf.key)}
                    >
                        {displayed_value}
                    </td>
                </tr>
            );
        }
    }

    var new_line = null;
    if(editing == NEW_LINE_VAL) {
        new_line = (
        <tr>
            <td colSpan="2">
                <ObjectCustomFieldsForm
                    obj={obj}
                    fields_used={used_key}
                    custom_fields={custom_fields}
                    backend_url={backendURL}
                    onDataChange={data => {
                        setEditing(null);
                        setObject(data);
                    }}
                    onCloseForm={() => setEditing(null)}
                />
            </td>
        </tr>
        );
    }
    // Do not display the add link if all custom fields have been used
    else if(used_key.length < custom_fields.length) {
        new_line = (
        <tr className="add-link-line">
            <td colSpan="2">
                <a className="pointer action-link" onClick={() => setEditing(NEW_LINE_VAL)}>
                    {trans.t("Ajouter un champ personnalisé")}
                </a>
            </td>
        </tr>
        );
    }

    // Append the new line button if the user isn't in readonly
    let lines = isReadOnly() ? existing_lines : (
        <React.Fragment>
            {existing_lines}
            {new_line}
        </React.Fragment>
    );

    if(props.renderLinesOnly) {
        return lines;
    }

    return (
        <table className="custom-fields-table">
            <tbody>
                {lines}
            </tbody>
        </table>
    );
};

export default ObjectCustomFieldsManager;
