import React, { useState, useEffect } from 'react';

/*
 * List of icons offered to users (we suppose we're using the fa-solid version)
 */
const _ICONS = {
    "ticket": trans.t("ticket"),
    "book": trans.t("livre"),
    "wrench": trans.t("clé à molette"),
    "bed": trans.t("lit"),
    "cutlery": trans.t("couverts"),
    "calendar": trans.t("calendrier"),
    "flask": trans.t("fiole"),
    "flag": trans.t("drapeau"),
    "eye": trans.t("œil"),
    "envelope": trans.t("enveloppe"),
    "comments": trans.t("commentaire"),
    "clock": trans.t("horloge"),
    "camera": trans.t("camera"),
    "coffee": trans.t("café"),
    "glass": trans.t("verre"),
    "group": trans.t("groupe"),
    "building": trans.t("bâtiment"),
    "bolt": trans.t("éclair"),
    "bell": trans.t("cloche"),
    "child": trans.t("enfant"),
    "bicycle": trans.t("vélo"),
    "bus": trans.t("bus"),
    "car": trans.t("voiture"),
    "plane": trans.t("avion"),
    "ship": trans.t("bateau"),
    "subway": trans.t("métro"),
    "taxi": trans.t("taxi"),
    "truck": trans.t("camion"),
    "person-cane": trans.t("personne malvoyante"),
    "bell-slash": trans.t("cloche barrée"),
    "circle-exclamation": trans.t("rond et exclamation"),
    "snowflake": trans.t("flocon"),
    "xmarks-lines": trans.t("bandeau"),
    "person-walking-arrow-right": trans.t("personne qui marche vers la droite"),
    "person-circle-xmark": trans.t("personne non autorisée"),
    "person-circle-question": trans.t("personne en question"),
    "person-circle-plus": trans.t("personne en plus"),
    "person-circle-minus": trans.t("personne en moins"),
    "person-circle-exclamation": trans.t("personne en attente"),
    "person-circle-check": trans.t("personne acceptée"),
    "address-card": trans.t("carte de visite"),
    "square-h": trans.t("hôpital"),
    "feather-pointed": trans.t("plume"),
    "shield-dog": trans.t("chien de sécurité"),
    "square-up-right": trans.t("flèche en haut à droite"),
    "square-caret-up": trans.t("flèche vers le haut"),
    "square-caret-right": trans.t("flèche vers la droite"),
    "square-caret-left": trans.t("flèche vers la gauche"),
    "square-caret-down": trans.t("flèche vers le bas"),
    "retweet": trans.t("recommencer"),
    "location-arrow": trans.t("flèche de direction"),
    "arrows-turn-to-dots": trans.t("itinéraire complexe"),
    "arrows-to-dot": trans.t("point de rassemblement"),
    "arrow-right-arrow-left": trans.t("changement"),
    "right-to-bracket": trans.t("sortie"),
    "moon": trans.t("lune"),
    "binoculars": trans.t("jumelle"),
    "globe": trans.t("globe"),
    "van-shuttle": trans.t("van"),
    "house": trans.t("maison"),
    "shop": trans.t("magasin"),
    "warehouse": trans.t("entrepôt de stockage"),
    "house-lock": trans.t("bâtiment fermé"),
    "house-flag": trans.t("bâtiment avec un drapeau"),
    "house-fire": trans.t("bâtiment en feu"),
    "house-crack": trans.t("bâtiment détérioré"),
    "house-circle-xmark": trans.t("bâtiment inaccessible"),
    "house-circle-exclamation": trans.t("bâtiment en attente"),
    "house-circle-check": trans.t("bâtiment validé"),
    "clipboard": trans.t("bloc note"),
    "folder-open": trans.t("dossier ouvert"),
    "thumbtack": trans.t("punaise"),
    "business-time": trans.t("temps des affaires"),
    "mug-saucer": trans.t("petit café"),
    "circle-half-stroke": trans.t("cercle à moitié"),
    "square-poll-vertical": trans.t("diagramme en bâton"),
    "sitemap": trans.t("plan de site"),
    "network-wired": trans.t("réseau informatique"),
    "diagram-project": trans.t("diagramme"),
    "signature": trans.t("signature"),
    "stapler": trans.t("agrafeuse"),
    "square-phone": trans.t("téléphone dans un carré"),
    "square-pen": trans.t("stylo dans un carré"),
    "square-envelope": trans.t("enveloppe dans un carré"),
    "person-chalkboard": trans.t("personne montrant un tableau"),
    "cake-candles": trans.t("gâteau d'anniversaire"),
    "bucket": trans.t("seau"),
    "glasses": trans.t("lunettes"),
    "graduation-cap": trans.t("chapeau d'écolier"),
    "note-sticky": trans.t("post-it"),
    "list-check": trans.t("liste de tâches"),
    "bullhorn": trans.t("porte-voix"),
    "route": trans.t("itinéraire"),
    "signs-post": trans.t("panneau"),
    "handshake": trans.t("poignée de mains"),
    "bars": trans.t("barres horizontales"),
    "shield-halved": trans.t("bouclier de défense"),
    "gear": trans.t("engrenage"),
    "barcode": trans.t("codebar"),
    "fire-extinguisher": trans.t("extincteur"),
    "video": trans.t("captation vidéo"),
    "video-slash": trans.t("pas de captation vidéo"),
    "paper-plane": trans.t("avion en papier"),
    "language": trans.t("langue"),
    "walkie-talkie": trans.t("talkie walkie"),
    "paint-roller": trans.t("rouleau de peinture"),
    "hammer": trans.t("marteau"),
    "ruler": trans.t("règle"),
    "toolbox": trans.t("boîte à outils"),
    "screwdriver-wrench": trans.t("clé et tournevis"),
    "compass-drafting": trans.t("compas de navigation"),
    "swatchbook": trans.t("palette de couleurs"),
    "scissors": trans.t("ciseaux"),
    "object-group": trans.t("groupe d'objets"),
    "crop-simple": trans.t("redimensionner"),
    "highlighter": trans.t("marqueur"),
    "power-off": trans.t("éteindre"),
    "display": trans.t("écran"),
    "plug": trans.t("prise électrique"),
    "plug-circle-xmark": trans.t("pas de prise électrique"),
    "plug-circle-plus": trans.t("prise électrique en plus"),
    "plug-circle-minus": trans.t("prise électrique en moins"),
    "plug-circle-exclamation": trans.t("prise électrique à contrôler"),
    "plug-circle-check": trans.t("prise électrique vérifiée"),
    "headphones": trans.t("casque audio"),
    "trash": trans.t("poubelle"),
    "eye-slash": trans.t("oeil barré"),
    "sliders": trans.t("contrôleur"),
    "link": trans.t("lien"),
    "square-check": trans.t("validé"),
    "masks-theater": trans.t("masque de théâtre"),
    "book-open": trans.t("livre ouvert"),
    "fire": trans.t("feu"),
    "lightbulb": trans.t("ampoule"),
    "microphone": trans.t("captation audio"),
    "microphone-slash": trans.t("pas de captation audio"),
    "faucet": trans.t("robinet"),
    "temperature-arrow-up": trans.t("augmentation de la température"),
    "road-barrier": trans.t("barrière"),
    "truck-ramp-box": trans.t("livraison camion"),
    "dolly": trans.t("dolly"),
    "box": trans.t("boîte"),
    "box-archive": trans.t("boîte d'archive"),
    "box-open": trans.t("boîte ouverte"),
    "location-dot": trans.t("localisation"),
    "music": trans.t("musique"),
    "guitar": trans.t("guitare"),
    "drum": trans.t("piano"),
    "tag": trans.t("tag"),
    "bookmark": trans.t("marque-page"),
    "briefcase": trans.t("malette"),
    "clock-rotate-left": trans.t("horloge retour en arrière"),
    "receipt": trans.t("reçu"),
    "cash-register": trans.t("caisse enregistreuse"),
    "award": trans.t("médaille"),
    "robot": trans.t("robot"),
    "hand-spock": trans.t("spock"),
    "wheelchair": trans.t("chaise roulante"),
    "calendar-xmark": trans.t("calendrier événement annulé"),
    "calendar-plus": trans.t("calendrier événement en plus"),
    "calendar-minus": trans.t("calendrier événement en moins"),
    "calendar-day": trans.t("calendrier jour"),
    "calendar-check": trans.t("calendrier événement confirmé"),
};


const FontAwesomeIconPickerPopover = props => {
    const [term, setTerm] = useState('');

    let icons_shown = Object.entries(_ICONS);
    if(term.length > 0) {
        let term_lowered = term.toLowerCase().trim();
        icons_shown = icons_shown.filter(
            ([key, label]) => (key.concat(label)).indexOf(term_lowered) !== -1
        );
    }

    return (
        <div style={{'position': 'absolute', 'top': '18px', 'left': '20px', 'zIndex': 999}}>
            <div className="icon-picker">
                <div className="search-header">
                    <input
                        type="text" className="form-control" autoFocus
                        value={term}
                        onChange={ev => setTerm(ev.target.value)}
                        onKeyDown={ev => {
                            if(ev.key == 'Enter')
                                ev.preventDefault()
                        }}
                    />

                    <div className="close-picker text-end">
                        <span className="pointer" onClick={props.onClose}>&times;</span>
                    </div>
                </div>
                <div className="icons">
                {icons_shown.map(([icon, label]) => (
                    <i
                        key={icon}
                        className={"pointer fa fa-" + icon + (icon == props.value ? ' current' : '')}
                        title={label}
                        onClick={() => {
                            props.onChange(icon)
                            props.onClose();
                        }}
                    ></i>
                ))}
                </div>
            </div>
        </div>
    )
}


const FontAwesomeIconPicker = props => {
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        let listener = ev => {
            if (ev.keyCode == 27 && expanded) {
                setExpanded(false)
            }
        }
        document.addEventListener('keyup', listener);
        return () => document.removeEventListener('keyup', listener);
    }, []);

    return (
    <div className="icon-input">
        <a role="button" onClick={() => !props.disabled && setExpanded(!expanded)}>
            <i className={"fa fa-" + (props.value || 'circle-question')}></i>
            <span className="dropdown-toggle"></span>
        </a>
        {expanded &&
            <FontAwesomeIconPickerPopover
                value={props.value}
                onChange={props.onChange}
                onClose={() => setExpanded(false)}
            />
        }
    </div>
    );
}

export default FontAwesomeIconPicker;
