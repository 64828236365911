const getTotalSeconds = program_elements => {
    let totalSeconds = 0;
    for(let element of program_elements) {
        let elementDuration = element.duration || 0;
        // If at least of duration is null, no need to bother to compute the remaining
        // --> duration will be undetermined
        if(elementDuration == null) {
            totalSeconds = 0;
            break;
        }
        totalSeconds += elementDuration;
    }
    return totalSeconds;
};

const containsAllGroups = (groups, section) => {
    return groups.filter(i => i.section == section.pk).length == section.instrument_groups.length;
}

const containsNothingButSections = (groups, sections) => {
    return groups.filter(i => !sections.map(s => s.pk).includes(i.section)).length == 0;
}

const getInstrumentGroupsDisplay = (all_instrument_groups, instrument_groups, sections) => {
    if (all_instrument_groups) {
        return trans.t("Tous les pupitres");
    } else if (instrument_groups.length == 0) {
        return trans.t("Aucun pupitre");
    }

    let string_section = sections.filter(s => s.name == "Cordes")[0];
    let woodwind_section = sections.filter(s => s.name == "Bois")[0];
    let brass_section = sections.filter(s => s.name == "Cuivres")[0];

    let all_strings = containsAllGroups(instrument_groups, string_section);
    let all_woodwinds = containsAllGroups(instrument_groups, woodwind_section);
    let all_brasses = containsAllGroups(instrument_groups, brass_section);

    if (all_woodwinds && all_brasses && containsNothingButSections(instrument_groups, [woodwind_section, brass_section]))
        return trans.t("Partielle harmonie");
    else if (all_woodwinds && containsNothingButSections(instrument_groups, [woodwind_section]))
        return trans.t("Partielle bois");
    else if (all_brasses && containsNothingButSections(instrument_groups, [brass_section]))
        return trans.t("Partielle cuivres");
    else if (all_strings && containsNothingButSections(instrument_groups, [string_section]))
        return trans.t("Partielle cordes");
    else if (instrument_groups.length <= 5){
        return instrument_groups.map(i => i.name).join(", ");
    } else {
        return trans.t('{{instrument_groups_count}} pupitres', {instrument_groups_count: instrument_groups.length});
    }
}

export { getTotalSeconds, getInstrumentGroupsDisplay};