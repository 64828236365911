import React, { useState } from 'react';
import AttachmentsView from 'orfeo/attachments/jsx/AttachmentsManager.jsx';

const PlanningEventDocumentsPanel = props => {
    const [includeAbsentArtists, setIncludeAbsentArtists] = useState(true);

    function getObjectUrl(path, params){
        let url = props.event.original_url.replace('/view', path);

        if(params){
            url += "?" + new URLSearchParams(params).toString();
        }
        return url;
    }

    return (
        <div>
            {(props.event.project || ['orchestra', 'venue'].includes(VARIANT)) && <div className="py-3">
                <h4>{trans.t("Émargement")}</h4>
                <div className="form-check mb-2">
                    <input
                        class="form-check-input" type="checkbox"
                        checked={includeAbsentArtists}
                        onChange={ev => setIncludeAbsentArtists(ev.target.checked)}
                    />
                    <label class="form-check-label">{trans.t("Afficher les salariés absents et non concernés")}</label>
                </div>
                <div className="list-group list-group-flush">
                    <a
                        href={getObjectUrl('/document/excel-attendance', includeAbsentArtists ? null : {'exclude_absent': 1})}
                        target="_blank"
                        className="list-group-item list-group-item-action"
                    >
                        <i className="fa fa-fw fa-lg fa-file-excel"></i> {trans.t("Fiche d'émargement")}
                    </a>

                    {VARIANT === 'orchestra' && (
                        <a
                            href={getObjectUrl('/document/pdf-attendance', includeAbsentArtists ? null : {'exclude_absent': 1})}
                            target="_blank"
                            className="list-group-item list-group-item-action"
                        >
                            <i className="fa fa-fw fa-lg fa-file-pdf"></i> {trans.t("Fiche d'émargement PDF")}
                        </a>
                    )}
                </div>
            </div>}
            <div className="py-3">
                <AttachmentsView
                    object_id={props.event.pk} content_type_id={window.PLANNING_EVENT_CT}
                    sharable={true} shareTitle={trans.t("Partager avec les participants")}
                />
            </div>
        </div>
    );
}

export default PlanningEventDocumentsPanel;