

const DATE_FMT = new Intl.DateTimeFormat('fr-FR');

const projectObjectRepresentation = obj => {
	return DATE_FMT.format(new Date(obj.start_date)) + " : " + obj.title;
}

export {
	projectObjectRepresentation,
}