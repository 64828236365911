import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client'

import { fetchCsrfWrapper, getModalWrapper, isObject } from 'orfeo_common/react-base.jsx';
import { SelectInput } from 'orfeo_common/Widgets.jsx';
import * as FormUtils from 'orfeo_common/FormUtils.jsx';
import { InputsHoursCount, InputUnitCount } from "./InputsCount";
import { getInstrumentGroupsDisplay } from 'orfeo/production/jsx/ProgramUtils.jsx';
import InstrumentGroupModal from 'orfeo/variants/orchestra/jsx/project/InstrumentGroupModal.jsx';


const PayrollExtraTypesSelector = ({
    participate,
    payrollExtraTypes,
    eventPayrollExtras,
    onPayrollExtraTypeChange,
    notAccountable

}) => {
    const [currentKeyFocus, setCurrentKeyFocus] = useState(null);

    return (
        <div>
            {payrollExtraTypes.map(payrollExtraType => (
                <div
                    key={payrollExtraType.key}
                    className="d-inline-block me-3 mb-2"
                >
                    {payrollExtraType.field_type == 'boolean' ?
                        <div className="input-group input-group-sm">
                            <div className="input-group-text">
                            <input
                                type="checkbox"
                                checked={eventPayrollExtras[payrollExtraType.key] || false}
                                onChange={ev => onPayrollExtraTypeChange(payrollExtraType.key, ev.target.checked)}
                                className="form-check-input"
                                id={"extra_type" + payrollExtraType.key}
                                disabled={notAccountable || !participate}
                            />
                            </div>
                            <label
                                className="input-group-text"
                                htmlFor={"extra_type" + payrollExtraType.key}
                            >{payrollExtraType.label}</label>
                        </div>
                        :
                        <div className="input-group input-group-sm">
                            <div className="input-group-text">
                                <input
                                    className="form-check-input" type="checkbox"
                                    checked={
                                        !!eventPayrollExtras[payrollExtraType.key]
                                        || currentKeyFocus == payrollExtraType.key
                                    }
                                    disabled={notAccountable || !participate}
                                    onChange={ev => {
                                        if(ev.target.checked) {
                                            onPayrollExtraTypeChange(payrollExtraType.key, 1)
                                            // Get sibling input number and set focus on it
                                            ev.target.closest('.input-group').querySelector('input[type="number"]').focus();
                                        }
                                        else {
                                            onPayrollExtraTypeChange(payrollExtraType.key, "");
                                        }
                                    }}
                                />
                            </div>
                            <input
                                type="number"
                                className="form-control d-inline text-end"
                                style={{width: '5em'}}
                                onFocus={ev => setCurrentKeyFocus(payrollExtraType.key)}
                                onBlur={ev => setCurrentKeyFocus(null)}
                                value={eventPayrollExtras[payrollExtraType.key] || ''}
                                onChange={ev => onPayrollExtraTypeChange(payrollExtraType.key, ev.target.value)}
                                disabled={notAccountable || !participate}
                                min="0" step="0.25"
                            />
                            <div className="input-group-text">{payrollExtraType.label}</div>
                        </div>
                    }
                </div>
            ))}
        </div>
    )
}


const InstrumentGroupSelector = props => {
    const openModal = () => {
        let root = createRoot(getModalWrapper());

        const onModalClose = data => {
            if (data) {
                props.onFieldChange({
                    instrument_groups: data.instrument_groups,
                    all_instrument_groups: data.all_instrument_groups
                });
            }

            root.unmount();
        }

        root.render(
            <InstrumentGroupModal
                event={props.event}
                onModalClose={onModalClose}
            />
        );
    }

    return (
        <div className="input-group" onClick={() => openModal()}>
            <div className="input-group-text">
                <i className="fa fa-user-o" />
            </div>
            <input
                type="text"
                className="form-control pointer input-sm"
                value={getInstrumentGroupsDisplay(props.event.all_instrument_groups, props.event.instrument_groups, props.sections)}
                onChange={ev => null}
            />
        </div>
    );
}


const PlanningEventFormationPanel = props => {
    const [formationInfos, _setFormationInfos] = useState(null);
    const [loading, setLoading] = useState(true);
    const [participate, setParticipate] = useState(false);
    const [hoursCountDirty, setHoursCountDirty] = useState(false);
    const [payrollExtraTypes, setPayrollExtraTypes] = useState([]);
    const [sections, setSections] = useState([]);

    const notAccountable = !props.event.hours_counted
    function setFormationInfos(newData){
        // Sync formation infos with parent
        props.onInfoChange(newData);
        _setFormationInfos(newData)
    }

    function resetHoursCountRelatedFields(formationData){
        return Object.assign({}, formationData, {
            'event_payroll_extras': {},
            'travelling_hours_count': null,
            'travelling_hours_count_extra': null,
            'working_hours_count': null,
            'working_hours_count_extra': null
        });
    }

    useEffect(() => {
        setLoading(true);

        let fetchPromises = [
            fetchCsrfWrapper(
                '/backend/planningeventpayrollextratype/?applicable_to=' + props.formation.pk,
                {'method': 'GET'}, {'cached': true}
            ),
        ];
        if(props.event.pk){
            fetchPromises.push(
                fetchCsrfWrapper(
                    `/backend/planningeventartisticformationinfo/?event=${props.event.pk}&formation=${props.formation.pk}`
                )
            );
        } else {
            fetchPromises.push(
                new Promise(resolve => resolve(
                    [(props.event.formations_infos || {})[props.formation.pk]]
                ))
            );
        }

        if(props.formation.is_orchestra){
            fetchPromises.push(
                fetchCsrfWrapper(
                    `/backend/instrumentalsection/?project=${props.event.project.pk}&with_instruments=false`,
                    {'method': 'GET'}, {'cached': true}
                )
            );
        }

        Promise.all(fetchPromises).then(responses => {
            let formationData = responses[1];
            if(formationData && formationData.length && formationData[0]){
                if(notAccountable){
                    formationData[0] = resetHoursCountRelatedFields(formationData[0]);
                }
                setFormationInfos(formationData[0]);
                setParticipate(true);
            } else {
                setFormationInfos(null);
                setParticipate(false);
            }
            const payrollExtraTypesData = responses[0];
            setPayrollExtraTypes(payrollExtraTypesData);

            if(props.formation.is_orchestra){
                const sectionsData = responses[2];
                setSections(sectionsData);
            } else {
                setSections([]);
            }
            setLoading(false);
        });
    }, [props.formation.pk, props.event.pk]);


    function getDefaultFormationInfos(){
        let data = {};
        if(props.formation.default_hours_count){
            const defaultHoursCount = props.formation.default_hours_count.find(x => x.activity_type == props.event.activity_type);
            if(defaultHoursCount){
                data = Object.assign(data, {
                    working_hours_count: defaultHoursCount.default_working_hours_count,
                    working_hours_count_extra: defaultHoursCount.default_working_hours_count_extra,
                    travelling_hours_count: defaultHoursCount.default_travelling_hours_count,
                    travelling_hours_count_extra: defaultHoursCount.default_travelling_hours_count_extra,
                });
            }
        }
        return data;
    }

    useEffect(() => {
        if(participate){
            // update hours_count only for accountable events
            setFormationInfos(
                (notAccountable ? resetHoursCountRelatedFields(formationInfos): formationInfos)
                || getDefaultFormationInfos()
            );
        } else {
            setFormationInfos(null);
        }
    }, [participate, notAccountable]);

    const onCountChange = (field, value) => {
        setHoursCountDirty(true);
        setFormationInfos({
            ...formationInfos,
            [field]: value
        });
    }

    const onPayrollExtraTypeChange = (key, value) => {
        const event_payroll_extras = eventPayrollExtras;
        event_payroll_extras[key] = value
        setFormationInfos({
            ...formationInfos,
            'event_payroll_extras': event_payroll_extras,
        });
    }

    const onCastsChange = value => {
        if(formationInfos !== null || value.length > 0){
            function getCastPk(x){
                if(isObject(x)){
                    return x.pk
                }
                return x
            }
            setFormationInfos({
                ...formationInfos,
                'casts': value.map(getCastPk)
            });
        }
    }

    if(loading){
        return <div className="d-flex justify-content-center mt-3">
            <div className="spinner-border" role="status"><span className="visually-hidden">{trans.t("Chargement...")}</span></div>
        </div>
    }

    const eventPayrollExtras = formationInfos?.event_payroll_extras || {}

    const casts = formationInfos ? formationInfos.casts : [];
    return (
      <div>
        <div className="my-4">
          <label className="form-switch">
            <input
              className="form-check-input me-2"
              type="checkbox"
              checked={participate}
              onChange={(ev) => setParticipate(ev.target.checked)}
            />
            {trans.t("Formation artistique concernée")}
          </label>
        </div>

        <div className="col-12">
          <div className="row my-2">
            <label className="col-3 col-form-label">{trans.t("Décompte permanents")}</label>
            {props.formation.count_mode === "hours_count" ? (
              <InputsHoursCount
                formationInfos={formationInfos}
                onCountChange={onCountChange}
                notAccountable={notAccountable}
                participate={participate}
                type=""
                errors={props.errors}
              />
            ) : (
              <InputUnitCount
                formationInfos={formationInfos}
                onCountChange={onCountChange}
                notAccountable={notAccountable}
                participate={participate}
                type=""
                errors={props.errors}
              />
            )}
          </div>

          <div className="row my-2">
            <label className="col-3 col-form-label">
              {trans.t("Décompte intermittents")}
            </label>
            {props.formation.count_mode === "hours_count" ? (
              <InputsHoursCount
                formationInfos={formationInfos}
                onCountChange={onCountChange}
                notAccountable={notAccountable}
                participate={participate}
                type="_extra"
                errors={props.errors}
              />
            ) : (
              <InputUnitCount
                formationInfos={formationInfos}
                onCountChange={onCountChange}
                notAccountable={notAccountable}
                participate={participate}
                type="_extra"
                errors={props.errors}
              />
            )}
          </div>

          {props.event.pk && hoursCountDirty && (
            <p className="text-info-emphasis fs-6">
              <i className="fa fa-info-circle"></i>&nbsp;
              {trans.t(
                "Si des décomptes individuels"
                + " ont été appliqués aux participants, ils seront remplacés par ces"
                + " nouvelles valeurs."
              )}
            </p>
          )}

          {!!payrollExtraTypes.length && (
            <div className="row my-3">
              <label className="col-3 col-form-label">
                {trans.t("Suppléments liés aux services")}
              </label>
              <div className="col-9">
                <PayrollExtraTypesSelector
                  participate={participate}
                  payrollExtraTypes={payrollExtraTypes}
                  onPayrollExtraTypeChange={onPayrollExtraTypeChange}
                  eventPayrollExtras={eventPayrollExtras}
                  notAccountable={notAccountable}
                />
              </div>
            </div>
          )}

          {notAccountable && (
            <div className="row">
              <p className="text-info-emphasis">
                <i className="fa fa-info-circle"></i>&nbsp;
                {trans.t(
                    "Le type d'élément de"
                    + " planning n'étant pas décomptable il n'est pas possible de"
                    + " renseigner les décomptes et suppléments."
                )}
              </p>
            </div>
          )}

          {props.event.pk &&
            props.formation &&
            props.formation.is_orchestra &&
            sections.length &&
            ["performance", "rehearsal", "technical"].includes(
              props.event.activity_type
            ) && (
              <div className="row my-3">
                <label className="col-3 col-form-label">
                  {trans.t("Pupitres concernés")}
                </label>
                <div className="col-9">
                  <InstrumentGroupSelector
                    event={props.event}
                    onFieldChange={props.onFieldChange}
                    sections={sections}
                  />
                </div>
              </div>
            )}

          {props.formation && !props.formation.is_orchestra && (
            <div className="row my-3">
              <label className="col-3 col-form-label">
                {trans.t("Sélection des casts concernés")}
              </label>
              <div className="col-9">
                <SelectInput
                  backendURL={`/backend/projectcast/?project=${props.event.project.pk}&formation=${props.formation.pk}`}
                  placeholder={trans.t("Casts")}
                  multiple
                  defaultValue={casts}
                  onChange={onCastsChange}
                  disabled={!participate}
                />
                <FormUtils.ErrorText errors={props.errors} field="casts" />
              </div>
            </div>
          )}

          <div className="row">
            <label className="col-3 col-form-label">{trans.t("Notes")}</label>
            <div className="col-9">
              <textarea
                className="form-control input-sm"
                placeholder={trans.t("Notes")}
                value={formationInfos ? formationInfos.notes : ""}
                disabled={!participate}
                onChange={(ev) =>
                  setFormationInfos({
                    ...formationInfos,
                    notes: ev.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
};

export default PlanningEventFormationPanel;
