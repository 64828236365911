'use strict'
import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom';

import { GenericListStoreFactory, getModalWrapper } from 'orfeo_common/react-base.jsx';
import Modal from 'orfeo_common/Modal.jsx';
import Tooltip from 'orfeo_common/Tooltip.jsx';
import { humanFileSize } from 'orfeo_common/utils/files.jsx';

import { get_mimetype_icon } from './utils.jsx';


/*
 * Defines the max size of files that can be attached to an e-mail in kbs
 */
const MAX_ATTACHMENT_FILE_SIZE_KB = 7000;
const MAX_TOTAL_ATTACHMENTS_SIZE_KB = 20000;


const AttachmentFileSizeError = ({message, onModalClose}) => (
    <Modal show>
        <Modal.Header>{trans.t("Impossible d'insérer cette pièce jointe")}</Modal.Header>

        <Modal.Body>
            <p>{message}</p>
            <p
                className="py-2 px-3 border-start border-warning text-warning-emphasis bg-warning-subtle"
                style={{'--bs-border-width': '2px'}}
            >
                <i className="fa fa-lightbulb"></i> Afin d'assurer la délivrabilité des e-mails
                chez la plupart des fournisseurs :<br />
                - le poids de chaque pièce jointe est limité à {MAX_ATTACHMENT_FILE_SIZE_KB / 1000}&nbsp;Mo<br />
                - le poids total de toutes les pièces jointes ne doit pas excéder {MAX_TOTAL_ATTACHMENTS_SIZE_KB / 1000}&nbsp;Mo.
            </p>
        </Modal.Body>

        <Modal.Footer>
            <button className="btn btn-secondary" onClick={onModalClose}>
                {trans.t("Fermer")}
            </button>
        </Modal.Footer>
    </Modal>
    );


const EmailAttachmentsStore = GenericListStoreFactory('filename');

function AttachmentNotUploadedException(msg) {
    this.message = msg;
}

const AttachmentLine = ({ file, aws_data }) => {
    const [currentXhr, setCurrentXhr] = useState(null);
    const [uploading, setUploading] = useState(!file.url);
    const [uploadProgress, setUploadProgress] = useState(0)
    const uploadFile = () => {
        var xhr = new XMLHttpRequest();
        setCurrentXhr(xhr);
        xhr.open('POST', `https://s3-eu-west-1.amazonaws.com/${aws_data.bucket}/`, true);
        if (xhr.upload) {
            xhr.upload.addEventListener('progress', function (ev) {
                setUploadProgress(parseInt(ev.loaded / ev.total * 100));
            }, false); // progressbar
        }
        xhr.onreadystatechange = function() {
            if(
                this.status == 0        // Call with wrong bucket URL
                || this.status == 403   // Call with invalid bucket credentials
            ) {
                alert(trans.t("Problème interne lors de l'upload du fichier."));
                return;
            }

            let url = this.getResponseHeader('location').replace('%2F', '/');
            setUploading(false);
            setUploadProgress(100);
            EmailAttachmentsStore.update(
                Object.assign({}, file, {url: url})
            );
        }
        xhr.send(file.data)
    }

    useEffect(() => {
        if (uploading) {
            uploadFile();
        }
    }, []);


    const removeFile = () => {
        if (uploading)
            currentXhr.abort();

        EmailAttachmentsStore.delete(file.filename);
    }

    var verbose_filename;
    const deletable = file.deletable === undefined || file.deletable;
    if (uploading) {
        verbose_filename = file.filename;
    }
    else {
        verbose_filename = (
            <a href={file.url} target="_blank" rel="noreferrer">
                {file.filename}
            </a>
        );
    }


    return (
        <li className="border py-2 px-1">
            <span>{get_mimetype_icon(file.mime_type)}</span>
            <span className="attachment-filename mx-1">{verbose_filename}</span>
            {file.size && <span className="text-muted">({humanFileSize(file.size)})</span>}

            {deletable ?
                <a role="button" onClick={removeFile} className="mx-1 px-1">
                    <i className="fa fa-trash"></i>
                </a>
            :
                <Tooltip title={trans.t("Cette pièce jointe n'est pas supprimable")}>
                    <span className="mx-2">
                        <i className="fa-solid fa-lock"></i>
                    </span>
                </Tooltip>
            }

            {uploading &&
            <div className="d-inline-block ms-2">
                <div className="progress" style={{'width': '125px', 'height': '15px'}}>
                    <div
                        className="progress-bar progress-bar-striped active" role="progressbar"
                        style={{'width': uploadProgress + '%', 'lineHeight': '15px'}}
                        aria-valuenow={uploadProgress} aria-valuemin="0" aria-valuemax="100"
                    >
                        <small>{uploadProgress} %</small>
                    </div>
                </div>
            </div>}

        </li>
    );
}
const AddAttachmentForm = ({ composerInfo }) => {

    // Open a modal with no further action when the attachment being uploaded is larger
    // than the threshold authorized
    const openAttachmentErrorModal = message => {
        let wrapper = createRoot(getModalWrapper());
        wrapper.render(
            <AttachmentFileSizeError
                message={message}
                onModalClose={() => wrapper.unmount()}
            />
        );
    }

    const toFileObject = file => {
        let data = new FormData();
        data.append("key", composerInfo.aws.directory + "${filename}");
        data.append("AWSAccessKeyId", composerInfo.aws.access_key);
        data.append("acl", "private");
        data.append("policy", composerInfo.aws.policy);
        data.append("signature", composerInfo.aws.signature);
        data.append("file", file)
        return {
            'source': 's3',
            'filename': file.name,
            'size': file.size,
            'mime_type': file.type,
            'url': null,
            'data': data
        }
    }
    const openFilePicker = () => {
        let inputElement = document.createElement('input');
        // Hide element and append to body (required to run on iOS safari)
        inputElement.style.display = 'none';
        document.body.appendChild(inputElement);
        inputElement.type = 'file';
        inputElement.multiple = true;
        inputElement.addEventListener('change', event => {
            // Compute the current total size to ensure adding a new file does not
            // exceed our total threshold
            let totalAttachmentsSize = EmailAttachmentsStore.getList().reduce(
                (acc, val) => acc + val.size, 0
            );
            for (let file of event.target.files) {
                // Check if the unit size is allowed
                if(file.size >= MAX_ATTACHMENT_FILE_SIZE_KB * 1000) {
                    openAttachmentErrorModal(
                        trans.t(
                            "Le fichier {{filename}} ({{size}}) est trop volumineux.",
                            {filename: file.name, size: humanFileSize(file.size)}
                        )
                    )
                    return;
                }
                // Check if adding this file to the existing ones would exceed the total allowed size
                if(totalAttachmentsSize + file.size >= MAX_TOTAL_ATTACHMENTS_SIZE_KB * 1000) {
                    openAttachmentErrorModal(
                        trans.t(
                            "Impossible d'ajouter le fichier {{filename}} sans dépasser la limite de poids de cet e-mail.",
                            {filename: file.name, size: humanFileSize(file.size)}
                        )
                    );
                    return;
                }
                // Increment total size for future checks if several files are added in bulk
                totalAttachmentsSize += file.size;
                EmailAttachmentsStore.add(toFileObject(file))
            }
            // remove element since we will create a new one if we click again
            document.body.removeChild(inputElement);
            // props?.onAttachmentAdded()
        })
        inputElement.dispatchEvent(new MouseEvent('click'));
    }
    if (!composerInfo) {
        return null
    }
    // The best thing would be to remove the form and handle the filePicker ourselves
    return (
        <a role="button" onClick={openFilePicker} style={{'color': '#333'}}>
            <i className="fa fa-paperclip"></i> Ajouter pièce jointe
        </a>
    );
}

const AttachmentsList = props => {
    return (
    <div className="attachment-zone">
        <ul className="s3-attachments-list">
        {props.attachments.map(
            (file, idx) => (
                <AttachmentLine
                    file={file}
                    key={file.filename}
                    aws_data={props.composerInfo.aws}
                />
                )
            )}
        </ul>
    </div>
    );
}
export default AttachmentsList;
export { AddAttachmentForm, AttachmentNotUploadedException, EmailAttachmentsStore }
