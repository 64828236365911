import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import * as FormUtils from 'orfeo_common/FormUtils.jsx';

import {
    deepCopy,
    GenericListStoreFactory,
    pluralize,
    flatten
} from 'orfeo_common/react-base.jsx';
import Modal from 'orfeo_common/Modal.jsx';

const SectionStore = GenericListStoreFactory();

function getNewSelection(ev, currentSelection, pks) {
    if (pks.length == 1) {
        let selected = currentSelection.indexOf(pks[0]) !== -1;
        // If the user has the Ctrl key set, just switch the selection of the selected item
        if(ev && ev.ctrlKey) {
            if(!selected)
                return currentSelection.concat(pks);
            else
                return currentSelection.filter(x => !pks.includes(x));
        }

    }
    return pks;
}


const AvailableInstrumentGroupsColumn = props => {
    const [previousGroupSelected, setPreviousGroupSelected] = useState();

    // Compute the number of the groups left in all sections to handle the "Select all" button at the end
    let nb_entries = props.sections.reduce((acc, item) => acc + item.available_groups.length, 0)

    return (
        <div className="column-container mt-2">
            <div className="column">
            {props.sections
                .filter(section => (
                    section.available_groups.length > 0
                    && (!props.sectionFilter || section.pk == props.sectionFilter)
                ))
                .map(section => {
                    let instrument_groups = section.available_groups.length;

                    return (
                    <React.Fragment key={section.pk}>
                        <h5 onClick={() => props.onSelectionChange(section.available_groups.map(x => x.pk))}>
                            {section.name}
                            <span className="float-end text-muted">
                                {instrument_groups} {pluralize('pupitre', instrument_groups)}
                            </span>
                        </h5>
                        <ul>
                        {section.available_groups.map((group, idx) => {
                            let selected = (props.selection.indexOf(group.pk) !== -1);

                            const onClick = ev => {
                                if (props.shiftKey && previousGroupSelected != null) {
                                    let groups;
                                    if (idx < previousGroupSelected) {
                                        groups = section.available_groups.slice(idx, previousGroupSelected + 1);
                                    } else {
                                        groups = section.available_groups.slice(previousGroupSelected, idx + 1);
                                    }
                                    props.onSelectionChange(getNewSelection(ev, props.selection, groups.map(g => g.pk)));
                                }
                                else {
                                    props.onSelectionChange(getNewSelection(ev, props.selection, [group.pk]));
                                    setPreviousGroupSelected(idx);
                                }
                            }

                            return (
                                <li
                                    key={group.pk + '' + selected}
                                    className={classNames({'selected': selected})}
                                    onClick={onClick}
                                >
                                    {group.name}
                                </li>
                            );
                        })}
                        </ul>
                    </React.Fragment>
                    )
                }
            )}
            </div>
            <div className="column-buttons mt-1">
            {nb_entries == props.selection.length ?
                <button className="btn btn-sm btn-secondary" onClick={() => props.onSelectionChange([])}>
                    {trans.t("Tout désélectionner")}
                </button>
                :
                <button className="btn btn-sm btn-secondary" onClick={() => props.onSelectionChange(
                    props.sections.reduce(
                        (acc, item) => acc.concat(item.available_groups.map(x => x.pk)), []
                    )
                )}>
                    {trans.t("Tout sélectionner")}
                </button>
            }
            </div>
        </div>
    );
}

/**
 * Right column of the UI: represents the list of seats selected to participate to the
 * project.
 */
const InstrumentGroupSelectedColumn = props => {
    const [previousLineSelected, setPreviousLineSelected] = useState();
    const [sections, setSections] = useState([]);

    useEffect(() => {
        let listener = sections => setSections(sections);
        SectionStore.addOnListChange(listener)
        SectionStore.setList(props.sections);
        return () => SectionStore.removeListener(listener);
    }, []);

    let nb_entries = props.sections.reduce(
        (acc, section) => acc + section.selected_groups.length, 0
    );

    return (
        <div className="column-container mt-2">
            <div className="column">
            {sections
                .filter(x => (
                    (!props.sectionFilter
                    || (x.pk == props.sectionFilter))
                    &&
                    x.selected_groups.length > 0
                )).map(
                ({pk, name, selected_groups}) => (
                    <React.Fragment key={pk + '' + selected_groups.map(x => x.pk).join(',')}>
                        <h5 onClick={() => props.onSelectionChange(selected_groups.map(x => x.pk))}>
                            {name}
                        </h5>
                        <ul>
                            {selected_groups.map(({pk, name}, idx) => {
                                let selected = (props.selection.indexOf(pk) !== -1);

                                const onClick = ev => {
                                    if (props.shiftKey && previousLineSelected != null) {
                                        let selectedLines;
                                        if (idx < previousLineSelected) {
                                            selectedLines = selected_groups.slice(idx, previousLineSelected + 1);
                                        } else {
                                            selectedLines = selected_groups.slice(previousLineSelected, idx + 1);
                                        }
                                        props.onSelectionChange(getNewSelection(ev, props.selection, selectedLines.map(s => s.pk)));
                                    }
                                    else {
                                        props.onSelectionChange(getNewSelection(ev, props.selection, [pk]));
                                        setPreviousLineSelected(idx);
                                    }
                                }

                                return (
                                    <li
                                        key={pk + '' + selected + idx}
                                        className={selected ? "selected" : ""}
                                        onClick={onClick}
                                    >
                                        {name}
                                    </li>
                                );
                            })}
                        </ul>
                    </React.Fragment>
                )
            )}
            </div>

            <div className="column-buttons mt-1">
            {nb_entries == props.selection.length ?
                <button className="btn btn-sm btn-secondary" onClick={() => props.onSelectionChange([])}>
                    {trans.t("Tout désélectionner")}
                </button>
                :
                <button className="btn btn-sm btn-secondary" onClick={() => props.onSelectionChange(
                    props.sections.reduce(
                        (acc, item) => acc.concat(item.selected_groups.map(x => x.pk)), []
                    )
                )}>
                    {trans.t("Tout sélectionner")}
                </button>
            }
            </div>
        </div>
    );
}

const loadInstrumentGroups = event => {
    return new Promise((resolve, reject) => {
        $.ajax({
            url: '/backend/planningevent/' + event.pk + '/sections/',
            method: 'GET'
        }).success(data => {
            SectionStore.setList(data);
            resolve(SectionStore.getList());
        }).fail(jqXHR => reject(jqXHR))
    });
}


const InstrumentGroupModal = props => {
    const [errors, setErrors] = FormUtils.useFormErrors();

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [availableSections, setAvailableSections] = useState([]);

    // Filters store
    const [sectionFilter, setSectionFilter] = useState(props.sectionFilter);

    // Keep current selection in each column
    const [availSelection, setAvailSelection] = useState([]);
    const [selection, setSelection] = useState([]);
    const [shiftKey, setShiftKey] = useState(false);

    const onKeyUp = ev => {
        if (!ev.shiftKey) {
            setShiftKey(false);
        }
    }

    const onKeyDown = ev => {
        if (ev.shiftKey) {
            setShiftKey(true);
        }
    }

    useEffect(() => {
        SectionStore.setList(props.instrument_groups);
        let listener = lst => setLoading(false);
        SectionStore.addOnListChange(listener);
        document.addEventListener('keydown', onKeyDown);
        document.addEventListener('keyup', onKeyUp);

        setLoading(true);
        loadInstrumentGroups(props.event).then(data => {
            setAvailableSections(SectionStore.getList());

            const isEmpty = SectionStore.getList().reduce((lastSectionEmpty, section) => {
               return lastSectionEmpty && (section.available_groups.length === 0 && section.selected_groups.length == 0);
            }, true);
            if(isEmpty) {
                alert(trans.t(
                    "Le projet n'a ni programme ni effectifs renseignés : "
                    + "la liste des pupitres ne peut pas être déterminée"
                ));

                setTimeout(() => {
                    props.onModalClose(null);
                }, 100);
            }
            setLoading(false);
        })

        return () => {
            SectionStore.removeListener(listener)
            document.removeEventListener('keydown', onKeyDown);
            document.removeEventListener('keyup', onKeyUp);
        }
    }, []);

    const onMoveToRight = ev => {
        let sections = deepCopy(SectionStore.getList());
        for (let pk of availSelection) {
            for(let section of sections) {
                let pos = section.available_groups.findIndex(x => x.pk == pk);
                if(pos !== -1) {
                    let group = section.available_groups.splice(pos, 1);
                    section.selected_groups.push(group[0]);
                    section.selected_groups.sort((a, b) => a.order_index > b.order_index ? 1 : -1);
                }
            }
        }
        setAvailableSections(sections);
        setSelection([]);
        SectionStore.setList(sections);
    }

    const onMoveToLeft = ev => {
        let sections = deepCopy(SectionStore.getList());
        for (let pk of selection) {
            for(let section of sections) {
                let pos = section.selected_groups.findIndex(x => x.pk == pk);
                if(pos !== -1) {
                    let group = section.selected_groups.splice(pos, 1);
                    section.available_groups.push(group[0]);
                    section.available_groups.sort((a, b) => a.order_index > b.order_index ? 1 : -1);
                }
            }
        }
        setAvailableSections(sections);
        setSelection([]);
        SectionStore.setList(sections);
    }

    const onSubmit = ev => {
        setSaving(true);

        let all_instrument_groups = true;
        let instrument_groups = [];
        for (let section of SectionStore.getList()) {
            instrument_groups = instrument_groups.concat(section.selected_groups);
            if (section.available_groups.length > 0) {
                all_instrument_groups = false;
            }
        }
        instrument_groups = instrument_groups.map(i => ({pk: i.pk}));

        $.ajax({
            url: '/backend/planningevent/' + props.event.pk + '/',
            method: 'PATCH',
            data: JSON.stringify({
                'instrument_groups': instrument_groups,
                'all_instrument_groups': all_instrument_groups
            }),
            contentType: 'application/json',
        }).success(data => {
            props.onModalClose(data);
        }).error(
            data => setErrors(data.responseJSON)
        ).always(() => setSaving(false));
    }

    return (
        <Modal show onHide={() => props.onModalClose(null)} modalCSSClass="wide orchestra-distribution-employee-modal two-columns-modal">
            <Modal.Header>
                {trans.t("Sélection des pupitres")}
            </Modal.Header>

            <Modal.Body>
                <div className="form-inline">
                    <div className="input-group" style={{'marginRight': '20px', 'minWidth': '250px'}}>
                        <select
                            className="form-select input-sm"
                            key={SectionStore.count() > 0}
                            value={sectionFilter}
                            onChange={ev => setSectionFilter(ev.target.value ? parseInt(ev.target.value) : ev.target.value)}
                        >
                            <option value="">Tous</option>
                            {SectionStore.getList()
                                .map(group => (
                                    <option
                                        value={group.pk}
                                        key={group.pk}
                                    >
                                        {group.name}
                                    </option>
                                )
                            )}
                        </select>
                    </div>
                </div>

                <div className="select-grid">
                    {loading ?
                        <p className="empty-list-placeholder">
                            <i className="fa fa-spin fa-spinner"></i><br />
                            {trans.t("Chargement de la liste des pupitres en cours")}
                        </p>
                    :
                        <AvailableInstrumentGroupsColumn
                            sections={availableSections}
                            sectionFilter={sectionFilter}
                            selection={availSelection}
                            onSelectionChange={newSelection => setAvailSelection(newSelection)}
                            event={props.event}
                            shiftKey={shiftKey}
                        />
                    }

                    <div className="buttons">
                        <a className="btn btn-secondary" onClick={onMoveToRight}>
                            <i className="fa fa-arrow-right"></i>
                        </a><br />
                        <a className="btn btn-secondary" onClick={onMoveToLeft}>
                            <i className="fa fa-arrow-left"></i>
                        </a>
                    </div>

                    <InstrumentGroupSelectedColumn
                        sections={availableSections}
                        sectionFilter={sectionFilter}
                        selection={selection}
                        onSelectionChange={newSelection => setSelection(newSelection)}
                        event={props.event}
                        shiftKey={shiftKey}
                    />

                </div>

                {Object.keys(errors).length > 0 &&
                <p className="react-form-errors-intro">
                   {FormUtils.getGlobalErrors(errors)}
                </p>}
            </Modal.Body>

            <Modal.Footer>
                <a className="btn btn-outline-secondary" onClick={() => props.onModalClose(null)}>
                    {trans.t("Annuler")}
                </a>
                <button className="btn btn-primary" onClick={onSubmit} disabled={saving}>
                    {saving && <i className="fa fa-spin fa-spinner"></i>} {trans.t("Sauvegarder")}
                </button>
            </Modal.Footer>
        </Modal>
    )
}


export default InstrumentGroupModal;
