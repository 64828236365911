/**
 * E-mail form used in Orfeo
 */
'use strict';
import React, { useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as FormUtils from 'orfeo_common/FormUtils.jsx';
import { EmailRecipientField } from 'orfeo_common/Widgets.jsx';
import { Editor as TinyMCE } from '@tinymce/tinymce-react';

import EmailStore from './EmailStore.jsx';
import AttachmentsList, {
    AddAttachmentForm,
    AttachmentNotUploadedException,
    EmailAttachmentsStore
} from './EmailAttachments.jsx';
import { deepCopy, fetchCsrfWrapper } from 'orfeo_common/react-base.jsx';

/**
 * The following global variable is used to keep track of the last recipient
 * field that have been focused. This way, we can add new recipients to the
 * correct field, depending on the navigation context of the user
 */
var _last_recipient_focus = "to";

var tinymce_config = {
    forced_root_block: false,
    branding: false,
    browser_spellcheck: true,
    contextmenu: false,
    menubar: false,
    statusbar: false,
    max_height: 400,
    autoresize_max_height: 400,
    entity_encoding: 'raw',
    relative_urls: false,
    remove_script_host: true,
    document_base_url: 'http://www.example.com/path1/',
    plugins: [
        'advlist autolink autoresize lists charmap preview hr link image',
        'searchreplace visualblocks visualchars code noneditable',
        'nonbreaking table directionality paste imagetools merge_fields'
    ],
    paste_auto_cleanup_on_paste: true,
    toolbar1: (
        'fontselect fontsizeselect | bold italic underline strikethrough | forecolor backcolor bullist '
        + ' | alignleft aligncenter alignright | outdent indent | link image'
    ),
    fontsize_formats: '8pt 9pt 10pt 11pt 12pt 13pt 14pt 15pt 16pt 18pt 24pt 36pt',
    file_picker_callback: tinymce_OrfeoS3_filebrowser,
    font_formats: (
        'Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;'
      + 'Book Antiqua=book antiqua,palatino;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;'
      + 'Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;'
      + 'Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Garamond=garamond'
    ),
    setup: function (editor) {
        // This prevents the blur event from hiding the toolbar
        editor.on('blur', function (ev) {
            $(editor.getBody()).removeClass('mce-edit-focus');
            ev.stopImmediatePropagation();
        });
    }
}

const EmailComposer = props => {
    let mode = props.mode || 'new';
    let entities = props.entities || null;
    // Initial subject can be prefixed differently according to the type of reply
    const _getInitialSubject = function () {
        if(!props.parent)
            return "";

        let subject = props.parent.subject;
        let prefix = '';
        if(mode.startsWith('reply')) {
            prefix = !subject.startsWith('Re:') ? 'Re: ' : '';
        }
        else if(mode == 'forward') {
            prefix = !subject.startsWith('Fwd:') ? 'Fwd: ' : '';
        }

        return prefix + subject;
    }

    const _getInitialRecipients = function () {
        let infos = {
            'to': [],
            'cc': [],
        };

        // Pre-fill recipient in reply mode
        if(mode == 'reply') {
            // In case the e-mail was some we sent previously and we reply back from there,
            // we must use the other field, used in the previous mail
            if(props.parent.email_from.email == EmailStore._email_address)
                infos.to = props.parent.email_to.map(x => x.email);
            else if(props.parent.email_replyto.length)
                infos.to = props.parent.email_replyto.map(x => x.email);
            else
                infos.to = [props.parent.email_from.email]
        }
        else if(mode == 'reply-all') {
            infos.to = props.parent.email_to.map(x => x.email);
            // Do not include ourself in the "to:"
            if(props.parent.email_from.email != EmailStore._email_address) {
                infos.to.push(props.parent.email_from.email)
            }
            infos.cc = props.parent.email_cc.map(x => x.email);
        }

        return infos;
    }

    const initComposerInfo = () => {
        fetchCsrfWrapper(props.composerInfosUrl).then(data => {
            let body = props.emailBody || data.default_body || '';
            body += '<br />' + data.email_signature;
            if(mode != 'new') {
                let email_from = props.parent.email_from;
                const intro = trans.t(
                    "Le {{date}}, {{email_from}} a écrit :",
                    {
                        date: moment(props.parent.date).locale('fr').format('LLL'),
                        email_from: email_from.full_name || email_from.email
                    }
                );

                body += (
                    '<br />' + intro
                    + '\n<blockquote>'
                    + props.parent.html_body
                    + '\n</blockquote>'
                );
            }
            setComposerInfo(data);
            if (body && body != emailBody) {
                setEmailBody(body)
            }
        })
    }

    const initialRecipients = _getInitialRecipients()
    const [subject, setSubject] = useState(_getInitialSubject());
    const [to, setTo] = useState(props.to || initialRecipients.to);
    const [cc, setCc] = useState(props.cc || initialRecipients.cc);
    const [bcc, setBcc] = useState(props.bcc || []);
    const [showCc, setShowCc] = useState(props.cc && props.cc.length > 0 || initialRecipients.showCc);
    const [showBcc, setShowBcc] = useState(props.bcc && props.bcc.length > 0 || false);
    const [attachments, setAttachments] = useState(props.attachments || []);
    const [errors, setErrors] = useState({});
    const [composerInfo, setComposerInfo] = useState(null);
    const [emailBody, setEmailBody] = useState(props.emailBody || '');
    const [sending, setSending] = useState(false);

    const body_field = useRef();

    useEffect(() => {
        EmailAttachmentsStore.addOnListChange(lst => setAttachments(lst));
        EmailAttachmentsStore.setList(props.attachments || []);
    }, [])

    useEffect(initComposerInfo, []);
    useEffect(() => {
        if (!composerInfo || !props.parent || mode != 'forward')
            return;

        const url = '/mails/api/attachments/upload/';
        for (let att of props.parent.attachments) {
            let data = Object.assign({
                msgId: props.parent.message_id,
                attachmentId: att.attachment_id
            }, composerInfo.aws);
            fetchCsrfWrapper(
                url,
                {method: 'POST', body: data}
            ).then(data => EmailAttachmentsStore.update(data))
        }
    }, [composerInfo]);

    useEffect(() => {
        if(mode != "new") {
            // It seems a small delay is needed otherwise the focus is ignored with
            // a late refresh of tinymce...
            setTimeout(function () {
                if(body_field.current) {
                    try {
                        tinymce.execCommand('mceFocus', false, body_field.current.id);
                    }
                    catch(err) {}
                }
            }, 300);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('Orfeo.OpenEmailComposer', ev => {
            let setters = {
                "to": setTo,
                "cc": setCc,
                "bcc": setBcc,
            };

            let setter = setters[_last_recipient_focus]
            if(!setter)
                return;

            const address = ev.detail['address'];
            setter(
                lst => lst.filter(elem => elem != address.email).concat([address.email])
            );
        })
    }, [])

    const sendMessage = ev => {
        ev.preventDefault();
        let attachments_json = JSON.stringify(
            EmailAttachmentsStore.getList().map(f => {
                if(f.url === undefined) {
                    throw new AttachmentNotUploadedException(trans.t(
                        `La pièce jointe « {{filename}} » n'est pas totalement `
                        + "chargée, veuillez patienter avant d'envoyer le mail.",
                        {filename: f.filename}
                    ));
                }
            return f;
        }));
        var data = {
            'subject': subject,
            'to': to,
            'body': emailBody,
            'attachments': attachments_json
        };

        if(props.parent) {
            data['in_reply_to'] = props.parent.message_id;
        }

        if(showCc){
            data['cc'] = cc;
        }
        if(showBcc) {
            data['bcc'] = bcc;
        }

        if (props.survey) {
            data['survey'] = props.survey.uuid
        }

        if(props.additionalData){
            data = Object.assign(data, props.additionalData);
        }

        const url = props.customUrl || "/mails/api/send/";
        setSending(true);
        fetchCsrfWrapper(
            url,
            {method:'POST', body: data},
            {contentType: "form-data", pendingMessage: trans.t("Envoi en cours...")}
        ).then(
            data => {
            props.onMailSent(data)
        })
        .catch(data => {
            setErrors(FormUtils.getErrorsDict(data))
        })
        .finally(() => setSending(false))
        ;
    }

    const onSelectTemplate = ev => {
        // Ignore empty item
        if(ev.target.value == "")
            return;

        // Load content and potential attachments
        var url = '/mails/api/composer/load-template/' + ev.target.value + '/';
        let queryParams = new URLSearchParams();
        if(entities && entities.length == 1) {
            queryParams.append('entity', entities[0]);
        }
        if (props.survey) {
            queryParams.append('survey', props.survey.uuid);
        }
        if (queryParams.size) {
            url += "?" + queryParams.toString();
        }

        fetchCsrfWrapper(url)
            .then(data => {
                setEmailBody(data.body);
                EmailAttachmentsStore.setList(
                    attachments.filter(a => a.deletable === false).concat(data.attachments)
                )
                setSubject(data.subject || subject)
                // Apply CC/BCC fields only if data are present in the template to avoid
                // overriding of the user initial input, if any
                if(data.cc_recipients.length > 0) {
                    setCc(data.cc_recipients)
                    setShowCc(true)
                }
                if(data.bcc_recipients.length > 0) {
                    setBcc(data.bcc_recipients)
                    setShowBcc(true)
                }
            })
            .catch(err => console.error(trans.t("Impossible de charger le template")));
    }

    const _render_recipients_row = function () {
        var show_more_fields_link = !showCc || !showBcc;

        // `to` row is always visble
        var rows = [
            <div className="row" key='to'>
                <div className={"col-md-" + (show_more_fields_link ? "8" : "12")}>
                    <EmailRecipientField prefix="À" field="to"
                        entities={entities}
                        contract={props.survey ? props.survey.contract : null}
                        values={to} onValueChange={val => setTo(val)}
                        key={to.length}
                        disabled={props.disabledToField}
                        onFocus={ev => _last_recipient_focus = "to"}
                    />
                    <FormUtils.ErrorText errors={errors} field='to' />
                </div>
                {show_more_fields_link &&
                <div className="col-md-4">
                    <div style={{'lineHeight': '30px', 'verticalAlign': 'center'}}>
                    {!showCc &&
                        <a className="pointer" role="button" onClick={() => {
                            setShowCc(true);
                            _last_recipient_focus = "cc";
                        }}>
                        CC
                        </a>
                    }
                    {!showCc && !showBcc && <span>&nbsp;&middot;&nbsp;</span>}
                    {!showBcc &&
                        <a className="pointer" onClick={() => {
                            setShowBcc(true);
                            _last_recipient_focus = "bcc";
                        }}>
                        CCi
                        </a>
                    }
                    </div>
                </div>}
            </div>
        ];

        if (showCc)
            rows.push(
                <div className="row" key='cc'>
                    <div className={"col-md-" + (show_more_fields_link ? "8" : "12")}>
                        <EmailRecipientField
                            prefix="CC"
                            field="cc"
                            values={cc}
                            onValueChange={val => setCc(val)}
                            key={cc.length}
                            entities={entities}
                            contract={props.survey ? props.survey.contract : null}
                            onFocus={ev => _last_recipient_focus = "cc"}
                        />
                        <FormUtils.ErrorText errors={errors} field='cc' />
                    </div>
                </div>
            );

        if (showBcc)
            rows.push(
                <div className="row" key='bcc'>
                    <div className={"col-md-" + (show_more_fields_link ? "8" : "12")}>
                        <EmailRecipientField
                            prefix="CCi"
                            field="bcc"
                            values={bcc}
                            onValueChange={val => setBcc(val)}
                            key={bcc.length}
                            entities={entities}
                            contract={props.survey ? props.survey.contract : null}
                            onFocus={ev => _last_recipient_focus = "bcc"}
                        />
                        <FormUtils.ErrorText errors={errors} field='bcc'/>
                    </div>
                </div>
            )

        return rows;
    }


    let templates, subject_size;
    if(composerInfo && composerInfo['templates'] != null) {
        subject_size = 8;
        if(composerInfo['templates'].length > 0) {
            templates = (
                <select className="form-select" onChange={onSelectTemplate}>
                    <option style={{'fontStyle': 'italic'}} value="">{trans.t("Choisir modèle")}</option>
                    {composerInfo['templates'].map(
                        tpl => <option value={tpl.pk} key={tpl.pk}>{tpl.name}</option>
                    )}
                </select>
            );
        }
        else {
            templates = <small><a href={composerInfo['templates_changelist']}>{trans.t("Créer un modèle d'e-mail")}</a></small>;
        }
    }
    else {
        subject_size = 12;
    }

    if(composerInfo && composerInfo['has_email_account'] === false) {
        return (
            <p className="empty-list-placeholder" style={{'lineHeight': '2'}}>
                <i className="fa fa-chain-broken"></i>&nbsp; {trans.t("Vous n'avez pas associé de compte e-mail.")}<br />
                <a href="/accounts/integrations/e-mail/">{trans.t("Associer un compte depuis les paramètres")}</a>.
            </p>
        )
    }
    else if(composerInfo && composerInfo['suspended'] === true) {
        return (
            <p className="empty-list-placeholder" style={{'lineHeight': '1.75'}}>
                <i className="fa fa-exclamation-circle"></i>&nbsp;
                <Trans>
                    La synchronisation de votre compte e-mail
                    est suspendue depuis le {{date: moment(composerInfo['suspended_date']).locale('fr').format('lll')}}.<br />
                    <small>Cela empêche la rédaction de messages avec ce compte. Cette suspension peut être dû à un
                    changement de mot de passe ou des erreurs de connexion avec le serveur.</small>
                </Trans>
                <br />
                <a href="/accounts/integrations/e-mail/">{trans.t("Accéder aux paramètres du compte")}</a>.
            </p>
        )
    }

    let tinyMCEConfig = deepCopy(tinymce_config);
    if(props.mergeFields){
        tinyMCEConfig['merge_fields'] = props.mergeFields;
        tinyMCEConfig['toolbar1'] += ' | merge_fields'
    }
    return (
    <div className="email-composer">
        <form className="form react-inline-form" style={{'width': '100%', 'maxWidth': 'none'}} onSubmit={sendMessage}>
            {Object.keys(errors).length > 0 &&
                <p className="react-form-errors-intro">
                    {trans.t("Erreur lors de l'envoi de l'email.")} {FormUtils.getGlobalErrors(errors)}
                </p>
            }

            {_render_recipients_row()}

            <div className="row">
                <div className={"col-md-"+subject_size}>
                    <input
                        type="text" className="form-control" placeholder="Sujet"
                        autoFocus={mode == "new"} value={subject}
                        onChange={ev => setSubject(ev.target.value)}
                    />
                </div>
                <div className={"col-md-"+(12 - subject_size)} style={{'lineHeight': '34px'}}>
                    {templates}
                </div>
            </div>

            {composerInfo ?
                <React.Fragment>
                    <TinyMCE
                        className="email-body-composer"
                        init={tinyMCEConfig} ref={body_field}
                        value={emailBody}
                        onEditorChange={content => setEmailBody(content)}
                    />
                    <AttachmentsList
                        attachments={attachments}
                        composerInfo={composerInfo}
                    />
                </React.Fragment>
                :
                <p className="lead text-center" style={{'padding': '30px 0'}}>
                    <i className="fa fa-spin fa-spinner"></i>
                </p>
            }

            <div className="row mt-1">
                <div className="col-md-6" style={{'lineHeight': '32px'}}>
                    <AddAttachmentForm composerInfo={composerInfo} />
                </div>
                <div className="col-md-6 text-end">
                    <a className="btn btn-outline-secondary" onClick={props.onCancel}>{trans.t("Annuler")}</a> &nbsp;

                    <button className="btn btn-primary" disabled={sending}>
                        {sending && <i className="fa fa-spin fa-spinner"></i>} {trans.t("Envoyer")}
                    </button>
                </div>
            </div>
        </form>
    </div>
    );
}

export default EmailComposer;
