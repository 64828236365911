import React, { useState } from 'react';

function initMatrix(group, entry) {
    // Initialize a matrix of `false` values as if nothing was checked
    let new_matrix = [];
    for(let i = 0; i < group.instruments.length; i++) {
        new_matrix.push(Array(entry.quantity).fill(false));
    }
    // Fill the matrix with `true` values when the tuple (instr_id, rank) exists
    let instruments_pks = group.instruments.map(x => x.pk);
    for(let item of entry.instruments) {
        new_matrix[instruments_pks.indexOf(item.instrument)][item.rank] = true;
    }
    // If no instruments have been specified yet, we need to check by default
    // the main one for each musician
    if(entry.instruments.length == 0) {
        for(let i = 0; i < entry.quantity; i++) { // the first line should be the default instrument
            new_matrix[0][i] = true;
        }
    }

    return new_matrix;
}

// Reverse the matrix transformation into a list objects that can be passed
// to the backend
function flattenMatrixIntoObjects(group, matrix) {
    let lines = [];

    for(let i = 0; i < group.instruments.length; i++) {
        // Iterate on the `i`th row of the matrix on create a new object for each true value
        for(let y = 0; y < matrix[i].length; y++) {
            if(matrix[i][y]) {
                lines.push({
                    'instrument': group.instruments[i].pk,
                    'rank': y,
                    'quantity': 1,
                })
            }
        }
    }
    return lines;
}

const InstrumentationInfoGrid = props => {
    const [matrix, setMatrix] = useState(() => initMatrix(props.group, props.entry))
    const switchValueInMatrix = (row_idx, col_idx, value) => setMatrix(
        matrix => {
            let new_matrix = matrix.slice();
            new_matrix[row_idx][col_idx] = value;
            return new_matrix;
        }
    )
    const submitMatrixIfValid = () => {
        let col_empty;
        for(let y = 0; y < matrix[0].length; y++) {
            col_empty = true;
            for (let i = 0; i < props.group.instruments.length; i++) {
                if (matrix[i][y]) {
                    col_empty = false;
                }
            }
            if (col_empty) {
                alert(trans.t('Vous devez préciser les instruments pour tous les musiciens du pupitre'));
                return;
            }
        }
        props.onSubmit(
            flattenMatrixIntoObjects(props.group, matrix)
        );

    }

    return (
    <div className="group-instrument-info-container">
        <div className="group-instrument-matrice">
            <table>
                <tbody>
                <tr>
                    <th></th>
                    {Array(props.entry.quantity).fill().map(
                        (x, idx) => <th key={idx}>#{idx + 1}</th>
                    )}
                </tr>
                {matrix.map(
                    (instr_row, row_idx) => (
                        <tr key={props.group.instruments[row_idx].pk}>
                            <th>{props.group.instruments[row_idx].name}</th>
                            {instr_row.map(
                                (instr_selected, col_idx) => (
                                    <td key={row_idx + ',' + col_idx}>
                                        <input
                                            type="checkbox" checked={instr_selected}
                                            onChange={ev => switchValueInMatrix(row_idx, col_idx, ev.target.checked)}
                                        />
                                    </td>
                                )
                            )}
                        </tr>
                    )
                )}
                </tbody>
            </table>
            <div className="actions">
                <button className="simple-icon-button" onClick={() => props.onClose()}>
                    <i className="fa fa-times"></i>
                </button>
                <button className="simple-icon-button" onClick={submitMatrixIfValid}>
                    <i className="fa fa-check"></i>
                </button>
            </div>
        </div>
    </div>
    );
}

export default InstrumentationInfoGrid;
