import React, { useEffect, useReducer, useState } from 'react';

import {
    AjaxNotification,
    GenericListStoreFactory,
    useListOrdering
} from 'orfeo_common/react-base.jsx';
import {fetchCsrfWrapper} from 'orfeo_common/react-base.jsx';
import { SelectInput } from 'orfeo_common/Widgets.jsx';
import * as FormUtils from 'orfeo_common/FormUtils.jsx';
import { SolistFunction } from 'orfeo/repertoire/jsx/Widgets.jsx';
import { isRepertoireReadOnly } from '../Utils.jsx';

const SolistsStore = GenericListStoreFactory('pk', 'order_index');


const SolistForm = props => {
    const {values, onChange, onKeyUp, onSubmit} = FormUtils.useForm(
        props.solist || {
            'instrument': null,
            'tessitura': null,
            'quantity': '1'
        },
        handleSave,
        props.onCancel
    );
    const [errors, setErrors] = FormUtils.useFormErrors();

    function onChangeFunction(val) {
        if (val && val._type == 'instrument') {
            onChange('instrument', val);
            onChange('tessitura', null);
        } else {
            onChange('tessitura', val);
            onChange('instrument', null)
        }
    }

    function handleSave() {
        let url = props.backendURL,
            method = 'POST';
        if(props.solist) {
            url += props.solist.pk + '/';
            method = 'PATCH'
        }

       fetchCsrfWrapper(
            url,
            {
                method: method,
                body: {
                    [props.parentKey]: {'pk': props.parent.pk},
                    'instrument': values.instrument,
                    'tessitura': values.tessitura,
                    'quantity': values.quantity
                }
            },
            {rejectOnError: true}
        ).then(
            data => props.onSave(data)
        ).catch(
            data => setErrors(data)
        )
    }

    return (
        <form className="react-inline-form" onSubmit={onSubmit} onKeyUp={onKeyUp} style={{ marginBottom: '10px' }}>
            <div className="row">
                <div className="col-md-5">
                    <SolistFunction sections={props.sections}
                        instrument={values.instrument}
                        tessitura={values.tessitura}
                        onChange={onChangeFunction} />
                    <FormUtils.ErrorText errors={errors} field="function" />
                </div>

                <div className="col-md-2">
                    <input
                        type="number" className="form-control input-sm text-end" min="0" step="1"
                        style={{'width': '100px'}} autoFocus required placeholder={trans.t("Effectif")}
                        name="quantity" value={values.quantity} onChange={onChange}
                    />
                    <FormUtils.ErrorText errors={errors} field="quantity" />
                </div>
            </div>
            <div className="actions text-end">
                <a className="btn btn-sm btn-outline-secondary" onClick={props.onCancel}>{trans.t("Annuler")}</a>&nbsp;
                <button type="submit" className="btn btn-sm btn-primary">{trans.t("Sauvegarder")}</button>
            </div>
        </form>
    )
}


const SolistLine = props => {
    const [editing, setEditing] = FormUtils.useFormEditing(false, isRepertoireReadOnly);

    const onDelete = ev => {
        if(confirm(trans.t("Êtes-vous sûr de vouloir supprimer ce rôle de soliste ?"))) {
            const notif_id = AjaxNotification.show(trans.t('Suppression...'));
            $.ajax({
                url: props.backendURL + props.solist.pk + '/', method: 'DELETE'
            }).success(
                data => SolistsStore.delete(props.solist.pk)
            ).error(
                data => setErrors(data.responseJSON)
            ).always(
                () => AjaxNotification.hide(notif_id)
            )
        }
    }

    if(editing) {
        return (
            <tr>
                <td colSpan="4">
                    <SolistForm
                        parent={props.parent}
                        parentKey={props.parentKey}
                        solist={props.solist}
                        sections={props.sections}
                        onSave={data => {
                            SolistsStore.update(data);
                            setEditing(false);
                        }}
                        onCancel={() => setEditing(false)}
                        backendURL={props.backendURL}
                    />
                </td>
            </tr>
        )
    }

    return (
        <tr className={!isRepertoireReadOnly() ? "inline-editable" : ""} onClick={setEditing} data-id={props.solist.pk}>
            {!isRepertoireReadOnly() &&
                <td className="reorder-icon" style={{'width': '45px'}}><i className="fa fa-bars"></i></td>
            }
            <td>{props.solist.function.name}</td>
            <td className="text-end" style={{'width': '75px'}}>{props.solist.quantity}</td>
            {!isRepertoireReadOnly() &&
            <td className="text-end" onClick={ev => ev.stopPropagation()} style={{'width': '50px'}}>
                <a role="button" onClick={onDelete}>
                    <i className="fa fa-trash"></i>
                </a>
            </td>}
        </tr>
    );
}


const SolistBlock = props => {
    const [adding, setAdding] = FormUtils.useFormEditing();

    const onListUpdate = lst => {
        props.onParentChange(
            Object.assign({}, props.parent, {instrumentation_solists: lst})
        );
    }
    useEffect(() => {
        // call removeListener before setList to avoid infinite loop
        SolistsStore.removeListener(onListUpdate);
        SolistsStore.setList(props.parent.instrumentation_solists);
        SolistsStore.addOnListChange(onListUpdate);
    }, [props.parent]);


    // Get DOM ref to allow re-ordering of the list
    const listRef = useListOrdering(
        props.backendURL + 'change_order/',
        {[props.parentKey]: props.parent.pk},
        newOrder => {
            // Update related order index in our local store
            var lines = SolistsStore.getList();
            for(var i = 0; i < lines.length; i++) {
                lines[i].order_index = newOrder.indexOf(lines[i].pk.toString())
            }
            SolistsStore.setList(lines);
        },
        true
    );

    return (
        <div>
            {props.parent.instrumentation_solists.length > 0 &&
                (<table className="table table-condensed table-striped" style={{'maxWidth': '600px'}}>
                    <thead>
                        <tr className="text-muted">
                            {!isRepertoireReadOnly() && <th></th>}
                            <th>{trans.t("Instrument")}</th>
                            <th>{trans.t("Effectif")}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody ref={listRef}>
                    {props.parent.instrumentation_solists.map(
                        solist => (
                            <SolistLine
                                parent={props.parent}
                                parentKey={props.parentKey}
                                solist={solist}
                                sections={props.sections}
                                key={solist.pk}
                                backendURL={props.backendURL}
                            />
                        )
                    )}
                    </tbody>
                </table>)
            }

            {adding ?
                <SolistForm
                    parent={props.parent}
                    parentKey={props.parentKey}
                    solist={null}
                    sections={props.sections}
                    onSave={data => {
                        SolistsStore.add(data);
                        setAdding(false);
                    }}
                    onCancel={() => setAdding(false)}
                    backendURL={props.backendURL}
                />
                :
                <p>
                    {!isRepertoireReadOnly() &&
                        <a className="action-link" onClick={() => setAdding(true)}>{trans.t("Ajouter un soliste")}</a>
                    }
                </p>
            }
        </div>
    )
}

export default SolistBlock;
