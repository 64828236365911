import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

/**
 * Display a tooltip on mouseover over the childrens
 *
 * title (string) : content of the tooltip
 * placement (left|top|bottom|right) (default 'top') => relative position of the tooltip
 * html (boolean) (default : false) => Compile html inside the tooltip
 * className (string) (default: '') => css classes to apply on the tooltip container
 * tagName (string) => html element to be used as tooltip container
 */
const BSTooltip = (props) => {
    const {
        title = '',
        className = '',
        placement = 'top',
        html = false,
        tagName = 'span',
        children,
        onClick,
        style = null,
    } = props
    const containerRef = useRef(null);

    useEffect(() => {
        const tp = new bootstrap.Tooltip(containerRef.current, {
            title: title || "",
            placement,
            html,
            trigger: 'hover',
            container: 'body',
            // Override template to apply custom class if defined in props
            template: `
            <div class="tooltip ${className} pe-none" role="tooltip">
                <div class="tooltip-arrow"></div>
                <div class="tooltip-inner"></div>
            </div>
            `
        });

        return () => {
            tp.dispose();
            // workaround for https://github.com/twbs/bootstrap/issues/37474
            tp._activeTrigger = {};
            tp._element = document.createElement('noscript');
        }
    }, [title, className, placement, html]);

    return React.createElement(
        tagName,
        {
            title,
            className,
            'ref': containerRef,
            'role': "tooltip",
            onClick,
            style,
        },
        children
    );
}

BSTooltip.propTypes = {
    html: PropTypes.bool,
    title: PropTypes.string.isRequired,
    placement: PropTypes.string,
    tagName: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
}


const OptionalTooltip = props => (
    props.title ? <BSTooltip {...props} /> : props.children
)

OptionalTooltip.propTypes = {
    html: PropTypes.bool,
    title: PropTypes.string,
    placement: PropTypes.string,
    tagName: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
}

export default BSTooltip;
export { OptionalTooltip };
