import React, { useState, useEffect } from 'react';

import { fetchCsrfWrapper } from 'orfeo_common/react-base.jsx';
import { SelectInput } from 'orfeo_common/Widgets.jsx';


const CurrencySelector = ({
    currency,
    org_profile,
    onChange,
    isReadOnly,
    showLoader,
}) => {
    const [loading, setLoading] = useState(true);
    const [currencies, setCurrencies] = useState([]);

    useEffect(() => {
        setLoading(true);

        fetchCsrfWrapper(
            '/backend/currency/organization_restricted/?org_profile=' + (org_profile || '')
        ).then(data => {
            setCurrencies(data)
            if(!currency)
                onChange(data[0])
        }).catch(
            error => alert(trans.t('Erreur lors de la récupération des devises'))
        ).finally(
            () => setLoading(false)
        );
    }, [org_profile])

    if(currencies.length > 1) {
        return (
            <SelectInput
                options={currencies}
                value={currency}
                required
                onChange={onChange}
                disabled={isReadOnly || false}
                objectRepresentation={obj => `${obj.symbol} ${obj.name}`}
            />
        );
    }

    if(loading && showLoader !== false) {
        return <i className="fa fa-spin fa-spinner"></i>;
    }

    return null;
}

export default CurrencySelector;
