import React, { useState } from "react";

import PropTypes from "prop-types";
import * as FormUtils from "orfeo_common/FormUtils.jsx";
import Modal from "orfeo_common/Modal.jsx";
import { fetchWrapper } from "orfeo_common/react-base.jsx";


const ResendElectronicSignatureModalProps = {
    // Contracts pks
    pks: PropTypes.arrayOf(PropTypes.number),
    // endpoint to viewset of type SignableDocumentViewSetMixin
    document_type: PropTypes.string,
    onCloseModal: PropTypes.func,
    onSuccess: PropTypes.func
};
const ResendElectronicSignatureModal = (props) => {
    const [errors, setErrors] = FormUtils.useFormErrors();
    const [displayPendingMessage, setDisplayPendingMessage] = useState(false);

    const onSend = () => {
        setDisplayPendingMessage(false);

        fetchWrapper("/backend/" + props.document_type + "/resend_electronic_signatures/", {
            method: "POST",
            body: {pks: props.pks},
        })
        .then(data => {
            // On success, acknowledge the user the action is currently running, then
            // close the modal after a while
            setDisplayPendingMessage(true);
            setTimeout(() => { props.onSuccess() }, 3000);
        })
        .catch(
            data => setErrors(data)
        );
    };

    return (
        <Modal show onHide={props.onCloseModal}>
            <Modal.Header>{trans.t("Relancer les demandes de signature")}</Modal.Header>
            <Modal.Body>
                <p>{trans.t("Êtes-vous sûr(e) de vouloir relancer les demandes de signature ?")}</p>
                {Object.keys(errors).length > 0 && (
                    <p className="react-form-errors-intro">
                        Erreur lors de la sauvegarde {FormUtils.getGlobalErrors(errors)}
                    </p>
                )}
                {displayPendingMessage &&
                <p className="text-success">
                    {trans.t(
                        "La relance est en cours de traitement. Le signataire devrait recevoir un nouvel "
                        + "e-mail d'ici quelques secondes"
                    )}
                </p>}
            </Modal.Body>
            <Modal.Footer>
                {displayPendingMessage ?
                    <a className="btn btn-outline-secondary" onClick={props.onCloseModal}>
                        {trans.t("Fermer")}
                    </a>
                    :
                    <>
                        <a className="btn btn-outline-secondary" onClick={props.onCloseModal}>
                            {trans.t("Annuler")}
                        </a>
                        <a className="btn btn-primary" onClick={onSend}>
                            {trans.t("Valider")}
                        </a>
                    </>
                }
            </Modal.Footer>
        </Modal>
    );
};
ResendElectronicSignatureModal.propTypes = ResendElectronicSignatureModalProps;

export default ResendElectronicSignatureModal;
