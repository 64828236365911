import React from 'react';

import * as FormUtils from 'orfeo_common/FormUtils.jsx';
import { SelectInput } from 'orfeo_common/Widgets.jsx';

const ReplacementContractSectionFields = props => {
    const obj = props.engagement;

    return (
        <React.Fragment>
            <div className="row">
                <p className="col-xs-3">
                    {trans.t("Musicien remplacé")}
                </p>
                <div className="col-xs-9">
                    <input
                        type="text" className="form-control input-sm" value={obj.substitute_name}
                        onChange={ev => props.onFieldChange('substitute_name', ev.target.value)}
                    />
                </div>
            </div>
            <div className="row">
                <p className="col-xs-3">
                    {trans.t("Motif absence")}
                </p>
                <div className="col-xs-9">
                    <input
                        type="text" className="form-control input-sm" value={obj.absence_reason}
                        onChange={ev => props.onFieldChange('absence_reason', ev.target.value)}
                    />
                </div>
            </div>
            <div className="row">
                <p className="col-xs-3">
                    {trans.t("Catégorie")}
                </p>
                <div className={"col-xs-9" + FormUtils.getCSSError(props.errors, 'category')}>
                    <SelectInput
                        backendURL="/backend/orchestraseatcategory/" value={obj.category}
                        onChange={instance => props.onFieldChange('category', instance)}
                    />
                    <FormUtils.ErrorText errors={props.errors} field='category' />
                </div>
            </div>
        </React.Fragment>
    )
}


export default ReplacementContractSectionFields;
