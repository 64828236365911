import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import {
    fetchCsrfWrapper,
    GenericListStoreFactory
} from 'orfeo_common/react-base.jsx';
import * as FormUtils from 'orfeo_common/FormUtils.jsx';
import { ForeignKeyField, SelectInput, CalendarInput, TimelineInput } from 'orfeo_common/Widgets.jsx';
import { getFromLocalStorage } from 'orfeo_common/utils/cache.jsx';

/** Date formats used with momentjs and bootstrap datepicker **/
const DATE_FORMAT = 'DD/MM/YYYY';

function showStructurePersonField() { return !!window['SC_VIEW_TYPE'] }
function get_opposite_entity(sp) {
    if(SC_VIEW_TYPE == 'structure')
        return sp.person;
    return sp.structure;
}

const TaskListContext = React.createContext();
const TasksStore = GenericListStoreFactory();

TasksStore.processItem = function (x) {
    x.due_date = moment(x.due_date);
    x.is_overdue = !x.is_template && !x.done && x.due_date.isBefore(new Date(), 'day');
    return x;
}
TasksStore.sort_function = (x, y) => {
    // Force done tasks to be at the end, regardless the date
    if(y['done'] && !x['done'])
        return -1;
    else if(!y['done'] && x['done'])
        return 1;

    // Otherwise, order tasks chronogically when undone, in the reverse order when both done
    let delta = y['due_date'].unix() - x['due_date'].unix();
    if(y['done'] && x['done'])
        return delta;

    return -delta;
}

/**
 * A task form can be included in place of a exiting element (as an updated form)
 * or be used at the bottom of the list. The class always has a parent, named
 * task in the props, if we are currently updating an existing object.
 */
const TaskForm = props => {
    const {values, onChange, onKeyUp, onSubmit} = FormUtils.useForm(
        Object.assign(
            {
                'title': '',
                'due_date': moment(),
                'due_date_offset': [],
                'assigned_to': USER_PROFILE,
                'is_private': false,
            },
            props.task || {}
        ),
        handleSave,
        props.onClose
    );
    const [errors, setErrors] = FormUtils.useFormErrors();
    const context = useContext(TaskListContext);

    function handleSave() {
        if(
            values.is_private
            && values.assigned_to?.pk != USER_PROFILE.pk
        ) {
            alert("Une tâche privée ne peut être assigné qu'à vous-même.");
            return;
        }
        let post_data = {
            'title': values.title,
            'is_template': props.is_template,
            'assigned_to': values.assigned_to,
            'is_private': values.is_private,
            'content_type': context.content_type // always needed, even in update method
        };
        if (props.is_template) {
            post_data.due_date_offset = values.due_date_offset;
        } else {
            post_data.due_date = values.due_date.format('YYYY-MM-DD');
        }
        if(showStructurePersonField())
            post_data['structure_person'] = {'pk': values.structure_person?.pk || null};

        let url = '/backend/task/', method = 'POST';
        if(!props.task) {
            post_data['object_id'] = context.object_id;
        }
        else {
            url += props.task.pk + '/';
            method = 'PATCH'
        }

        fetchCsrfWrapper(
            url, {method: method, body: post_data}
        ).then(data => {
            props.onClose();
            TasksStore.update(data);
        }).catch(
            data => setErrors(data)
        );
    }

    const onDelete = ev => {
        if(confirm('Êtes-vous sûr de vouloir supprimer cette tâche ?')) {
            fetchCsrfWrapper(
                `/backend/task/${props.task.pk}/`,
                {method: "DELETE"}
            ).then(
                data => TasksStore.delete(props.task.pk)
            ).catch(
                data => alert("Erreur lors de la suppression de la tâche")
            );
        }
    }

    return (
        <form className="form react-inline-form" id="task-form" onSubmit={onSubmit} onKeyUp={onKeyUp}>
            {Object.keys(errors).length > 0 &&
                <p className="react-form-errors-intro">
                    Erreur lors de la sauvegarde. {FormUtils.getGlobalErrors(errors)}
                </p>
            }
            <div className="row">
                <div className="col">
                    <input
                        type="text" className="form-control input-sm" placeholder="Libellé" maxLength="100"
                        autoFocus name="title" value={values.title} onChange={onChange}
                    />
                    <FormUtils.ErrorText field="title" errors={errors} />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {props.is_template ?
                        <TimelineInput value={[values.due_date_offset]} onChange={val => onChange('due_date_offset', val[0])} />
                        :
                        <CalendarInput
                            defaultValue={values.due_date.format("YYYY-MM-DD")}
                            onChange={val => onChange('due_date', moment(val))}
                            quickButtons={[
                                {colspan: 3, offset: 1, label: "Demain"},
                                {colspan: 2, offset: 7},
                                {offset: 14},
                                {offset: 30},
                            ]}
                        />
                    }
                    <FormUtils.ErrorText field="due_date" errors={errors} />
                </div>

                <div className="col">
                    <SelectInput
                        backendURL={"/backend/user/?is_active=true&account_type=" + context.account_types}
                        placeholder="Non attribuée"
                        labelKey="display_name"
                        value={values.assigned_to}
                        onChange={val => onChange('assigned_to', val)}
                        disabled={ACCOUNT_TYPE != "standard" || values.is_private}
                        disableClientFiltering
                    />
                </div>
            </div>

            <div className="actions text-end">
                <div className="float-start">
                {props.task != null &&
                    <a className="btn btn-danger" onClick={onDelete}>Supprimer</a>
                }
                {showStructurePersonField() &&
                <div style={{'maxWidth': '200px', 'display': 'inline-block', 'marginRight': '10px'}}>
                    <ForeignKeyField
                        backend_url={`/backend/structureperson/?is_archived=false&${SC_VIEW_TYPE}=${context.object_id}`}
                        objectRepresentation={x => get_opposite_entity(x).name}
                        blank_choice_label="Associée à..."
                        defaultValue={values.structure_person}
                        onChange={(pk, val) => onChange('structure_person', val)}
                        allowValueOutOfList={true}
                    />
                </div>}

                {(!props.task || props.task.created_by?.pk == USER_PROFILE.PK) &&
                    <div style={{'display': 'inline-block'}} title="Cette tâche ne sera visible que par vous.">
                        <input
                            type="checkbox" id="task_is_private"
                            checked={values.is_private || false}
                            name="is_private"
                            onChange={ev => {
                                onChange('is_private', ev.target.checked)
                                if(ev.target.checked)
                                    onChange('assigned_to', USER_PROFILE)
                            }}
                        />&nbsp;
                        <label htmlFor="task_is_private">Privée</label>
                    </div>
                }
                </div>

                <a className="btn btn-outline-secondary" onClick={props.onClose}>Annuler</a>&nbsp;
                <button type="submit" className="btn btn-primary">Sauvegarder</button>
            </div>
        </form>
    );
}


const Task = ({task, object_id}) => {
    const [editing, setEditing] = FormUtils.useFormEditing(false);

    const onCheckboxClick = ev => {
        // Avoid the opening of the edit form when you change the status of a task
        if(FormUtils.isReadOnly())
            return;

        fetchCsrfWrapper(
            `/backend/task/${task.pk}/`,
            {method: "PATCH", body: {'done': ev.target.checked}}
        ).then(
            data => TasksStore.update(data)
        ).catch(
            data => alert("Erreur lors de l'enregistrement de la tâche")
        );
    }

    if(editing)
        return (
            <li>
                <TaskForm task={task} onClose={() => setEditing(false)} is_template={task.is_template}/>
            </li>
        );

    let offset = task.due_date_offset;
    if (offset && offset >0) {
        offset = `+${offset}`
    }
    return (
        <li className={task.done ? "done" : ""} onClick={() => setEditing(true)}>
            {task.assigned_to &&
                <span className="assigned-to">
                    {task.is_private && <i className="fa fa-lock"></i>}&nbsp;
                    {task.structure_person &&
                        <span>
                            Associée à&nbsp;
                            <a href={get_opposite_entity(task.structure_person).absolute_url} onClick={ev => ev.stopPropagation()}>
                                {get_opposite_entity(task.structure_person).name}
                            </a>&nbsp;/ &nbsp;
                        </span>
                    }
                    {task.assigned_to.display_name}
                </span>
            }
            <input
                type="checkbox" className="done-box"
                checked={task.done} readOnly={FormUtils.isReadOnly()}
                onChange={onCheckboxClick} onClick={ev => ev.stopPropagation()}
            />

            <span className={classNames('badge', {
                'bg-danger': task.is_overdue,
                'bg-info': !task.is_overdue && !task.done,
                'bg-secondary': task.done
             })}>
                {task.is_template ? "J" + offset : task.due_date.format(DATE_FORMAT)}
            </span>
            &nbsp;
            <span className={task.done ? "done" : ""}>{task.title}</span>
        </li>
    );
}

const STORAGE_PREFERENCES_KEY = 'tasks-filter-preference';
const TASKS_PER_PAGE = 15;

const TaskList = props => {
    // Loads preference in cache
    let pref_cache = getFromLocalStorage(STORAGE_PREFERENCES_KEY);
    // State management
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [editing, setEditing] = FormUtils.useFormEditing();
    const [last_filter_change, setLastFilterChange] = useState(moment());
    const [types_shown, setTypesShown] = useState(pref_cache ? JSON.parse(pref_cache) : {'done': false, 'todo': true})
    // Sync tasks with the store and load initial list
    useEffect(() => {
        TasksStore.addOnListChange(lst => setTasks(lst));
        fetchCsrfWrapper(
            '/backend/task/?object_id=' + props.object_id + '&content_type=' + props.content_type
        ).then(data => {
            setLoading(false);
            TasksStore.setList(data)
        }).catch(
            data => console.error("Impossible de charger les tâches")
        );
    }, []);

    // Switch the boolean of a type in the dict `type_shown`
    const switchTypeShow = name => {
        let new_types_shown = Object.assign(types_shown, {[name]: !types_shown[name]});
        window.localStorage.setItem(STORAGE_PREFERENCES_KEY, JSON.stringify(new_types_shown));
        setTypesShown(new_types_shown);
        setLastFilterChange(moment());
        setCurrentPage(1);
    }
    // Helper to display the filter with different style if it's checked or not
    const getTypeLink = (name, label, nb) => {
        let shown = !!types_shown[name];
        return (
            <a onClick={ev => switchTypeShow(name)} className={shown ? 'active': ''}>
                <i className={'fa-regular fa-' + (shown ? "check-square" : "square")}></i>&nbsp;
                {label} {!!nb && `(${nb})`}
            </a>
        );
    }

    let nb_done = null, nb_todo = null;
    if(tasks) {
        nb_done = tasks.filter(x => x.done).length;
        nb_todo = tasks.length - nb_done;
    }
    const should_display_filters = !!nb_done;
    let shown_tasks = tasks;
    if(should_display_filters) {
        shown_tasks = shown_tasks.filter(
            task => (
                (task.done && types_shown['done'])
                || (!task.done && types_shown['todo'])
                || moment(task.update_date).isAfter(last_filter_change)
            )
        );
    }

    let nb_pages = Math.ceil(shown_tasks.length / TASKS_PER_PAGE);
    shown_tasks = shown_tasks.slice(
        (currentPage - 1) * TASKS_PER_PAGE,
        currentPage * TASKS_PER_PAGE
    )

    return (
        <TaskListContext.Provider value={{
            'object_id': props.object_id,
            'content_type': props.content_type,
            'account_types': props.account_types
        }}>
            {should_display_filters ?
            /* Display filters only when there're tasks to filter */
            <div className="float-end">
                Afficher :
                <div className="inline-type-switcher" role="group">
                    {getTypeLink('todo', 'À faire', nb_todo)}
                    {getTypeLink('done', 'Effectuées', nb_done)}
                </div>
            </div> : null}

            <h4>
                Tâches
                {(!editing && !FormUtils.isReadOnly()) &&
                    <a className="add-link action-link" onClick={setEditing}>Ajouter</a>
                }
            </h4>
            {editing &&
                <TaskForm task={null} onClose={() => setEditing(false)} is_template={props.is_template}/>
            }
            {!loading ?
                <ul className="tasks">
                {shown_tasks.map(
                        (task, index) => (
                            <Task task={task} key={task.pk + task.update_date}/>
                        )
                    )
                }
                </ul>
            :
                <i className="fa fa-spin fa-spinner"></i>
            }

            {nb_pages > 1 &&
            <div>
                <nav aria-label="Page navigation">
                  <ul className="pagination pagination-sm">
                    <li className={currentPage == 1 ? "disabled": ""}>
                      <a role="button" onClick={ev => setCurrentPage(Math.max(1, currentPage - 1))}>&laquo;</a>
                    </li>
                    <li><a href="#">{currentPage} / {nb_pages}</a></li>
                    <li className={currentPage == nb_pages ? "disabled": ""}>
                      <a role="button" onClick={ev => setCurrentPage(Math.min(currentPage + 1, nb_pages))}> &raquo;</a>
                    </li>
                  </ul>
                </nav>
            </div>
            }
        </TaskListContext.Provider>
    );
}


export default TaskList;
