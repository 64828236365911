import React, { useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';

import {
    fetchCsrfWrapper,
    hasPerm,
    getModalWrapper,
    get_pretty_dates
} from 'orfeo_common/react-base.jsx';
import { useFormEditing } from 'orfeo_common/FormUtils.jsx';
import { CalendarInput } from 'orfeo_common/Widgets.jsx';
import { getDPAEStateIcon } from 'orfeo/administration/jsx/employeeengagement/Utils.jsx';
import Tooltip from 'orfeo_common/Tooltip.jsx';

/* DPAE can be done starting from eight calendar days before the beginning of the engagement */
function getDPAEDueDate(engagement) {
    return moment(engagement.start_date).subtract(8, 'days');
}

function getAPIState(dpae_info) {
    let status = dpae_info.api_status;
    if(status == -1)
        return (
            <p>
                {getDPAEStateIcon('error')} {trans.t("Erreur lors de l'envoi")} ({dpae_info.api_notes})
            </p>
        );

    if(status == 0)
        return (
            <p>
                {getDPAEStateIcon('pending')} {trans.t("Envoi planifié à l'URSSAF")}
            </p>
        );

    if(status == 1)
        return (
            <p>
                {getDPAEStateIcon('pending')} {trans.t("Traitement en cours par l'URSSAF")}
            </p>
        );

    if(status == 2) {
        // There was a response from the URSSAF, but we need to check if it is valid or an error
        let intro;

        const [urssaf_code, urssaf_label] = dpae_info.urssaf_response_code;
        if(urssaf_code == "00")
            intro = <React.Fragment>{getDPAEStateIcon('success')} {trans.t("Validée par l'URSSAF")}</React.Fragment>
        else if(urssaf_code == "98")  // 98 response code is special "error" code when a DPAE was already validated in the past
            intro = (
                <React.Fragment>
                    {getDPAEStateIcon('success')} {trans.t("Validée par l'URSSAF")}&nbsp;
                    <Tooltip title={trans.t("L'URSSAF a déclaré avoir déjà accusé réception de cette DPAE auparavant.")}>
                        <small className="text-muted">({trans.t("envoi en double détecté")})</small>
                    </Tooltip>
                </React.Fragment>
            )
        else
            intro = <React.Fragment>{getDPAEStateIcon('error')} {urssaf_code} - {urssaf_label}</React.Fragment>

        return (
            <div>
                {intro}
                <div className="text-muted">
                    {trans.t(
                        "Accusé réception de l'URSSAF le {{date}}",
                        {date: moment(dpae_info.urssaf_confirmation_date).locale('fr').format('LLL')}
                    )}<br />
                    {dpae_info.urssaf_reference && (trans.t("Référence du dossier : ") + dpae_info.urssaf_reference)}
                </div>
            </div>
        );
    }
    return trans.t("État inconnu");
}


const ManualEditingForm = props => {
    const [sendingDate, setSendingDate] = useState(props.dpae ? props.dpae.sending_date : null);
    const [urssafRef, setUrssafRef] = useState(props.dpae ? props.dpae.urssaf_reference : '');

    const onSubmit = ev => {
        ev.preventDefault();

        let url = '/backend/employeedpaeinfos/', method = 'POST';
        let post_data = {
            'type_dpae': 'manual',
            'sending_date': sendingDate,
            'urssaf_reference': urssafRef,
        };
        // Sending date is a datetime field for case where we're dealing with the API.
        // Just suppose here the DPAE has been sent at midnight.
        if(sendingDate && !sendingDate.includes('T00:00:00')) {
            post_data['sending_date'] += 'T00:00:00';
        }

        // Get the manual DPAE object already existing, if any
        if(!!props.dpae) {
            url += props.dpae.pk + '/';
            // In case the DPAE was previously specified and has been cleared, we delete the object
            method = post_data['sending_date'] ? 'PATCH' : 'DELETE';
        }
        else {
            // on initial creation, specifiy the engagement
            post_data['engagement'] = props.engagement.pk;
        }
        fetchCsrfWrapper(
            url, {method: method, body: post_data}
        ).then(data => {
            props.onChange(method == 'DELETE' ? 'delete' : 'update', data);
            props.onClose();
        }).catch(
            data => console.error(data || trans.t('Erreur lors de la sauvegarde'))
        );
    };

    return (
        <div className="react-inline-form">
            <div className="row mb-2">
                <label className="col-form-label col-2">{trans.t("Effectuée le")}</label>
                <div className="col-4">
                    <CalendarInput
                        defaultValue={sendingDate}
                        required={false}
                        onChange={val => setSendingDate(val ? val.format('YYYY-MM-DD') : null)}
                    />
                </div>
                <label className="col-form-label col-3">{trans.t("N° de référence")}</label>
                <div className="col-3">
                    <input
                        type="text" value={urssafRef} className="form-control"
                        onChange={ev => setUrssafRef(ev.target.value)}
                    />
                </div>
            </div>
            <p className="text-end">
                <a className="btn btn-outline-secondary" onClick={props.onClose}>{trans.t("Annuler")}</a>&nbsp;
                <button className="btn btn-primary" onClick={onSubmit}>{trans.t("Sauvegarder")}</button>
            </p>
        </div>
    );
};


const SendDPAEThroughAPIForm = props => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    const onSend = ev => {
        ev.stopPropagation();

        setLoading(true);
        fetchCsrfWrapper(
            '/backend/employeedpaeinfos/make_api_declaration/',
            {method: 'POST', body: {'engagement': props.engagement.pk}}
        ).then(data => {
            props.onChange(data)
            props.onClose();
        }).catch(data => {
            setErrors(data);
            // no need to reset Loading in always since the form should be closed on success
            setLoading(false);
        });
    }

    return (
        <div style={{'width': '90%', 'margin': 'auto'}}>
            <p className="text-center">
                {trans.t("Êtes-vous sûr de vouloir envoyer la déclaration à l'URSSAF ?")}<br />
                {trans.t("Le début de contrat sera déclaré au {{date}}.", {date: get_pretty_dates(moment(props.engagement.start_date).locale('fr'))})}<br /><br />

                <a className="btn btn-primary" onClick={onSend} disabled={loading}>
                    {loading &&
                        <i className="fa fa-spin fa-spinner"></i>
                    }
                    &nbsp;{trans.t("Envoyer la DPAE")}
                </a>
            </p>

            {errors &&
                <React.Fragment>
                    <div className="text-danger">
                        <p>
                            {trans.t("Des erreurs sont apparues avant l'envoi de la DPAE. Veuillez les corriger avant de réessayer")} :
                        </p>

                        <div style={{'whiteSpace': 'pre-line'}}>{errors['message']}</div>
                        {errors['redirect_url'] &&
                            <a href={errors['redirect_url']} target="_blank">{trans.t("Accéder à la fiche")}</a>
                        }
                    </div>
                </React.Fragment>
            }
        </div>
    )
}


const EmployeeDPAEInfosView = props => {
    const [formDisplayed, setFormDisplayed] = useFormEditing();

    let dpaes = props.engagement.dpae_infos.slice();
    dpaes.sort((x, y) => x.pk - y.pk); // order them the first as the oldest
    const last_dpae = props.engagement.last_dpae_infos;

    let editing_form;
    if(formDisplayed == 'manual') {
        editing_form = (
            <ManualEditingForm
                engagement={props.engagement}
                dpae={last_dpae}
                onChange={(action, data) => props.onEngagementChange(
                    Object.assign({}, props.engagement, {
                        'dpae_infos': action == 'delete' ? [] : [data],
                        'last_dpae_infos': action == 'delete' ? null : data
                    })
                )}
                onClose={() => setFormDisplayed(false)}
            />
        )
    }
    else if(formDisplayed == 'api') {
        editing_form = (
            <SendDPAEThroughAPIForm
                engagement={props.engagement}
                onChange={data => props.onEngagementChange(
                    Object.assign({}, props.engagement, {
                        'dpae_infos': [data].concat(props.engagement.dpae_infos),
                        'last_dpae_infos': data
                    })
                )}
                onClose={() => setFormDisplayed(false)}
            />
        );
    }

    // No DPAE info available, display a message warning when it should be done and action links
    if(!last_dpae) {
        let due_date = getDPAEDueDate(props.engagement);
        let days_remaining = moment(props.engagement.start_date).diff(moment(), 'days');

        let warning_msg;
        if(days_remaining < 0)
            warning_msg = <p className="alert alert-danger">
                {trans.t("Cet engagement est dans le passé, vérifiez la cohérence des informations d'engagement.")}
            </p>
        else if(days_remaining < 8)
            warning_msg = <p className="alert alert-info">
                {trans.t("Cet engagement est dans moins de 8 jours, la DPAE peut être effectuée.")}
            </p>


        let main_part = (
            <React.Fragment>
                <p>
                    {getDPAEStateIcon('todo')}&nbsp;
                    {trans.t("À effectuer à partir du {{date}}.", {date: get_pretty_dates(due_date.locale('fr'))})}
                </p>
                {warning_msg}

                {dpaes.length > 0 &&
                    <div>
                        {trans.t("Une déclaration a été faite auparavant mais n'est plus valide suite aux modifications faites sur l'engagement")} :
                        <div style={{'borderLeft': '4px solid #ddd', 'opacity': '0.75', 'paddingLeft': '8px', 'margin': '5px 0'}}>
                            <strong>{trans.t("Le {{date}}", {date: get_pretty_dates(moment(dpaes[0].sending_date).locale('fr'))})}</strong><br />
                            {getAPIState(dpaes[0])}
                        </div>
                    </div>
                }
            </React.Fragment>
        )
        if(props.display_last_only)
            return main_part;

        return (
            <div>
                <h4>{trans.t("État actuel")}</h4>
                {main_part}
                <hr style={{'margin': '10px 0'}} />
                {
                    editing_form ||
                    <p className="text-center">
                        {hasPerm('administration.allow_dpae_through_api') &&
                        <span style={{'marginRight': '25px'}}>
                            <a className="action-link" onClick={() => setFormDisplayed('api')}>
                                {trans.t("Envoyer la DPAE à l'URSSAF")}
                            </a>
                        </span>
                        }
                        <a className="action-link" onClick={() => setFormDisplayed('manual')}>
                            {trans.t("Marquer la DPAE comme effectuée")}
                        </a>
                    </p>
                }
            </div>
        );
    }

    // DPAE has been done manually = no interaction with our API. Just display the sending date
    else if(last_dpae.type_dpae == 'manual') {
        let main_part = (
            <p>
                {getDPAEStateIcon('success')}&nbsp;
                {trans.t("Marquée comme effectuée le {{date}}", {date: get_pretty_dates(moment(last_dpae.sending_date).locale('fr'))})}
                <br />
                {last_dpae.urssaf_reference && <span>{trans.t("Numéro de référence")} : {last_dpae.urssaf_reference}</span>}
            </p>
        );
        if(props.display_last_only)
            return main_part;

        return (
            <div>
                <h4>{trans.t("État actuel")}</h4>
                {main_part}
                {editing_form ?
                    editing_form
                    :
                    <a role="button" className="action-link" onClick={() => setFormDisplayed('manual')}>
                        {trans.t("Modifier la date de déclaration")}
                    </a>
                }
            </div>
        );
    }

    // We fall in the case where the last DPAE is sent through the API.
    return (
        <div>
            {!props.display_last_only && <h4>{trans.t("État actuel")}</h4>}
            {getAPIState(last_dpae)}

            {(!props.display_last_only && dpaes.length > 1) &&
            <div style={{'marginTop': '25px', 'borderTop': '1px solid #ddd'}}>
                <h4>{trans.t("Anciennes tentatives")}</h4>

                {dpaes.reverse().slice(1).map(
                    (dpae_info, idx) => {
                        return <div key={dpae_info.pk}>
                            <strong>
                                {trans.t("Le {{date}}", {date: get_pretty_dates(moment(dpae_info.sending_date).locale('fr'))})}
                            </strong><br />
                            {getAPIState(dpae_info)}
                        </div>
                    }
                )}
            </div>}
            {!props.display_last_only &&
            <React.Fragment>
                <hr style={{'margin': '15px 0'}} />
                {editing_form || (
                    // Allow another sending only if no DPAE have been is pending right now
                    ([-1, 2].indexOf(last_dpae.api_status) !== -1) &&
                        <p>
                            <a className="action-link" onClick={() => setFormDisplayed('api')}>
                                {trans.t("Renvoyer la DPAE à l'URSSAF")}
                            </a>
                        </p>
                    )
                }
            </React.Fragment>
            }
        </div>
    );
}


export default EmployeeDPAEInfosView;
