import React, { useReducer, useState } from 'react';
import { SelectInput } from 'orfeo_common/Widgets.jsx';
import * as FormUtils from 'orfeo_common/FormUtils.jsx';


function initMatrix(group, entry) {
    // Initialize a matrix of `false` values as if nothing was checked
    let new_matrix = [];
    for(let i = 0; i < group.instruments.length; i++) {
        new_matrix.push(Array(entry.quantity).fill(false));
    }
    // Fill the matrix with `true` values when the tuple (instr_id, rank) exists
    let instruments_pks = group.instruments.map(x => x.pk);
    for(let item of entry.instruments) {
        new_matrix[instruments_pks.indexOf(item.instrument)][item.rank] = true;
    }
    // If no instruments have been specified yet, we need to check by default
    // the main one for each musician
    if(entry.instruments.length == 0) {
        for(let i = 0; i < entry.quantity; i++) { // the first line should be the default instrument
            new_matrix[0][i] = true;
        }
    }

    return new_matrix;
}

const InstrumentLine = props => {
    let values = props.line || {'quantity': 1};
    const instrumentSelected = (
        values.instrument ?
            props.group.instruments.filter(x => x.pk == values.instrument)[0]
        :
            null
    );

    return (
    <div className="d-flex wiginfo-list-line">

            <SelectInput
                options={props.instrumentsAvailables} autoFocus={!props.line}
                value={instrumentSelected}
                onChange={instance => {
                    props.onChange(Object.assign(values, {'instrument': instance ? instance.pk : null}))
                }}
                onClear={
                    // Empty line can't be deleted so ignore clear action
                    () => props.onDelete ? props.onDelete() : null
                }
                ignoreAccents
            />

        <div className="input-group" style={{ marginLeft: '5px' }}>
            <input
                type="number" className="form-control input-sm text-end"
                disabled={!props.line} value={values.quantity}
                onChange={ev => props.onChange(
                    Object.assign(values, {'quantity': ev.target.value})
                )}
            />
        </div>
        <div className="input-group" style={{ marginLeft: '5px' }}>
            <input
                type="text" className="form-control input-sm"
                disabled={!props.line} value={values.notes} maxLength="100"
                onChange={ev => props.onChange(
                    Object.assign(values, {'notes': ev.target.value})
                )}
            />
        </div>
        {!!props.line && <div className="input-group text-center">
            <a className="p-2" role="button" onClick={() => props.onDelete()}>
                <i className="fa fa-trash"></i>
            </a>
        </div>}
    </div>
    )
}


function reducer(lines, action) {
  switch (action.type) {
    case 'add':
        return lines.concat([action.line]);
    case 'update':
        let new_lines = lines.slice();
        new_lines[action.index] = action.line;
        return new_lines;
    case 'delete': {
        let new_lines = lines.slice();
        new_lines.splice(action.index, 1);
        return new_lines;
    }
    default:
        throw new Error();
  }
}


const InstrumentationInfoList = props => {
    const [errors, setErrors] = FormUtils.useFormErrors({});
    const instrumentsCopy = props.entry?
        props.entry.instruments.map(i =>
            ({ pk: i.pk, instrument: i.instrument, work_group: i.work_group, rank: i.rank, quantity: i.quantity, notes:i.notes })
        )
        : [];
    const [lines, dispatch] = useReducer(reducer, instrumentsCopy);

    const instrumentsSelected = lines.map(info => info.instrument);
    const instrumentsAvailables = props.group.instruments.filter(instr => instrumentsSelected.indexOf(instr.pk) === -1);

    const onSubmit = () => {
        const linesWithoutInstr = lines.filter(l => !l.instrument);
        if (linesWithoutInstr.length > 0){
            setErrors({'__all__': [trans.t('Chaque ligne doit avoir un instrument et une quantité.')]});
            return;
        }
        let cpt = 1;
        for (let line of lines) {
            line.order_index = cpt;
            cpt++;
        }
        props.onSubmit(lines, data => setErrors(data.responseJSON));
    }

    return (
    <div className="group-instrument-info-container">
        <div className="group-instrument-list">
            {lines.map(
                (line, idx) => (
                    <InstrumentLine
                        key={line.instrument || 'empty-'+idx}
                        line={line}
                        group={props.group}
                        instrumentsAvailables={instrumentsAvailables}
                        onDelete={item => dispatch({type: 'delete', index: idx})}
                        onChange={item => dispatch({type: 'update', index: idx, line: item})}
                    />
                )
            )}
            <InstrumentLine
                key={'add'+lines.length}
                group={props.group}
                instrumentsAvailables={instrumentsAvailables}
                line={null}
                onChange={item => dispatch({type: 'add', line: item})}
            />
            <FormUtils.ErrorText errors={errors} field="instruments"/>

            {Object.keys(errors).length > 0 &&
                <p className="react-form-errors-intro">
                    {trans.t("Erreur à la sauvegarde.")} {FormUtils.getGlobalErrors(errors)}
                </p>
            }

            <div className="actions">
                <button className="simple-icon-button" onClick={() => props.onClose()}>
                    <i className="fa fa-times"></i>
                </button>
                <button className="simple-icon-button" onClick={onSubmit}>
                    <i className="fa fa-check"></i>
                </button>
            </div>
        </div>
    </div>
    );
}

export default InstrumentationInfoList;
