import React from 'react';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

import Tooltip from 'orfeo_common/Tooltip.jsx';
import { floatFormat, GenericListStoreFactory } from 'orfeo_common/react-base.jsx';
import { MEAL_TIMES } from 'orfeo/production/jsx/Constants.jsx';

const ATTENDANCE_EXPORT_URL = '/calendar/planning/export/excel-attendances/'
/**
 * Returns a promise loading non working periods
 */
const loadNonWorkingPeriods = (start_date, end_date) => {
    return new Promise((resolve, reject) => {
        $.get({
            url: (
                '/calendar/non-working-periods/'
                 + '?start_date=' + start_date.format('YYYY-MM-DD')
                 + '&end_date=' + end_date.format('YYYY-MM-DD')
            ), cache: true
        }).then(data => {
            resolve(data);
        }).fail(
            jqXHR => reject(jqXHR.responseJSON || null)
        );
    });
}


/**
 * Returns array of moment object of days in the range given, augmented
 * with three additional attributes to handle non working periods display
 * in plannings
 */
const getDaysWithNonWorkingPeriods = (start_date, end_date, non_working_periods) => {
    // Return a range of day featuring the holidays informations
    let days = Array.from(moment.range(start_date, end_date).by("days"));
    let holidays = non_working_periods['holidays'] || [];
    let day_offs = non_working_periods['day_offs'] || [];
    days.forEach(day => {
        day.holidays = holidays.filter(
            holiday => (
                holiday.type === "holiday"
                && day.isBetween(holiday.start_date, holiday.end_date, null, "[]")
            )
        );
        day.day_off = day_offs.filter(
            doff => day.isSame(doff.start_datetime, "day")
        );

        day.national_holiday = holidays.filter(holiday => {
            let holiday_obj = moment(holiday.start_date)
            if(holiday.is_recurrent)
                holiday_obj = holiday_obj.year(day.year())

            return (
                holiday.type === "national_holiday"
                && day.isSame(holiday_obj, "day")
            );
        });
    })
    return days;
}


const getHolidayRuban = (holidays) => {
    if(!holidays) return null;

    // Get holiday where the zone is specified
    let zones = holidays.reduce((zones, holiday) => {
        if(holiday.zone !== null){
            zones[holiday.zone] = holiday.name
        }
        return zones;
    }, {})

    // Color all the ruban if there's no zone specified
    if(holidays[0] && Object.keys(zones).length == 0){
        zones = (window['HOLIDAY_CONFIGURATION']?.school_zones || ["A", "B", "C"]).reduce((acc, key) => {
            acc[key] = holidays[0].name
            return acc
        }, {})
    }
    if(!holidays[0])
        return null;

    return (
    <div className="planning-holiday-ruban-container">
        <Tooltip tagName='div' title={holidays[0].name} placement="right">
            {['A', 'B', 'C'].map(zone => (
            <div
                key={zone} className={
                    "planning-holiday-ruban "
                    + (Object.keys(zones).includes(zone) ? 'holiday-zone-' + zone : "non-colored'")
                }>
            </div>
            ))}
        </Tooltip>
    </div>
    );
}

const getEventOverlapWarning = (currentDate, event) => {
    if(!event.start_datetime || event.activity_type == 'accommodation'){
        return null;
    }

    let text = null;
    if(event.start_datetime.day() != moment(currentDate).day()){
        text = trans.t("Cet événement se déroule le lendemain");
    } else if (event.end_datetime.day() != moment(currentDate).day()){
        text = trans.t("Cet événement se finit le lendemain");
    }

    if(text){
        return <Tooltip title={text} tagName="sup">&nbsp;J+1</Tooltip>
    }
    return null;
}

const jsonDurationToTimeInput = (duration_string) => (duration_string || "").substring(0, 5);
const timeInputToJsonDuration = (duration_string) => moment(duration_string, 'HH:mm').format('HH:mm:ss');

/**
 * Take the configuration of daily slots of the organization, and moment object representing a time
 * and return in which slot this time is contained
 */
function getTimeDailySlot(dailySlotConfiguration, start_datetime){
    let computedSlot = null;
    // Only compare times
    const time = moment().set({
        hour: start_datetime.hours(),
        minute: start_datetime.minutes(),
        second: 0,
        millisecond: 0,
    });
    if(dailySlotConfiguration && time.isValid()){
        const startOfDay = moment(dailySlotConfiguration.morning_starting_hour, 'HH:mm');
        const midnight = moment().set({hour:0,minute:0,second:0,millisecond:0})
        if(time.isSameOrAfter(midnight) && time.isBefore(startOfDay)){
            time.add(1, 'day')
        }
        const getRange = (start, end) => {
            let momentStart = moment(start, 'HH:mm');
            let momentEnd = moment(end, 'HH:mm');

            if(momentStart.isSameOrAfter(midnight) && momentStart.isBefore(startOfDay)){
                momentStart.add(1, 'day')
            }
            if(momentEnd.isSameOrAfter(midnight) && momentEnd.isSameOrBefore(startOfDay)){
                momentEnd.add(1, 'day')
            }
            return moment.range(momentStart, momentEnd);
        }

        const dailySlotsRange = {
            'morning': getRange(
                dailySlotConfiguration.morning_starting_hour,
                dailySlotConfiguration.afternoon_starting_hour
            ),
            'afternoon': getRange(
                dailySlotConfiguration.afternoon_starting_hour,
                dailySlotConfiguration.evening_starting_hour,
            ),
            'evening': getRange(
                dailySlotConfiguration.evening_starting_hour,
                dailySlotConfiguration.night_starting_hour
            ),
            'night': getRange(
                dailySlotConfiguration.night_starting_hour,
                dailySlotConfiguration.morning_starting_hour
            )
        }

        for(const [slot, range] of Object.entries(dailySlotsRange)){
            if(range.contains(time, {excludeStart: false, excludeEnd: true})){
                computedSlot = slot;
                break;
            }
        }
    }

    return computedSlot;
}

function datetimes_overlap(a, b) {
    return (
        a["start_datetime"].isBefore(b["end_datetime"], "minute") &&
        a["end_datetime"].isAfter(b["start_datetime"], "minute")
    );
}

function roundToNearestXMinutes(minutes, datetime){
    datetime.minute() > parseInt(60-minutes/2) && datetime.add(1, 'hours');
    datetime.minutes((Math.round(datetime.minute()/minutes) * minutes) % 60);
    return datetime
}

const PUBLICATION_STATUS = {
    'published' : {label: trans.t("Publié"), icon_class: "fa-eye", classname: "bg-success-subtle text-success"},
    'unpublished': {label: trans.t("Non publié"), icon_class: "fa-eye-slash", classname: "text-secondary"},
    'publish' : {label: trans.t("Publier"), icon_class: "fa-eye", classname: "text-primary bg-primary-subtle"},
    'unpublish': {label: trans.t("Dépublier"), icon_class: "fa-eye-slash", classname: "unpublish text-secondary"},
}

const getMealFromDatetime = (start_datetime) => {
    // Only compare times
    const time = moment().set({
        hour: start_datetime.hours(),
        minute: start_datetime.minutes(),
        second: 0,
        millisecond: 0,
    });
    const lunch_lower_time = moment().set({
        hour: 11,
        minute: 0,
        second: 0,
        millisecond: 0,
    });
    const lunch_upper_time = moment().set({
        hour: 17,
        minute: 0,
        second: 0,
        millisecond: 0,
    });
    if(time.isValid()){
        if(time.isBefore(lunch_lower_time)){
            return 'breakfast'
        }
        if(time.isSameOrAfter(lunch_upper_time)){
            return 'dinner'
        }
    }
    return 'lunch';
}
const getMealFieldFromStartDatetime = (start_datetime) => {
    const meal = getMealFromDatetime(start_datetime)
    return [meal, MEAL_TIMES[meal].label]
}
const getParticipationForEvent = (event, technician, need, external_working_ressource) => {
    const field = technician ? 'entity' : external_working_ressource ? 'external_working_ressource' : 'staffing_need';
    return event.technicians.find(x => x[field] === (technician ? technician.pk : external_working_ressource ? external_working_ressource.pk : need.pk));
}

const getProfessionStr = (profession) => {
    return (
        profession.name +
        (profession.hourly_rate.currency
            ? " - " + floatFormat(profession.hourly_rate.amount) + profession.hourly_rate.currency.symbol + "/h"
            : "")
    );
};

const getAbsenceText = absence => {
    return absence.reason ? absence.reason.name : trans.t('Indisponible')
}
const getShortAbsenceText = absence => {
    return absence.reason ? absence.reason.short_name : trans.t('Indis.')
}

const StaffingNeedStore = GenericListStoreFactory();

export {
    ATTENDANCE_EXPORT_URL,
    PUBLICATION_STATUS,
    StaffingNeedStore,
    datetimes_overlap,
    getAbsenceText,
    getDaysWithNonWorkingPeriods,
    getEventOverlapWarning,
    getHolidayRuban,
    getMealFieldFromStartDatetime,
    getParticipationForEvent,
    getProfessionStr,
    getShortAbsenceText,
    getTimeDailySlot,
    loadNonWorkingPeriods,
    roundToNearestXMinutes,
    jsonDurationToTimeInput,
    timeInputToJsonDuration
};
