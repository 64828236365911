const loadScript = (url, onLoad) => {
    let script = document.createElement("script");
    script.onload = onLoad;
    script.src = url;
    document.head.appendChild(script);
};

const triggerMatomoEvent = (event_name, data) => {
    data = data || {};
    for(let val of Object.values(data)) {
        if(typeof val === "boolean") {
            throw new Error(
                "Matomo doesn't handle boolean variable for an event, "
                + "please fix your call to triggerMatomoEvent."
            );
        }
    }

    window._mtm = window._mtm || [];
    window._mtm.push(Object.assign({'event': event_name}, data))
}

export { loadScript, triggerMatomoEvent };
