import React from 'react';

import EmailComposer from './EmailComposer.jsx';
import { AjaxNotification } from 'orfeo_common/react-base.jsx';
import Modal from 'orfeo_common/Modal.jsx';


const EmailComposerModal = props => {
    return (
        <Modal show={true} onHide={props.closeModal} modalCSSClass="modal-lg"
>
            <Modal.Header>{trans.t("Rédaction d'un e-mail")}</Modal.Header>
            <Modal.Body>
                <EmailComposer
                    parent={null} mode='new'
                    attachments={props.attachments}
                    entities={[props.entity] || null}
                    onCancel={props.closeModal}
                    onMailSent={(data) => {
                        if(props.redirection) {
                            window.location.replace(props.redirection);
                        }
                        if (props.onMailSent) {
                            props.onMailSent()
                        }
                        AjaxNotification.show(trans.t("L'envoi du message est en cours."), 3000);
                        props.closeModal(data);
                    }}
                    disabledToField={props.disabledToField}
                    customUrl={props.customUrl}
                    composerInfosUrl={props.composerInfosUrl || '/mails/api/composer/init/'}
                />
            </Modal.Body>
        </Modal>
    );
}

export default EmailComposerModal;
